import { create } from "zustand";

interface Props {
    isOverlayOpen: boolean;
    openOverLay: () => void;
    closeOverLay: () => void;
 }
 
export const useLoadingOverlay = create<Props>((set) => ({
    isOverlayOpen: false,
    openOverLay: () => set({ isOverlayOpen: true }),
    closeOverLay: () => set({ isOverlayOpen: false }),
 }));


const LoadingOverlay = () => {
	const { isOverlayOpen } =  useLoadingOverlay();

	return(
<>
{ isOverlayOpen && <div className="h-screen bg-black/70 w-full top-0 fixed z-[99999] grid place-items-center"> 
{/* <RiLoader4Fill className="h-[5rem] w-[5rem] text-green animate-spin" /> */}
<img src="/logo192.png" className='h-24 animate-bounce' alt="quickleap logo" />
</div>}
</>

    )
};

export default LoadingOverlay;
