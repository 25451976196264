import { IoIosStar } from "react-icons/io";
import { IoIosStarHalf } from "react-icons/io";

const StarRating = ({ rating }: { rating: number }) => {
	// Calculate the number of full stars
	const fullStars = Math.floor(rating);

	// Calculate whether there's a half star
	const hasHalfStar = rating - fullStars >= 0.5;

	// Determine the number of empty stars needed
	const emptyStars = 5 - fullStars - (hasHalfStar ? 1 : 0);

	// Array to store the JSX elements for stars
	const stars = [];

	// Add full stars
	for (let i = 0; i < fullStars; i++) {
		stars.push(<IoIosStar />);
	}

	// Add half star if necessary
	if (hasHalfStar) {
		stars.push(<IoIosStarHalf color="" />);
	}

	// Add empty stars
	for (let i = 0; i < emptyStars; i++) {
		stars.push(<IoIosStar color="#D9D9D9" />);
	}

	return <div className="flex items-center gap-1">{stars}</div>;
};

export default StarRating;
