import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../index";
import {
	fetchUserLoggedInUserAction,
	loginAction,
	logoutUserAction,
	registerIndividualAction,
	registerCollaboratorAction,
} from "./authActions";
import { UserInterface } from "../../types/user";

//Define a type for the slice
export type UserState = {
	isAuthenticated: boolean;
	currentUser: UserInterface | null;
	login: {
		error: string | null;
		loading: boolean;
		success: boolean;
	};
	register: {
		error: string | null;
		loading: boolean;
		success: boolean;
	};
	getUserInfo: {
		error: string | null;
		loading: boolean;
		success: boolean;
	};
	logout: {
		error: string | null;
		loading: boolean;
		success: boolean;
	};
};

//Define the initial state using that type
const initialState: UserState = {
	isAuthenticated: false,
	currentUser: null,
	login: {
		loading: false,
		error: null,
		success: false,
	},
	register: {
		loading: false,
		error: null,
		success: false,
	},
	getUserInfo: {
		loading: false,
		error: null,
		success: false,
	},
	logout: {
		error: null,
		loading: false,
		success: false,
	},
};

const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		resetLogin(state) {
			state.login = initialState.login;
		},
		socialLogin(state, action){
			state.login.loading = false;
			state.login.error = null;
			state.login.success = true;
			state.isAuthenticated = true;
			state.currentUser = action.payload;
		}
	},
	extraReducers: (builder) => {
		//Get logged user
		builder
			.addCase(fetchUserLoggedInUserAction.pending, (state) => {
				state.getUserInfo.loading = true;
			})
			.addCase(
				fetchUserLoggedInUserAction.fulfilled,
				(state, { payload }) => {
					state.getUserInfo.loading = false;
					state.getUserInfo.error = null;
					state.getUserInfo.success = true;
					state.isAuthenticated = true;
					state.currentUser = payload;
				}
			)
			.addCase(
				fetchUserLoggedInUserAction.rejected,
				(state, { payload }) => {
					state.getUserInfo.loading = false;
					state.getUserInfo.error = payload as string;
					state.getUserInfo.success = false;
				}
			);
		//Login user
		builder
			.addCase(loginAction.pending, (state) => {
				state.login.loading = true;
			})
			.addCase(loginAction.fulfilled, (state, { payload }) => {
				state.login.loading = false;
				state.login.error = null;
				state.login.success = true;
				state.isAuthenticated = true;
				state.currentUser = payload;
			})
			.addCase(loginAction.rejected, (state, { payload }) => {
				state.login.loading = false;
				state.login.error = payload as string;
				state.login.success = false;
			});
		//Register user
		builder
			.addCase(registerCollaboratorAction.pending, (state) => {
				state.register.loading = true;
			})
			.addCase(
				registerCollaboratorAction.fulfilled,
				(state, { payload }) => {
					state.register.loading = false;
					state.register.error = null;
					state.register.success = true;
					state.isAuthenticated = true;
					state.currentUser = payload;
				}
			)
			.addCase(registerCollaboratorAction.rejected, (state, { payload }) => {
				state.register.loading = false;
				state.register.error = payload as string;
				state.register.success = false;
			});
		
		//Register individual
		builder
			.addCase(registerIndividualAction.pending, (state) => {
				state.register.loading = true;
			})
			.addCase(
				registerIndividualAction.fulfilled,
				(state, { payload }) => {
					state.register.loading = false;
					state.register.error = null;
					state.register.success = true;
					state.isAuthenticated = true;
					state.currentUser = payload;
				}
			)
			.addCase(registerIndividualAction.rejected, (state, { payload }) => {
				state.register.loading = false;
				state.register.error = payload as string;
				state.register.success = false;
			});

		builder
			.addCase(logoutUserAction.pending, (state) => {
				state.logout.loading = true;
			})
			.addCase(logoutUserAction.fulfilled, (state, { payload }) => {
				state.isAuthenticated = false;
				state.logout.error = null;
				state.logout.loading = false;
				state.logout.success = true;
				state.isAuthenticated = initialState.isAuthenticated
				state.currentUser = initialState.currentUser
			})
			.addCase(logoutUserAction.rejected, (state, { payload }) => {
				state.logout.error = payload as string;
				state.logout.loading = false;
				state.logout.success = false;
			});
	},
});

export const selectAuthUser = (state: RootState) => state.auth;

export default authSlice.reducer;
