import Modal from "../../modal/Modal";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "../../../store/hooks";
import { selectAuthUser } from "../../../store/auth/authReducer";

const toolTipData = [
	{
		imageUrl: "/assets/tool-tip/dashboard.png",
		title: "Welcome to Collaborator Center",
		desc: "Your dashboard gives you insights into the numbers that matter and a comprehensive view of your businesses performance. ",
	},
	{
		imageUrl: "/assets/tool-tip/marketplace.png",
		title: "Market Place",
		desc: "Here you can source and buy everything you need to create good food. The market place has a wide variety of products with details on origination. ",
	},
	{
		imageUrl: "/assets/tool-tip/product_upload.png",
		title: "Product Upload",
		desc: "Here you can upload all the products you produce or have in-stock, we will then review and approve and notify you. ",
	},
	{
		imageUrl: "/assets/tool-tip/product_list.png",
		title: "Product List",
		desc: "Your product listing contains all the products that have been reviewed and approved. You can come back to edit your selling price and the quantities you have left per product here. ",
	},
	{
		imageUrl: "/assets/tool-tip/orders.png",
		title: "Orders",
		desc: "Here you will find all the orders we send you. ",
	},
];

const CollaboratorToolTip = () => {
	const [toolTipStep, setToolTipStep] = useState(0);
	const { pathname } = useLocation();
	const { currentUser } = useAppSelector(selectAuthUser);
	const [showCollaboratorTooltip, setShowCollabaoratorToolTip] = useState(
		localStorage.getItem("showCollaboratorTooltip") &&
		pathname === "/collaborator/dashboard" &&
		currentUser?.user?.collaborator?.collaboratorType !== "field_agent"
		? true
		: false
	);
	
	const closeCollaboratorTooltip = () => {
		localStorage.removeItem("showCollaboratorTooltip");
		setShowCollabaoratorToolTip(false);
	};

	const handleNextClick = () => {
		if (toolTipStep === toolTipData.length - 1) {
			return closeCollaboratorTooltip();
		}
		setToolTipStep(toolTipStep + 1);
	};

	const handlePrevClick = () => {
		if (toolTipStep === 0) return;
		setToolTipStep(toolTipStep - 1);
	};

	return (
		<Modal
			isOpen={!!showCollaboratorTooltip}
			onClose={() => null}
			full={false}
			className="translate relative mx-auto flex-col
  border-0 bg-green flex shadow-lg outline-none focus:outline-none md:h-auto p-4 rounded-lg 
  w-full max-w-[450px] md:p-5 max-h-[80vh] overflow-auto"
		>
			<button className="self-end" onClick={closeCollaboratorTooltip}>
				<svg
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<circle cx="12" cy="12" r="12" fill="white" />
					<path
						d="M8.50684 7.7998L7.7998 8.50684L11.4463 12.1533L7.7998 15.7998L8.50684 16.5068L12.1533 12.8604L15.7998 16.5068L16.5068 15.7998L12.8604 12.1533L16.5068 8.50684L15.7998 7.7998L12.1533 11.4463L8.50684 7.7998Z"
						fill="black"
					/>
				</svg>
			</button>

			{toolTipData.map((tooltip, index) => (
				<div
					className={`${
						index === toolTipStep ? "flex" : "hidden"
					} flex-col gap-3 text-white mt-3`}
					key={index}
				>
					<img
						src={tooltip.imageUrl}
						className="w-full object-contain"
						alt=""
					/>
					<h2 className="text-xl font-medium mt-5">{tooltip.title}</h2>
					<p className="text-sm">{tooltip.desc}</p>
					<section className="flex justify-between items-center mt-5">
						<div className="flex gap-1 items-center">
							{toolTipData.map((el, index) => (
								<button
									key={index}
									className={`h-2 w-2 rounded-full ${
										index === toolTipStep
											? "bg-white"
											: "bg-[#006C18]"
									}`}
									onClick={() => setToolTipStep(index)}
								></button>
							))}
						</div>

						<div>
							{toolTipStep !== 0 && (
								<button className="p-3 px-8" onClick={handlePrevClick}>
									Previous
								</button>
							)}
							<button
								className="bg-[#006C18] p-3 px-8 rounded-md"
								onClick={handleNextClick}
							>
								{index === toolTipData.length - 1 ? "Close" : "Next"}
							</button>
						</div>
					</section>
				</div>
			))}
		</Modal>
	);
};

export default CollaboratorToolTip;
