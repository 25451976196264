import GrowforLifeToolTip from "../organisms/GrowforLife/GrowforLifeToolTip";
import LoadingOverlay from "../atoms/LoadingOverlay";
import AuthInfoModal from "./AuthInfoModal";
import ContactUsModal from "./ContactUsModal";
import DeleteModal from "./DeleteModal";
import ShareModal from "./ShareModal";
import SuccessItemModal from "./SuccessItemModal";
import UploadProductSuccessModal from "./UploadProductSuccessModal";
import CollaboratorToolTip from "../organisms/Collaborator/CollaboratorToolTip";

const AllModals = () => {
	return (
		<div>
			<DeleteModal />
			<SuccessItemModal />
			<AuthInfoModal />
			<LoadingOverlay />
			<GrowforLifeToolTip />
			<UploadProductSuccessModal />
			<ContactUsModal />
			<ShareModal />
			<CollaboratorToolTip />
		</div>
	);
};

export default AllModals;
