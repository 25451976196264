import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import {
	fecthUserProfile,
	getNotifications,
	handleCollaboratorProfileUpdate,
	handleProfileBankAdd,
	handleProfileBankUpdate,
	handleUserProfileUpdate,
} from "../services/profileService";


export const clearUserProfileAction = createAction(
	"profile/clearUserProfile"
  );

export const getUserProfileAction = createAsyncThunk(
	"profile/fetch-user-profile",
	async (_, { rejectWithValue }) => {
		try {
			const response = await fecthUserProfile();
			return response.data.profile;
		} catch (error: any) {
			return rejectWithValue(error?.response.data.message);
		}
	}
);

export const fetchNotifications = createAsyncThunk(
	"collaborator/notifications",
	async (_, { rejectWithValue }) => {
		try {
			const response = await getNotifications();
			return response.data;
		} catch (error: any) {
			return rejectWithValue(error.response.data.message);
		}
	}
);

export const updateUserProfileAction = createAsyncThunk<any, any>(
	"profile/update-user-profile",
	async (data, { rejectWithValue }) => {
		try {
			const response = await handleUserProfileUpdate(data);

			return response.data;
		} catch (error: any) {
			return rejectWithValue(error.response.data.message);
		}
	}
);

export const updateCollaboratorProfileAction = createAsyncThunk<any, any>(
	"collaborator/update-collaborator-profile",
	async (data, { rejectWithValue }) => {
		try {
			const response = await handleCollaboratorProfileUpdate(data);

			return response.data;
		} catch (error: any) {
			return rejectWithValue(error.response.data.message);
		}
	}
);

export const addBankAccountInfoAction = createAsyncThunk<any, any>(
	"profile/add-bank-account-info",
	async (data, { rejectWithValue }) => {
		try {
			const response = await handleProfileBankAdd(data);

			return response.data;
		} catch (error: any) {
			return rejectWithValue(error.response.data.message);
		}
	}
);

export const updateBankAccountInfoAction = createAsyncThunk<any, any>(
	"profile/update-bank-account-info",
	async (data, { rejectWithValue }) => {
		try {
			const response = await handleProfileBankUpdate(data);

			return response.data;
		} catch (error: any) {
			return rejectWithValue(error.response.data.message);
		}
	}
);
