import React, { useState } from "react";
import Modal from "./Modal";
import {
	EmailIcon,
	EmailShareButton,
	FacebookIcon,
	FacebookShareButton,
	LinkedinIcon,
	LinkedinShareButton,
	TwitterIcon,
	TwitterShareButton,
	WhatsappIcon,
	WhatsappShareButton,
} from "react-share";
import useShareModal from "../../hooks/modal-hooks/useShareModal";

const ShareModal = () => {
	const { shareSlug, closeShare } = useShareModal();

	const [copied, setCopied] = useState(false);
	const baseUrl =
		"https://quickleap-web-staging.onrender.com/grow-for-life/recipe";
	if (!shareSlug) {
		return <></>;
	}

	return (
		<Modal
			isOpen={shareSlug ? true : false}
			onClose={() => {
				setCopied(false);
				closeShare();
			}}
			full={false}
			className="translate relative mx-auto flex-col border-0 bg-white inline-flex shadow-lg outline-none focus:outline-none md:h-auto p-5 md:p-7 rounded-lg md:w-[550px] max-h-[80vh] overflow-scroll"
		>
			<p>You can share via</p>
			<div className="flex items-center md:justify-center gap-4 my-6">
				<div className="flex items-center flex-col gap-1 md:gap-3">
					<FacebookShareButton
						url={`${baseUrl}/${shareSlug}`}
						className="h-14 md:h-16 w-14 md:w-16 rounded-none"
					>
						<FacebookIcon className="rounded-full h-14 md:h-16 w-14 md:w-16" />
					</FacebookShareButton>
					<p className="capitalize text-sm hidden md:block">facebook</p>
				</div>
				<div className="flex items-center flex-col gap-3">
					<TwitterShareButton url={`${baseUrl}/${shareSlug}`}>
						<TwitterIcon className="rounded-full h-14 md:h-16 w-14 md:w-16" />
					</TwitterShareButton>
					<p className="capitalize text-sm hidden md:block">twitter</p>
				</div>
				<div className="flex items-center flex-col gap-3">
					<LinkedinShareButton url={`${baseUrl}/${shareSlug}`}>
						<LinkedinIcon className="rounded-full h-14 md:h-16 w-14 md:w-16" />
					</LinkedinShareButton>
					<p className="capitalize text-sm hidden md:block">linkedin</p>
				</div>
				<div className="flex items-center flex-col gap-3">
					<WhatsappShareButton url={`${baseUrl}/${shareSlug}`}>
						<WhatsappIcon className="rounded-full h-14 md:h-16 w-14 md:w-16" />
					</WhatsappShareButton>
					<p className="capitalize text-sm hidden md:block">whatsapp</p>
				</div>

				<div className="flex items-center flex-col gap-3">
					<EmailShareButton url={`${baseUrl}/${shareSlug}`}>
						<EmailIcon className="rounded-full h-14 md:h-16 w-14 md:w-16" />
					</EmailShareButton>
					<p className="capitalize text-sm hidden md:block">email</p>
				</div>
			</div>
			<p className="mb-4 text-sm">Or copy the link below</p>
			<div className="flex items-center rounded-b">
				<div
					className="w-full flex items-center justify-between bg-[#F9F9F9] border-solid
       border-[#E8E8E8] rounded-2xl px-4 py-2 text-sm md:text-base gap-1"
				>
					<p className="ellipsis-one-line">{`${baseUrl}/${shareSlug}`}</p>
					<button
						className="capitalize text-white bg-[#0635DE] py-1 px-3 rounded-2xl"
						type="button"
						onClick={() => {
							setCopied(true);
							navigator.clipboard.writeText(`${baseUrl}/${shareSlug}`);
						}}
					>
						{copied ? "Copied" : "Copy"}
					</button>
				</div>
			</div>
		</Modal>
	);
};

export default ShareModal;
