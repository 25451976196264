import axios from "../../axios.config";

export const getAllProducts = async (data: { limit: number; page: number; search?:string }) => {
	const { limit, page, search } = data;
	let response
	if(search){
		response = await axios.get(`/grow-for-life/products?limit=${limit}&page=${page}&search=${search}`);
	}
	else{
		response = await axios.get(`/grow-for-life/products?limit=${limit}&page=${page}`);
	}
	
	if (response.status === 200 || response.status === 201) {
		return response.data;
	}

	throw new Error(response.data.message);
};

export const getProductDetails = async ( slug: string ) => {
	const response = await axios.get(`/grow-for-life/products/${slug}`);
	if (response.status === 200 || response.status === 201) {
		return response.data;
	}

	throw new Error(response.data.message);
};

export const getShoppingListProducts = async () => {
	const response = await axios.get(`/grow-for-life/cart`);
	if (response.status === 200 || response.status === 201) {	
		return response.data;
	}

	throw new Error(response.data.message);
};

export const addProductToShoppingList = async (data:any) => {
	const response = await axios.post(`/grow-for-life/cart/add-item`, data);
	if (response.status === 200 || response.status === 201) {		
		return response.data;
	}

	throw new Error(response.data.message);
};

export const updateProductQuantityInShoppingList = async (data: any) => {
	const response = await axios.post(`/grow-for-life/cart/update-item`, data);
	if (response.status === 200 || response.status === 201) {		
		return response.data;
	}

	throw new Error(response.data.message);
};


export const deleteProductFromShoppingList = async (data: any) => {
	const response = await axios.post(`/grow-for-life/cart/delete-item`, data);
	if (response.status === 200 || response.status === 201) {		
		return response.data;
	}
	throw new Error(response.data.message);
};

export const cancelGrowforLifeShoppingListSubscription = async (cartId: string) => {
	const response = await axios.post(`/grow-for-life/cart/${cartId}/cancel-subscription`);
	if (response.status === 200 || response.status === 201) {		
		return response.data;
	}
	throw new Error(response.data.message);
};

export const clearShoppingList = async (cartId:string) => {
	const response = await axios.post(`grow-for-life/cart/${cartId}/clear`);
	if (response.status === 200 || response.status === 201) {		
		return response.data;
	}

	throw new Error(response.data.message);
};


export const getAllCategoryProducts = async (data: { limit: number; page: number; categorySlug:string }) => {
	const { limit, page, categorySlug } = data;
	const response = await axios.get(`/grow-for-life/products/categories/${categorySlug}/all?limit=${limit}&page=${page}`);
	if (response.status === 200 || response.status === 201) {

		return response.data;
	}

	throw new Error(response.data.message);
};

export const getAllSubCategoryProducts = async (data: { limit: number; page: number; subCategorySlug:string }) => {
	const { limit, page, subCategorySlug } = data;
	const response = await axios.get(`/grow-for-life/products/sub-categories/${subCategorySlug}/all?limit=${limit}&page=${page}`);
	if (response.status === 200 || response.status === 201) {

		return response.data;
	}

	throw new Error(response.data.message);
};

// export const getAllSubCategoryProducts = async (subCategorySlug:string) => {
// 	const response = await axios(`/grow-for-life/products/sub-categories/${subCategorySlug}/all`);
// 	if (response.status === 200 || response.status === 201) {
// 		return response.data;
// 	}

// 	throw new Error(response.data.message);
// };

export const getProductCategories = async () => {
	const response = await axios(`/grow-for-life/products/categories/all`);
	if (response.status === 200 || response.status === 201) {
		return response.data;
	}

	throw new Error(response.data.message);
};


export const getSuggestedProducts = async (data: { limit: number; page: number}) => {
	const { limit, page } = data;
	const response = await axios(`/grow-for-life/products/suggested/all?limit=${limit}&page=${page}`);
	if (response.status === 200 || response.status === 201) {
		return response.data;
	}

	throw new Error(response.data.message);
};


export const getBestSellingProducts = async (data: { limit: number; page: number}) => {
	const { limit, page } = data;
	const response = await axios(`/grow-for-life/products/best-selling/all?limit=${limit}&page=${page}`);
	if (response.status === 200 || response.status === 201) {
		return response.data;
	}

	throw new Error(response.data.message);
};

export const getUserFavouriteProducts = async (data:{ limit: number; page: number}) => {
	const { limit, page } = data;
	const response = await axios(`grow-for-life/products/favourites/all?limit=${limit}&page=${page}`);
	if (response.status === 200 || response.status === 201) {
		return response.data;
	}

	throw new Error(response.data.message);
};

export const addFavouriteGrowforLifeProduct = async (productSlug:string) => {
	const response = await axios.post(`/grow-for-life/products/${productSlug}/favourites/add`);
	if (response.status === 200 || response.status === 201) {
		return response.data;
	}

	throw new Error(response.data.message);
};

export const removeFavouriteGrowforLifeProduct = async (productSlug:string) => {
	const response = await axios.put(`/grow-for-life/products/${productSlug}/favourites/remove`);
	if (response.status === 200 || response.status === 201) {
		return response.data;
	}

	throw new Error(response.data.message);
};


export const createGrowforLifeOrder = async (data:any) => {
	const response = await axios.post(`/grow-for-life/order`, data);
	if (response.status === 200 || response.status === 201) {
		return response.data;
	}

	throw new Error(response.data.message);
};

export const initializeGrowforLifeOrderPayment = async (orderId:string) => {
	const response = await axios.post(`/grow-for-life/order/${orderId}/initialize-payment`)
	if (response.status === 200 || response.status === 201) {
		return response.data;
	}

	throw new Error(response.data.message);
};


export const verifyGrowforLifeOrderPayment = async (ref:string) => {
	const response = await axios.post(`/grow-for-life/order/${ref}/verify-payment`)
	if (response.status === 200 || response.status === 201) {
		return response.data;
	}

	throw new Error(response.data.message);
};

export const getGrowforLifeShoppingListSubscriptions = async () => {
	const response = await axios.get(`/grow-for-life/cart/subscriptions`)
	if (response.status === 200 || response.status === 201) {
		return response.data;
	}

	throw new Error(response.data.message);
};


export const getGrowforLifePaymentHistory = async (data:{ limit: number; page: number }) => {
	const {limit, page} = data
	const response = await axios.get(`/grow-for-life/order/history?limit=${limit}&page=${page}&isTransaction=true`)
	if (response.status === 200 || response.status === 201) {
		return response.data;
	}

	throw new Error(response.data.message);
};



export const getGrowforLifeProductReviews = async (productSlug:string) => {
	const response = await axios.get(`/grow-for-life/products/${productSlug}/reviews`)
	if (response.status === 200 || response.status === 201) {
		return response.data;
	}

	throw new Error(response.data.message);
};


export const addGrowforLifeProductReview = async (data:{
	rating: number,
	message:string
}, productSlug:string) => {
	const response = await axios.post(`/grow-for-life/products/${productSlug}/reviews`, data) 
	if (response.status === 200 || response.status === 201) {
		return response.data;
	}

	throw new Error(response.data.message);
};

export const getUserBudgetAgeRange = async ()=>{
const response = await axios.get(`/grow-for-life/budget`)
	if (response.status === 200 || response.status === 201) {
		return response.data;
	}

	throw new Error(response.data.message);
}

export const createUserBudgetAgeRange = async (data:{
	amount: number,
	minAge: number,
	maxAge: number,
})=> {
	const response = await axios.post(`/grow-for-life/budget`, data)
	if (response.status === 200 || response.status === 201) {
		return response.data;
	}

	throw new Error(response.data.message);
}


export const updateUserBudgetAgeRange = async (data:{
	amount: number,
	minAge: number,
	maxAge: number,
} | null)=> {
	const response = await axios.put(`/grow-for-life/budget`, data)
	if (response.status === 200 || response.status === 201) {
		return response.data;
	}

	throw new Error(response.data.message);
}

export const checkDeliveryLocation = async (data:{
	state: string,
	city: string,
})=> {
	const {state, city} = data
	const response = await axios.get(`/grow-for-life/check-delivery-location?state=${state}&city=${city}`)
	if (response.status === 200 || response.status === 201) {
		return response.data;
	}

	throw new Error(response.data.message);
}

