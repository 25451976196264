import { useState } from "react";
import useBudgetAgeRangeModal from "../../hooks/modal-hooks/useBudgetAgeRangeModal";
import Modal from "./Modal";
import { IoCloseOutline } from "react-icons/io5";
import { Form, Formik } from "formik";
import { useAppSelector } from "../../store/hooks";
import { selectGrowForLife } from "../../store/grow-for-life/growForLifeReducer";
import * as yup from "yup";
import FormSelectDropDown from "../molecules/FormSelectDropDown";
import {
	createUserBudgetAgeRange,
	updateUserBudgetAgeRange,
} from "../../store/services/growForLifeServices";
import { useLoadingOverlay } from "../atoms/LoadingOverlay";
import { toast } from "react-toastify";
import FormMoneyInput from "../molecules/FormMoneyInput";
import { MoneyIcon } from "../Icons";

const budgetSchema = yup.object({
	amount: yup.string().required("Amount is required"),
});

const ageRangeSchema = yup.object({
	ageRange: yup.string().required("Select an age range"),
});

const BudgetAgeRangeModal = ({ showAgeRange }: { showAgeRange: boolean }) => {
	const [budget, setBudget] = useState<number | null>(null);
	const { isOpen, onClose, modalType, setModalType } =
		useBudgetAgeRangeModal();
	const { openOverLay, closeOverLay } = useLoadingOverlay();
	const { budgetAgeRange } = useAppSelector(selectGrowForLife);

	const BudgetForm = () => {
		return (
			<div className="flex flex-col gap-2">
				<section className="flex gap-2 mt-5">
					<MoneyIcon />
					<div>
						<h2 className="font-semibold text-lg">Budget</h2>
						<p className="text-[#6C6C6C]">
							Enter an amount you want to spend.
						</p>
					</div>
				</section>
				<Formik
					initialValues={{
						amount: budgetAgeRange?.amount ?? "",
					}}
					validationSchema={budgetSchema}
					onSubmit={(values) => {
						const numberWithOutCommas = values?.amount?.replace(/,/g, "");
						submitBudget({
							amount: numberWithOutCommas,
						});
						setBudget(parseInt(numberWithOutCommas));
					}}
				>
					{({ values, setFieldValue, isSubmitting }) => {
						return (
							<Form className="mt-4">
								<FormMoneyInput
									id="amount"
									name="amount"
									placeholder="450,000"
									label="Enter a particular amount"
									onChange={(e) => {
										setFieldValue("amount", e.target.value);
									}}
								/>
								<div className="grid grid-cols-2 gap-2 font-semibold mt-2">
									<button
										className="border border-green text-green p-2 py-3 rounded-md"
										onClick={closeModal}
									>
										No, Cancel
									</button>
									<button
										className="bg-green text-white p-2 rounded-md py-3"
										type="submit"
									>
										Proceed
									</button>
								</div>
							</Form>
						);
					}}
				</Formik>
			</div>
		);
	};

	const AgeRange = () => {
		return (
			<div className="flex flex-col gap-2">
				<div>
					<h2 className="font-semibold text-lg">Age range</h2>
					<p className="text-[#6C6C6C]">
						Tell us the age range of people in your household, this will
						enable us suggest products with the nutrition that fits.
					</p>
				</div>
				<Formik
					initialValues={{
						ageRange: budgetAgeRange
							? `${budgetAgeRange.minAge}-${budgetAgeRange.maxAge}`
							: "",
					}}
					validationSchema={ageRangeSchema}
					onSubmit={(values) => {
						submitAgeRange(values);
					}}
				>
					{({ values, setFieldValue, isSubmitting }) => {
						return (
							<Form className="mt-4">
								<FormSelectDropDown
									name="ageRange"
									id="ageRange"
									onChange={(value: any) => {
										setFieldValue("ageRange", value);
									}}
									options={ageRanges}
									label="Select a particular age range"
								/>

								<div className="grid grid-cols-2 gap-2 font-semibold mt-2">
									<button
										className="border border-green text-green p-2 py-3 rounded-md"
										onClick={closeModal}
									>
										No, Cancel
									</button>
									<button
										className="bg-green text-white p-2 rounded-md py-3"
										type="submit"
									>
										Save
									</button>
								</div>
							</Form>
						);
					}}
				</Formik>
			</div>
		);
	};

	const submitBudget = async (values: any) => {
		if (!budgetAgeRange || budgetAgeRange.maxAge === 0) {
			openOverLay();
			try {
				 await createUserBudgetAgeRange({
					amount: parseInt(values.amount),
					minAge: 0,
					maxAge: 0,
				});
				toast.success("Budget created");
				setModalType("AGE_RANGE");
			} catch (err: any) {
				console.log(err);
				toast.error(err?.response.data.message[0]);
			} finally {
				closeOverLay();
			}
		} else {
			openOverLay();
			try {
				await updateUserBudgetAgeRange({
					...budgetAgeRange,
					amount: parseInt(values.amount),
				});
				toast.success("Budget updated");
				showAgeRange ? setModalType("AGE_RANGE") : onClose();
			} catch (err: any) {
				console.log(err);
				toast.error(err?.response.data.message[0]);
			} finally {
				closeOverLay();
			}
		}
	};

	const closeModal = () => {
		setModalType("BUDGET");
		setBudget(null);
		onClose();
	};

	const submitAgeRange = async (values: any) => {
		const [start, end] = values.ageRange.split("-").map(Number);
		openOverLay();
		try {
			const res = await updateUserBudgetAgeRange({
				amount: budget
					? budget
					: budgetAgeRange
					? parseInt(budgetAgeRange?.amount)
					: 0,
				minAge: start,
				maxAge: end,
			});
			console.log(res);
			toast.success("Age range updated");
			setModalType("BUDGET");
			onClose();
		} catch (err: any) {
			console.log(err);
			toast.error(err?.response.data.message[0]);
		} finally {
			closeOverLay();
		}
	};

	const ageRanges = [
		{
			value: "0-25",
			label: "0-25 years old",
		},
		{
			value: "0-30",
			label: "0-30 years old",
		},
		{
			value: "0-35",
			label: "0-35 years old",
		},
		{
			value: "0-45",
			label: "0-45 years old",
		},
		{
			value: "3-50",
			label: "3-50 years old",
		},
		{
			value: "10-45",
			label: "10-45 years old",
		},
		{
			value: "15-65",
			label: "15-65 years old",
		},
		{
			value: "18-85",
			label: "18-85 years old",
		},
		{
			value: "20-100",
			label: "20-100 years old",
		},
		{
			value: "15-125",
			label: "15-125 years old",
		},
	];

	if (!isOpen) {
		return <></>;
	}

	return (
		<Modal
			isOpen={isOpen}
			onClose={closeModal}
			full={false}
			className="translate relative mx-auto flex-col
border-0 bg-white inline-flex shadow-lg outline-none focus:outline-none md:h-auto p-8 rounded-lg w-[400px] md:w-[500px]"
		>
			<button
				className="absolute top-4 right-4 h-8 w-8 rounded-full bg-[#E5E5E5]"
				onClick={closeModal}
			>
				<IoCloseOutline className="h-[80%] w-[80%] m-auto" />
			</button>

			{modalType === "BUDGET" && <BudgetForm />}
			{modalType === "AGE_RANGE" && <AgeRange />}
		</Modal>
	);
};

export default BudgetAgeRangeModal;
