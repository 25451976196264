import React, {
	FC,
	useMemo,
	useState,
} from "react";
import { ErrorMessage, Field } from "formik";
import { IoIosArrowDown } from "react-icons/io";
import { useOutsideClick } from "../../helpers/clickOutside";
import { cn } from "../lib/utils";

interface IProps {
	id: string;
	name: string;
	options: {
		label: string;
		value: string;
	}[];
	label?: string;
	onChange: (event: any) => void;
	placeholder?: string;
	className?: string;
}

const FormSearchDropDown: FC<IProps> = ({
	id,
	name,
	options,
	label,
	onChange,
	placeholder,
	className,
}) => {
	const [isOpen, setIsOpen] = useState(false);

	const dropdownRef = useOutsideClick(() => {
		setIsOpen(false);
	});

	const [searchTerm, setSearchTerm] = useState("");

	const searchOptions = useMemo(() => {
		if (searchTerm === "") {
			return options;
		}

		return options.filter((option) =>
			option.label.toLowerCase().includes(searchTerm.toLowerCase())
		);
	}, [searchTerm, options]);

	const [inputIsFocused, setInputIsFocused] = useState(false);

	return (
		<div className="w-full flex flex-col items-start">
			{label && (
				<label
					htmlFor={name}
					className="font-medium text-[#111111] text-[.9rem]"
				>
					{label}
				</label>
			)}
			<Field id={id} name={name}>
				{({ field, form, meta }: { field: any; form: any; meta: any }) => {
					return (
						<div
							ref={dropdownRef}
							className={cn(
								`${
									isOpen ? "custom-select active" : "custom-select"
								} mt-1 w-full`
							)}
						>
							<button
								type="button"
								aria-haspopup="listbox"
								aria-controls="select-dropdown"
								{...field}
								className={cn(
									`select-button rounded-md min-h-[2.8rem] ${
										meta.touched &&
										meta.error &&
										!inputIsFocused &&
										searchOptions.length > 0
											? "ring-2 ring-red-400 focus:ring-red-400"
											: "border-[#9F9F9F] hover:border-green focus:border-green"
									}`,
									className
								)}
								onClick={() => {
									setIsOpen(true);
								}}
							>
								{isOpen ? (
									<input
										type="text"
										onChange={(e) => setSearchTerm(e.target.value)}
										value={searchTerm}
										className={cn(
											`w-full text-sm outline-none border-none`
										)}
										placeholder="Search..."
										id="search"
										name="searchInput"
										onFocus={() => setInputIsFocused(true)}
										onBlur={() => setInputIsFocused(false)}
										autoFocus
									/>
								) : (
									<>
										{field?.value ? (
											<p className="text-[#6C6C6C] text-sm font-medium text-start capitalize ellipsis-one-line">
												{
													options.find(
														(option) =>
															option.value === field.value
													)?.label
												}
											</p>
										) : (
											<p className="font-normal text-sm text-start text-[#A1A7AD] ellipsis-one-line">
												{placeholder ?? ""}
											</p>
										)}
									</>
								)}

								<span className="arrow">
									<IoIosArrowDown />
								</span>
							</button>
							<ul
								role="listbox"
								className="select-dropdown rounded-md gap-y-1"
								id="select-dropdown"
							>
								{searchOptions?.map(
									(item: { value: string; label: string }) => (
										<li
											key={item.value}
											onClick={() => {
												onChange(item.value);
												setIsOpen(false);
												setSearchTerm("");
											}}
										>
											<input
												type="radio"
												id={item.label}
												name={name}
											/>
											<label htmlFor={item.label}>
												{item.label}
											</label>
										</li>
									)
								)}
								{searchOptions.length === 0 && (
									<li className="text-center text-sm text-[#A1A7AD]">
										No options found.
									</li>
								)}
							</ul>
						</div>
					);
				}}
			</Field>
			<span className="text-red-600 text-xs font-medium w-full h-2 my-1">
				{searchOptions.length > 0 && <ErrorMessage name={name} />}
			</span>
		</div>
	);
};

export default FormSearchDropDown;
