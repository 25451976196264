import { create } from "zustand";

interface ModalInterface {
	isOpen: boolean;
	onOpen: () => void;
	onClose: () => void;
	modalType: 'BUDGET' | '' | 'AGE_RANGE'
	setModalType: (arg: 'BUDGET' | '' | 'AGE_RANGE' ) => void
}

const useBudgetAgeRangeModal = create<ModalInterface>((set) => ({
	isOpen: false,
	modalType: 'BUDGET',
	onOpen: () => set({ isOpen: true }),
	setModalType: (arg) => set({ modalType: arg }),
	onClose: () => set({ isOpen: false, modalType: 'BUDGET' }),
}));

export default useBudgetAgeRangeModal;
