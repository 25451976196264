import React, { useMemo } from "react";
import * as yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AuthLoginLayout from "../components/organisms/layouts/AuthLoginLayout";
import LoginHeader from "../components/molecules/LoginHeader";
import { Formik, Form, Field } from "formik";
import PasswordInput from "../components/molecules/PasswordInput";
import Button from "../components/molecules/Button";
import FormInput from "../components/molecules/FormInput";
import { RegisterCollaboratorProps } from "../store/services/userServices";
import { registerCollaboratorAction } from "../store/auth/authActions";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { selectAuthUser } from "../store/auth/authReducer";
import FormSelectDropDown from "../components/molecules/FormSelectDropDown";
import FormSearchDropDown from "../components/molecules/FormSearchDropDown";
import useCountryList from "../hooks/useCountryList";

const businessUserSchema = yup.object({
	full_name: yup.string().required("Full name is required"),

	password: yup
		.string()
		.required("Password is required")
		.min(8, "Password must be at least 8 characters long")
		.matches(/[A-Z]/, "Password must contain at least one uppercase letter")
		.matches(/[0-9]/, "Password must contain at least one number")
		.matches(
			/[!@#$%^&*()\-_=+{};:,<.>]/,
			"Password must contain at least one special character"
		),
	company_name: yup.string().required("Company name is required"),
	company_email: yup
		.string()
		.email("Enter a valid email")
		.required("Company email is required"),
	collaborator_account: yup
		.string()
		.required("Collaborator account is required"),
	address: yup.string().required("Company address is required"),
	country: yup.string().required("Country is required"),
	number_code: yup.string().required("Select code"),
	phone_number: yup.string().required("Phone number is required"),
	state: yup.string().required("State is required"),
	city: yup.string().required("City is required"),
	accepted_terms: yup.boolean().required("Accepted term is required"),
});

type BusinessUser = yup.InferType<typeof businessUserSchema>;

const RegisterCollaboratorScreen = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const { register } = useAppSelector(selectAuthUser);
	const [acceptedTerms, setAcceptedTerms] = React.useState<boolean>(true);

	const {
		countries,
		setSelectedCountry,
		countryStates,
		setSelectedState,
		stateCities,
	} = useCountryList();

	const collaboratorOptions = [
		{ label: "Farmer", value: "farmer" },
		{ label: "Trader", value: "trader" },
		{ label: "Chef", value: "chef" },
		{ label: "Restaurant", value: "restaurant" },
		{ label: "Processor", value: "processor" },
		{ label: "Field-Agent", value: "field_agent" },
	];

	const businessUserValues: BusinessUser = {
		// individual_email: "",
		company_email: "",
		full_name: "",
		company_name: "",
		address: "",
		collaborator_account: "",
		number_code: "",
		phone_number: "",
		city: "",
		state: "",
		password: "",
		country: "",
		accepted_terms: false,
	};

	const filteredCountries = useMemo(() => {
		if (countries?.length !== 0) {
			const newArray: any[] = [];

			countries.forEach((country) => {
				if (country.label === "Nigeria") {
					newArray.push(country);
				}
			});

			return newArray;
		}
		return [];
	}, [countries]);

	const handleCollaboratorSubmit = async (values: BusinessUser) => {
		const {
			accepted_terms,
			address,
			city,
			collaborator_account,
			company_email,
			company_name,
			country,
			full_name,
			number_code,
			password,
			phone_number,
			state,
		} = values;

		if (!accepted_terms) {
			toast.error("Accept terms & conditions.");
			setAcceptedTerms(false);

			return;
		}

		if (
			!collaborator_account ||
			!company_email ||
			!company_name ||
			!phone_number ||
			!state ||
			!country ||
			!city ||
			!number_code ||
			!address
		) {
			toast.error("All fields are required");
			setAcceptedTerms(false);

			return;
		}

		const data: RegisterCollaboratorProps = {
			fullName: full_name,
			individualEmail: company_email,
			password,
			collaboratorName: company_name,
			collaboratorEmail: company_email,
			collaboratorCountryCode: number_code,
			collaboratorPhoneNumber: phone_number,
			collaboratorType: collaborator_account,
			address,
			country,
			state,
			city,
		};

		try {
			const {
				meta: { requestStatus },
				payload,
			} = await dispatch(registerCollaboratorAction(data));

			if (requestStatus === "rejected") {
				toast.error(payload?.message);
				return;
			}
			localStorage.setItem("showCollaboratorTooltip", JSON.stringify(true));
			toast.success(
				"Succesfully created an account. Log in for better experience."
			);

			navigate("/login");
		} catch (error) {
			//@ts-ignore
			toast.error(error?.response?.data?.message);
		}
	};

	return (
		<AuthLoginLayout>
			<div className="p-8 py-10 rounded-md w-full h-full overflow-scroll bg-white max-w-[600px] mx-auto">
				{/*Company Logo*/}
				<div className="w-full h-16">
					<Link to="/collaborator">
						<img
							src={"/assets/images/logo.png"}
							alt="logo"
							className="w-full h-full object-contain"
						/>
					</Link>
				</div>

				<div
					className="bg-[#F2F1F1] mx-auto w-full max-w-[300px] py-[10px] my-3 cursor-pointer rounded-lg flex items-center justify-center"
					onClick={() => navigate("/register")}
				>
					<h3 className="text-blakc text-base leading-6 font-normal">
						Sign up as an individual{" "}
						<strong className="font-semibold text-xs leading-[18px] text-green">
							change
						</strong>
					</h3>
				</div>

				<div className="w-full">
					<LoginHeader
						header="Create your account"
						subHeader="Sign up with us today"
					/>

					<Formik
						initialValues={businessUserValues}
						onSubmit={async (
							values: BusinessUser,
							{
								resetForm,
								setSubmitting,
							}: { resetForm: any; setSubmitting: any }
						) => {
							setSubmitting(false);
						}}
						validationSchema={businessUserSchema}
					>
						{({ setFieldValue, errors, touched, values }) => {
							return (
								<Form
									onSubmit={async (e) => {
										e.preventDefault();
										await handleCollaboratorSubmit(values);
									}}
									className="mt-8 flex flex-col gap-2"
								>
									<FormInput
										id="full_name"
										name="full_name"
										placeholder="Full name"
										label="Full name"
										onChange={(e) => {
											setFieldValue("full_name", e.target.value);
										}}
									/>
									{/* <FormInput
                    id="individual_email"
                    name="individual_email"
                    placeholder="Individual Email address"
                    label="Individual Email Address"
                    onChange={(e) => {
                      setFieldValue("individual_email", e.target.value);
                    }}
                  /> */}
									<FormInput
										id="company_email"
										name="company_email"
										placeholder="Company Email address"
										label="Company Email Address"
										onChange={(e) => {
											setFieldValue("company_email", e.target.value);
										}}
									/>
									<FormSelectDropDown
										id="collaborator_account"
										name="collaborator_account"
										label="Collaborator Account"
										placeholder="Collaborator Account"
										options={collaboratorOptions}
										onChange={(value: any) => {
											setFieldValue("collaborator_account", value);
										}}
									/>

									<FormInput
										id="company_name"
										name="company_name"
										placeholder="Company name"
										label="Company Name"
										onChange={(e) => {
											setFieldValue("company_name", e.target.value);
										}}
									/>
									<FormInput
										id="address"
										name="address"
										placeholder="Company address"
										label="Company Address"
										onChange={(e) => {
											setFieldValue("address", e.target.value);
										}}
									/>

									<div className="flex w-full grid-cols-[30%,1fr] gap-4">
										<div>
											<FormSelectDropDown
												name="number_code"
												id="number_code"
												onChange={(value) => {
													setFieldValue("number_code", value);
												}}
												options={[{ value: "234", label: "+234" }]}
												label="Phone code"
												placeholder="Code"
											/>
										</div>
										<div className="w-full">
											<FormInput
												id="phone_number"
												name="phone_number"
												placeholder="Phone Number (08157253282)"
												label="Phone Number"
												onChange={(e) => {
													setFieldValue(
														"phone_number",
														e.target.value
													);
												}}
											/>
										</div>
									</div>

									<FormSearchDropDown
										name="country"
										id="country"
										onChange={(value) => {
											setSelectedCountry(
												countries.find(
													(country) => country.value === value
												)
											);
											setFieldValue("country", value);
											setFieldValue("state", "");
										}}
										options={filteredCountries}
										label="Country"
										placeholder="Select country"
									/>

									<div className="flex flex-col w-full lg:flex-row gap-2 gap-x-4">
										<FormSearchDropDown
											name="state"
											id="state"
											onChange={(value) => {
												setSelectedState(
													countryStates.find(
														(state: { value: string }) =>
															state.value === value
													)
												);
												setFieldValue("state", value);
												setFieldValue("city", "");
											}}
											options={countryStates}
											label="State"
											placeholder="Select state"
										/>

										<FormSearchDropDown
											name="city"
											id="city"
											onChange={(value) => {
												setFieldValue("city", value);
											}}
											options={stateCities}
											label="City"
											placeholder="Select city"
										/>
									</div>

                  <PasswordInput
                    id="password"
                    name="password"
                    placeholder="Password"
                    label="Password"
                    autoCompletePassword={true}
                  />

                  <p className="text-sm text-[#6c6c6c]">
                      Read our Service Level Agreement (SLA) {" "}
                      <Link
                        to="/legal?currentTab=sla"
                        target="_blank"
                        className="font-medium  text-sm text-green"
                      >
                        here
                      </Link>
                    </p>

                  <label className="w-full flex items-center cursor-pointer mt-1 mb-4">
                    <Field
                      type="checkbox"
                      name="accepted_terms" 
                      className="w-4 h-4"
                      id="accepted_terms"
                      onClick={() => setAcceptedTerms(!acceptedTerms)}
                    />
                    <p className="text-sm text-[#6c6c6c] ml-3">
                      By signing up you agree to the{" "}
                      <Link
                        to="/legal?currentTab=terms"
                        target="_blank"
                        className="font-medium  text-sm text-green"
                      >
                        Terms & Conditions
                      </Link>{" "}
                      and{" "}
                      <Link
                        to="/legal?currentTab=privacy"
                        target="_blank"
                        className="font-medium text-green"
                      >
                        Privacy Policy
                      </Link>
                    </p>

                    
                  </label>
                  
                  <Button
                    type="submit"
                    className="bg-green w-full font-semibold rounded-lg h-[49px] flex items-center justify-center leading-7"
                    isLoading={register.loading}
                  >
                    Create Account
                  </Button>

									{/* <div className="my-3">
                    <p className="font-semibold text-center mb-3">
                      Or sign up using
                    </p>

                   <SocialAuthButtons />
                  </div> */}
								</Form>
							);
						}}
					</Formik>

					<div className="max-w-[300px] mx-auto mt-4 font-normal text-center text-[#A4A4A4]">
						Already have an account?{" "}
						<Link to="/login" className="font-medium text-green">
							Log In
						</Link>
					</div>
				</div>
			</div>
		</AuthLoginLayout>
	);
};

export default RegisterCollaboratorScreen;
