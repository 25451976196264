import { useEffect, useState } from "react";
import { Link, Element, Events, scrollSpy } from "react-scroll";
import SectionHeader from "../../../molecules/SectionHeader";

const TermsSection = () => {
	const [activeTab, setActiveTab] = useState<string>("introduction");
	const sideTabs: { label: string; value: string }[] = [
		{ label: "Introduction", value: "introduction" },
		{ label: "Contractual Relationship", value: "contractual" },
		{ label: "Definitions", value: "definitions" },
		{ label: "Basis", value: "basis-terms" },
		{
			label: "Mandatory Declaration",
			value: "mandatory-declarations",
		},
		{ label: "Services", value: "services" },
		{ label: "Ordering & Delivery", value: "order-delivery" },
		{ label: "Limitation of Liability", value: "limitation" },
		{ label: "Obligations", value: "obligations" },
		{ label: "Penalty", value: "penalty" },
		{ label: "Intellectual Property", value: "intellectual-property" },
		{ label: "Marketing", value: "marketing" },
		{ label: "Termination", value: "termination" },
		{ label: "Waiver", value: "waiver" },
		{ label: "Severance", value: "severance" },
		{ label: "Entire Agreement", value: "agreement" },
		{ label: "Assigntment & Subcontracting", value: "assignment" },
		{ label: "No Partnership or Agency", value: "partnership-agency" },
		{ label: "Third-party Rights", value: "third-party" },
		{ label: "Notices", value: "notices" },
		{ label: "Governing Law & Judrisdiction", value: "governing-law" },
		{ label: "Risk of loss", value: "risk-loss" },
		{
			label: "Pricing/Tyographical error/Omissions",
			value: "pricing-omissions",
		},
		{ label: "Cancellation of order", value: "cancel-order" },
		{
			label: "Our Response to Volatility in Prices",
			value: "volatility-prices",
		},
		{ label: "Amendments", value: "amendments" },
	];

	useEffect(() => {
		// Registering the 'begin' event and logging it to the console when triggered.
		Events.scrollEvent.register("begin", (to, element) => {
			console.log("begin", to, element);
		});

		// Registering the 'end' event and logging it to the console when triggered.
		Events.scrollEvent.register("end", (to, element) => {
			console.log("end", to, element);
		});

		// Updating scrollSpy when the component mounts.
		scrollSpy.update();

		// Returning a cleanup function to remove the registered events when the component unmounts.
		return () => {
			Events.scrollEvent.remove("begin");
			Events.scrollEvent.remove("end");
		};
	}, []);

	return (
		<div className="relative w-full min-h-[100vh] max-h-[100vh]">
			<div
				className="absolute left-0 top-0 hidden xl:w-[200px] 2xl:w-[250px] border-r bg-white
		 border-gray-100 overflow-y-scroll h-[100dvh] xl:flex show-scrollbar items-start justify-start text-left flex-col gap-7 p-2"
			>
				{sideTabs.map(
					(tab: { label: string; value: string }, indx: number) => (
						<Link
							to={`${tab.value}`}
							key={tab.value}
							smooth={true}
							duration={600}
							containerId="containerElement"
						>
							<div
								className={`cursor-pointer ${
									activeTab === tab.value
										? "text-[#118507] "
										: "text-black"
								}`}
								onClick={() => {
									setActiveTab(tab.value);
								}}
							>
								{tab.label}
							</div>
						</Link>
					)
				)}
			</div>
			<div
				className="absolute right-0 top-0 h-full w-full xl:w-[calc(100%-200px)] 2xl:w-[calc(100%-250px)] max-h-[100dvh] overflow-y-scroll bg-white py-2 xl:px-5 2xl:px-8 gap-1"
				id="containerElement"
			>
				<Element name="introduction">
					<div className="min-h-[100px] pb-5 pt-10">
						<div className="w-full flex flex-col items-center justify-center gap-3 text-center">
							<h2 className="text-3xl md:text-4xl xl:text-5xl text-black font-semibold">
								Terms & Conditions
							</h2>
							<p className="text-sm text-gray-300 font-normal">
								Last Updated: November 8th, 2022
							</p>
						</div>
						<p className="text-black text-sm font-normal mt-5">
							This document is an electronic record in terms of National
							Information Technology Development Agency Act, 2007 and
							rules there under as applicable and the amended provisions
							pertaining to electronic records in various statutes as
							amended by the National Information Technology Development
							Agency Act, 2007. This electronic record is generated by a
							computer system and does not require any physical or
							digital signatures.
						</p>
						<p className="text-black text-sm font-normal mt-2">
							Please ensure that you have read and understand all these
							terms and condition (Hereinafter T&Cs) before you sign up
							for the services on Quick Leap- you shall be bound by all
							the T&Cs contained herein - PLEASE DO NOT ENROL IF YOU DO
							NOT ACCEPT ANY OF THE T&CS.
						</p>
					</div>
				</Element>

				<Element name="contractual">
					<div className="min-h-[100px] pb-5 pt-10">
						<SectionHeader
							pageHeader="Contractual Relationship"
							pageNumber={1}
						/>

						<p className="text-black text-sm font-normal mt-5">
							These Terms and Conditions ("Terms") govern your access to
							and use of the Quick Leap platform ("Platform"), provided
							by Quick Leap Global Limited, a company registered in
							Nigeria, and its affiliates ("Quick Leap"). By accessing or
							using the Platform, you agree to be bound by these Terms.
							If you do not agree to these Terms, you may not access or
							use the Platform.
						</p>

						<p className="text-black text-sm font-normal mt-5">
							By accessing or using the Quick Leap platform ("Platform"),
							you agree to be bound by these Terms and Conditions
							("Terms"). These Terms establish a contractual relationship
							between you and Quick Leap, governing your access to and
							use of the Platform.
						</p>
					</div>
				</Element>

				<Element name="definitions">
					<div className="min-h-[100px] pb-5 pt-10">
						<SectionHeader pageHeader="Definitions" pageNumber={2} />

						<p className="text-black text-sm font-normal mt-5">
							Quick Leap is a one stop shop “Platform” for everything
							food, our goal is to provide good food for everyone,
							everywhere, everytime with a focus on providing all the
							nutritious food people need to live a healthy and happy
							life from infancy to adulthood.
						</p>

						<ol className="mt-2 pl-2 space-y-2">
							<li className="text-black text-sm font-normal flex items-start justify-start gap-1">
								a.{" "}
								<p>
									Platform" refers to the digital ecosystem provided by
									Quick Leap, which includes the Grow for Life
									subscription service, Inventory manager, Collaborator
									Center, and other solutions related to food and
									beverage.
								</p>
							</li>
							<li className="text-black text-sm font-normal flex items-start justify-start gap-1">
								b.{" "}
								<p>
									"User" refers to any individual or entity accessing
									or using the Platform, including consumers and
									Collaborators.
								</p>
							</li>
							<li className="text-black text-sm font-normal flex items-start justify-start gap-1">
								c.{" "}
								<p>
									"Collaborator" refers to any vendor or supplier
									working with Quick Leap to provide products and
									services through the Platform.
								</p>
							</li>
							<li className="text-black text-sm font-normal flex items-start justify-start gap-1">
								d.{" "}
								<p>
									"Grow for Life" refers to the free subscription-based
									product offered by Quick Leap for individuals and
									families, aimed at automating home stocking and
									monthly delivery of shopping lists.
								</p>
							</li>
							<li className="text-black text-sm font-normal flex items-start justify-start gap-1">
								e.{" "}
								<p>
									"Collaborator Center" refers to the section of the
									Platform where Collaborators can manage their
									inventory, purchase supplies, and sell products to
									Quick Leap.
								</p>
							</li>
						</ol>
					</div>
				</Element>
				<Element name="basis-terms">
					<div className="min-h-[100px] pb-5 pt-10">
						{" "}
						<SectionHeader
							pageHeader="Basis of these terms and conditions"
							pageNumber={3}
						/>
						<p className="text-black text-sm font-normal mt-5">
							These Terms constitute the entire agreement between you and
							Quick Leap regarding your use of the Platform. Any
							additional terms or conditions provided by Quick Leap shall
							be considered supplementary to these Terms and shall not
							modify or supersede them unless expressly stated.
						</p>
					</div>
				</Element>
				<Element name="mandatory-declarations">
					<div className="min-h-[100px] pb-5 pt-10">
						{" "}
						<SectionHeader
							pageHeader="MANDATORY DECLARATION (For individuals and families on Grow for Life)"
							pageNumber={4}
						/>
						<p className="text-black text-sm font-normal mt-5">
							You hereby declare that you have provided all necessary
							Legal Delivery Details for the shipment of the product to
							the destination specified by you in the Order or
							subscription.
						</p>
						<p className="text-black text-sm font-normal mt-5">
							You further declare that the Product will be acquired for
							personal use of the Consignee and/or Buyer. The Product
							will not be sold, resold, bartered or in any way disposed
							for profit.
						</p>
					</div>
				</Element>
				<Element name="services">
					<div className="min-h-[100px] pb-5 pt-10">
						{" "}
						<SectionHeader pageHeader="Services" pageNumber={5} />
						<p className="text-black text-sm font-normal mt-5">
							Quick Leap provides an ecosystem of solutions aimed at
							providing food and beverage-related services to users and
							Collaborators. These services include but are not limited
							to subscription-based services, inventory management tools,
							and the Collaborator Center.
						</p>
						<h4 className="font-semibold mt-5 text-base leading-7 text-black">
							I. Subscription Plans
						</h4>
						<ol className=" pl-2 space-y-2">
							<li className="text-black text-sm font-normal flex items-start justify-start gap-1">
								a.{" "}
								<p>
									Quick Leap offers subscription-based access to the
									Platform, including the free Grow for Life
									subscription for individuals and families.
								</p>
							</li>
							<li className="text-black text-sm font-normal flex items-start justify-start gap-1">
								b.{" "}
								<p>
									Users may also have access to premium subscription
									plans with additional features and benefits, subject
									to payment of applicable fees.
								</p>
							</li>
							<li className="text-black text-sm font-normal flex items-start justify-start gap-1">
								c.{" "}
								<p>
									Subscription fees and terms will be clearly stated at
									the time of registration or upgrade.
								</p>
							</li>
							<li className="text-black text-sm font-normal flex items-start justify-start gap-1">
								d.{" "}
								<p>
									Users agree to pay all applicable subscription fees
									in a timely manner.
								</p>
							</li>
						</ol>
						<h4 className="font-semibold mt-5 text-base leading-7 text-black">
							II. Collaborator Services
						</h4>
						<ol className=" pl-2 space-y-2">
							<li className="text-black text-sm font-normal flex items-start justify-start gap-1">
								a.{" "}
								<p>
									Collaborators may use the Collaborator Center to
									manage their inventory, purchase supplies, and sell
									products to Quick Leap.
								</p>
							</li>
							<li className="text-black text-sm font-normal flex items-start justify-start gap-1">
								b.{" "}
								<p>
									Quick Leap does not guarantee the availability of
									specific products or services through the
									Collaborator Center.
								</p>
							</li>
							<li className="text-black text-sm font-normal flex items-start justify-start gap-1">
								c.{" "}
								<p>
									Collaborators are solely responsible for the quality,
									safety, and legality of the products they offer
									through the Platform.
								</p>
							</li>
							<li className="text-black text-sm font-normal flex items-start justify-start gap-1">
								d.{" "}
								<p>
									Collaborators agree to comply with all applicable
									laws and regulations regarding the production, sale,
									and distribution of food and beverage products.
								</p>
							</li>
						</ol>
					</div>
				</Element>

				<Element name="order-delivery">
					<div className="min-h-[100px] pb-5 pt-10">
						{" "}
						<SectionHeader
							pageHeader="Ordering and Delivery"
							pageNumber={4}
						/>
						<h4 className="font-semibold mt-5 text-lg leading-7 text-black">
							General Terms of Delivery
						</h4>
						<p className="text-black text-sm font-normal mt-5">
							Once an order has been confirmed, we will take steps to
							keep you updated as to the expected delivery date and time.
							Specifically, upon dispatch of the order to your designated
							delivery location, we will keep you informed as to the
							estimated delivery time.
						</p>
						<p className="text-black text-sm font-normal mt-5">
							Kindly note that our delivery partners will only wait for a
							maximum period of 15 minutes at a particular location
							before moving, after which the delivery partner will move
							to the next location and a further request for delivery
							will attract an additional cost on your part.
						</p>
						<p className="text-black text-sm font-normal mt-5">
							Any incident of refusal to pay the applicable costs will
							result in a refund of the purchase amount, subject to such
							deductions as Quick Leap may determine. Where any change to
							the delivery date is done by you{" "}
							<strong>after an order has been dispatched</strong>, Quick
							Leap will not be liable for any change in the quality of
							the items due to the change in delivery date. Where an
							order is cancelled after being dispatched, Quick Leap shall
							refund the purchase amount subject to a cancellation fee as
							determined by Quick Leap. Also, please note that our
							Collaborators/delivery partners are not obligated to enter
							into your home space for security reasons. Home space
							refers to inside your house and in your compound.
							Accordingly, orders will be deemed delivered once the
							product has entered into the premises. Kindly note that our
							delivery partners have been instructed to decline any
							request granting them access into personal quarters of our
							customers.
						</p>
						<p className="text-black text-sm font-normal mt-5">
							Upon delivery of the order to you, you will inspect the
							delivery within the time frame of 10 minutes to ensure that
							they meet the specification requested.
						</p>
						<p className="text-black text-sm font-normal mt-5">
							Where the order is placed by a customer for delivery to a
							third party, we will not be liable for any loss or damage
							to the goods which occurs after the goods have been
							inspected and accepted by the third party. We are absolved
							of any and all liability once delivery is completed, i.e.
							upon acceptance by the customer or any third party.
						</p>
						<h4 className="font-semibold mt-5 text-base leading-7 text-black">
							Please note
						</h4>
						<ol className=" pl-2 space-y-2">
							<li className="text-black text-sm font-normal flex items-start justify-start gap-1">
								a.{" "}
								<p>
									Users may place orders for products and services
									offered by Collaborators through the Platform.
								</p>
							</li>
							<li className="text-black text-sm font-normal flex items-start justify-start gap-1">
								b.{" "}
								<p>
									Orders are subject to availability and acceptance by
									the Collaborator.
								</p>
							</li>
							<li className="text-black text-sm font-normal flex items-start justify-start gap-1">
								c.{" "}
								<p>
									Delivery times provided on the Platform are estimates
									only and may vary due to factors beyond Quick Leap's
									control.
								</p>
							</li>
							<li className="text-black text-sm font-normal flex items-start justify-start gap-1">
								d.{" "}
								<p>
									Users are responsible for inspecting orders upon
									delivery and must report any issues or complaints to
									Quick Leap within a 10 minutes of delivery.
								</p>
							</li>
						</ol>
						<h4 className="font-semibold mt-5 text-base leading-7 text-black">
							Payment
						</h4>
						<ol className=" pl-2 space-y-2">
							<li className="text-black text-sm font-normal flex items-start justify-start gap-1">
								a.{" "}
								<p>
									Users agree to pay all fees and charges associated
									with their orders, including the cost of products,
									delivery fees, and taxes.
								</p>
							</li>
							<li className="text-black text-sm font-normal flex items-start justify-start gap-1">
								b.{" "}
								<p>
									Payment methods accepted on the Platform may include
									credit/debit cards, mobile money, or other electronic
									payment methods.
								</p>
							</li>
							<li className="text-black text-sm font-normal flex items-start justify-start gap-1">
								c.{" "}
								<p>
									Users authorise Quick Leap to charge the payment
									method provided for all orders placed through the
									Platform.
								</p>
							</li>

							<li className="text-black text-sm font-normal flex items-start justify-start gap-1">
								d.{" "}
								<p>
									Quick Leap accepts payment via debit/credit card and
									bank transfer. All payments are processed through
									secure and trusted payment gateways.
								</p>
							</li>

							<li className="text-black text-sm font-normal flex items-start justify-start gap-1">
								e.{" "}
								<p>
									Quick Leap does not store or have access to your
									payment information.
								</p>
							</li>

							<li className="text-black text-sm font-normal flex items-start justify-start gap-1">
								f.{" "}
								<p>
									By placing an order on our platform, you agree to pay
									the total amount due, including any applicable taxes
									and delivery fees.
								</p>
							</li>
						</ol>
						<h4 className="font-semibold mt-5 text-base leading-7 text-black">
							Complaints and Disputes
						</h4>
						<ol className=" pl-2 space-y-2">
							<li className="text-black text-sm font-normal flex items-start justify-start gap-1">
								a.{" "}
								<p>
									Users may lodge complaints regarding the quality of
									products, delivery issues, or any other matters
									related to their experience with the Platform.
								</p>
							</li>
							<li className="text-black text-sm font-normal flex items-start justify-start gap-1">
								b.{" "}
								<p>
									Complaints must be submitted in writing to Quick Leap
									within a reasonable time frame.
								</p>
							</li>
							<li className="text-black text-sm font-normal flex items-start justify-start gap-1">
								c.{" "}
								<p>
									Quick Leap will investigate all complaints promptly
									and may take appropriate action, including issuing
									refunds or credits, as deemed necessary.
								</p>
							</li>
							<li className="text-black text-sm font-normal flex items-start justify-start gap-1">
								d.{" "}
								<p>
									Users agree to cooperate with Quick Leap in the
									resolution of any disputes arising from their use of
									the Platform.
								</p>
							</li>
						</ol>
						<h4 className="font-semibold mt-5 text-base leading-7 text-black">
							Breach
						</h4>
						<p className="text-black text-sm font-normal mt-5">
							Without limiting other remedies, Quick Leap may limit your
							activity, immediately remove your information, warn other
							Users of your actions, immediately temporarily/indefinitely
							suspend or terminate or block your membership, and/or
							refuse to provide you with access to If it is believed that
							your actions may cause legal liability for you, other Users
							or Quick Leap’s website in the event, but not limited to:
						</p>
						<ol className="list-disc">
							<li className="text-black text-sm font-normal flex items-start md:items-center justify-start gap-1">
								<div className="w-[5px] h-[5px] min-w-[5px] min-h-[5px] rounded-full bg-black" />
								<p>
									If you breach the Terms of Use or Privacy Policy or
									other rules and policies, if any;
								</p>
							</li>
							<li className="text-black text-sm font-normal flex items-start md:items-center justify-start gap-1">
								<div className="w-[5px] h-[5px] min-w-[5px] min-h-[5px] rounded-full bg-black" />
								<p>
									If it is believed that your actions may cause legal
									liability for you, other Users or Quick Leap;
								</p>
							</li>
							<li className="text-black text-sm font-normal flex items-start md:items-center justify-start gap-1">
								<div className="w-[5px] h-[5px] min-w-[5px] min-h-[5px] rounded-full bg-black" />
								<p>
									If Quick Leap is unable to verify or authenticate any
									information you provide; or
								</p>
							</li>
							<li className="text-black text-sm font-normal flex items-start md:items-center justify-start gap-1">
								<div className="w-[5px] h-[5px] min-w-[5px] min-h-[5px] rounded-full bg-black" />
								<p>
									Quick Leap may at any time at its sole discretion
									reinstate suspended Users.
								</p>
							</li>
						</ol>
						<p className="text-black text-sm font-normal mt-5">
							A User that has been suspended or blocked may not register
							or attempt to register with If it is believed that your
							actions may cause legal liability for you, other Users or
							Quick Leap or use If it is believed that your actions may
							cause legal liability for you, other Users or Quick Leap
							website in any manner whatsoever until such time that such
							User is reinstated by If it is believed that your actions
							may cause legal liability for you, other Users or Quick
							Leap. Notwithstanding the foregoing, if you breach the
							Terms of Use or Privacy Policy or other rules and policies,
							If it is believed that your actions may cause legal
							liability for you, other Users or Quick Leap reserves the
							right to recover any amounts due and owing by you to If it
							is believed that your actions may cause legal liability for
							you, other Users or Quick Leap and to take strict legal
							action including but not limited to a referral to the
							appropriate law enforcement or other authorities for
							initiating criminal or other proceedings against you.
						</p>
					</div>
				</Element>

				<Element name="limitation">
					<div className="min-h-[100px] pb-5 pt-10">
						{" "}
						<SectionHeader
							pageHeader="Limitation of Liability"
							pageNumber={7}
						/>
						<p className="text-black text-sm font-normal mt-5">
							Quick Leap disclaims all warranties, express or implied,
							regarding the Platform. Users and Collaborators agree that
							Quick Leap shall not be liable for any direct, indirect,
							incidental, special, or consequential damages arising out
							of or in any way connected with the use of the Platform.
						</p>
						<ol className="mt-5 pl-2 space-y-2">
							<li className="text-black text-sm font-normal flex items-start justify-start gap-1">
								a.{" "}
								<p>
									To the fullest extent permitted by law, Quick Leap
									shall not be liable for any direct, indirect,
									incidental, special, or consequential damages arising
									out of or in any way connected with the use of the
									Platform.
								</p>
							</li>
							<li className="text-black text-sm font-normal flex items-start justify-start gap-1">
								b.{" "}
								<p>
									Quick Leap makes no warranties or representations
									regarding the quality, accuracy, or reliability of
									the products or services offered by Collaborators, we
									confirm to the best of our ability through our field
									agents the quality of the food we put on the
									platform, we are committed to making available
									healthy and nutritious food of the best quality.
								</p>
							</li>
						</ol>
					</div>
				</Element>

				<Element name="obligations">
					<div className="min-h-[100px] pb-5 pt-10">
						{" "}
						<SectionHeader pageHeader="Obligations" pageNumber={8} />
						<p className="text-black text-sm font-normal mt-5">
							Users and Collaborators agree to comply with all applicable
							laws and regulations when using the Platform. Users are
							responsible for maintaining the confidentiality of their
							account credentials, while Collaborators agree to provide
							accurate information and comply with all terms and
							conditions related to the Collaborator Center.
						</p>
					</div>
				</Element>
				<Element name="penalty">
					<div className="min-h-[100px] pb-5 pt-10">
						{" "}
						<SectionHeader
							pageHeader="Penalty for Late Payments"
							pageNumber={9}
						/>
						<p className="text-black text-sm font-normal mt-5">
							TIn the event of late payments by Users or Collaborators,
							Quick Leap reserves the right to impose penalties or
							suspend access to certain features of the Platform until
							outstanding payments are settled. Users and collaborators
							shall be liable to pay Quick Leap a penalty fee equal to 1%
							percent (one percent) on such unpaid invoice.
						</p>
					</div>
				</Element>
				<Element name="intellectual-property">
					<div className="min-h-[100px] pb-5 pt-10">
						{" "}
						<SectionHeader
							pageHeader="Intellectual Property"
							pageNumber={10}
						/>
						<p className="text-black text-sm font-normal mt-5">
							All content and materials available on the Platform,
							including text, graphics, logos, images, and software, are
							owned by Quick Leap or its licensors and are protected by
							copyright and other intellectual property laws.
						</p>
						<p className="text-black text-sm font-normal">
							All confidential information, copyright works, database
							rights, toolsets, inventions and patent rights and all
							other intellectual property rights subsisting at the
							commencement of the Services and which may be utilised by
							either party in the course of performing the Services or
							use of the platform shall remain the property of Quick
							Leap.
						</p>
						<p className="text-black text-sm font-normal mt-5">
							The User/collaborator grants Quick Leap an irrevocable,
							unlimited and royalty-free licence to use the User Data
							provided to Quick Leap on the platform. Notwithstanding any
							provision to the contrary, nothing in these Conditions or
							concerning any project or the provision of Services shall
							deprive Quick Leap or grant the User rights to any of the
							Quick Leap’s research, know-how functionality or
							methodology document, supplementary knowledge and design or
							technology process including as used by Quick Leap in its
							general business or for its platform.
						</p>
					</div>
				</Element>
				<Element name="marketing">
					<div className="min-h-[100px] pb-5 pt-10">
						{" "}
						<SectionHeader pageHeader="Marketing" pageNumber={11} />
						<p className="text-black text-sm font-normal mt-5">
							We at Quick Leap respect the intellectual property of
							others. In case you feel that your work has been copied in
							a way that constitutes copyright infringement please write
							to us at{" "}
							<strong className="font-normal text-sm text-green underline">
								{" "}
								hello@quickleap.co
							</strong>
							.
						</p>
					</div>
				</Element>
				<Element name="termination">
					<div className="min-h-[100px] pb-5 pt-10">
						{" "}
						<SectionHeader pageHeader="Termination" pageNumber={12} />
						<p className="text-black text-sm font-normal mt-5">
							Quick Leap reserves the right to terminate or suspend
							access to the Platform at any time, with or without cause,
							and without prior notice.
						</p>
					</div>
				</Element>

				<Element name="waiver">
					<div className="min-h-[100px] pb-5 pt-10">
						{" "}
						<SectionHeader pageHeader="Waiver" pageNumber={13} />
						<p className="text-black text-sm font-normal mt-5">
							The failure of Quick Leap to enforce any provision of these
							Terms shall not constitute a waiver of such provision or
							any other provision.
						</p>
					</div>
				</Element>
				<Element name="severance">
					<div className="min-h-[100px] pb-5 pt-10">
						{" "}
						<SectionHeader pageHeader="Severance" pageNumber={14} />
						<p className="text-black text-sm font-normal mt-5">
							If any provision of these Terms is found to be invalid or
							unenforceable, the remaining provisions shall remain in
							full force and effect.
						</p>
					</div>
				</Element>
				<Element name="agreement">
					<div className="min-h-[100px] pb-5 pt-10">
						{" "}
						<SectionHeader
							pageHeader="Entire Agreement"
							pageNumber={15}
						/>
						<p className="text-black text-sm font-normal mt-5">
							These Terms constitute the entire agreement between you and
							Quick Leap regarding your use of the Platform, superseding
							any prior agreements or understandings.
						</p>
					</div>
				</Element>
				<Element name="assignment">
					<div className="min-h-[100px] pb-5 pt-10">
						{" "}
						<SectionHeader
							pageHeader="Assignment and Subcontracting"
							pageNumber={16}
						/>
						<p className="text-black text-sm font-normal mt-5">
							Quick Leap may assign or subcontract any of its rights or
							obligations under these Terms without prior notice.
						</p>
					</div>
				</Element>

				<Element name="partnership-agency">
					<div className="min-h-[100px] pb-5 pt-10">
						{" "}
						<SectionHeader
							pageHeader="No Partnership or Agency"
							pageNumber={17}
						/>
						<p className="text-black text-sm font-normal mt-5">
							Nothing in these Terms shall be construed as creating a
							partnership, joint venture, employment, or agency
							relationship between you and Quick Leap.
						</p>
					</div>
				</Element>
				<Element name="third-party">
					<div className="min-h-[100px] pb-5 pt-10">
						{" "}
						<SectionHeader
							pageHeader="Third-party Rights"
							pageNumber={18}
						/>
						<p className="text-black text-sm font-normal mt-5">
							These Terms are intended for the benefit of Quick Leap and
							its users and Collaborators and are not intended to confer
							any rights upon any third party.
						</p>
					</div>
				</Element>
				<Element name="notices">
					<div className="min-h-[100px] pb-5 pt-10">
						{" "}
						<SectionHeader pageHeader="Notices" pageNumber={19} />
						<p className="text-black text-sm font-normal mt-5">
							Any notices or communications required or permitted under
							these Terms shall be sent to the contact information
							provided by the parties
						</p>
					</div>
				</Element>
				<Element name="governing-law">
					<div className="min-h-[100px] pb-5 pt-10">
						{" "}
						<SectionHeader
							pageHeader="Governing Law and Judrisdiction"
							pageNumber={20}
						/>
						<ol className="mt-5 pl-2 space-y-2">
							<li className="text-black text-sm font-normal flex items-start justify-start gap-1">
								a.{" "}
								<p>
									These Terms shall be governed by and construed in
									accordance with the laws of Nigeria.
								</p>
							</li>
							<li className="text-black text-sm font-normal flex items-start justify-start gap-1">
								b.{" "}
								<p>
									Any disputes arising out of or in connection with
									these Terms shall be subject to the exclusive
									jurisdiction of the courts of Nigeria.
								</p>
							</li>
						</ol>
					</div>
				</Element>
				<Element name="risk-loss">
					<div className="min-h-[100px] pb-5 pt-10">
						{" "}
						<SectionHeader pageHeader="Risk of loss" pageNumber={21} />
						<p className="text-black text-sm font-normal mt-5">
							All items purchased from Quick Leap are made pursuant to a
							shipment contract. This means that the Risk of Loss shall
							remain with Quick Leap until the item is transferred to
							You. In the event that the items are damaged after receipt,
							the risk falls on the customer.
						</p>
					</div>
				</Element>
				<Element name="pricing-omissions">
					<div className="min-h-[100px] pb-5 pt-10">
						{" "}
						<SectionHeader
							pageHeader="Pricing/Tyographical error/Omissions"
							pageNumber={22}
						/>
						<p className="text-black text-sm font-normal mt-5">
							If Quick Leap comes across any difference in pricing
							resulting from typographic errors with regards to pricing
							or product/service information, Quick Leap shall have the
							right to rectify the same or cancel the order(s) and refund
							monies, if any, collected from the customer within 10-15
							business days of such corrective action taken. The mode of
							refund will be the same as at the time of placing the
							order. Cash on Delivery order refunds will be done by
							cheque or transfers.
						</p>
					</div>
				</Element>
				<Element name="cancel-order">
					<div className="min-h-[100px] pb-5 pt-10">
						{" "}
						<SectionHeader
							pageHeader="Cancellation of order"
							pageNumber={23}
						/>
						<p className="text-black text-sm font-normal mt-5">
							Quick Leap reserves the right to cancel any order without
							any explanation for doing so, under a situation where Quick
							Leap is not able to meet the requirement of the order
							placed or order so placed/cancelled does not comply with
							the Quick Leap’s policy or for any reason. However, Quick
							Leap will ensure that any communication of cancellation of
							an order is intimated within appropriate time to the
							concerned person and any applicable refund, will be made in
							reasonable time.
						</p>
						<p className="text-black text-sm font-normal mt-5">
							We move quickly, when an order has been made on Quick Leap,
							order cancellation by a user or collaborator after order
							fulfilment has begun will attract a cost commiserate to the
							value of the cost that has been incurred in the process of
							order fulfilment.
						</p>
					</div>
				</Element>
				<Element name="volatility-prices">
					<div className="min-h-[100px] pb-5 pt-10">
						{" "}
						<SectionHeader
							pageHeader="Our Response to Volatility in Prices of Commodities"
							pageNumber={24}
						/>
						<p className="text-black text-sm font-normal mt-5">
							Quick Leap recognizes the dynamic nature of the commodities
							market, where prices of agricultural products are subject
							to fluctuations due to various factors such as weather
							conditions, market demand, and geopolitical events. As
							such, Quick Leap is committed to navigating these
							fluctuations effectively to ensure fair and sustainable
							pricing for both Collaborators and Users.
						</p>
						<p className="text-black text-sm font-normal mt-5">
							In response to significant increases in prices at the
							farmgate, Quick Leap reserves the right to conduct a
							thorough review of affected invoices, even those for
							completed purchases. This review process aims to assess the
							extent of the price increase and its impact on the overall
							cost structure.
						</p>
						<p className="text-black text-sm font-normal mt-5">
							During the review, Quick Leap will consider various
							factors, including the magnitude and duration of the price
							increase, market trends, and the availability of
							alternative sourcing options. Quick Leap will also engage
							with Collaborators to discuss the implications of the price
							changes and explore potential mitigation strategies.
						</p>
						<p className="text-black text-sm font-normal mt-5">
							Based on the outcome of the review, Quick Leap may propose
							adjustments to affected invoices to reflect the revised
							cost of goods. These adjustments will be communicated
							transparently to Collaborators and Users, along with the
							rationale behind the decision.
						</p>
						<p className="text-black text-sm font-normal mt-5">
							Quick Leap understands the importance of maintaining
							stability and predictability in pricing while also ensuring
							fairness and competitiveness in the market. Therefore, any
							adjustments made to prices will be guided by these
							principles, with the aim of striking a balance between the
							interests of all parties involved.
						</p>
						<p className="text-black text-sm font-normal mt-5">
							By implementing this detailed approach to addressing
							volatility in commodity prices, Quick Leap aims to foster
							trust, transparency, and resilience within its ecosystem.
							This proactive stance underscores Quick Leap's commitment
							to effectively managing market dynamics and delivering
							value to its stakeholders amidst changing conditions.
						</p>
						<h4 className="font-semibold mt-5 text-base leading-7 text-black">
							Cancellation Fee Policy
						</h4>
						<p className="text-black text-sm font-normal mt-5">
							In the event that Users or Collaborators decide to cancel
							invoices after they have been agreed upon and processed,
							Quick Leap implements a cancellation fee equivalent to 5%
							of the invoice value. This fee is intended to cover the
							operational costs incurred by Quick Leap in the process of
							fulfilling the order, including but not limited to
							procurement, logistics, and administrative expenses.
						</p>
						<p className="text-black text-sm font-normal mt-5">
							Quick Leap understands that cancellations can disrupt the
							supply chain and incur costs for all parties involved.
							Therefore, the imposition of a cancellation fee aims to
							mitigate these costs and ensure that Quick Leap's
							operations remain sustainable. Costs incurred by Quick Leap
							in the process of fulfilling the order, including but not
							limited to procurement, logistics, and administrative
							expenses.
						</p>
						<p className="text-black text-sm font-normal mt-5">
							Users and Collaborators are encouraged to carefully review
							and confirm their orders before finalising invoices to
							avoid unnecessary cancellations and associated fees. By
							adhering to this policy, Quick Leap seeks to foster
							accountability and responsible decision-making among its
							stakeholders while maintaining the efficiency and integrity
							of its operations.
						</p>
					</div>
				</Element>
				<Element name="amendments">
					<div className="min-h-[100px] pb-5 pt-10">
						{" "}
						<SectionHeader pageHeader="Amendments" pageNumber={25} />
						<ol className="mt-5 pl-2 space-y-2">
							<li className="text-black text-sm font-normal flex items-start justify-start gap-1">
								a.{" "}
								<p>
									Quick Leap reserves the right to amend these Terms at
									any time without prior notice.
								</p>
							</li>
							<li className="text-black text-sm font-normal flex items-start justify-start gap-1">
								b.{" "}
								<p>
									Amendments to these Terms will be effective
									immediately upon posting on the Platform.
								</p>
							</li>
							<li className="text-black text-sm font-normal flex items-start justify-start gap-1">
								c.{" "}
								<p>
									Users are responsible for reviewing these Terms
									regularly to ensure compliance with the latest
									version.
								</p>
							</li>
						</ol>
						<p className="text-black text-sm font-normal mt-5">
							By using the Platform, you acknowledge that you have read,
							understood, and agree to be bound by these Terms. If you do
							not agree to these Terms, you must refrain from using the
							Platform.
						</p>
					</div>
				</Element>
			</div>
		</div>
	);
};

export default TermsSection;
