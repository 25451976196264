import { useEffect, useState } from "react";
import { Link, Element, Events, scrollSpy } from "react-scroll";
import SectionHeader from "../../../molecules/SectionHeader";

const ServiceLevelAgreement = () => {
  const [activeTab, setActiveTab] = useState<string>("introduction");
  const sideTabs: { label: string; value: string }[] = [
    { label: "Introduction", value: "introduction" },
    { label: "Services Provided", value: "services-provided" },
    { label: "Service Availability", value: "service-availability" },
    { label: "Support Services", value: "support-services" },
    { label: "User Responsibilities", value: "user-responsibilities" },
    { label: "Performance Metrics", value: "performance-metrics" },
    { label: "Payments", value: "payments" },
    { label: "Collaborators and Their Deliveries", value: "collaborators-deliveries" },
    { label: "Delivery Timeline", value: "delivery-timeline" },
    { label: "Escalation Procedures", value: "escalation-procedures" },
    { label: "Penalties and Remedies", value: "penalties-remedies" },
    { label: "Term and Termination", value: "term-termination" },
    { label: "Amendments", value: "amendments" },
    { label: "Governing Law", value: "governing-law" },
    { label: "Quick Leap Supplies", value: "quick-leap-supplies" },
    { label: "Contact Information", value: "contact-information" }
  ];

  useEffect(() => {
    Events.scrollEvent.register("begin", (to, element) => {
      console.log("begin", to, element);
    });

    Events.scrollEvent.register("end", (to, element) => {
      console.log("end", to, element);
    });

    scrollSpy.update();

    return () => {
      Events.scrollEvent.remove("begin");
      Events.scrollEvent.remove("end");
    };
  }, []);

  return (
    <div className="relative w-full min-h-[100vh] max-h-[100vh]">
      <div className="absolute left-0 top-0 hidden xl:w-[200px] 2xl:w-[250px] show-scrollbar border-r bg-white border-gray-100 overflow-y-scroll h-[100dvh] xl:flex items-start justify-start text-left flex-col gap-7 p-2">
        {sideTabs.map((tab: { label: string; value: string }, indx: number) => (
          <Link
            to={`${tab.value}`}
            key={tab.value}
            smooth={true}
            duration={600}
            containerId="containerElement"
          >
            <div
              className={`cursor-pointer ${
                activeTab === tab.value ? "text-[#118507]" : "text-black"
              }`}
              onClick={() => {
                setActiveTab(tab.value);
              }}
            >
              {tab.label}
            </div>
          </Link>
        ))}
      </div>

      <div
        className="absolute right-0 top-0 h-full w-full xl:w-[calc(100%-200px)] 2xl:w-[calc(100%-250px)] max-h-[100dvh] overflow-y-scroll bg-white py-2 xl:px-5 2xl:px-8 gap-1"
        id="containerElement"
      >
        <Element name="introduction">
          <div className="min-h-[100px] pb-5 pt-10">
            <SectionHeader pageHeader="Introduction" pageNumber={1} />
            <p className="text-black text-sm font-normal mt-5">
              This Service Level Agreement (SLA) outlines the expectations and
              responsibilities between Quick Leap (the "Service Provider") and
              the Collaborators (the "Users") regarding the use of the Quick
              Leap platform.
            </p>

            <div>
              <h3 className="text-black mt-5 font-semibold">1.1. Objectives</h3>
              <ol className=" mt-3 pl-2 space-y-1">
                <li className="text-black text-sm font-normal flex items-start md:items-center justify-start gap-1">
                  <div className="w-[5px] h-[5px] min-w-[5px] min-h-[5px] rounded-full bg-black" />
                  <p>
                    Define the service expectations and responsibilities of both
                    parties.
                  </p>
                </li>
                <li className="text-black text-sm font-normal flex items-start md:items-center justify-start gap-1">
                  <div className="w-[5px] h-[5px] min-w-[5px] min-h-[5px] rounded-full bg-black" />
                  <p>
                    Ensure mutual understanding of service requirements and
                    quality standards.
                  </p>
                </li>
                <li className="text-black text-sm font-normal flex items-start md:items-center justify-start gap-1">
                  <div className="w-[5px] h-[5px] min-w-[5px] min-h-[5px] rounded-full bg-black" />
                  <p>Establish metrics to evaluate service performance.</p>
                </li>
              </ol>
            </div>
          </div>
        </Element>

        <Element name="services-provided">
          <div className="min-h-[100px] pb-5 pt-10">
            <SectionHeader pageHeader="Services Provided" pageNumber={2} />
            <p className="text-black text-sm font-normal mt-5">
              Quick Leap provides the following services to Collaborators:
            </p>
            <ul className="mt-5 text-sm space-y-1 list-disc pl-5">
              <li>
                <strong>Collaboration Tools:</strong> Features for product
                upload, market data and insights, sourcing and procuring raw
                materials, communication, file sharing, and selling their
                finished products.
              </li>

              <li>
                <strong>Support Services:</strong> Technical support and
                assistance for platform-related issues.
              </li>
            </ul>
          </div>
        </Element>

        <Element name="service-availability">
          <div className="min-h-[100px] pb-5 pt-10">
            <SectionHeader pageHeader="Service Availability" pageNumber={3} />
            <p className="text-black text-sm font-normal mt-5">
              The following expectations are set for both Quick Leap and
              Collaborators:
            </p>
            <ul className="mt-5 text-sm space-y-1 list-disc pl-5">
              <li>
                <strong>Uptime Commitment:</strong> Quick Leap guarantees a minimum of 99.5% uptime per calendar month, excluding scheduled maintenance.
              </li>

              <li>
                <strong>Scheduled Maintenance:</strong> Maintenance windows will be announced at least 48 hours in advance and will be scheduled during off-peak hours.
              </li>
            </ul>
          </div>
        </Element>

        <Element name="support-services">
          <div className="min-h-[100px] pb-5 pt-10">
            <SectionHeader pageHeader="Support Services" pageNumber={4} />
            <ul className="mt-5 text-sm space-y-1 list-disc pl-5">
              <li>
                <strong>Support Hours:</strong> Support is available Monday to Friday, 9:00 AM to 6:00 PM (local time).
                <ul className="mt-2 text-sm space-y-1 list-disc pl-5">
                  <li>
                    <strong>Response Times:</strong>
                    <ul className="mt-2 text-sm space-y-1 list-disc pl-5">
                      <li>Critical Issues: Response within 1 hour.</li>
                      <li>High Priority Issues: Response within 4 hours.</li>
                      <li>Medium Priority Issues: Response within 8 hours.</li>
                      <li>Low Priority Issues: Response within 24 hours.</li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </Element>

        <Element name="user-responsibilities">
          <div className="min-h-[100px] pb-5 pt-10">
            <SectionHeader pageHeader="User Responsibilities" pageNumber={5} />
            <ul className="mt-5 text-sm space-y-1 list-disc pl-5">
              <li>
                <strong>Account Management:</strong> Users must maintain accurate account information and protect their login credentials.
              </li>
              <li>
                <strong>Compliance:</strong> Users must comply with Quick Leap’s terms of service, privacy policy, and acceptable use policy.
              </li>
              <li>
                <strong>Reporting Issues:</strong> Users should promptly report any issues or outages to Quick Leap’s support team.
              </li>
            </ul>
          </div>
        </Element>

		<Element name="performance-metrics">
          <div className="min-h-[100px] pb-5 pt-10">
            <SectionHeader pageHeader="Performance Metrics" pageNumber={6} />
            <ul className="mt-5 text-sm space-y-1 list-disc pl-5">
              <li><strong>Service Uptime:</strong> Percentage of time the platform is available.</li>
              <li><strong>Incident Response Times:</strong> Time taken to respond to Collaborator-reported issues.</li>
              <li><strong>Issue Resolution Times:</strong> Time taken to resolve Collaborator-reported issues.</li>
            </ul>
          </div>
        </Element>

        <Element name="payments">
          <div className="min-h-[100px] pb-5 pt-10">
            <SectionHeader pageHeader="Payments" pageNumber={7} />
            <ul className="mt-5 text-sm space-y-1 list-disc pl-5">
              <li><strong>Payment Timeline:</strong> Quick Leap will make payments and settle all transactions within 10 to 72 hours post supply.</li>
            </ul>
          </div>
        </Element>

        <Element name="collaborators-deliveries">
          <div className="min-h-[100px] pb-5 pt-10">
            <SectionHeader pageHeader="Collaborators and Their Deliveries" pageNumber={8} />
            <ul className="mt-5 text-sm space-y-1 list-disc pl-5">
              <li><strong>Quality Commitment:</strong> By using the platform or by signing up, Collaborators agree to deliver only quality products that meet the specifications of the Good Food Handbook.</li>
              <li><strong>Returns:</strong> Spoilt products or expired products will be returned at the cost of the Collaborator.</li>
            </ul>
          </div>
        </Element>

        <Element name="delivery-timeline">
          <div className="min-h-[100px] pb-5 pt-10">
            <SectionHeader pageHeader="Delivery Timeline" pageNumber={9} />
            <ul className="mt-5 text-sm space-y-1 list-disc pl-5">
              <li><strong>Timeliness:</strong> To ensure Quick Leap is able to meet its obligations, Collaborators are to ensure timely deliveries to Quick Leap's order fulfilment centres.</li>
            </ul>
          </div>
        </Element>

        <Element name="escalation-procedures">
          <div className="min-h-[100px] pb-5 pt-10">
            <SectionHeader pageHeader="Escalation Procedures" pageNumber={10} />
            <ul className="mt-5 text-sm space-y-1 list-disc pl-5">
              <li>If an issue is not resolved within the specified response time, users can escalate the issue as follows:
                <ul className="mt-2 text-sm space-y-1 list-disc pl-5">
                  <li><strong>Level 1:</strong> Contact the support team lead.</li>
                  <li><strong>Level 2:</strong> Contact the support manager.</li>
                  <li><strong>Level 3:</strong> Contact the head of customer service.</li>
                </ul>
              </li>
            </ul>
          </div>
        </Element>

        <Element name="penalties-remedies">
          <div className="min-h-[100px] pb-5 pt-10">
            <SectionHeader pageHeader="Penalties and Remedies" pageNumber={11} />
            <ul className="mt-5 text-sm space-y-1 list-disc pl-5">
              <li><strong>Service Credits:</strong> If Quick Leap fails to meet the uptime commitment, users are eligible for service credits. Credits are calculated as 5% of the monthly fee for each 1% of downtime below the 99.5% threshold.</li>
              <li><strong>Limitations:</strong> Service credits are the sole remedy for downtime and performance issues.</li>
            </ul>
          </div>
        </Element>

        <Element name="term-termination">
          <div className="min-h-[100px] pb-5 pt-10">
            <SectionHeader pageHeader="Term and Termination" pageNumber={12} />
            <ul className="mt-5 text-sm space-y-1 list-disc pl-5">
              <li><strong>Effective Date:</strong> This SLA is effective from the date the Collaborator registers on Quick Leap’s Collaborator.</li>
              <li><strong>Termination:</strong> Either party may terminate this agreement with 30 days written notice. Quick Leap may terminate the agreement immediately for breaches of terms or misuse of the platform.</li>
            </ul>
          </div>
        </Element>

        <Element name="amendments">
          <div className="min-h-[100px] pb-5 pt-10">
            <SectionHeader pageHeader="Amendments" pageNumber={13} />
            <ul className="mt-5 text-sm space-y-1 list-disc pl-5">
              <li>This SLA may be amended by Quick Leap with 30 days’ notice to users. Users will be notified of any changes via email and on the platform.</li>
            </ul>
          </div>
        </Element>

        <Element name="governing-law">
          <div className="min-h-[100px] pb-5 pt-10">
            <SectionHeader pageHeader="Governing Law" pageNumber={14} />
            <ul className="mt-5 text-sm space-y-1 list-disc pl-5">
              <li>This agreement is governed by the laws of the jurisdiction of the Federal republic of Nigeria
				 in which Quick Leap primarily operates.</li>
            </ul>
          </div>
        </Element>

        <Element name="quick-leap-supplies">
          <div className="min-h-[100px] pb-5 pt-10">
            <SectionHeader pageHeader="Quick Leap Supplies" pageNumber={15} />
            <ul className="mt-5 text-sm space-y-1 list-disc pl-5">
              <li><strong>Quality and Timeliness:</strong> Quick Leap will supply produce, products, grains, equipment, etc., of the best quality, in the right quantities, as and when due, to the best of its abilities.</li>
              <li><strong>Price Changes:</strong> Quick Leap will communicate changes to prices due to volatility in the market or changes in prices post-purchase well ahead of deliveries if they occur. Please see the terms and conditions for the clause on our response to volatility in the commodities market.</li>
            </ul>
          </div>
        </Element>

        <Element name="contact-information">
          <div className="min-h-[100px] pb-5 pt-10">
            <SectionHeader pageHeader="Contact Information" pageNumber={16} />
            <p className="text-black text-sm font-normal mt-5">
              For support and inquiries, users can contact Quick Leap at:
            </p>
            <ul className="mt-5 text-sm space-y-1 list-disc pl-5">
              <li><strong>Email:</strong> <a href="mailto:contact-us@quickleap.co">contact-us@quickleap.co</a> </li>
              <li><strong>Phone:</strong> (+234) 8134860483</li>
            </ul>
          </div>
        </Element>

<hr />
<p className="text-sm font-bold mt-2">This SLA is designed to provide clear guidelines and ensure a reliable and efficient collaboration experience on Quick Leap.</p>
</div>
</div>
  )
};

export default ServiceLevelAgreement;