import { useEffect, useMemo, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { IoIosArrowDown } from "react-icons/io";
import { HiMenuAlt2 } from "react-icons/hi";
import Button from "./Button";
import useAuthInfoModal from "../../hooks/modal-hooks/useAuthInfoModal";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { selectAuthUser } from "../../store/auth/authReducer";
import { logoutUserAction } from "../../store/auth/authActions";

export default function Navbar() {
	const { currentUser, isAuthenticated, getUserInfo } =
		useAppSelector(selectAuthUser);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const authInfoModal = useAuthInfoModal();
	const [mobileMenuToggle, setMenuMobileToggle] = useState<boolean>(false);
	const location = useLocation();

	const navbarColor = useMemo(() => {
		if (location.pathname.includes("blog")) {
			return "bg-white";
		} else {
			return "bg-green-light";
		}
	}, [location.pathname]);

	const MenuDropDown = ({
		children,
		title,
	}: {
		children: React.ReactNode;
		title: string;
	}) => {
		const [open, setOpen] = useState(false);

		return (
			<div onClick={() => setOpen(!open)} className="cursor-pointer">
				<p className="text-green font-bold text-xl flex justify-between">
					{title}
					<span
						className={`${
							open ? "" : "-rotate-90"
						} duration-150 ease-in-out`}
					>
						<IoIosArrowDown size={16} />
					</span>
				</p>
				<div
					className={`${
						open ? "flex" : "hidden"
					} pl-4 flex-col text-green justify-evenly gap-4 pt-6 transition-all ease-in-out 
  duration-100`}
				>
					{children}
				</div>
			</div>
		);
	};

	useEffect(() => {
		const mobileMenu = document.querySelector("#mobile_menu");
		if (mobileMenuToggle) {
			mobileMenu?.classList.remove("hidden");
			mobileMenu?.classList.add("block");
		} else {
			mobileMenu?.classList.remove("block");
			mobileMenu?.classList.add("hidden");
		}
	}, [mobileMenuToggle]);

	useEffect(() => {
		const header: any = document.querySelector("header");

		function headerPosition() {
			if (window.scrollY > 200) {
				header.classList.add("bg-white");
			} else {
				header.classList.remove("bg-white");
			}
		}

		window.addEventListener("scroll", headerPosition);

		// Clean up the event listener on unmount
		return () => {
			window.removeEventListener("scroll", headerPosition);
		};
	}, []);
	return (
		<header
			className={`sticky z-[999] top-0 left-0 w-full h-full ${navbarColor}`}
		>
			<div className="h-9 w-full bg-green flex items-center justify-center gap-1 md:gap-2 xl:gap-3">
				<div className="bg-[#00D348] px-[12px] rounded-2xl py-[2px]">
					<p className="text-white font-medium leading-5 text-sm">Beta</p>
				</div>
				<p className="text-white font-medium leading-5 hidden sm:block text-sm md:text-base xl:text-lg">
					We are currently onboarding collaborators
				</p>
				<p className="text-white font-medium leading-5 block sm:hidden text-sm md:text-base xl:text-lg">
				 Collaborators onboarding ongoing
				</p>
				{/* <FaArrowRightLong color="#fff" /> */}
			</div>
			{/* Web navigation */}
			<div
				className={`w-full h-[80px] flex items-center justify-between max-w-[1300px] mx-auto px-5 ${
					mobileMenuToggle ? "bg-white lg:bg-white/0" : "lg:bg-white/0"
				}`}
			>
				<Link to="/">
					<img
						src={"/assets/images/logo.png"}
						alt="logo"
						className="object-contain h-16"
					/>
				</Link>
				<div className="hidden items-center lg:flex space-x-6 ">
					<div className="relative flex items-center space-x-2 justify-between cursor-pointer group">
						<p className="text-base font-medium flex gap-3 items-center">
							Company{" "}
							<span className="group-hover:-rotate-180 transition ease-in-out duration-100">
								<IoIosArrowDown size={10} />
							</span>
						</p>
						<div
							className="hidden group-hover:md:block w-[240px] bg-white shadow-sm absolute top-6 left-0 rounded-3xl
							 p-3 translate-y-0 duration-200 ease-in-out transition"
						>
							<Link to="/about">
								<div className="group w-full rounded-xl hover:bg-green-light p-2">
									<h2 className="font-semibold text-sm text-[#000] group-hover:text-green">
										About
									</h2>
									<p className="font-normal text-xs leading-[18px] text-[#6C6C6C]">
										What is QuickLeap, who are we and what do we stand
										for?
									</p>
								</div>
							</Link>
							{/* <Link to="/blog">
								<div className="group w-full rounded-xl hover:bg-green-light p-2">
									<h2 className="font-semibold text-sm text-[#000] group-hover:text-green">
										Blog
									</h2>
									<p className="font-normal text-xs leading-[18px] text-[#6C6C6C]">
										Know more about us
									</p>
								</div>
							</Link> */}
							<Link to="/contact">
								<div className="group w-full rounded-xl hover:bg-green-light p-2">
									<h2 className="font-semibold text-sm text-[#000] group-hover:text-green">
										Contact us
									</h2>
									<p className="font-normal text-xs leading-[18px] text-[#6C6C6C]">
										Get in touch with us
									</p>
								</div>
							</Link>
							{/* <Link to="/blog">
								<div className="group w-full rounded-xl hover:bg-green-light p-2">
									<h2 className="font-semibold text-sm text-[#000] group-hover:text-green">
										FAQs
									</h2>
									<p className="font-normal text-xs leading-[18px] text-[#6C6C6C]">
										Frequently asked questions
									</p>
								</div>
							</Link> */}
						</div>
					</div>

					<Link to="/grow-for-life" className="text-base font-medium">
						Grow for life
					</Link>

					<Link to="/collaborator" className="text-base font-medium">
						Collaborator center
					</Link>
				</div>
				<div className="flex items-center lg:hidden cursor-pointer">
					{mobileMenuToggle ? (
						<svg
							onClick={() => setMenuMobileToggle(false)}
							width="18"
							height="18"
							viewBox="0 0 21 21"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M1.75 19.25L19.25 1.75M1.75 1.75L19.25 19.25"
								stroke="#0B7A24"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
						</svg>
					) : (
						<HiMenuAlt2
							size={25}
							color="#0B7A24"
							onClick={() => setMenuMobileToggle(true)}
						/>
					)}
				</div>
				
					<div className={`hidden lg:flex items-center space-x-6 text-sm ${isAuthenticated && currentUser 
						&& !getUserInfo.loading  && 'invisible'}`}>
						<Button
							className="border border-green bg-green text-white font-medium py-2 px-7 rounded-md"
							onClick={authInfoModal.onOpen}
						>
							Sign up
						</Button>
						<Link
							to="/login"
							className="border border-green text-green py-2 px-7 font-medium rounded-md"
						>
							Log In
						</Link>
					</div>
				
			</div>

			{/* Mobile navigation */}
			<div
				id="mobile_menu"
				className="lg:hidden absolute top-[100%] left-0 z-50 bg-black/20 w-full h-screen overflow-scroll"
			>
				<section className="bg-white flex flex-col gap-10 px-5 py-10">
					<MenuDropDown title="Company">
						<Link to="/about">About us</Link>
						{/* <Link to="/blog">Blog</Link> */}
						<Link to="/contact">Contact us</Link>
						{/* <Link to="/">FAQs</Link> */}
					</MenuDropDown>

					<Link
						to="/grow-for-life"
						className="text-green font-bold text-xl"
					>
						Grow for life
					</Link>

					<Link
						to="/collaborator"
						className="text-green font-bold text-xl"
					>
						Collaborator Center{" "}
					</Link>

					{isAuthenticated && currentUser && !getUserInfo.loading ? (
						<>
							<Button
								className="w-full border-2 hover:duration-500 border-red-600 py-5
								 hover:bg-red-600 hover:text-white text-red-600 font-medium 
								 flex items-center justify-center rounded-lg h-12"
								onClick={() => {
									dispatch(logoutUserAction());
									navigate("/login");
								}}
							>
								Logout
							</Button>
						</>
					) : (
						<div className="flex flex-col gap-3 mt-5">
							<Link
								to="/login"
								className="border py-3 text-center text-green font-semibold
								 border-green rounded-md"
							>
								Log in
							</Link>

							<Button
								onClick={authInfoModal.onOpen}
								className="py-3 bg-green text-center text-white font-semibold
								 rounded-md justify-center flex items-center h-12"
							>
								Sign up
							</Button>
						</div>
					)}
				</section>
			</div>
		</header>
	);
}
