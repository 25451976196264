import { useEffect, useState } from "react";
import { Country, State, City } from "country-state-city";
import { extraLocations } from "../constants/growForLife";

const useCountryList = () => {
	const [selectedCountry, setSelectedCountry] = useState<any>(null);
	const [selectedState, setSelectedState] = useState<any>(null);
	// console.log(selectedState);
	const [countryStates, setCountryStates] = useState<any>([]);
	// console.log(countryStates);
	const [stateCities, setStateCities] = useState<any>([]);

	const countryArray = Country.getAllCountries();

	const countries = countryArray.map((country) => {
		let newCountry = {
			...country,
			value: country.name.toLocaleLowerCase(),
			label: country.name,
		};

		return newCountry;
	});

	/* eslint-disable react-hooks/exhaustive-deps*/
	useEffect(() => {
		if (selectedCountry) {
			const country = countries?.find(
				(country) => country.value === selectedCountry?.value
			);

			const states = State.getStatesOfCountry(country?.isoCode).map(
				(state) => {
					let itemState = {
						value: state.name.toLocaleLowerCase(),
						label: state.name,
					};

					return itemState;
				}
			);
			// console.log(states)
			setCountryStates(states);
		}
	}, [selectedCountry]);

	/* eslint-disable react-hooks/exhaustive-deps*/
	useEffect(() => {
		if (selectedState) {
			const country = countries?.find(
				(country) => country.value === selectedCountry?.value
			);
			const states = State.getStatesOfCountry(country?.isoCode).map(
				(state) => {
					// console.log(state);
					let itemState = {
						value: state.name.toLocaleLowerCase(),
						label: state.name,
						isoCode: state.isoCode,
						countryCode: state.countryCode,
					};

					return itemState;
				}
			);

			const state = states?.find(
				(item: any) => item.value === selectedState?.value
			);

			const cities = City.getCitiesOfState(
				//@ts-ignore
				state?.countryCode,
				state?.isoCode
			).map((item) => {
				let itemCity = {
					value: item.name.toLocaleLowerCase(),
					label: item.name,
				};

				return itemCity;
			});

			const checkLocations = extraLocations.find(location => location.state === state?.value)?.locations

			const newLocations = checkLocations?.map((item) => {
				let itemCity = {
					value: item.toLocaleLowerCase(),
					label: item,
				};

				return itemCity;
			});

			if(checkLocations){
				setStateCities(newLocations);
			}else{
					setStateCities(cities)																		
			}
			
		}
	}, [selectedState]);

	return {
		stateCities,
		selectedCountry,
		setSelectedCountry,
		selectedState,
		setStateCities,
		setSelectedState,
		countryStates,
		setCountryStates,
		countries,
	};
};

export default useCountryList;
