import React from "react";
import { Link } from "react-router-dom";

interface BlogCardTypes {
  category: string;
  date: string;
  title: string;
  img: string;
}

const BlogCard: React.FC<BlogCardTypes> = ({ img, title, category, date }) => {
  return (
    <Link to={`/blog/first-post`} className="flex flex-col gap-5">
      <img
        src="/assets/images/grain.png"
        className="w-full object-cover rounded-md h-[18rem]"
        alt=""
      />
      <div className="flex flex-col gap-2 w-full text-sm font-semibold">
        <p className="text-[#6c6c6c]">Impact</p>
        <h3 className="text-lg font-bold text-green leading-6">
          The case for commodity trading- A Quick leap perspective
        </h3>
        <span className="text-xs">Feb 18, 2023</span>
      </div>
    </Link>
  );
};

export default BlogCard;
