export function numberWithCommas(number: number) {
	if (!number) return number;
	return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function deepClone(object: any) {
	return JSON.parse(JSON.stringify(object));
}

export const shortenWord = (str: string, n: number) => {
	return str.length >= n ? str.slice(0, n) + "..." : str;
};

export function decimalNumberWithCommas(number: number) {
	const options = {
		minimumFractionDigits: 2,
		maximumFractionDigits: 4,
	};
	const formattedWithOptions = number.toLocaleString("en-US", options);

	return formattedWithOptions;
}
