import React from "react";
import Container from "../../Container";

const ProcessGrowforlifeSection = () => {
	const processItems = [
		{
			image: "../assets/images/membership-man.png",
			title: "Become a member",
			info: "Create a free subscription for all your good food, groceries, and other home essentials  We grow food and fresh vegetables for you; we also curate from farmers, partner farms, and growers who grow food, vegetables, livestock and other food crops responsibly.",
		},
		{
			image: "../assets/images/build-order.png",
			title: "Build your order",
			info: "You make a weekly, bi-weekly, or monthly pick of fresh vegetables, meat, fresh produce, roots, tubers, essentially everything you need to stock the house and cook remarkable meals.",
		},
		{
			image: "../assets/images/unpack-image.png",
			title: "Unpack & enjoy",
			info: "Unpack & enjoy your shopping list delivered to you, we will deliver your shopping list promptly.",
		},
	];
	return (
		<div className="w-full bg-white">
			<Container className="px-5 py-8 lg:py-10 xl:py-16">
				<div className="w-full flex items-center justify-center flex-col space-y-2 my-5">
					<h2 className="text-black font-semibold text-2xl md:text-4xl">
						How it works
					</h2>
					<p className="text-[#2E2D2D] text-center font-normal text-base md:text-lg leading-8">
						We have tailored this product intentionally to serve the
						specific needs you have.
					</p>
				</div>
				<div className="w-full grid grid-cols-1 md:grid-cols-3 gap-5 mt-10">
					{processItems.map((items, index) => (
						<div
							key={index}
							className="w-full border border-[#B1B1B1] rounded-2xl p-4"
						>
							<div className="w-full h-[16rem] rounded-lg overflow-hidden">
								<img
									src={items.image}
									alt={items.title}
									className="w-full h-full object-cover block"
								/>
							</div>
							<h2 className="font-semibold text-black text-xl md:text-3xl mt-5">
								{items.title}
							</h2>
							<p className="text-[#6C6C6C] font-normal leading-[1.5rem] mt-3">
								{items.info}
							</p>
						</div>
					))}
				</div>
			</Container>
		</div>
	);
};

export default ProcessGrowforlifeSection;
