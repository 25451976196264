import * as yup from "yup";
import AuthLoginLayout from "../../components/organisms/layouts/AuthLoginLayout";
import LoginHeader from "../../components/molecules/LoginHeader";
import { Link } from "react-router-dom";
import { Form, Formik } from "formik";
import { toast } from "react-toastify";
import FormInput from "../../components/molecules/FormInput";
import PasswordInput from "../../components/molecules/PasswordInput";
import Button from "../../components/molecules/Button";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { selectAdmin } from "../../store/admin/reducer";
import { handleAdminLoginAction } from "../../store/admin/action";

const userSchema = yup.object({
	password: yup.string().required("Password is required"),
	email: yup.string().email().required("Email is required"),
});

type User = yup.InferType<typeof userSchema>;

const AdminLoginPage = () => {
	const dispatch = useAppDispatch();
	const { getAdminLogin } = useAppSelector(selectAdmin);
	const initialValues: User = {
		password: "",
		email: "",
	};

	const handleSubmit = async (values: User) => {
		const { password, email } = values;

		if (!password || !email) {
			// setLoading(false);
			return toast.error("All fields are required");
		}

		const data = {
			email,
			password,
		};

		const {
			meta: { requestStatus },
			payload,
		} = await dispatch(handleAdminLoginAction(data));

		if (requestStatus === "rejected") {
			console.log(payload);
			toast.error(payload ?? "Something went wrong");
			return;
		}

		if (requestStatus === "fulfilled") {
			toast.success("Login successful");
			localStorage.setItem("quickleapUser", JSON.stringify(payload));
			window.location.href = "/admin/dashboard";
		}
	};

	return (
		<AuthLoginLayout loginPage={"LOGIN"}>
			<section className="px-8 py-10 bg-white rounded-md w-full">
				<div className="w-full h-16 mb-2">
					<Link to="/">
						<img
							src={"/assets/images/logo.png"}
							alt="logo"
							className="w-full h-full object-contain"
						/>
					</Link>
				</div>

				<div className="w-full xl:px-10">
					<LoginHeader
						header="Welcome Back!"
						subHeader="Log in to your account"
					/>

					<Formik
						initialValues={initialValues}
						onSubmit={async (values: User, { resetForm }) => {
							await handleSubmit(values);
							resetForm();
						}}
						validationSchema={userSchema}
					>
						{({ values, setFieldValue }) => {
							return (
								<Form className="mt-8 flex flex-col gap-4">
									<FormInput
										id="email"
										name="email"
										placeholder="Email Address"
										label="Email Address"
										onChange={(e) => {
											setFieldValue("email", e.target.value);
										}}
									/>

									<PasswordInput
										id="password"
										name="password"
										placeholder="Password"
										label="Password"
									/>

									<Link
										to="/admin/reset-password"
										className="text-green text-right inline-block text-sm font-medium mb-2"
									>
										Forgot Password?
									</Link>

									<Button
										type="submit"
										className="bg-green w-full font-semibold rounded-lg h-[3rem] flex items-center justify-center leading-7"
										isLoading={getAdminLogin.loading}
										disabled={getAdminLogin.loading}
									>
										Login
									</Button>
								</Form>
							);
						}}
					</Formik>

					{/* <div className="max-w-[300px] mx-auto mt-4 text-center font-normal text-[#A4A4A4] flex items-center justify-center gap-1">
						Don't have an account?{" "}
						<p
							onClick={() => {
								authInfoModal.onOpen();
							}}
							className="font-medium text-green cursor-pointer"
						>
							Create one
						</p>
					</div> */}
				</div>
			</section>
		</AuthLoginLayout>
	);
};

export default AdminLoginPage;
