import React from "react";
import Container from "../../Container";
import { MdArrowForwardIos } from "react-icons/md";

const StriveSection = () => {
	return (
		<div className="w-full bg-green-light overflow-hidden">
			<Container className="px-5 py-10 lg:py-24">
				<div className="w-full relative bg-green rounded-2xl grid grid-cols-1 md:grid-cols-2 p-3 pb-20 md:pb-0">
					<div className="self-stretch relative min-h-[20rem] scale-[1.2] md:scale-[1.5]  w-full">
						<img
							src="/assets/images/phone.png"
							className="absolute w-full h-full object-contain z-10 md:top-[-9%]"
							alt="phone"
						/>
						<svg
							className="absolute w-44 h-44 lg:w-40 lg:h-60 top-1/2 left-[35%] lg:left-[50%] -translate-y-[60%]"
							viewBox="0 0 384 431"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<rect
								x="221.407"
								y="-11"
								width="212.681"
								height="404"
								rx="30"
								transform="rotate(35.1182 221.407 -11)"
								fill="#78BC82"
								fillOpacity="0.52"
							/>
						</svg>
					</div>

					<div className="flex flex-col gap-7 ml-auto justify-center md:py-16 bg-green relative z-10">
						<h3 className="text-white font-semibold text-2xl lg:text-4xl leading-10">
							We strive to deliver absolute delight!
						</h3>
						<div className="mt-2 space-y-2 lg:space-y-5 pl-3 md:pl-0">
							<InfoComponent infoP="355,000+ Kilograms of produce shipped We deliver, locally, within the region, and globally!" />
							<InfoComponent infoP="We deliver to processors, Food businesses & FMCG’s locally, within the region, and globally!" />
							<InfoComponent infoP="We deliver to homes and restaurants locally, within the region, and globally!" />
						</div>
					</div>

					<div className="absolute h-full flex items-end bottom-0 overflow-hidden rounded-2xl w-full">
						<svg
							className="relative top-16  w-32 h-[12rem] lg:w-40 lg:h-[20rem]"
							viewBox="0 0 112 88"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<rect
								x="24.8047"
								y="-11"
								width="120.715"
								height="402.952"
								rx="30"
								transform="rotate(35.1182 24.8047 -11)"
								fill="#78BC82"
								fillOpacity="0.52"
							/>
						</svg>
					</div>
				</div>
			</Container>
		</div>
	);
};

export default StriveSection;

const InfoComponent = ({ infoP }: { infoP: string }) => {
	return (
		<div className="flex items-center w-full space-x-3">
			<MdArrowForwardIos color="#FFF" size={18} />
			<p className="text-white">{infoP}</p>
		</div>
	);
};
