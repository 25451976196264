import { useEffect, useState } from "react";
import { Link, Element, Events, scrollSpy } from "react-scroll";
import SectionHeader from "../../../molecules/SectionHeader";

const ReturnPolicySection = () => {
	const [activeTab, setActiveTab] = useState<string>("introduction");
	const sideTabs: { label: string; value: string }[] = [
		{ label: "Introduction", value: "introduction" },
		{ label: "Eligibility for Returns", value: "eligibility-returns" },
		{ label: "Return Process", value: "return-process" },
		{ label: "Refund or Exchange", value: "refund-exchange" },
		{
			label: "Return Shipping",
			value: "return-shipping",
		},
		{ label: "Inspection and Processing", value: "inspection-processing" },
		{ label: "Exceptions", value: "exceptions" },
		{ label: "Cancellation Fee Policy", value: "cancellation" },
		{ label: "Contact Us", value: "contact" },
	];

	useEffect(() => {
		// Registering the 'begin' event and logging it to the console when triggered.
		Events.scrollEvent.register("begin", (to, element) => {
			console.log("begin", to, element);
		});

		// Registering the 'end' event and logging it to the console when triggered.
		Events.scrollEvent.register("end", (to, element) => {
			console.log("end", to, element);
		});

		// Updating scrollSpy when the component mounts.
		scrollSpy.update();

		// Returning a cleanup function to remove the registered events when the component unmounts.
		return () => {
			Events.scrollEvent.remove("begin");
			Events.scrollEvent.remove("end");
		};
	}, []);
	return (
		<div className="relative w-full min-h-[100vh] max-h-[100vh]">
			<div className="absolute left-0 top-0 hidden xl:w-[200px] 2xl:w-[250px] show-scrollbar border-r bg-white border-gray-100 overflow-y-scroll h-[100dvh] xl:flex items-start justify-start text-left flex-col gap-7 p-2">
				{sideTabs.map(
					(tab: { label: string; value: string }, indx: number) => (
						<Link
							to={`${tab.value}`}
							key={tab.value}
							smooth={true}
							duration={600}
							containerId="containerElement"
						>
							<div
								className={`cursor-pointer ${
									activeTab === tab.value
										? "text-[#118507] "
										: "text-black"
								}`}
								onClick={() => {
									setActiveTab(tab.value);
								}}
							>
								{tab.label}
							</div>
						</Link>
					)
				)}
			</div>

			<div
				className="absolute right-0 top-0 h-full w-full xl:w-[calc(100%-200px)] 2xl:w-[calc(100%-250px)] max-h-[100dvh] overflow-y-scroll bg-white py-2 xl:px-5 2xl:px-8 gap-1"
				id="containerElement"
			>
				<Element name="introduction">
					<div className="min-h-[100px] pb-5 pt-10">
						<div className="w-full flex flex-col items-center justify-center gap-3 text-center">
							<h2 className="text-3xl md:text-4xl xl:text-5xl text-black font-semibold">
								Return Policy
							</h2>
							<p className="text-sm text-gray-300 font-normal">
								Last Updated: November 8th, 2022
							</p>
						</div>
						<p className="text-black text-sm font-normal mt-5">
							Thank you for choosing Quick Leap, our goal is to provide
							you ease and rest in the process of acquiring all the
							healthy and nutritious food you need to live a healthy and
							happy life from infancy to adulthood.
						</p>
						<p className="text-black text-sm font-normal mt-5">
							At Quick Leap, we strive to provide you and all of our
							customers and collaborators with the highest quality food,
							water and beverage products and services. We understand
							that there may be occasions where a return or exchange is
							necessary. To ensure a smooth and efficient process, we
							have developed the following return policy:
						</p>
						<h4 className="font-semibold mt-5 text-base leading-7 text-black">
							Key Terms
						</h4>
						<ol className="mt-5 pl-2 space-y-2">
							<li className="text-black text-sm font-normal flex items-start justify-start gap-1">
								1.{" "}
								<p>
									Cancellation on the day of delivery is not possible
									as all items will have already begun processing for
									delivery. Cancellation of any order must be done
									before the delivery day.
								</p>
							</li>
							<li className="text-black text-sm font-normal flex items-start justify-start gap-1">
								2.{" "}
								<p>
									In the event that you reject an order or you are not
									available to receive your order after it has been
									dispatched to you, an additional delivery fee will be
									charged for a second delivery and Quick Leap is not
									liable for natural deterioration of perishable items
									in such cases.
								</p>
							</li>
							<li className="text-black text-sm font-normal flex items-start justify-start gap-1">
								3.{" "}
								<p>
									While our utmost priority is to ensure our users and
									collaborators; satisfaction, we acknowledge that
									there may be instances where items are not delivered
									in their entirety. This can occur due to delays on
									the part of our collaborators and suppliers or issues
									related to the quality of specific items supplied.
									Nevertheless, please rest assured that we are fully
									committed to making every effort to provide you with
									complete orders whenever possible.
								</p>
							</li>
							<li className="text-black text-sm font-normal flex items-start justify-start gap-1">
								4.{" "}
								<p>
									Please be advised that our delivery
									partners/collaborators adhere to a strict 15-minute
									waiting window at designated locations for users and
									30 minutes to 2 hours for collaborators as the case
									demands . This policy is in place to ensure timely
									deliveries to all our valued users and collaborators,
									including those who may be awaiting their orders.{" "}
									<br />
									To prevent any potential delays or additional
									transportation costs, we kindly request your prompt
									availability to receive your order within this
									specified timeframe. Your cooperation in this matter
									is greatly appreciated.
								</p>
							</li>
							<li className="text-black text-sm font-normal flex items-start justify-start gap-1">
								5.{" "}
								<p>
									We want you to be happy with your purchases, so we
									offer refunds for both perishable and non-perishable
									items within six (6) hours of delivery and seven (3)
									to fifteen (15) days for collaborators, please see
									the rest of this policy for specifics .
								</p>
							</li>
						</ol>
						<p className="text-black text-sm font-normal mt-5">
							To learn more about our refund policy, please read this
							guide. But please keep in mind that once you've paid for
							food items, you can't cancel your order on the day of
							delivery.
							<br />
							If you do need a refund, we'll process it through your
							provided bank account, unless our refund policy says
							otherwise.
						</p>
					</div>
				</Element>
				<Element name="eligibility-returns">
					<div className="min-h-[100px] pb-5 pt-10">
						<SectionHeader
							pageHeader="Eligibility for Returns"
							pageNumber={1}
						/>
						<ol className="mt-5 pl-2 space-y-1">
							<li className="text-black text-sm font-normal flex items-start md:items-center justify-start gap-1">
								<div className="w-[5px] h-[5px] min-w-[5px] min-h-[5px] rounded-full bg-black" />
								<p>
									Products eligible for return must be in their
									original condition, unopened, and unused (where
									applicable).
								</p>
							</li>
							<li className="text-black text-sm font-normal flex items-start md:items-center justify-start gap-1">
								<div className="w-[5px] h-[5px] min-w-[5px] min-h-[5px] rounded-full bg-black" />
								<p>
									Requests for returns must be made between six (6)
									hours of delivery for both users and collaborators.
								</p>
							</li>
							<li className="text-black text-sm font-normal flex items-start md:items-center justify-start gap-1">
								<div className="w-[5px] h-[5px] min-w-[5px] min-h-[5px] rounded-full bg-black" />
								<p>
									Returns are accepted within 24 hours of the delivery
									for non-perishable food items, and 6-8 hours for
									certain perishable items.
								</p>
							</li>
							<li className="text-black text-sm font-normal flex items-start md:items-center justify-start gap-1">
								<div className="w-[5px] h-[5px] min-w-[5px] min-h-[5px] rounded-full bg-black" />
								<p>
									Perishable items, such as fresh produce, dairy, and
									meats, are not eligible for return, we will work
									within our very best to ensure that the best of the
									very best is what is delivered to you.
								</p>
							</li>
						</ol>
						<h4 className="font-semibold mt-5 text-base leading-7 text-black">
							Exempted Food Items for Return/Refunds
						</h4>
						<p className="text-black text-sm font-normal mt-5">
							Under this policy, Quick Leap will not accept any returns
							or issue refunds for any food items (regardless of whether
							they are perishable or non-perishable) unless a valid
							complaint is filed within 6 hours of receipt of delivery
							and confirmed by Quick Leap.
						</p>
						<h4 className="font-semibold mt-5 text-base leading-7 text-black">
							Delayed Refunds
						</h4>
						<p className="text-black text-sm font-normal mt-5">
							Once Quick Leap confirms your refund, kindly check your
							bank account to confirm if the refund has been processed.
							<br />
							If not please allow some time for the refund to be
							officially posted by your bank.
							<br />
							In case you have checked your accounts and have not
							received your refund, please contact us at
							my-success@quickleap.co, via WhatsApp at 08134860483, or
							call 08134860483 for assistance.
						</p>
					</div>
				</Element>
				<Element name="return-process">
					<div className="min-h-[100px] pb-5 pt-10">
						<SectionHeader pageHeader="Return Process" pageNumber={2} />
						<ol className="mt-5 pl-2 space-y-1">
							<li className="text-black text-sm font-normal flex items-start md:items-center justify-start gap-1">
								<div className="w-[5px] h-[5px] min-w-[5px] min-h-[5px] rounded-full bg-black" />
								<p>
									To initiate a return, customers must contact Quick
									Leap Customer Support within the specified return
									period of six (6) hours.
								</p>
							</li>
							<li className="text-black text-sm font-normal flex items-start md:items-center justify-start gap-1">
								<div className="w-[5px] h-[5px] min-w-[5px] min-h-[5px] rounded-full bg-black" />
								<p>
									Customers may be required to provide proof of
									purchase, such as date of purchase, invoice, order no
									where assigned to facilitate the return process.
								</p>
							</li>
							<li className="text-black text-sm font-normal flex items-start md:items-center justify-start gap-1">
								<div className="w-[5px] h-[5px] min-w-[5px] min-h-[5px] rounded-full bg-black" />
								<p>
									Quick Leap reserves the right to refuse returns that
									do not meet the eligibility criteria outlined in this
									policy.
								</p>
							</li>
						</ol>
					</div>
				</Element>

				<Element name="refund-exchange">
					<div className="min-h-[100px] pb-5 pt-10">
						<SectionHeader
							pageHeader="Refund or Exchange"
							pageNumber={3}
						/>
						<ol className="mt-5 pl-2 space-y-1">
							<li className="text-black text-sm font-normal flex items-start md:items-center justify-start gap-1">
								<div className="w-[5px] h-[5px] min-w-[5px] min-h-[5px] rounded-full bg-black" />
								<p>
									Upon approval of a return, customers may choose to
									receive a refund or exchange for an equivalent item.
								</p>
							</li>
							<li className="text-black text-sm font-normal flex items-start md:items-center justify-start gap-1">
								<div className="w-[5px] h-[5px] min-w-[5px] min-h-[5px] rounded-full bg-black" />
								<p>
									Refunds will be issued to the original form of
									payment used for the purchase.
								</p>
							</li>
							<li className="text-black text-sm font-normal flex items-start md:items-center justify-start gap-1">
								<div className="w-[5px] h-[5px] min-w-[5px] min-h-[5px] rounded-full bg-black" />
								<p>
									Exchanges are subject to product availability and may
									be processed as a replacement item or store credit.
								</p>
							</li>
						</ol>
					</div>
				</Element>

				<Element name="return-shipping">
					<div className="min-h-[100px] pb-5 pt-10">
						<SectionHeader pageHeader="Return Shipping" pageNumber={4} />
						<ol className="mt-5 pl-2 space-y-1">
							<li className="text-black text-sm font-normal flex items-start md:items-center justify-start gap-1">
								<div className="w-[5px] h-[5px] min-w-[5px] min-h-[5px] rounded-full bg-black" />
								<p>
									Customers are responsible for the cost of return
									shipping unless the return is due to an error on the
									part of Quick Leap, such as incorrect or damaged
									items.
								</p>
							</li>
							<li className="text-black text-sm font-normal flex items-start md:items-center justify-start gap-1">
								<div className="w-[5px] h-[5px] min-w-[5px] min-h-[5px] rounded-full bg-black" />
								<p>
									 If the user or collaborator rescinds or changes an
									initial order, they will be responsible for bearing
									the cost of transportation.
								</p>
							</li>
							<li className="text-black text-sm font-normal flex items-start md:items-center justify-start gap-1">
								<div className="w-[5px] h-[5px] min-w-[5px] min-h-[5px] rounded-full bg-black" />
								<p>
									Quick Leap may provide prepaid return labels for
									eligible returns at its discretion.
								</p>
							</li>
						</ol>
					</div>
				</Element>

				<Element name="inspection-processing">
					<div className="min-h-[100px] pb-5 pt-10">
						<SectionHeader
							pageHeader="Inspection and Processinge"
							pageNumber={5}
						/>
						<ol className="mt-5 pl-2 space-y-1">
							<li className="text-black text-sm font-normal flex items-start md:items-center justify-start gap-1">
								<div className="w-[5px] h-[5px] min-w-[5px] min-h-[5px] rounded-full bg-black" />
								<p>
									All returned items will be inspected upon receipt to
									ensure compliance with the return policy.
								</p>
							</li>
							<li className="text-black text-sm font-normal flex items-start md:items-center justify-start gap-1">
								<div className="w-[5px] h-[5px] min-w-[5px] min-h-[5px] rounded-full bg-black" />
								<p>
									Refunds or exchanges will be processed within 3-15
									business days of receiving the returned items.
								</p>
							</li>
						</ol>
					</div>
				</Element>

				<Element name="exceptions">
					<div className="min-h-[100px] pb-5 pt-10">
						<SectionHeader
							pageHeader="Refund or Exchange"
							pageNumber={6}
						/>
						<ol className="mt-5 pl-2 space-y-1">
							<li className="text-black text-sm font-normal flex items-start md:items-center justify-start gap-1">
								<div className="w-[5px] h-[5px] min-w-[5px] min-h-[5px] rounded-full bg-black" />
								<p>
									Certain items may be non-returnable or subject to
									additional restrictions, such as personalised or
									custom-made products.
								</p>
							</li>
							<li className="text-black text-sm font-normal flex items-start md:items-center justify-start gap-1">
								<div className="w-[5px] h-[5px] min-w-[5px] min-h-[5px] rounded-full bg-black" />
								<p>
									Returns of non-conforming or defective items will be
									handled on a case-by-case basis in accordance with
									applicable consumer protection laws.
								</p>
							</li>
						</ol>
					</div>
				</Element>
				<Element name="cancellation">
					<div className="min-h-[100px] pb-5 pt-10">
						<SectionHeader
							pageHeader="Cancellation Fee Policy"
							pageNumber={7}
						/>
						<p className="text-black text-sm font-normal mt-5">
							In the event that Users or Collaborators decide to cancel
							an invoice or order after they have been agreed upon and
							processed, Quick Leap implements a cancellation fee
							equivalent to 5% of the invoice or order value. This fee is
							intended to cover the operational costs incurred by Quick
							Leap in the process of fulfilling the order, including but
							not limited to procurement, logistics, and administrative
							expenses.
						</p>
						<p className="text-black text-sm font-normal mt-5">
							Quick Leap understands that cancellations can disrupt the
							supply chain and incur costs for all parties involved.
							Therefore, the imposition of a cancellation fee aims to
							mitigate these costs and ensure that Quick Leap's
							operations remain sustainable.
						</p>
						<p className="text-black text-sm font-normal mt-5">
							Users and Collaborators are encouraged to carefully review
							and confirm their orders before finalising invoices to
							avoid unnecessary cancellations and associated fees. By
							adhering to this policy, Quick Leap seeks to foster
							accountability and responsible decision-making among its
							stakeholders while maintaining the efficiency and integrity
							of its operations.
						</p>
					</div>
				</Element>
				<Element name="cancellation">
					<div className="min-h-[100px] pb-5 pt-10">
						<SectionHeader
							pageHeader="Contact Us"
							pageNumber={8}
						/>
						<ul className="ordered__list mt-5 pl-2 space-y-1 ">
							<li className="text-black text-sm font-normal flex items-start  justify-start  gap-1 list-decimal">
								<div className="w-[5px] h-[5px] min-w-[5px] min-h-[5px] rounded-full bg-black" />

								<p className="mb-1">
									For assistance with returns or inquiries regarding
									our return policy, please contact Quick Leap Customer
									Support at my-success@quickleap.co or 08134860483.
								</p>
							</li>
						</ul>
					</div>
				</Element>
			</div>
		</div>
	);
};

export default ReturnPolicySection;
