import React from "react";
import Container from "../../Container";
import { Link } from "react-router-dom";
import useAuthInfoModal from "../../../../hooks/modal-hooks/useAuthInfoModal";
import Button from "../../../molecules/Button";
import { useAppSelector } from "../../../../store/hooks";
import { selectAuthUser } from "../../../../store/auth/authReducer";
import { FaArrowRightLong } from "react-icons/fa6";

const HeroSection = () => {
	const authInfoModal = useAuthInfoModal();
	const { currentUser, isAuthenticated } = useAppSelector(selectAuthUser);

	return (
		<div className="bg-green-light lg:pt-10 ">
			<Container className="w-full gap-5 px-5 py-16 flex flex-col justify-center">
				<h2 className="text-4xl md:text-[3.5rem] leading-tight max-w-[80rem] text-[#1B1C24] text-center font-extrabold md:font-bold lg:px-5 mx-auto">
					Good food for everyone, everywhere, everytime!
				</h2>
				<p className="leading-6 text-[#3D3E48] font-normal text-center md:max-w-2xl xl:max-w-5xl md:mx-auto">
					We exist to promote your life, providing all the food you’ll need
					to live a healthy and happy life from infancy to adulthood,
					enabling businesses by providing access to well graded raw
					materials at scale, while, transforming the communities we
					operate in.
				</p>
				<div className="flex items-center justify-center space-x-5 my-5">
					{!isAuthenticated && !currentUser ? (
						<>
							<Button
								className="border py-5 flex items-center justify-center border-green text-center px-10 h-[50px] font-semibold rounded-md bg-green text-white"
								onClick={authInfoModal.onOpen}
							>
								Sign up
							</Button>
							<Link
								to={"/login"}
								className="border border-green w-32 text-center p-3
					px-4 font-semibold rounded-md text-green"
							>
								Log in
							</Link>
						</>
					) : (
						<>
							<Link
								to={"/grow-for-life/home"}
								className="border border-green  bg-green text-white w-fit text-center p-3 px-4 font-semibold rounded-md flex items-center justify-center gap-2"
							>
								Get started <FaArrowRightLong />
							</Link>
						</>
					)}
				</div>
			</Container>
		</div>
	);
};

export default HeroSection;
