import Axios from "axios";
const NODE_ENV = process.env.REACT_APP_NODE_ENV;

const axios = Axios.create({
	baseURL:
		NODE_ENV === "development" ||
		NODE_ENV === "DEVELOPMENT" ||
		NODE_ENV === "dev"
			? "https://quickleap-backend-v1-main.onrender.com/v1"
			: "https://api.quickleap.co/v1",
	withCredentials: true,
});

axios.interceptors.request.use(
	(config) => {
		const authUser =
			//@ts-ignore
			JSON.parse(localStorage.getItem("quickleapUser")) ?? null;
		config.url = config.url?.replace(/[^\x20-\x7E]/g, "");
		config.headers!["Content-Type"] = "application/json";
		if (authUser) {
			config.headers!["Authorization"] = `Bearer ${authUser?.accessToken}`;
		}

		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

axios.interceptors.response.use(undefined, (err) => {
	if (
		err.response?.status === 403 ||
		err.response?.data?.message === "Unauthorized"
	) {
		localStorage.setItem("quickleapUser", JSON.stringify(null));
		window.location.reload();
	}
	return Promise.reject(err);
});

export default axios;
