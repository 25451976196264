import Modal from "./Modal";
import { IoCloseOutline } from "react-icons/io5";
import FormInput from "../molecules/FormInput";
import { Form, Formik } from "formik";
import { useAppSelector } from "../../store/hooks";
import * as yup from "yup";
import { toast } from "react-toastify";
import useContactModal from "../../hooks/modal-hooks/useContactModal";
import FormTextArea from "../molecules/FormTextArea";
import { selectProfile } from "../../store/profile/reducer";
import Button from "../molecules/Button";
import { useLocation } from "react-router-dom";
import FormSearchDropDown from "../molecules/FormSearchDropDown";
import { makeComplaint } from "../../store/services/userServices";

interface ComplaintFormTypes {
  email: string
  issueType: string
  description: string
}

const complaintFormSchema = yup.object({
  email: yup.string().email().required("Email is required"),
  issueType: yup.string().required("Select an issue type"),
  description: yup.string().required("Description is required"),
});

const collaboratorIssues = ['Delayed product approval', 'Delayed payments', 'Product pricing']
const growForLifeIssues:any = []

const ContactUsModal = () => {
  const { isOpen, onClose } = useContactModal();
  const { profile } = useAppSelector(selectProfile);
  const location = useLocation()
  let issues = location.pathname.includes('collaborator') ? collaboratorIssues : growForLifeIssues
  issues = [...issues, 'Payment issues', 'Delayed Deliveries', 'Issues with my account',
   'Wrong product delivered', 'Bad or expired product'] 

   const handleComplaintSubmit = async(data:ComplaintFormTypes, setSubmitting: (isSubmitting: boolean) => void)=>{
    try{
      const res = await makeComplaint(data)
      if(res){
        toast.success('Complaint has been submitted')
        onClose()
      }
    }
    catch (error: any) {
      toast.error(error?.response.data.message);
    } finally{
      setSubmitting(false)
    }
   }

	if (!isOpen) {
		return <></>;
	}

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			full={false}
			className="translate relative mx-auto flex-col border-0 bg-white inline-flex shadow-lg outline-none focus:outline-none md:h-auto p-5 rounded-md w-full max-w-[500px]"
		>
			<div className="flex justify-between text-2xl items-center font-medium">
				<h2>Help & Support</h2>
				<button onClick={onClose}>
					<IoCloseOutline />
				</button>
			</div>

      <Formik
        initialValues={{
          email: profile?.email ?? "",
          description: "",
          issueType: "",
        }}
        validationSchema={complaintFormSchema}
        onSubmit={(values, {setSubmitting}) => {
          handleComplaintSubmit(values, setSubmitting);
        }}
      >
        {({ values, setFieldValue, isSubmitting }) => {
          return (
            <Form className="mt-4 flex flex-col gap-2">
              <FormInput
                id="email"
                name="email"
                placeholder=""
                label="Email"
                onChange={(e) => {
                  setFieldValue("email", e.target.value);
                }}
              />

              <FormSearchDropDown
                name="issueType"
                id="issueType"
                onChange={(value) => {
                  setFieldValue("issueType", value);
                }}
                options={issues.map((issue:string)=>{
                  return {label:issue, value:issue}
                } ) }
                label="Issue Type"
                placeholder="Select"
              />

							<FormTextArea
								id="description"
								name="description"
								placeholder="Describe your issue"
								label="Description"
								onChange={(e) => {
									setFieldValue("description", e.target.value);
								}}
								rows="3"
							/>

              <Button
                disabled={isSubmitting}
                isLoading={isSubmitting}
                className="p-3 bg-green font-semibold text-white rounded-md w-full
                            flex items-center justify-center h-12 mt-3"
                type="submit"
              >
                Submit
              </Button>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default ContactUsModal;
