import React from "react";
import FaqsAccordin from "../../../molecules/FaqsAccordin";
import Container from "../../Container";

const FaqsCollaboratorSection = () => {
  return (
    <div className="w-full h-full bg-green-light">
      <Container className="px-5 py-5 lg:py-10">
        <div className="my-14">
          <h2 className="font-semibold text-black text-center text-[30px] leading-9 sm:text-[35px] sm:leading-10 lg:text-[40px] lg:leading-[60px]">
            FAQs
          </h2>

          <div className="flex flex-col mt-10 items-center gap-6">
            <FaqsAccordin header="What is Collaborator Center?">
              <p>
                Collaborator center is our collection center, it is designed to
                bring together the players in the food space and the supply we
                need to serve good food to people everywhere. Collaborator
                center serves the collaborators we work with a single point to
                source all the raw materials they need and sell the finished
                products they create.
              </p>
            </FaqsAccordin>

            <FaqsAccordin header="How do I access my dashboard?">
              <p>
                Simply login to your Collaborator account if you already have an
                account or create an account to enjoy your dashboard and other
                remarkable features designed to bring you ease and delight.
              </p>
            </FaqsAccordin>

            <FaqsAccordin header="What functions can I perform on Collaborator Center?">
              <p>
                As a collaborator you can:
                <br />
                1. Source all the raw materials you need to create and arrange
                for them to be delivered to you in an orderly way through the
                Inventory manager.
                <br />
                <br />
                2. Sell all the finished products you have produced or created.
                <br />
                <br />
                3. List assets for deliveries and get unending orders as our 3rd
                party delivery partner.
              </p>
            </FaqsAccordin>

            <FaqsAccordin header="How can I access my uploaded products?">
              <p>
                You can find all your uploaded products at the Uploaded products
                tab on your navigation bar to the right of your dashboard, you
                can also edit Uploaded products to reflect your current inhouse
                Inventory.
              </p>
            </FaqsAccordin>

            <FaqsAccordin header="What is the function of a field agent">
              <p>
                Our field agents are your friends, think of them as our people
                dedicated to ensuring your success, they will help you get
                onboarded and ensure your success with Quick Leap
              </p>
            </FaqsAccordin>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default FaqsCollaboratorSection;
