import React from "react";
import Container from "../../components/organisms/Container";
import WebLayout from "../../components/organisms/layouts/WebLayout";
import GetStartedCta from "../../components/molecules/GetStartedCta";
import BlogCard from "../../components/organisms/layouts/BlogCard";

const BlogPostScreen: React.FC = () => {
	return (
		<WebLayout>
			<Container className="py-10 lg:py-20 px-5 flex flex-col gap-10">
				<section className="space-y-5">
					<h1 className="font-bold text-green text-2xl md:text-3xl">
						Our Impact story so far
					</h1>
					<div className="flex gap-3 text-xs items-center">
						<p className="flex gap-2 items-center ">
							{" "}
							<svg
								width="16"
								height="17"
								viewBox="0 0 16 17"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M10.6654 4.86198C10.6654 5.56922 10.3844 6.2475 9.88432 6.7476C9.38422 7.24769 8.70594 7.52865 7.9987 7.52865C7.29145 7.52865 6.61318 7.24769 6.11308 6.7476C5.61298 6.2475 5.33203 5.56922 5.33203 4.86198C5.33203 4.15474 5.61298 3.47646 6.11308 2.97636C6.61318 2.47626 7.29145 2.19531 7.9987 2.19531C8.70594 2.19531 9.38422 2.47626 9.88432 2.97636C10.3844 3.47646 10.6654 4.15474 10.6654 4.86198V4.86198ZM7.9987 9.52865C6.76102 9.52865 5.57404 10.0203 4.69887 10.8955C3.8237 11.7707 3.33203 12.9576 3.33203 14.1953H12.6654C12.6654 12.9576 12.1737 11.7707 11.2985 10.8955C10.4234 10.0203 9.23637 9.52865 7.9987 9.52865V9.52865Z"
									stroke="#6C6C6C"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
							</svg>
							By Quickleap
						</p>
						<p className="flex gap-2 items-center ">
							<svg
								width="16"
								height="17"
								viewBox="0 0 16 17"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M8 5.52865V8.19531L10 10.1953M14 8.19531C14 8.98324 13.8448 9.76346 13.5433 10.4914C13.2417 11.2194 12.7998 11.8808 12.2426 12.438C11.6855 12.9951 11.0241 13.4371 10.2961 13.7386C9.56815 14.0401 8.78793 14.1953 8 14.1953C7.21207 14.1953 6.43185 14.0401 5.7039 13.7386C4.97595 13.4371 4.31451 12.9951 3.75736 12.438C3.20021 11.8808 2.75825 11.2194 2.45672 10.4914C2.15519 9.76346 2 8.98324 2 8.19531C2 6.60401 2.63214 5.07789 3.75736 3.95267C4.88258 2.82745 6.4087 2.19531 8 2.19531C9.5913 2.19531 11.1174 2.82745 12.2426 3.95267C13.3679 5.07789 14 6.60401 14 8.19531Z"
									stroke="#6C6C6C"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
							</svg>
							Feb 13, 2019
						</p>
						<p>9 mins read</p>
					</div>

					<img
						src="/assets/images/farm statistics.png"
						className="w-full"
						alt=""
					/>

					<p className="tracking-tight">
						Hello, we are Quick Leap, we provide access to local, regional
						and global markets for small holder farmers, and this is a bit
						of our story so far. We hope you enjoy the read and this
						inspires you to do something amazing
					</p>
				</section>

				<section className="space-y-6">
					<h2 className="font-bold text-green text-xl md:text-2xl">
						Identifying the need
					</h2>

					<p className="tracking-tight">
						As entrepreneurs when you come into an industry you basically
						try to identify how you can add value. We had to decide what
						part of the value chain we were going to operate in. There are
						several innovations in the input: seed, advisory, fertilizer
						value chain, but not so much in access to markets for
						smallholder farmers. The innovations in providing input
						provide access to markets for the farmers they onboard through
						off takers who provide the kinds of solutions we provide or
						are directly processing the smallholders produce. So we
						decided connecting the end produce: the harvest to the needed
						local, regional and global markets would be the value we would
						add and the addressable market is massive thirty eight million
						smallholder farmers in Nigeria alone. <br /> <br /> So we
						started out with the idea to connect smallholder farmers to
						markets; help farmers meet markets, reduce the number of
						middle men involved and help the farmers earn better from
						their production activities, reduce post-harvest crop loss,
						and food loss and keep the farmers motivated to keep planting
						by ensuring consistent local, regional and global markets are
						available to them. <br /> <br /> So Quick Leap was birthed we
						exist to give the small holder farmer that needed leap to
						profitable and sustainable planting and the demand a
						consistent supply, in essence connect the harvest to markets,
						homes, and basically where ever it is needed. <br /> <br />
						The original way we wanted to do this was simply build an app
						that allowed the demand to meet the supply. So we thought we’d
						get smallholder farmers to upload there produce to the app,
						we’d have a field agent that confirms the produce, then the
						buyers would place an order we would hold the money in escrow
						till when the produce got to the buyer and then release the
						funds to the small holder farmer while we ensured last mile
						delivery from point A to B transparently. Real simple, nothing
						fancy, very functional and effective, at least that’s what we
						thought.
					</p>
				</section>

				<div className="my-12">
					<GetStartedCta />
				</div>

				<section>
					<h3 className="text-2xl font-bold">Recent Articles</h3>
					<div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-5">
						<BlogCard title="" img="" category="" date="" />
						<BlogCard title="" img="" category="" date="" />
						<BlogCard title="" img="" category="" date="" />
						<BlogCard title="" img="" category="" date="" />
						<BlogCard title="" img="" category="" date="" />
						<BlogCard title="" img="" category="" date="" />
					</div>
				</section>
			</Container>
		</WebLayout>
	);
};

export default BlogPostScreen;
