import WebLayout from "../components/organisms/layouts/WebLayout";
import Container from "../components/organisms/Container";
import { Form, Formik } from "formik";
import * as yup from "yup";
import FormInput from "../components/molecules/FormInput";
import FormTextArea from "../components/molecules/FormTextArea";
import Button from "../components/molecules/Button";
import { sendContactUsMessage } from "../store/services/profileService";
import { toast } from "react-toastify";

const ContactScreen = () => {
  const detailsSchema = yup.object({
    fullname: yup.string().required("Full name is required"),
    email: yup.string().email().required("Email is required"),
    message: yup.string().required("Message is required"),
    phone: yup.string().required("Phone number is required"),
  });

  type DetailsProps = yup.InferType<typeof detailsSchema>;

  const detailsInitialValues: DetailsProps = {
    fullname: "",
    phone: "",
    email: "",
    message: "",
  };

  const handleSubmit = async (value:DetailsProps, resetForm:any)=>{  
      try {
        await sendContactUsMessage(value)
       toast.success('Email sent successfully');
       resetForm()
      } catch (error:any) {
        toast.error(`Error sending email: ${error.response ? 
          error?.response?.data.message[0] : error?.message}`);
      }
  }

  return (
    <WebLayout>
      <div className="bg-green-light py-12">
        <Container className="px-3">
          <div className="">
            <h2 className="text-center text-4xl md:text-5xl text-green font-bold">
              Contact Us
            </h2>
            <p className="text-center mt-4 text-ash">
              Drop a message and we will get back to you
            </p>

            <article className="grid grid-cols-1 md:grid-cols-2 gap-12 mt-12">
              <img
                src="/assets/images/contact-us.png"
                className="object-contain"
                alt=""
              />

              <div className="flex items-center">
                <Formik
                  initialValues={detailsInitialValues}
                  onSubmit={async (value, {resetForm}) => {
                    await handleSubmit(value, resetForm)

                  }}
                  validationSchema={detailsSchema}
                >
                  {({ values, setFieldValue, isSubmitting }) => {
                    return (
                      <div className="w-full">
                        <Form>
                          <section className="grid w-full gap-4">
                            <FormInput
                              id="fullname"
                              name="fullname"
                              placeholder="Full Name"
                              label=""
                              onChange={(e) => {
                                setFieldValue("fullname", e.target.value);
                              }}
                            />

                            <FormInput
                              id="email"
                              name="email"
                              placeholder="Email"
                              label=""
                              onChange={(e) => {
                                setFieldValue("email", e.target.value);
                              }}
                            />

                            <FormInput
                              id="phone"
                              name="phone"
                              placeholder="Phone Number"
                              label=""
                              onChange={(e) => {
                                setFieldValue("phone", e.target.value);
                              }}
                            />

                            <FormTextArea
                              id="message"
                              name="message"
                              placeholder="Message"
                              label=""
                              onChange={(e) => {
                                setFieldValue("message", e.target.value);
                              }}
                              rows="5"
                            />

                            <Button isLoading={isSubmitting}
                             className="p-3 bg-green rounded-md font-semibold text-white flex justify-center 
                             rounded-m w-full md:w-[20rem] mx-auto h-12">
                              Submit
                            </Button>
                          </section>
                        </Form>
                      </div>
                    );
                  }}
                </Formik>
              </div>
            </article>
          </div>
        </Container>
      </div>
    </WebLayout>
  );
};

export default ContactScreen;

