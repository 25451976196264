import { useMemo } from "react";
import { Link, useRouteError, useLocation } from "react-router-dom";

export default function ErrorPage() {
	const error: any = useRouteError();
	const location = useLocation();

	const redirectLink = useMemo(() => {
		if (location.pathname.includes("collaborator")) {
			return "/collaborator";
		}

		if (location.pathname.includes("grow-for-life")) {
			return "/grow-for-life";
		}

		if (location.pathname.includes("inventory-manager")) {
			return "/inventory-manager";
		}

		if (location.pathname.includes("admin")) {
			return "/admin/dashboard";
		}

		return "/";
	}, [location]);

	const Error404 = () => {
		return (
			<div className="flex flex-col items-center p-5 h-screen py-20">
				<div className="grow w-full relative">
					<img
						src="/assets/images/404.png"
						className="object-contain h-full w-full absolute md:scale-[1.2]"
						alt="404"
					/>
				</div>

				<div className="flex flex-col gap-3 text-center items-center">
					<h1 className="text-[#111827] font-bold text-[3rem]">
						Page not found
					</h1>
					<p className="text-[#6B7280]">
						Oops! Looks like you followed a bad link. If you think this is
						a problem with us, please tell us.
					</p>
					<Link
						to={redirectLink}
						className="bg-green p-3 px-5 rounded-lg text-white font-semibold text-sm mt-4"
					>
						Go back home
					</Link>
				</div>
			</div>
		);
	};

	const RanIntoAProblem = () => {
		return (
			<div className="flex flex-col items-center justify-center p-5 h-screen py-20">
				<div className="grow w-full relative">
					<img
						src="/logo192.png"
						className="object-contain h-full w-full absolute"
						alt="logo"
					/>
				</div>
				<h1 className="text-[#111827] font-bold text-[3rem]">Uh oh!</h1>
				<p className="text-[#6B7280]">Something went wrong!</p>
				<button
					onClick={() => window.location.reload()}
					className="p-2 px-4 text-white rounded-md bg-green mt-3"
				>
					Refresh
				</button>
			</div>
		);
	};

	return (
		<div>{error.status === 404 ? <Error404 /> : <RanIntoAProblem />}</div>
	);
}
