import Container from "../../Container";
import Carousel from "nuka-carousel";

const PartnerSection = () => {
	return (
		<div className="w-full bg-white">
			<Container className="px-5 py-10 xl:py-20 gap-5 flex flex-col items-center">
				<h2 className="text-[#1B1C24] font-medium text-2xl xl:text-[32px] leading-9 xl:leading-10">
					Trusted by businesses across the Nation
				</h2>

				<div className="w-full mt-8 max-w-6xl">
					<Carousel
						wrapAround={true}
						autoplay={true}
						speed={500}
						slidesToShow={4}
						withoutControls={true}
					>
						<div className="w-full max-w-[150px] h-20 mx-2">
							<img
								src="/assets/images/thrive-agric.png"
								alt="thrive"
								className="w-[80%] md:w-full h-full object-contain"
							/>
						</div>

						<div className="w-full max-w-[150px] h-20 mx-2">
							<img
								src="/assets/images/meadow-foods.svg"
								alt="meadow"
								className="w-[50%] md:w-full h-full object-contain"
							/>
						</div>

						<div className="w-full max-w-[150px] h-20 mx-2">
							<img
								src="/assets/images/soilless.png"
								alt="soilles"
								className="w-[80%] md:w-full h-full object-contain"
							/>
						</div>

						<div className="w-full max-w-[150px] h-20 mx-2">
							<img
								src="/assets/images/paystack.png"
								alt="paystack"
								className="w-[80%] md:w-full h-full object-contain"
							/>
						</div>
						<div className="w-full max-w-[150px] h-20 mx-2">
							<img
								src="/assets/images/across-trade.svg"
								alt="trades"
								className="w-[50%] md:w-full h-full object-contain"
							/>
						</div>

						{/* <div className="w-full max-w-[150px] h-20 mx-2">
							<img
								src="/assets/images/sona-agro.png"
								alt="sona"
								className="w-[80%] md:w-full h-full object-contain"
							/>
						</div> */}

						{/* <div className="w-full max-w-[150px] h-20 mx-2">
							<img
								src="/assets/images/fybapay.png"
								alt="thrive"
								className="w-[80%] md:w-full h-full object-contain"
							/>
						</div> */}
					</Carousel>
				</div>
			</Container>
		</div>
	);
};

export default PartnerSection;
