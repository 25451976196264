import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../index";
import { PaginationType } from "../../types/collaborator";
import {
	CustomerOrderType,
	FieldAgentCollaboratorList,
	FieldAgentCollaboratorProductType,
	FieldAgentCollaboratorProfileType,
	FieldAgentOnboardedUser,
} from "../../types/fieldagentTypes";
import {
	fetchCustomOrdersAssignedToFieldAgentAction,
	fetchFieldAgentCollaboratorListAction,
	fetchFieldAgentCollaboratorProductsAction,
	handleFieldAgentCollaboratorOnboardAction,
	handleFieldAgentCollaboratorProfileAction,
	handleProductToEditAction,
} from "./action";

interface ReduerState {
	collaboratorList: {
		meta: PaginationType;
		data: FieldAgentCollaboratorList[];
	};
	getCollaboratorList: {
		loading: boolean;
		error: string | null;
		success: boolean;
	};
	onboardedUser: FieldAgentOnboardedUser | null;
	getOnboardedUser: {
		loading: boolean;
		error: string | null;
		success: boolean;
	};

	onboardUserProduct: any;
	getUploadProcess: {
		loading: boolean;
		error: string | null;
		success: boolean;
	};

	collaboratorProfile: FieldAgentCollaboratorProfileType | null;
	getCollaboratorProfile: {
		loading: boolean;
		error: string | null;
		success: boolean;
	};

	collaboratorUploadedProducts: {
		data: FieldAgentCollaboratorProductType[];
		meta: PaginationType;
	};
	getCollaboratorUploadedProducts: {
		loading: boolean;
		error: string | null;
		success: boolean;
	};
	agentCustomerOrder: {
		data: CustomerOrderType[];
		meta: PaginationType;
	};
	getCustomerOrder: {
		loading: boolean;
		error: string | null;
		success: boolean;
	};

	productToEdit: FieldAgentCollaboratorProductType | null;
}

const initialState: ReduerState = {
	collaboratorList: {
		meta: {
			totalCount: 0,
			currentPage: 1,
			itemsPerPage: 0,
			totalPages: 0,
			hasNextPage: false,
			hasPreviousPage: false,
		},
		data: [],
	},
	productToEdit: null,
	getCollaboratorList: {
		loading: false,
		error: null,
		success: false,
	},
	onboardedUser: null,
	getOnboardedUser: {
		loading: false,
		error: null,
		success: false,
	},
	onboardUserProduct: null,
	getUploadProcess: {
		loading: false,
		error: null,
		success: false,
	},
	collaboratorProfile: null,
	getCollaboratorProfile: {
		loading: false,
		error: null,
		success: false,
	},
	collaboratorUploadedProducts: {
		data: [],
		meta: {
			totalCount: 0,
			currentPage: 1,
			itemsPerPage: 0,
			totalPages: 0,
			hasNextPage: false,
			hasPreviousPage: false,
		},
	},
	getCollaboratorUploadedProducts: {
		loading: false,
		error: null,
		success: false,
	},

	agentCustomerOrder: {
		data: [],
		meta: {
			totalCount: 0,
			currentPage: 1,
			itemsPerPage: 0,
			totalPages: 0,
			hasNextPage: false,
			hasPreviousPage: false,
		},
	},
	getCustomerOrder: {
		loading: false,
		error: null,
		success: false,
	},
};

const fieldAgentSlice = createSlice({
	name: "fieldAgent",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		//Get collaborator list under field agent
		builder
			.addCase(fetchFieldAgentCollaboratorListAction.pending, (state) => {
				state.getCollaboratorList.loading = true;
			})
			.addCase(
				fetchFieldAgentCollaboratorListAction.fulfilled,
				(state, { payload }) => {
					state.getCollaboratorList.loading = false;
					state.getCollaboratorList.error = null;
					state.getCollaboratorList.success = true;
					state.collaboratorList = payload;
				}
			)
			.addCase(
				fetchFieldAgentCollaboratorListAction.rejected,
				(state, { payload }) => {
					state.getCollaboratorList.error = payload as string;
					state.getCollaboratorList.success = false;
					state.getCollaboratorList.loading = false;
				}
			);

		//Onboarded user
		builder
			.addCase(
				handleFieldAgentCollaboratorOnboardAction.pending,
				(state) => {
					state.getOnboardedUser.loading = true;
				}
			)
			.addCase(
				handleFieldAgentCollaboratorOnboardAction.fulfilled,
				(state, { payload }) => {
					state.getOnboardedUser.loading = false;
					state.getOnboardedUser.error = null;
					state.getOnboardedUser.success = true;
					state.onboardedUser = payload;
				}
			)
			.addCase(
				handleFieldAgentCollaboratorOnboardAction.rejected,
				(state, { payload }) => {
					state.getOnboardedUser.error = payload as string;
					state.getOnboardedUser.success = false;
					state.getOnboardedUser.loading = false;
					state.onboardedUser = null;
				}
			);

		//Get collaborator profile
		builder
			.addCase(
				handleFieldAgentCollaboratorProfileAction.pending,
				(state) => {
					state.getCollaboratorProfile.loading = true;
				}
			)
			.addCase(
				handleFieldAgentCollaboratorProfileAction.fulfilled,
				(state, { payload }) => {
					state.getCollaboratorProfile.loading = false;
					state.getCollaboratorProfile.error = null;
					state.getCollaboratorProfile.success = true;
					state.collaboratorProfile = payload;
				}
			)
			.addCase(
				handleFieldAgentCollaboratorProfileAction.rejected,
				(state, { payload }) => {
					state.getCollaboratorProfile.error = payload as string;
					state.getCollaboratorProfile.success = false;
					state.getCollaboratorProfile.loading = false;
					state.collaboratorProfile = null;
				}
			);

		builder
			.addCase(
				fetchFieldAgentCollaboratorProductsAction.pending,
				(state) => {
					state.getCollaboratorUploadedProducts.loading = true;
				}
			)
			.addCase(
				fetchFieldAgentCollaboratorProductsAction.fulfilled,
				(state, { payload }) => {
					state.getCollaboratorUploadedProducts.loading = false;
					state.getCollaboratorUploadedProducts.error = null;
					state.getCollaboratorUploadedProducts.success = true;
					state.collaboratorUploadedProducts = payload;
				}
			)
			.addCase(
				fetchFieldAgentCollaboratorProductsAction.rejected,
				(state, { payload }) => {
					state.getCollaboratorUploadedProducts.error = payload as string;
					state.getCollaboratorUploadedProducts.success = false;
					state.getCollaboratorUploadedProducts.loading = false;
				}
			);

		//Get custom orders
		builder
			.addCase(
				fetchCustomOrdersAssignedToFieldAgentAction.pending,
				(state) => {
					state.getCustomerOrder.loading = true;
				}
			)
			.addCase(
				fetchCustomOrdersAssignedToFieldAgentAction.fulfilled,
				(state, { payload }) => {
					state.getCustomerOrder.loading = false;
					state.getCustomerOrder.error = null;
					state.getCustomerOrder.success = true;
					state.agentCustomerOrder = payload;
				}
			)
			.addCase(
				fetchCustomOrdersAssignedToFieldAgentAction.rejected,
				(state, { payload }) => {
					state.getCustomerOrder.error = payload as string;
					state.getCustomerOrder.success = false;
					state.getCustomerOrder.loading = false;
				}
			);

		//Set product to edit
		builder.addCase(
			handleProductToEditAction.fulfilled,
			(state, { payload }) => {
				state.productToEdit = payload;
			}
		);
	},
});

export const selectFieldAgent = (state: RootState) => state.fieldAgent;
export default fieldAgentSlice.reducer;
