import { createAsyncThunk } from "@reduxjs/toolkit";
import {
	deleteDashboardProductBySlug,
	getDashboardAnalytics,
	getDashboardProducts,
	getDashboardTopSellingItems,
	handleProductUploadService,
	getCollaboratorOrders,
	getCollaboratorTransactions,
	getProductsAssignedToFieldAgent,
	verifyProduct,
	getCollaboratorListing,
	getQuickleapPricingList,
	getProductBySlug,
	updateProductBySlug,
} from "../services/collaboratorServices";

const fetchDashboardAnalyticsAction = createAsyncThunk(
	"collaborator/dashboard-analytics",
	async (_, { rejectWithValue }) => {
		try {
			const response = await getDashboardAnalytics();

			return response.data;
		} catch (error: any) {
			return rejectWithValue(error.response.data.message);
		}
	}
);

const fetchDashboardTopSellingItemsAction = createAsyncThunk(
	"collaborator/dashboard-top-selling-items",
	async (_, { rejectWithValue }) => {
		try {
			const response = await getDashboardTopSellingItems();

			return response.data;
		} catch (error: any) {
			return rejectWithValue(error.response.data.message);
		}
	}
);

const handleUploadProductAction = createAsyncThunk<any, any>(
	"collaborator/dashboard-product-upload",
	async (data, { rejectWithValue }) => {
		try {
			const response = await handleProductUploadService(data);

			return response.data;
		} catch (error: any) {
			return rejectWithValue(error.response.data.message);
		}
	}
);

const deleteProductBySlugAction = createAsyncThunk<any, any>(
	"collaborator/dashboard-product-delete",
	async (data: { slug: string }, { rejectWithValue }) => {
		try {
			const response = await deleteDashboardProductBySlug(data);

			return response.data;
		} catch (error: any) {
			return rejectWithValue(error.response.data.message);
		}
	}
);

const fetchDashboardProductsAction = createAsyncThunk<any, any>(
	"collaborator/dashboard-products",
	async (
		data: { limit: number; page: number; search: string },
		{ rejectWithValue }
	) => {
		try {
			const response = await getDashboardProducts(data);
			return response.data;
		} catch (error: any) {
			return rejectWithValue(error.response.data.message);
		}
	}
);

const fetchCollaboratorListingAction = createAsyncThunk<any, any>(
	"collaborator/collaborator-listing",
	async (
		data: { limit: number; page: number; search: string },
		{ rejectWithValue }
	) => {
		try {
			const response = await getCollaboratorListing(data);
			return response.data;
		} catch (error: any) {
			return rejectWithValue(error.response.data.message);
		}
	}
);

const fetchCollaboratorOrdersAction = createAsyncThunk<any, any>(
	"collaborator/collaborator-orders",
	async (data: { page: number; limit: number }, { rejectWithValue }) => {
		try {
			const response = await getCollaboratorOrders(data);
			return response.data;
		} catch (error: any) {
			return rejectWithValue(error.response.data.message);
		}
	}
);

const fetchCollaboratorTransactionsAction = createAsyncThunk<any, any>(
	"collaborator/collaborator-transactions",
	async (data: { page: number; limit: number }, { rejectWithValue }) => {
		try {
			const response = await getCollaboratorTransactions(data);
			return response.data;
		} catch (error: any) {
			return rejectWithValue(error.response.data.message);
		}
	}
);

const fecthProductsAssignedToFieldAgentAction = createAsyncThunk<any, any>(
	"collaborator/product-assigned-to",
	async (data: { page: number; search: string }, { rejectWithValue }) => {
		try {
			const response = await getProductsAssignedToFieldAgent(data);
			return response.data;
		} catch (error: any) {
			return rejectWithValue(error.response.data.message);
		}
	}
);

const handleProductVerificationAction = createAsyncThunk<any, any>(
	"collaborator/product-verification",
	async (
		data: {
			collaboratorProductId: string;
			comment: string;
		},
		{ rejectWithValue }
	) => {
		try {
			const response = await verifyProduct(data);

			return response.data;
		} catch (error: any) {
			return rejectWithValue(error.response.data.message);
		}
	}
);

const fetchQuicleapPricingListAction = createAsyncThunk<any, any>(
	"collaborator/pricing-list",
	async (data: { page: number }, { rejectWithValue }) => {
		try {
			const response = await getQuickleapPricingList(data);

			return response.data;
		} catch (error: any) {
			return rejectWithValue(error.response.data.message);
		}
	}
);

const fetchProductBySlugAction = createAsyncThunk<any, any>(
	"collaborator/product-by-slug",
	async (data: { slug: string }, { rejectWithValue }) => {
		try {
			const response = await getProductBySlug(data);

			return response.data;
		} catch (error: any) {
			return rejectWithValue(error.response.data.message);
		}
	}
);

const handleUpdateProductBySlugAction = createAsyncThunk<any, any>(
	"collaborator/update-product-by-slug",
	async (data: { slug: string; productData: any }, { rejectWithValue }) => {
		try {
			const response = await updateProductBySlug(data);

			return response.data;
		} catch (error: any) {
			return rejectWithValue(error.response.data.message);
		}
	}
);

export {
	fetchDashboardAnalyticsAction,
	fetchDashboardTopSellingItemsAction,
	handleUploadProductAction,
	fetchDashboardProductsAction,
	deleteProductBySlugAction,
	fetchCollaboratorOrdersAction,
	fetchCollaboratorTransactionsAction,
	fecthProductsAssignedToFieldAgentAction,
	handleProductVerificationAction,
	fetchCollaboratorListingAction,
	fetchQuicleapPricingListAction,
	fetchProductBySlugAction,
	handleUpdateProductBySlugAction,
};
