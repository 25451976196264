import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../index";
import { BudgetAgeRangeType, CategoryData, GrowforlifeProductType, GrowforlifeShoppingListType, PaymentHistoryType, ProductReviewsType } from "../../types/growforlife";
import {
  getGrowForLifeProductDetails,
  getGrowForLifeProductCategories,
  getShoppingListProductsAction,
  getGrowForLifeBestSellingProducts,
  getGrowForLifeAllCategoryProducts,
  getGrowForLifeUserFavouriteProducts,
  getGrowforLifeShoppingListSubscriptionsAction,
  getGrowforLifePaymentHistoryAction,
  getGrowforLifeProductReviewsAction,
  getGrowForLifeAllProductsAction,
  getGrowforLifeBudgetAgeRangeAction,
  getGrowForLifeAllSubCategoryProducts,
  getGrowForLifeSuggestedProducts,
} from "./growForLifeAction";

export type GrowForLifeState = {
  productCategories: CategoryData[];
  getProductCategoriesInfo: {
    loading: boolean;
    error: string | null;
    success: boolean;
  };

  bestSellingProducts: GrowforlifeProductType[] ;
  getBestSellingProducts: {
    loading: boolean;
    error: string | null;
    success: boolean;
  };

  suggestedProducts: GrowforlifeProductType[] ;
  getSuggestedProducts: {
    loading: boolean;
    error: string | null;
    success: boolean;
  };

  product: GrowforlifeProductType | null;
  getProductDetails: {
    loading: boolean;
    success: boolean;
    error: string | null;
  };

  allProducts: {
    data: GrowforlifeProductType[],
    meta: {
     hasNextPage: boolean
    }
  }
  searchStringQuery: string
  getAllProductsInfo: {
    loading: boolean;
    success: boolean;
    error: string | null;
  };

   allCategoryProducts:{
    data: GrowforlifeProductType[];
    meta: {
     hasNextPage: boolean
    }
   } 

   allCategoryProductsQuery:{
    categorySlug: string;
    subCategorySlug: string;
    limit: string
   } | null

  getAllCategoryProductsInfo: {
    loading: boolean;
    success: boolean;
    error: string | null;
  };

  allSubCategoryProducts:GrowforlifeProductType[] ;
  getAllSubCategoryProductsInfo: {
    loading: boolean;
    success: boolean;
    error: string | null;
  };

  favouriteProducts:{
   data: GrowforlifeProductType[],
   meta: {
    hasNextPage: boolean
   }
  }
  getAllFavouriteProductsInfo: {
    loading: boolean;
    success: boolean;
    error: string | null;
  };
  

  myShoppingList: GrowforlifeShoppingListType | null;
  getMyShoppingListInfo: {
    loading: boolean;
    success: boolean;
    error: string | null;
  };

  paymentHistory: {
    data : PaymentHistoryType[],
    meta: {
      hasNextPage: boolean
    }
  };
  getPaymentHistoryInfo: {
    loading: boolean;
    success: boolean;
    error: string | null;
  };

  productReviews:ProductReviewsType;
  getProductReviewsInfo: {
    loading: boolean;
    success: boolean;
    error: string | null;
  };

  shoppingListSubscription: {
    data: GrowforlifeShoppingListType[]
    meta:{
      hasNextPage: boolean
    }
  };
  shoppingListSubscriptionInfo: {
    loading: boolean;
    success: boolean;
    error: string | null;
  };

  budgetAgeRange: BudgetAgeRangeType |  null ; 
  getBudgetAgeRangeInfo: {
     loading: boolean;
     success: boolean;
     error: string | null;
  }
};

const initialState: GrowForLifeState = {
  productCategories: [],
  getProductCategoriesInfo: {
    loading: false,
    error: null,
    success: false,
  },

  bestSellingProducts: [],
  getBestSellingProducts: {
    loading: false,
    error: null,
    success: false,
  },

 suggestedProducts: [],
  getSuggestedProducts: {
    loading: false,
    error: null,
    success: false,
  },
  
  myShoppingList: null,
  getMyShoppingListInfo: {
    loading: false,
    success: false,
    error: null,
  },

  productReviews: {
    averageRating: 5,
    reviews: {
      data: [],
      meta: {
        hasNextPage: false
      }
    },
    
  },
  getProductReviewsInfo: {
    loading: false,
    success: false,
    error: null,
  },

  paymentHistory: {
    data: [],
    meta: {
      hasNextPage: false
    }
  },
  getPaymentHistoryInfo: {
    loading: false,
    success: false,
    error: null,
  },

  shoppingListSubscription: {
    data: [],
    meta:{
      hasNextPage: false
    }

  },
  shoppingListSubscriptionInfo: {
    loading: false,
    success: false,
    error: null,
  },

  product: null,
  getProductDetails: {
    loading: false,
    error: null,
    success: false,
  },

  allProducts:{
    data: [],
    meta:{
      hasNextPage: false
    }
  } ,
  searchStringQuery:'',
  getAllProductsInfo: {
    loading: false,
    error: null,
    success: false,
  },

  allCategoryProducts: {
    data: [],
    meta:{
      hasNextPage: false
    }
  }, 
  allCategoryProductsQuery: null,
  getAllCategoryProductsInfo: {
    loading: false,
    error: null,
    success: false,
  },

  allSubCategoryProducts: [],
  getAllSubCategoryProductsInfo: {
    loading: false,
    error: null,
    success: false,
  },

  favouriteProducts: {
    data: [],
    meta:{
      hasNextPage: false
    }
  },
  getAllFavouriteProductsInfo: {
    loading: false,
    error: null,
    success: false,
  },

  budgetAgeRange: {
    id: '',
    minAge: 0,
    maxAge: 0,
    amount: ''
  },
  getBudgetAgeRangeInfo:{
    loading:false,
    error: null,
    success: false
  }

};

const growForLifeSlice = createSlice({
  name: "growforlife",
  initialState,
  reducers: {
    toggleFavouriteProducts: (state, action) => {
      const productInFavourite = state.favouriteProducts.data.find((item) => item.id === action.payload.id);
      if (productInFavourite) {
        state.favouriteProducts.data = state.favouriteProducts.data.filter((item) => item.id !== action.payload.id);
      } else {
        state.favouriteProducts.data.push({...action.payload});
       }
    },

    addProductToShoppingList: (state, action) =>{
      if(!state.myShoppingList) return 
      const productInShoppingList = state.myShoppingList.items.find((item) => item.productId === action.payload.productId);
      const productIndex = state.myShoppingList.items.findIndex((item) => item.productId === action.payload.productId);
      
      if (productInShoppingList) {
        state.myShoppingList.items[productIndex].quantity++ 
      } else {
        state.myShoppingList.items.push({...action.payload});
      }
    },

    deleteProductInShoppingList:(state, action) =>{
      if(!state.myShoppingList) return 
      state.myShoppingList.items = state.myShoppingList.items.filter((item) => item.id !== action.payload);
      },

    updateProductInShoppingList:(state, action) =>{
      if(!state.myShoppingList) return 
    const productIndex = state.myShoppingList.items.findIndex((item) => item.id === action.payload.id);
    state.myShoppingList.items[productIndex].quantity = action.payload.quantity
    state.myShoppingList.items[productIndex].price =  (action.payload.quantity * action.payload.price).toString()
    },

    clearProductInShoppingList: (state, action)=>{
      if(!state.myShoppingList) return 
      state.myShoppingList.items = []
    }


  },
  extraReducers(builder) {
    //Get product categories
    builder
      .addCase(getGrowForLifeProductCategories.pending, (state) => {
        state.getProductCategoriesInfo.loading = true;
      })
      .addCase(
        getGrowForLifeProductCategories.fulfilled,
        (state, { payload }) => {
          state.getProductCategoriesInfo.loading = false;
          state.getProductCategoriesInfo.error = null;
          state.getProductCategoriesInfo.success = true;
          state.productCategories = payload;
        }
      )
      .addCase(
        getGrowForLifeProductCategories.rejected,
        (state, { payload }) => {
          state.getProductCategoriesInfo.error = payload as string;
          state.getProductCategoriesInfo.success = false;
          state.getProductCategoriesInfo.loading = false;
        }
      );

    //Get product details
    builder
      .addCase(getGrowForLifeProductDetails.pending, (state) => {
        state.getProductDetails.loading = true;
      })
      .addCase(getGrowForLifeProductDetails.fulfilled, (state, { payload }) => {
        state.product = payload.data;
        state.getProductDetails.loading = false;
        state.getProductDetails.error = null;
        state.getProductDetails.success = true;
      })
      .addCase(getGrowForLifeProductDetails.rejected, (state, { payload }) => {
        state.getProductDetails.error = payload as string;
        state.getProductDetails.success = false;
        state.getProductDetails.loading = false;
      });


      // all grow for life product
      builder
      .addCase(getGrowForLifeAllProductsAction.pending, (state) => {
        state.getAllProductsInfo.loading = true;
      })
      .addCase(getGrowForLifeAllProductsAction.fulfilled, (state, { payload }) => {
        state.allProducts = payload.response.data;
        state.searchStringQuery = payload.searchString;
        state.getAllProductsInfo.loading = false;
        state.getAllProductsInfo.error = null;
        state.getAllProductsInfo.success = true;
      })
      .addCase(getGrowForLifeAllProductsAction.rejected, (state, { payload }) => {
        state.getAllProductsInfo.error = payload as string;
        state.getAllProductsInfo.success = false;
        state.getAllProductsInfo.loading = false;
      });

    // Get grow for life product reviews
    builder
      .addCase(getGrowforLifeProductReviewsAction.pending, (state) => {
        state.getProductReviewsInfo.loading = true;
      })
      .addCase(
        getGrowforLifeProductReviewsAction.fulfilled,
        (state, { payload }) => {
          state.getProductReviewsInfo.error = null;
          state.getProductReviewsInfo.loading = false;
          state.getProductReviewsInfo.success = true;
          state.productReviews = payload;
        }
      )
      .addCase(
        getGrowforLifeProductReviewsAction.rejected,
        (state, { payload }) => {
          state.getProductReviewsInfo.error = payload as string;
          state.getProductReviewsInfo.loading = false;
          state.getProductReviewsInfo.success = false;
        }
      );


    //Get all grow for life products in particular category
    builder
      .addCase(getGrowForLifeAllCategoryProducts.pending, (state) => {
        state.getAllCategoryProductsInfo.loading = true;
      })
      .addCase(
        getGrowForLifeAllCategoryProducts.fulfilled,
        (state, { payload }) => {
          state.getAllCategoryProductsInfo.error = null;
          state.getAllCategoryProductsInfo.loading = false;
          state.getAllCategoryProductsInfo.success = true;
          state.allCategoryProducts = payload.data;
          state.allCategoryProductsQuery = payload.queryInfo;
        }
      )
      .addCase(
        getGrowForLifeAllCategoryProducts.rejected,
        (state, { payload }) => {
          state.getAllCategoryProductsInfo.error = payload as string;
          state.getAllCategoryProductsInfo.loading = false;
          state.getAllCategoryProductsInfo.success = false;
        }
      );


 //Get all grow for life products in particular sub-category
 builder
 .addCase(getGrowForLifeAllSubCategoryProducts.pending, (state) => {
   state.getAllSubCategoryProductsInfo.loading = true;
 })
 .addCase(
  getGrowForLifeAllSubCategoryProducts.fulfilled,
   (state, { payload }) => {
     state.getAllSubCategoryProductsInfo.error = null;
     state.getAllSubCategoryProductsInfo.loading = false;
     state.getAllSubCategoryProductsInfo.success = true;
     state.allSubCategoryProducts = payload;
   }
 )
 .addCase(
  getGrowForLifeAllSubCategoryProducts.rejected,
   (state, { payload }) => {
     state.getAllSubCategoryProductsInfo.error = payload as string;
     state.getAllSubCategoryProductsInfo.loading = false;
     state.getAllSubCategoryProductsInfo.success = false;
   }
 );


    //Get best selling Products
    builder
      .addCase(getGrowForLifeBestSellingProducts.pending, (state) => {
        state.getBestSellingProducts.loading = true;
      })
      .addCase(
        getGrowForLifeBestSellingProducts.fulfilled,
        (state, { payload }) => {
          state.getBestSellingProducts.error = null;
          state.getBestSellingProducts.loading = false;
          state.getBestSellingProducts.success = true;
          state.bestSellingProducts = payload.data;
        }
      )
      .addCase(
        getGrowForLifeBestSellingProducts.rejected,
        (state, { payload }) => {
          state.getBestSellingProducts.error = payload as string;
          state.getBestSellingProducts.loading = false;
          state.getBestSellingProducts.success = false;
        }
      );
 //Get suggested Products
    builder
      .addCase(getGrowForLifeSuggestedProducts.pending, (state) => {
        state.getSuggestedProducts.loading = true;
      })
      .addCase(
        getGrowForLifeSuggestedProducts.fulfilled,
        (state, { payload }) => {
          state.getSuggestedProducts.error = null;
          state.getSuggestedProducts.loading = false;
          state.getSuggestedProducts.success = true;
          state.suggestedProducts = payload.data;
        }
      )
      .addCase(
        getGrowForLifeSuggestedProducts.rejected,
        (state, { payload }) => {
          state.getSuggestedProducts.error = payload as string;
          state.getSuggestedProducts.loading = false;
          state.getSuggestedProducts.success = false;
        }
      );

 //Get user favourite products
    builder
      .addCase(getGrowForLifeUserFavouriteProducts.pending, (state) => {
        state.getAllFavouriteProductsInfo.loading = true;
      })
      .addCase(getGrowForLifeUserFavouriteProducts.fulfilled, (state, { payload }) => {
        state.getAllFavouriteProductsInfo.error = null;
        state.favouriteProducts = payload;
        state.getAllFavouriteProductsInfo.loading = false;
        state.getAllFavouriteProductsInfo.success = true;
      })
      .addCase(getGrowForLifeUserFavouriteProducts.rejected, (state, { payload }) => {
        state.getAllFavouriteProductsInfo.error = payload as string;
        state.getAllFavouriteProductsInfo.loading = false;
        state.getAllFavouriteProductsInfo.success = false;
      });


    //Get shopping list
    builder
      .addCase(getShoppingListProductsAction.pending, (state) => {
        state.getMyShoppingListInfo.loading = true;
      })
      .addCase(getShoppingListProductsAction.fulfilled, (state, { payload }) => {
        state.getMyShoppingListInfo.error = null;
        state.getMyShoppingListInfo.loading = false;
        state.getMyShoppingListInfo.success = true;
        state.myShoppingList = payload.cart;
      })
      .addCase(getShoppingListProductsAction.rejected, (state, { payload }) => {
        state.getMyShoppingListInfo.error = payload as string;
        state.getMyShoppingListInfo.loading = false;
        state.getMyShoppingListInfo.success = false;
      });
  //Get grow for life payment history
    builder
      .addCase(getGrowforLifePaymentHistoryAction.pending, (state) => {
        state.getPaymentHistoryInfo.loading = true;
      })
      .addCase(getGrowforLifePaymentHistoryAction.fulfilled, (state, { payload }) => {
        state.getPaymentHistoryInfo.error = null;
        state.getPaymentHistoryInfo.loading = false;
        state.getPaymentHistoryInfo.success = true;
        state.paymentHistory = payload;
      })
      .addCase(getGrowforLifePaymentHistoryAction.rejected, (state, { payload }) => {
        state.getPaymentHistoryInfo.error = payload as string;
        state.getPaymentHistoryInfo.loading = false;
        state.getPaymentHistoryInfo.success = false;
      });

      
      //Get shopping list subscriptions
    builder
      .addCase(getGrowforLifeShoppingListSubscriptionsAction.pending, (state) => {
        state.shoppingListSubscriptionInfo.loading = true;
      })
      .addCase(getGrowforLifeShoppingListSubscriptionsAction.fulfilled, (state, { payload }) => {
        state.shoppingListSubscriptionInfo.error = null;
        state.shoppingListSubscriptionInfo.loading = false;
        state.shoppingListSubscriptionInfo.success = true;
        state.shoppingListSubscription = payload;
      })
      .addCase(getGrowforLifeShoppingListSubscriptionsAction.rejected, (state, { payload }) => {
        state.shoppingListSubscriptionInfo.error = payload as string;
        state.shoppingListSubscriptionInfo.loading = false;
        state.shoppingListSubscriptionInfo.success = false;
      }); 

     //Get user budget and age range
    builder
      .addCase(getGrowforLifeBudgetAgeRangeAction.pending, (state) => {
        state.getBudgetAgeRangeInfo.loading = true;
      })
      .addCase(getGrowforLifeBudgetAgeRangeAction.fulfilled, (state, { payload }) => {
        state.getBudgetAgeRangeInfo.error = null;
        state.getBudgetAgeRangeInfo.loading = false;
        state.getBudgetAgeRangeInfo.success = true;
        state.budgetAgeRange = payload;
      })
      .addCase(getGrowforLifeBudgetAgeRangeAction.rejected, (state, { payload }) => {
        state.getBudgetAgeRangeInfo.error = payload as string;
        state.getBudgetAgeRangeInfo.loading = false;
        state.getBudgetAgeRangeInfo.success = false;
      }); 
    },
  
});

export const selectGrowForLife = (state: RootState) => state.growForLife;
export default growForLifeSlice.reducer;
