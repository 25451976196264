import axios from "../../axios.config";
import {
	FieldAgentOnboardUserType,
	FieldAgentProductUploadType,
} from "../../types/collaborator";
import { FieldAgentCollboratorProfileUpdateTypes } from "../../types/fieldagentTypes";

const getFieldAgentCollaboratorList = async (data: {
	page: number;
	limit: number;
}) => {
	const { page, limit } = data;

	const response = await axios.get(
		`/collaborator/field-agent/onboarded-collaborators?page=${page}&limit=${limit}`
	);

	if (response.status === 200 || response.status === 201) {
		return response.data;
	}

	throw new Error(response.data.message);
};

const getFieldAgentCollaboratorProducts = async (data: {
	collaboratorId: string;
	page: number;
	limit: number;
}) => {
	const { collaboratorId, page, limit } = data;

	const response = await axios.get(
		`/collaborator/field-agent/onboarded-collaborators/${collaboratorId}/products?page=${page}&limit=${limit}`
	);

	if (response.status === 200 || response.status === 201) {
		return response.data;
	}

	throw new Error(response.data.message);
};

const getFieldAgentCollaborator = async (data: { collaboratorId: string }) => {
	const { collaboratorId } = data;

	const response = await axios.get(
		`/collaborator/field-agent/onboarded-collaborators/${collaboratorId}`
	);

	if (response.status === 200 || response.status === 201) {
		return response.data;
	}

	throw new Error(response.data.message);
};

const handleFieldAgentProductUpload = async (
	data: FieldAgentProductUploadType
) => {
	const { productData, collaboratorId } = data;

	const response = await axios.post(
		`/collaborator/field-agent/onboarded-collaborators/${collaboratorId}/products`,
		{
			...productData,
			categoryId: productData.category,
		}
	);

	if (response.status === 200 || response.status === 201) {
		return response.data;
	}

	throw new Error(response.data.message);
};

const handleFieldAgentCollaboratorOnboard = async (
	data: FieldAgentOnboardUserType
) => {
	const response = await axios.post(
		`/collaborator/field-agent/onboard-collaborator`,
		{ ...data }
	);

	if (response.status === 200 || response.status === 201) {
		return response.data;
	}

	throw new Error(response.data.message);
};

const getOnboardedCollaboratorProducts = async (collaboratorId: string) => {
	const response = await axios.get(
		`/collaborator/field-agent/onboarded-collaborators/${collaboratorId}/products`
	);

	if (response.status === 200 || response.status === 201) {
		return response.data;
	}

	throw new Error(response.data.message);
};

const updateCollaboratorProfile = async (
	data: FieldAgentCollboratorProfileUpdateTypes,
	collabortorId: string
) => {
	const response = await axios.put(
		`/collaborator/field-agent/onboarded-collaborators/${collabortorId}`,
		data
	);

	if (response.status === 200 || response.status === 201) {
		return response.data;
	}

	throw new Error(response.data.message);
};

const getAssignedCustomerOrders = async (data: { page: number }) => {
	const { page } = data;

	const response = await axios.get(
		`/collaborator/field-agent/processing/customer-orders?page=${Number(
			page
		)}&limit=${Number(10)}`
	);

	if (response.status === 200 || response.status === 201) {
		return response.data;
	}

	throw new Error(response.data.message);
};

const updateAssignedCustomerOrders = async (data: {
	customerOrderId: string;
	status: string;
}) => {
	const { customerOrderId, status } = data;

	const response = await axios.put(
		`/collaborator/field-agent/processing/customer-orders/${customerOrderId}`,
		{
			status: status,
		}
	);

	if (response.status === 200 || response.status === 201) {
		return response.data;
	}

	throw new Error(response.data.message);
};

const deleteMyCollaboratorProduct = async (data: {
	collaboratorId: string;
	productSlug: string;
}) => {
	const { collaboratorId, productSlug } = data;

	const response = await axios.delete(
		`/collaborator/field-agent/onboarded-collaborators/${collaboratorId}/products/${productSlug}`
	);

	if (response.status === 200 || response.status === 201) {
		return response.data;
	}

	throw new Error(response.data.message);
};

const updateCollaboratorProduct = async (data: {
	collaboratorId: string;
	productSlug: string;
	productData: {
		name: string;
		status: string;
		type: "PRODUCT" | "MEAL";
		moistureContent?: string;
		images: string[];
		quantity: number;
		quantityUnit: string;
		price: number;
		address: string;
		country: string;
		state: string;
		city: string;
		category: number;
		cuisine_type?: string | null;
	};
}) => {
	const { collaboratorId, productSlug, productData } = data;

	const response = await axios.put(
		`/collaborator/field-agent/onboarded-collaborators/${collaboratorId}/products/${productSlug}`,
		{
			...productData,
			categoryId: productData?.category,
		}
	);

	if (response.status === 200 || response.status === 201) {
		return response.data;
	}

	throw new Error(response.data.message);
};

export {
	handleFieldAgentProductUpload,
	handleFieldAgentCollaboratorOnboard,
	getOnboardedCollaboratorProducts,
	getFieldAgentCollaboratorList,
	getFieldAgentCollaboratorProducts,
	getFieldAgentCollaborator,
	updateCollaboratorProfile,
	getAssignedCustomerOrders,
	updateAssignedCustomerOrders,
	deleteMyCollaboratorProduct,
	updateCollaboratorProduct,
};
