import React from 'react'

const LoadingScreen = () => {
  return (
    <div className="h-screen bg-green-light w-full top-0 fixed z-[99999] grid place-items-center"> 
    {/* <RiLoader4Fill className="h-[5rem] w-[5rem] text-green animate-spin" /> */}
    <img src="/logo192.png" className='h-24 animate-bounce' alt="quickleap logo" />
    </div>
  )
}

export default LoadingScreen
