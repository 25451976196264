import React, { Dispatch, SetStateAction } from "react";
import { Field, ErrorMessage } from "formik";
import { cn } from "../lib/utils";

interface FieldProps {
	id: string;
	name: string;
	label?: string;
	placeholder?: string;
	type?: string;
	onChange?: (event: any) => void;
	setFormikValue?: (value: any) => void;
	setStateAction?: Dispatch<SetStateAction<any>>;
	className?: string;
	isDisabled?: boolean;
}

const FormMoneyInput: React.FC<FieldProps> = ({
	id,
	name,
	label,
	placeholder,
	type,
	onChange,
	className,
	isDisabled,
}) => {
	function formatNumberWithCommas(numberString: string) {
		const numberWithOutCommas = numberString.replace(/,/g, "");
		const number = Number(numberWithOutCommas); // Convert string to number
		return number.toLocaleString(); // Format number with commas
	}

	return (
		<div className={cn(`w-full flex flex-col items-start ${className}`)}>
			{label && (
				<label
					htmlFor={name}
					className="font-medium text-[#111111] text-[.9rem]"
				>
					{label}
				</label>
			)}
			<Field
				id={id}
				name={name}
				placeholder={placeholder}
				type={type}
				onChange={onChange}
			>
				{({ field, form, meta }: { field: any; form: any; meta: any }) => (
					<div
						className={` w-full h-[2.8rem] mt-1 px-3 border rounded-md flex gap-2 items-center
					placeholder:text-[#A1A7AD] outline-none  ${
						meta.touched && meta.error
							? "ring-2 ring-red-400 focus:ring-red-400"
							: "border-[#C6C5C5] hover:border-green focus:border-green font-medium"
					}
					placeholder:text-sm placeholder:font-normal text-[#6C6C6C]`}
					>
						<span>&#8358;</span>
						<input
							{...field}
							placeholder={placeholder}
							inputMode="numeric"
							className="h-full w-full border-0 outline-0 grow"
							value={formatNumberWithCommas(field?.value)}
						/>
					</div>
				)}
			</Field>

			<span className="text-red-600 text-xs font-medium w-full h-2 my-1">
				<ErrorMessage name={name} />
			</span>
		</div>
	);
};

export default FormMoneyInput;
