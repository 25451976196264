import { FC, useMemo, useState } from "react";
import { BiHeart, BiSolidHeart } from "react-icons/bi";
import { numberWithCommas, shortenWord } from "../../../utils/formatter";
import StarRating from "../../atoms/StarRating";
import Button from "../Button";
import { Link, useNavigate } from "react-router-dom";
import { RiHeart3Fill, RiHeart3Line } from "react-icons/ri";
import {
  addFavouriteGrowforLifeProduct,
  removeFavouriteGrowforLifeProduct,
} from "../../../store/services/growForLifeServices";

import { toast } from "react-toastify";
import { useLoadingOverlay } from "../../atoms/LoadingOverlay";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { selectGrowForLife } from "../../../store/grow-for-life/growForLifeReducer";
import { toggleFavouriteProductsAction } from "../../../store/grow-for-life/growForLifeAction";
import { IoPlay } from "react-icons/io5";
import { LuClock3, LuShare2 } from "react-icons/lu";
import { FiMinus, FiPlus } from "react-icons/fi";
import useShareModal from "../../../hooks/modal-hooks/useShareModal";

interface CardProps {
  carousel?: boolean;
  product: any;
}

const GrowforlifeAssetCard: FC<CardProps> = ({ product, carousel }) => {
  const navigate = useNavigate();

  return (
    <div
      className={`w-full ${
        carousel ? "min-w-[280px]" : "min-w-[180px]"
      } max-w-[300px] rounded-2xl border border-[#B1B1B1] h-[438px] p-[8px] md:p-[12px] relative cursor-pointer bg-white`}
    >
      <div className="w-full h-[216px] mb-4 rounded-lg overflow-hidden relative">
        <img
          src={`../${product?.image ?? product?.imageUrl}`}
          alt={product.name}
          className="w-full h-full object-cover"
        />
        <button className="bg-[#BCBCBC] w-[35px] h-[35px] flex items-center justify-center absolute top-0 right-0">
          {product?.isLiked ? (
            <BiSolidHeart size={20} color="#fff" />
          ) : (
            <BiHeart size={20} color="#fff" />
          )}
        </button>
      </div>
      <h2 className="font-normal text-black text-xs leading-[30px] uppercase">
        {product?.category}
      </h2>
      <h1 className="font-semibold text-black text-xl leading-[24px]">
        {shortenWord(product?.title, 20)}
      </h1>
      <h4 className="text-base mt-2 font-normal mb-1">
        <strong className="font-semibold text-green text-base">
          &#8358;{numberWithCommas(product?.price)}
        </strong>
        /{product?.measurement}
      </h4>

      <StarRating rating={product?.rating} />
      <Button
        className="w-full border mt-4 flex items-center justify-center border-green text-green font-semibold"
        onClick={() => navigate(`/grow-for-life/products/${product?.id}`)}
      >
        View product
      </Button>
    </div>
  );
};

export const ProductCard: FC<{ product: any; imageWidth?: string }> = ({
  product,
  imageWidth,
}) => {
  const { openOverLay, closeOverLay } = useLoadingOverlay();
  const { favouriteProducts } = useAppSelector(selectGrowForLife);
  const dispatch = useAppDispatch();
  const [servingsQuantity, setServingsQuantity] = useState(1);
  const { openShare } = useShareModal();


  const favouriteStatus = useMemo(() => {
    return favouriteProducts.data.some((item: any) => item.id === product.id);
  }, [favouriteProducts, product.id]);

  const favouriteProductHandler = async (product: any, status: boolean) => {
    openOverLay();
    try {
      if (status) {
        await removeFavouriteGrowforLifeProduct(product.slug);
        dispatch(toggleFavouriteProductsAction(product));
        toast.info("Item removed from favourites.");
      } else {
        await addFavouriteGrowforLifeProduct(product.slug);
        dispatch(toggleFavouriteProductsAction(product));
        toast.success("Item added to favourites.");
      }
    } catch (err) {
      console.log(err);
    } finally {
      closeOverLay();
    }
  };

  if (product.type === "RECIPE") {
    return (
      <>
       
        <div
          className={`border border-[#BEBEBE] h-full flex flex-col ${
            imageWidth ? imageWidth : "w-full"
          } bg-white rounded-lg relative`}
        >
          <div
            className={`group cursor-pointer aspect-square md:aspect-[4/2] w-full 
    overflow-hidden relative`}
          >
            <img
              src={product.images ? product.images[0] : ""}
              className="w-full h-full object-center object-cover rounded-md sm:object-cover group-hover:scale-110 
        duration-300 ease-in-out transition brightness-75"
        alt="product"
            />
            <IoPlay
              className="h-14 w-14 absolute z-20 text-white  top-1/2 left-1/2 transform 
      -translate-x-1/2 -translate-y-1/2"
            />
            {
              <button
                className="h-10 w-10 bg-[#BCBCBC] absolute top-4 right-4 z-10 grid 
          place-items-center text-white"
                onClick={() => {
                  favouriteProductHandler(product, favouriteStatus);
                }}
              >
                {favouriteStatus ? (
                  <RiHeart3Fill className="h-[70%] w-[70%]" />
                ) : (
                  <RiHeart3Line className="h-[70%] w-[70%]" />
                )}
              </button>
            }
          </div>

          <div className="flex flex-col pb-3 px-3 md:px-5 md:pb-5 grow">
            <p className="text-sm font-medium text-black capitalize mt-3 mb-1 flex justify-between items-center">
              Recipes{" "}
              <button onClick={() => openShare(product.slug)}>
                <LuShare2 size={16} />
              </button>
            </p>
            <h2 className=" text-lg font-medium text-black pb-2 ellipsis-two-lines capitalize">
              {product?.name}
            </h2>
            <p className="flex gap-1 items-center text-xs md:text-sm mt-auto">
              <LuClock3 /> Ready in under {product?.recipe.preparationTime}
            </p>
            <p className="mt-3 mb-2 text-xs md:text-sm font-semibold">
              Ingredients for {product?.recipe.servings} servings
            </p>
            <div className="items-center justify-center gap-1 grid grid-cols-[30%,1fr,30%] place-items-center self-start max-w-[5rem]">
              <button
                className="border border-[#CACACA] rounded-md flex items-center justify-center w-full h-full"
                onClick={() => {
                  if (servingsQuantity === 1) return;
                  setServingsQuantity(servingsQuantity - 1);
                }}
              >
                <FiMinus />
              </button>
              <input
                type="text"
                inputMode="numeric"
                className="outline-none p-1 text-center bg-white/0 border-[#CACACA] rounded-md w-full h-full"
                value={servingsQuantity}
                disabled
                // onChange={handleQuantityInput}
              />
              <button
                className="border border-[#CACACA] rounded-md flex items-center justify-center w-full h-full"
                onClick={() => {
                  if (servingsQuantity === 20) return;
                  setServingsQuantity(servingsQuantity + 1);
                }}
              >
                <FiPlus />
              </button>
            </div>

            <section className="flex gap-1 items-center text-sm mb-2">
              <div className="text-[#FFC700] my-2 text-lg">
                <StarRating rating={product?.averageRating ?? 0} />
              </div>{" "}
              ({product?.averageRating}/5.0)
            </section>
            <Link
              className="w-full border border-green text-green font-semibold p-2 inline-block text-center rounded-md"
              to={`/grow-for-life/recipe/${product?.slug}?servings=${servingsQuantity}`}
            >
              View Ingredients
            </Link>
          </div>
        </div>
      </>
    );
  }

  return (
    <div
      className={`p-3 md:p-5 h-full border flex flex-col ${
        imageWidth ? imageWidth : "w-full"
      } 
     border-[#BEBEBE] bg-white rounded-lg relative`}
    >
      <div
        className={`group cursor-pointer aspect-square md:aspect-[4/3] w-full 
      overflow-hidden relative`}
      >
        <img
          src={
            product?.imageUrl
              ? product.imageUrl
              : product.images
              ? product.images[0]
              : ""
          }
          className="w-full h-full object-center object-cover rounded-md sm:object-cover group-hover:scale-110 
          duration-300 ease-in-out transition"
          alt="grow for life product"
        />
        {
          <button
            className="h-10 w-10 bg-[#BCBCBC] absolute top-0 right-0 z-10 grid place-items-center text-white"
            onClick={() => {
              favouriteProductHandler(product, favouriteStatus);
            }}
          >
            {favouriteStatus ? (
              <RiHeart3Fill className="h-[70%] w-[70%]" />
            ) : (
              <RiHeart3Line className="h-[70%] w-[70%]" />
            )}
          </button>
        }
      </div>
      <p className="text-sm font-medium text-black capitalize mt-3 mb-1">
        {product?.category?.name}
      </p>
      <h2 className=" text-lg font-medium text-black py-1 ellipsis-two-lines">
        {product?.name}
      </h2>
      <h4 className="text-base sm:text-lg font-semibold text-black sm:leading-8 mt-auto flex items-center gap-1">
        &#8358;{numberWithCommas(product?.price)}{" "}
        {!product.inSeason && (
          <p
            className="flex w-fit px-[10px] capitalize font-medium text-xs leading-[18px] rounded-[200px] py-[2px] 
      items-center space-x-1 bg-[#FEF3F2] "
          >
            <div
              className={`h-[5px] w-[5px] min-h-[5px] min-w-[5px] rounded-full bg-[#F04438]`}
            />
            <span className={`text-[#F04438] capitalize`}>Off season</span>
          </p>
        )}
      </h4>

      <div className="text-[#FFC700] my-2 text-lg">
        <StarRating rating={product?.averageRating ?? 0} />
      </div>

      <Link
        className="w-full border border-green text-green font-semibold mt-4 p-2 inline-block text-center rounded-md"
        to={`/grow-for-life/products/${product?.slug}`}
      >
        View product
      </Link>
    </div>
  );
};

export default GrowforlifeAssetCard;
