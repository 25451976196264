import WebLayout from "../../components/organisms/layouts/WebLayout";
import HeroCollaboratorSection from "../../components/organisms/pageSections/CollaboratorSections/HeroCollaboratorSection";
import FaqsCollaboratorSection from "../../components/organisms/pageSections/CollaboratorSections/FaqsCollaboratorSection";
import CollaboratorProcessSection from "../../components/organisms/pageSections/CollaboratorSections/CollaboratorProcessSection";

const CollaboratorLandingScreen = () => {
	return (
		<WebLayout>
			<HeroCollaboratorSection />
			<CollaboratorProcessSection />
			<FaqsCollaboratorSection />
		</WebLayout>
	);
};

export default CollaboratorLandingScreen;
