import React from "react";
import useDeleteModal from "../../hooks/modal-hooks/useDeleteModal";
import Modal from "./Modal";
import DeleteNotificationIcon from "../atoms/icons/DeleteNotifcationIcon";
import Button from "../molecules/Button";
import { useAppSelector } from "../../store/hooks";
import { selectCollaborator } from "../../store/collaborator/reducer";

const DeleteModal = () => {
	const { isOpen, onClose, proceed, modalHeader, modalContent } =
		useDeleteModal();

	const { deleteProductBySlug } = useAppSelector(selectCollaborator);

	const handleDelete = () => {
		proceed();
	};

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			full={false}
			className="bg-white relative w-full md:w-auto md:max-w-[400px] overflow-scroll z-50 p-7 rounded-2xl md:rounded-xl"
		>
			<div className="w-full">
				<DeleteNotificationIcon />
				<div className="w-full mt-4 space-y-2">
					<h2 className="text-[#101828] text-lg font-medium leading-7">
						{modalHeader}
					</h2>
					<p className="text-[#667085] text-sm font-normal leading-5">
						{modalContent}
					</p>
				</div>
				<div className="flex items-center w-full space-x-5 mt-5">
					<Button
						className="text-[#344054] font-medium text-base leading-6 flex items-center justify-center w-full  shadow-sm border border-[#D0D5DD]"
						onClick={onClose}
						disabled={deleteProductBySlug.loading}
					>
						Cancel
					</Button>
					<Button
						className="text-white shadow-sm bg-[#D92D20] w-full font-medium text-base leading-6 justify-center"
						onClick={handleDelete}
						disabled={deleteProductBySlug.loading}
						isLoading={deleteProductBySlug.loading}
					>
						Delete
					</Button>
				</div>
			</div>
		</Modal>
	);
};

export default DeleteModal;
