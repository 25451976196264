import { Link, useNavigate } from "react-router-dom";
import LoginHeader from "../components/molecules/LoginHeader";
import { Formik, Form } from "formik";
import * as yup from "yup";
import FormInput from "../components/molecules/FormInput";
import PasswordInput from "../components/molecules/PasswordInput";
import AuthLoginLayout from "../components/organisms/layouts/AuthLoginLayout";
import {
  resetPassword,
  resetPasswordSendOtp,
  verifyOtp,
} from "../store/services/profileService";
import { useState } from "react";
import Button from "../components/molecules/Button";
import { toast } from "react-toastify";
import { useLoadingOverlay } from "../components/atoms/LoadingOverlay";

const userSchema = yup.object({
  email: yup.string().email().required("Email is required"),
});

const otpSchema = yup.object({
  otp: yup.string().required("Enter Otp"),
});

const passwordSchema = yup.object({
  password: yup
    .string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters long")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/[0-9]/, "Password must contain at least one number")
    .matches(
      /[!@#$%^&*()\-_=+{};:,<.>]/,
      "Password must contain at least one special character"
    ),
  confirmPassword: yup.string().required("Required"),
});

type User = yup.InferType<typeof userSchema>;

const ForgotPasswordScreen = () => {
  const initialValues: User = {
    email: "",
  };
  const [token, setToken] = useState(null);
  const [verifiedOtp, setVerifiedOtp] = useState<any>(null);
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const { openOverLay, closeOverLay } = useLoadingOverlay();

  const getOtpHandler = async (values: User, resetForm?: any) => {
    setEmail(values.email);
    try {
      const res = await resetPasswordSendOtp(values);
      if (res) {
        setToken(res.data.token);
      }
      else{
        toast.info('Check email address')
      }
    } catch (error:any) {
      toast.error(error?.message)
      console.log(error);
    } finally {
      resetForm && resetForm();
    }
  };

  const verifyOtpHandler = async (values: any, resetForm: any) => {
    try {
      const res = await verifyOtp({
        otp: values.otp,
        token: token ?? "",
      });
      if (res) {
        setVerifiedOtp(res);
      }
      console.log(res)
    } catch (err:any) {
      toast.error(err?.response?.data?.message);
    } finally {
      resetForm();
    }
  };

  const resetPasswordHandler = async (
    values: any,
    resetForm: any,
    setSubmitting: any
  ) => {
    if (values.password !== values.confirmPassword) {
      setSubmitting(false);
      return toast.error("Ensure both passwords match");
    }
    try {
      const res = await resetPassword({
        password: values.password,
        token: verifiedOtp?.data?.token ?? "",
      });
      if (res) {
        toast.success("Password reset successful");
        setTimeout(() => {
          navigate("/login");
        }, 2500);
      }
      console.log(res);
    } catch (err) {
      console.log(err);
    } finally {
      resetForm();
    }
  };

  const resendOtp = async () => {
    openOverLay();
    try {
      await getOtpHandler({ email });
    } catch (err) {
      console.log(err);
    } finally {
      closeOverLay();
    }
  };

  return (
    <AuthLoginLayout loginPage="LOGIN">
      <section className="p-8 py-10 bg-white rounded-md w-full">
        <div className="w-full h-16 mb-2">
          <Link to="/">
            <img
              src={"/assets/images/logo.png"}
              alt="logo"
              className="w-full h-full object-contain"
            />
          </Link>
        </div>
        {!token && (
          <div className="w-full">
            <LoginHeader
              header="Forgot Password?"
              subHeader="Enter the email you used to create your account so we can send you instructions on how to reset your password."
            />
            <Formik
              initialValues={initialValues}
              onSubmit={(values: User, { resetForm }) => {
                getOtpHandler(values, resetForm);
              }}
              validationSchema={userSchema}
            >
              {({ isSubmitting, setFieldValue }) => {
                return (
                  <Form className="mt-8 flex flex-col gap-2">
                    <FormInput
                      id="email"
                      name="email"
                      placeholder="Email Address"
                      label="Email Address"
                      onChange={(e)=>setFieldValue('email', e.target.value)}
                    />
                    <Button
                      type="submit"
                      isLoading={isSubmitting}
                      className="text-white leading-7 h-[3rem] w-full
						  font-semibold bg-green rounded-lg mt-5 flex justify-center "
                    >
                      Send
                    </Button>
                    <Link
                      to="/login"
                      className="text-green justify-center flex items-center leading-7 h-[3rem] mt-2 w-full font-semibold bg-white
							  border border-green rounded-lg"
                    >
                      Back To Login
                    </Link>
                  </Form>
                );
              }}
            </Formik>
          </div>
        )}

        {token && !verifiedOtp && (
          <div className="w-full">
            <LoginHeader
              header="Enter OTP"
              subHeader="Enter OTP sent to your email address."
            />

            <Formik
              initialValues={{
                otp: "",
              }}
              onSubmit={(values, { resetForm }) => {
                verifyOtpHandler(values, resetForm);
              }}
              validationSchema={otpSchema}
            >
              {({ isSubmitting,setFieldValue }) => {
                return (
                  <Form className="mt-8 flex flex-col gap-2">
                    <FormInput
                      id="otp"
                      name="otp"
                      placeholder="OTP"
                      label="Enter OTP"
                      onChange={(e)=>setFieldValue('otp', e.target.value)}
                    />
                    <Button
                      type="submit"
                      isLoading={isSubmitting}
                      className="text-white leading-7 h-[3rem] w-full
								  font-semibold bg-green rounded-lg mt-5 flex justify-center "
                    >
                      Verify OTP
                    </Button>
                    <button
                      type="button"
                      className="text-green justify-center flex items-center leading-7 h-[3rem] mt-2 w-full font-semibold bg-white
							  border border-green rounded-lg"
                      onClick={resendOtp}
                    >
                      Resend OTP
                    </button>
                  </Form>
                );
              }}
            </Formik>
          </div>
        )}

        {token && verifiedOtp && (
          <div className="w-full">
            <LoginHeader
              header="Reset your password"
              subHeader="Enter your new password and confirm"
            />

            <Formik
              initialValues={{
                password: "",
                confirmPassword: "",
              }}
              onSubmit={(values, { resetForm, setSubmitting }) => {
                resetPasswordHandler(values, resetForm, setSubmitting);
              }}
              validationSchema={passwordSchema}
            >
              {({ isSubmitting }) => {
                return (
                  <Form className="mt-8 flex flex-col gap-2">
                    <PasswordInput
                      id="password"
                      name="password"
                      placeholder="New Password"
                      label="Enter new password"
                    />
                    <PasswordInput
                      id="confirmPassword"
                      name="confirmPassword"
                      placeholder="Confirm Password"
                      label="Confirm new password"
                    />
                    <Button
                      type="submit"
                      isLoading={isSubmitting}
                      className="text-white leading-7 h-[3rem] w-full
								  font-semibold bg-green rounded-lg mt-5 flex justify-center "
                    >
                      Reset Password
                    </Button>
                    <Link
                      to="/login"
                      className="text-green justify-center flex items-center leading-7 h-[3rem] mt-2 w-full font-semibold bg-white
							  border border-green rounded-lg"
                    >
                      Back To Login
                    </Link>
                  </Form>
                );
              }}
            </Formik>
          </div>
        )}
      </section>
    </AuthLoginLayout>
  );
};

export default ForgotPasswordScreen;
