import Modal from "../../modal/Modal";
import { useState } from "react";
import { useLocation } from "react-router-dom";

const toolTipData = [
  {
    imageUrl: "/assets/tool-tip/gfl_1.png",
    title: "Welcome to Grow for Life",
    desc: "Hello there, here is a quick tour to show you around how Grow for Life as a product works.",
  },
  {
    imageUrl: "/assets/tool-tip/gfl_2.png",
    title: "Age range",
    desc: "The age range questionnaire allows us suggest food of the best quality nutrition wise for optimal growth and development for you and the members of your household. ",
  },
  {
    imageUrl: "/assets/tool-tip/gfl_3.png",
    title: "Budget",
    desc: "The budget feature allows you shop with a budget if you like to. ",
  },
  {
    imageUrl: "/assets/tool-tip/gfl_4.png",
    title: "Budget Monitor",
    desc: "The budget monitor allows you monitor the progression as you spend.",
  },
  {
    imageUrl: "/assets/tool-tip/gfl_5.png",
    title: "Analytics",
    desc: "The analytics feature allows you enjoy insights into how you have spent detailing what percentages you have spent per product category. .",
  },
  {
    imageUrl: "/assets/tool-tip/gfl_6.png",
    title: "Product Category",
    desc: "Here you will find a wide range of products clearly arranged in categories for your enjoyment. ",
  },

  {
    imageUrl: "/assets/tool-tip/gfl_7.png",
    title: "Shopping List",
    desc: "Your shopping list is designed to mimic its paper counterpart with attention to details, it displays your purchases per category organically. ",
  },
];

const GrowforLifeToolTip = () => {
  const [toolTipStep, setToolTipStep] = useState(0);
  const { pathname } = useLocation();
  const [showGflTooltip, setShowGflToolTip] = useState(
    localStorage.getItem("showGflTooltip") && pathname === "/grow-for-life/home"
      ? true
      : false
    );


  const closeGflTooltip = () => {
    localStorage.removeItem("showGflTooltip");
    setShowGflToolTip(false);
  };

  const handleNextClick = () => {
    if (toolTipStep === toolTipData.length - 1) {
      return closeGflTooltip();
    }
    setToolTipStep(toolTipStep + 1);
  };

  const handlePrevClick = () => {
    if (toolTipStep === 0) return;
    setToolTipStep(toolTipStep - 1);
  };

  return (
    <Modal
      isOpen={showGflTooltip}
      onClose={() => null}
      full={false}
      className="translate relative mx-auto flex-col
  border-0 bg-green flex shadow-lg outline-none focus:outline-none md:h-auto p-4 rounded-lg 
  w-full max-w-[450px] md:p-5 max-h-[80vh] overflow-auto"
    >
      <button className="self-end" onClick={closeGflTooltip}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="12" cy="12" r="12" fill="white" />
          <path
            d="M8.50684 7.7998L7.7998 8.50684L11.4463 12.1533L7.7998 15.7998L8.50684 16.5068L12.1533 12.8604L15.7998 16.5068L16.5068 15.7998L12.8604 12.1533L16.5068 8.50684L15.7998 7.7998L12.1533 11.4463L8.50684 7.7998Z"
            fill="black"
          />
        </svg>
      </button>

      {toolTipData.map((tooltip, index) => (
        <div
          key={index}
          className={`${
            index === toolTipStep ? "flex" : "hidden"
          } flex-col gap-3 text-white mt-3`}
        >
          <img
            src={tooltip.imageUrl}
            className="w-full object-contain"
            alt=""
          />
          <h2 className="text-xl font-medium mt-5">{tooltip.title}</h2>
          <p className="text-sm">{tooltip.desc}</p>
          <section className="flex justify-between items-center mt-5">
            <div className="flex gap-1 items-center">
              {toolTipData.map((el, index) => (
                <button
                  key={index}
                  className={`h-2 w-2 rounded-full ${
                    index === toolTipStep ? "bg-white" : "bg-[#006C18]"
                  }`}
                  onClick={() => setToolTipStep(index)}
                ></button>
              ))}
            </div>

            <div>
              {toolTipStep !== 0 && (
                <button className="p-3 px-8" onClick={handlePrevClick}>
                  Previous
                </button>
              )}
              <button
                className="bg-[#006C18] p-3 px-8 rounded-md"
                onClick={handleNextClick}
              >
                {index === toolTipData.length - 1 ? "Close" : "Next"}
              </button>
            </div>
          </section>
        </div>
      ))}
    </Modal>
  );
};

export default GrowforLifeToolTip;
