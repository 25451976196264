import { useState, useEffect } from "react";
import Button from "../../../molecules/Button";
import { Form, Formik } from "formik";
import FormSearchDropDown from "../../../molecules/FormSearchDropDown";
import useCountryList from "../../../../hooks/useCountryList";
import Modal from "../../../modal/Modal";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { getGrowforLifeBudgetAgeRangeAction } from "../../../../store/grow-for-life/growForLifeAction";
import { useNavigate } from "react-router-dom";
import { checkDeliveryLocation } from "../../../../store/services/growForLifeServices";
import * as yup from "yup";
import { useLoadingOverlay } from "../../../atoms/LoadingOverlay";
import { selectAuthUser } from "../../../../store/auth/authReducer";
import HeroQuickleapDeliversSection from "./HeroQuickleapDeliversSection";

const locationSchema = yup.object({
  state: yup.string().required("State is required"),
  city: yup.string().required("City is required"),
});

const HeroGrowforlifeSection = () => {
  const [quickleapDelivers, setQuickleapDelivers] = useState<any>(
    localStorage.getItem("quickleapDelivers") ?? null
  );
  const {
    setSelectedState,
    countryStates,
    stateCities,
    countries,
    setSelectedCountry,
  } = useCountryList();
  const [modalInfo, setModalInfo] = useState("success");
  const { isAuthenticated } = useAppSelector(selectAuthUser);
  const [modal, setModal] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { openOverLay, closeOverLay } = useLoadingOverlay();

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    setSelectedCountry(
      countries.find((country) => country.value === "nigeria")
    );
    dispatch(getGrowforLifeBudgetAgeRangeAction());
  }, []);

  const checkDeliveryArea = async (values: any) => {
    // console.log(values)
    openOverLay();
    try {
      const res = await checkDeliveryLocation(values);
      if (res.data.deliveryToLocation) {
        setModalInfo("success");
        setQuickleapDelivers("true");
        localStorage.setItem("quickleapDelivers", "true");
      } else {
        setModalInfo("fail");
        setQuickleapDelivers(null);
        localStorage.removeItem("quickleapDelivers");
      }
      setModal(true);
    } catch (err) {
      console.log(err);
    } finally {
      closeOverLay();
    }
  };

  const checkBudgetAgeRange = () => {
    setModal(false);
    if (!isAuthenticated) {
      return navigate("/register");
    }
 else {
      navigate("/grow-for-life/home");
    }
  };

  return (
    <>
      <Modal
        isOpen={modal}
        onClose={() => {
          setModal(false);
        }}
        full={false}
        className="translate relative mx-auto flex-col overflow-y-scroll md:overflow-y-visible
		  border-0 bg-white inline-flex shadow-lg outline-none focus:outline-none md:h-auto p-8 md:p-10 rounded-lg"
      >
        {modalInfo === "success" ? (
          <div className="flex flex-col gap-6 font-semibold text-center">
            <img
              src="/assets/grow-for-life/smiley-face.png"
              className="h-24 object-contain"
              alt=""
            />
            <p> We are good to go we deliver to your location</p>
            <button
              className="bg-green p-2 text-white w-full rounded-sm"
              onClick={() => checkBudgetAgeRange()}
            >
              {isAuthenticated ? "Continue" : "Sign up"}
            </button>
          </div>
        ) : (
          <div className="flex flex-col gap-6 font-semibold text-center">
            <img
              src="/assets/grow-for-life/sad_face.png"
              className="h-24 object-contain"
              alt=""
            />
            <p>We are currently not available in your location</p>
            <button
              className="bg-green p-2 text-white"
              onClick={() => setModal(false)}
            >
              Close
            </button>
          </div>
        )}
      </Modal>

      {quickleapDelivers && isAuthenticated ? (
       <HeroQuickleapDeliversSection />
      ) : (
        <div className="w-full h-[70vh] md:h-[85vh] relative">
          <div className="grow-for-life-banner w-full h-full" />

          <div className="z-10 absolute top-0 left-0 w-full h-full flex items-center justify-center">
            <div className="bg-white rounded-2xl p-5 md:p-9 w-[85vw] max-w-[600px]">
              <h2 className="text-xl sm:text-2xl lg:text-3xl font-bold">
                Let’s start by confirming we currently deliver to your area
              </h2>

              <Formik
                initialValues={{
                  city: "",
                  state: "",
                }}
                onSubmit={(values) => {
                  checkDeliveryArea(values);
                }}
                validationSchema={locationSchema}
              >
                {({ values, setFieldValue, isSubmitting }) => {
                  return (
                    <Form className="space-y-4 mt-4">
                      <section className="grid w-full">
                        <FormSearchDropDown
                          name="state"
                          id="state"
                          onChange={(value) => {
                            setSelectedState(
                              countryStates.find(
                                (state: { value: string }) =>
                                  state.value === value
                              )
                            );
                            setFieldValue("state", value);
                            setFieldValue("city", "");
                          }}
                          options={countryStates}
                          label=""
                          placeholder="State"
                        />

                        <FormSearchDropDown
                          name="city"
                          id="city"
                          onChange={(value) => {
                            setFieldValue("city", value);
                          }}
                          options={stateCities}
                          label=""
                          placeholder="City"
                        />
                      </section>

                      <div className="flex justify-center mt-1">
                        <Button
                          className="p-3 h-12 bg-green font-semibold text-white rounded-md w-full flex items-center justify-center"
                          type="submit"
                        >
                          Continue
                        </Button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default HeroGrowforlifeSection;
