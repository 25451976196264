import HeroGrowforlifeSection from "../../components/organisms/pageSections/GrowforlifeSections/HeroGrowforlifeSection";
import BenefitsGrowforlifeSection from "../../components/organisms/pageSections/GrowforlifeSections/BenefitsGrowforlifeSection";
import ProcessGrowforlifeSection from "../../components/organisms/pageSections/GrowforlifeSections/ProcessGrowforlifeSection";
import Container from "../../components/organisms/Container";
import TopSellingGrowforlifeSection from "../../components/organisms/pageSections/GrowforlifeSections/TopSellingGrowforlifeSection";
import GetStartedCta from "../../components/molecules/GetStartedCta";
import FaqsGrowforLifeSection from "../../components/organisms/pageSections/GrowforlifeSections/FaqsGrowforLifeSection";
import WebLayout from "../../components/organisms/layouts/WebLayout";
import BudgetAgeRangeModal from "../../components/modal/BudgetAgeRangeModal";

const GrowforlifeLandingScreen = () => {

	return (
		<WebLayout>
        <BudgetAgeRangeModal showAgeRange={true} />
		 <HeroGrowforlifeSection />
			<ProcessGrowforlifeSection />
			<BenefitsGrowforlifeSection />
			<TopSellingGrowforlifeSection />
			{/* <CustomerFeedbackGrowforlifeSection /> */}
			<FaqsGrowforLifeSection />
			
			<Container className="px-5 mt-20">
			<GetStartedCta />
			</Container>
			
		</WebLayout>
	);
};

export default GrowforlifeLandingScreen;
