import Container from "../../Container";

const BenefitsGrowforlifeSection = () => {
	return (
		<div className="w-full bg-green-light">
			<Container className="py-5 lg:py-10 xl:py-14">
				<div className="w-full flex flex-col  px-5 lg:flex-row gap-4 md:gap-10">
					<div className="w-full max-w-[450px] lg:max-w-[500px] mx-auto h-[450px] lg:min-h-[600px] lg:h-full lg:max-h-[800px] overflow-hidden rounded-xl">
						<img
							src="../assets/images/enjoy.png"
							alt="enjoy"
							className="w-full h-full object-cover"
						/>
					</div>
					<div className="w-full lg:w-[50%] pt-10">
						<h2 className="font-semibold text-xl sm:text-[24px] lg:text-[28px] text-black xl:text-[35px] 2xl:text-[40px]">
							You get to enjoy
						</h2>
						<div className="w-full flex items-center space-x-2 mt-5">
							<img
								src="../assets/images/tick.png"
								alt="marker"
								className="w-[30px] h-[30px] md:w-[35px] md:h-[35px] lg:w-[40px] lg:h-[40px]"
							/>
							<p className="font-normal leading-9 text-black text-base lg:text-lg">
								No membership fee
							</p>
						</div>
						<div className="w-full flex items-center space-x-2 mt-5">
							<img
								src="../assets/images/tick.png"
								alt="marker"
								className="w-[30px] h-[30px] md:w-[35px] md:h-[35px] lg:w-[40px] lg:h-[40px]"
							/>
							<p className="font-normal leading-9 text-black text-base lg:text-lg">
								Access to our complete selection of fresh fruits,
								produce, meat, roots, tubers, recipes etc and high
								quality, sustainable groceries
							</p>
						</div>
						<div className="w-full flex items-center space-x-2 mt-5">
							<img
								src="../assets/images/tick.png"
								alt="marker"
								className="w-[30px] h-[30px] md:w-[35px] md:h-[35px] lg:w-[40px] lg:h-[40px]"
							/>
							<p className="font-normal leading-9 text-black text-base lg:text-lg">
								Everyday savings of up to 10% off compared to market
								prices
							</p>
						</div>
						<div className="w-full flex items-center space-x-2 mt-5">
							<img
								src="../assets/images/tick.png"
								alt="marker"
								className="w-[30px] h-[30px] md:w-[35px] md:h-[35px] lg:w-[40px] lg:h-[40px]"
							/>
							<p className="font-normal leading-9 text-black text-base lg:text-lg">
								Delicious recipes, produce guides, how-to, and more to
								inspire you in the kitchen
							</p>
						</div>
						<div className="w-full flex items-center space-x-2 mt-5">
							<img
								src="../assets/images/tick.png"
								alt="marker"
								className="w-[30px] h-[30px] md:w-[35px] md:h-[35px] lg:w-[40px] lg:h-[40px]"
							/>
							<p className="font-normal leading-9 text-black text-base lg:text-lg">
								You get to support farmers, by buying directly what they
								produce, enabling them farm sustainably
							</p>
						</div>
						<div className="w-full flex items-center space-x-2 mt-5">
							<img
								src="../assets/images/tick.png"
								alt="marker"
								className="w-[30px] h-[30px] md:w-[35px] md:h-[35px] lg:w-[40px] lg:h-[40px]"
							/>
							<p className="font-normal leading-9 text-black text-base lg:text-lg">
								Your order gets packed a day to delivery, ensuring you
								have your produce fresh and ready to enjoy
							</p>
						</div>
					</div>
				</div>
			</Container>
		</div>
	);
};

export default BenefitsGrowforlifeSection;
