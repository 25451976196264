import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../index";
import {
	CollaboratorProductType,
	DashboardAnalyticsType,
	PaginationType,
	ProductsForVerificationType,
} from "../../types/collaborator";
import {
	deleteProductBySlugAction,
	fetchDashboardAnalyticsAction,
	fetchDashboardProductsAction,
	fetchDashboardTopSellingItemsAction,
	handleUploadProductAction,
	fetchCollaboratorOrdersAction,
	fetchCollaboratorTransactionsAction,
	fecthProductsAssignedToFieldAgentAction,
	fetchCollaboratorListingAction,
	fetchQuicleapPricingListAction,
	fetchProductBySlugAction,
} from "./action";
import { PreApprovedProductType } from "../../types/admin";

export interface CollaboratorTypes {
	dashboardAnalytics: DashboardAnalyticsType;
	getDashboardAnalyticsInfo: {
		loading: boolean;
		error: string | null;
		success: boolean;
	};

	topSellingProducts: any[];
	getTopSellingProductsInfo: {
		loading: boolean;
		error: string | null;
		success: boolean;
	};

	uploadedProduct: {
		id: string;
		name: string;
		slug: string;
		images: string[];
		quantity: number;
		quantityUnit: string;
		price: string;
		status: string;
		address: string;
		country: string;
		state: string;
		city: string;
		type: string;
		moisture_content: string | null;
		cuisine_type: string | null;
		collaboratorId: string;
		isApproved: boolean;
		createdAt: string;
		updatedAt: string;
		deletedAt: string | null;
	} | null;

	uploadProductInfo: {
		loading: boolean;
		error: string | null;
		success: boolean;
	};

	uploadedProducts: {
		data: CollaboratorProductType[];
		meta: PaginationType;
	};
	getUploadedProductsInfo: {
		loading: boolean;
		error: string | null;
		success: boolean;
	};

	productListing: {
		data: CollaboratorProductType[];
		meta: PaginationType;
	};
	getProductListing: {
		loading: boolean;
		error: string | null;
		success: boolean;
	};

	deleteProductBySlug: {
		success: boolean;
		error: string | null;
		loading: boolean;
	};

	transactionData: {
		data: any[];
		meta: PaginationType;
	};
	getTransactionsInfo: {
		loading: boolean;
		error: string | null;
		success: boolean;
	};
	ordersData: {
		data: any[];
		meta: PaginationType;
	};
	getOrdersInfo: {
		loading: boolean;
		error: string | null;
		success: boolean;
	};

	fieldAgentProductsToVerify: {
		data: ProductsForVerificationType[];
		meta: PaginationType;
	};
	getProductsToVerify: {
		loading: boolean;
		error: string | null;
		success: boolean;
	};

	quickLeapPricingList: {
		data: PreApprovedProductType[];
		meta: PaginationType;
	};
	getQuickLeapPricingListState: {
		loading: boolean;
		error: string | null;
		success: boolean;
	};

	product: CollaboratorProductType | null;
	getProductBySlugState: {
		error: string | null;
		loading: boolean;
		success: boolean;
	};
}

const initialState: CollaboratorTypes = {
	product: null,
	getProductBySlugState: {
		error: null,
		loading: false,
		success: false,
	},
	deleteProductBySlug: {
		success: false,
		error: null,
		loading: false,
	},

	dashboardAnalytics: {
		totalRevenue: 0,
		averageSalesPerMonth: 0,
		totalOrders: 0,
		totalOutstandingBalance: 0,
	},
	getDashboardAnalyticsInfo: {
		loading: false,
		error: null,
		success: false,
	},
	topSellingProducts: [],
	getTopSellingProductsInfo: {
		loading: false,
		error: null,
		success: false,
	},

	uploadedProduct: null,
	uploadProductInfo: {
		loading: false,
		error: null,
		success: false,
	},

	uploadedProducts: {
		data: [],
		meta: {
			totalCount: 0,
			currentPage: 0,
			itemsPerPage: 0,
			totalPages: 0,
			hasNextPage: false,
			hasPreviousPage: false,
		},
	},

	getUploadedProductsInfo: {
		loading: false,
		error: null,
		success: false,
	},

	transactionData: {
		data: [],
		meta: {
			totalCount: 0,
			currentPage: 0,
			itemsPerPage: 0,
			totalPages: 0,
			hasNextPage: false,
			hasPreviousPage: false,
		},
	},

	getTransactionsInfo: {
		loading: false,
		error: null,
		success: false,
	},
	ordersData: {
		data: [],
		meta: {
			totalCount: 0,
			currentPage: 0,
			itemsPerPage: 0,
			totalPages: 0,
			hasNextPage: false,
			hasPreviousPage: false,
		},
	},
	getOrdersInfo: {
		loading: false,
		error: null,
		success: false,
	},
	fieldAgentProductsToVerify: {
		data: [],
		meta: {
			totalCount: 0,
			currentPage: 0,
			itemsPerPage: 0,
			totalPages: 0,
			hasNextPage: false,
			hasPreviousPage: false,
		},
	},
	getProductsToVerify: {
		loading: false,
		error: null,
		success: false,
	},

	productListing: {
		data: [],
		meta: {
			totalCount: 0,
			currentPage: 0,
			itemsPerPage: 0,
			totalPages: 0,
			hasNextPage: false,
			hasPreviousPage: false,
		},
	},
	getProductListing: {
		loading: false,
		error: null,
		success: false,
	},
	quickLeapPricingList: {
		data: [],
		meta: {
			totalCount: 0,
			currentPage: 0,
			itemsPerPage: 0,
			totalPages: 0,
			hasNextPage: false,
			hasPreviousPage: false,
		},
	},
	getQuickLeapPricingListState: {
		loading: false,
		error: null,
		success: false,
	},
};

const collaboratorSlice = createSlice({
	name: "collaborator",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		//Get dashboard analytics info
		builder
			.addCase(fetchDashboardAnalyticsAction.pending, (state) => {
				state.getDashboardAnalyticsInfo.loading = true;
			})
			.addCase(
				fetchDashboardAnalyticsAction.fulfilled,
				(state, { payload }) => {
					state.dashboardAnalytics = payload;
					state.getDashboardAnalyticsInfo.loading = false;
					state.getDashboardAnalyticsInfo.error = null;
					state.getDashboardAnalyticsInfo.success = true;
				}
			)
			.addCase(
				fetchDashboardAnalyticsAction.rejected,
				(state, { payload }) => {
					state.getDashboardAnalyticsInfo.error = payload as string;
					state.getDashboardAnalyticsInfo.success = false;
					state.getDashboardAnalyticsInfo.loading = false;
				}
			);

		//Get top selling products info
		builder
			.addCase(fetchDashboardTopSellingItemsAction.pending, (state) => {
				state.getTopSellingProductsInfo.loading = true;
			})
			.addCase(
				fetchDashboardTopSellingItemsAction.fulfilled,
				(state, { payload }) => {
					state.topSellingProducts = payload;
					state.getTopSellingProductsInfo.loading = false;
					state.getTopSellingProductsInfo.error = null;
					state.getTopSellingProductsInfo.success = true;
				}
			)
			.addCase(
				fetchDashboardTopSellingItemsAction.rejected,
				(state, { payload }) => {
					state.getTopSellingProductsInfo.error = payload as string;
					state.getTopSellingProductsInfo.success = false;
					state.getTopSellingProductsInfo.loading = false;
				}
			);

		//	Get uploaded product
		builder
			.addCase(handleUploadProductAction.pending, (state) => {
				state.uploadProductInfo.loading = true;
			})
			.addCase(handleUploadProductAction.fulfilled, (state, { payload }) => {
				state.uploadedProduct = payload.product;
				state.uploadProductInfo.loading = false;
				state.uploadProductInfo.error = null;
				state.uploadProductInfo.success = true;
			})
			.addCase(handleUploadProductAction.rejected, (state, { payload }) => {
				state.uploadProductInfo.error = payload as string;
				state.uploadProductInfo.success = false;
				state.uploadProductInfo.loading = false;
			});

		//Get uploaded products
		builder
			.addCase(fetchDashboardProductsAction.pending, (state) => {
				state.getUploadedProductsInfo.loading = true;
			})
			.addCase(
				fetchDashboardProductsAction.fulfilled,
				(state, { payload }) => {
					state.uploadedProducts = payload;
					state.getUploadedProductsInfo.loading = false;
					state.getUploadedProductsInfo.error = null;
					state.getUploadedProductsInfo.success = true;
				}
			)
			.addCase(
				fetchDashboardProductsAction.rejected,
				(state, { payload }) => {
					state.getUploadedProductsInfo.error = payload as string;
					state.getUploadedProductsInfo.success = false;
					state.getUploadedProductsInfo.loading = false;
				}
			);

		//Delete product by slug
		builder
			.addCase(deleteProductBySlugAction.pending, (state) => {
				state.deleteProductBySlug.loading = true;
			})
			.addCase(deleteProductBySlugAction.fulfilled, (state) => {
				state.deleteProductBySlug.loading = false;
				state.deleteProductBySlug.success = true;
				state.deleteProductBySlug.error = null;
			})
			.addCase(deleteProductBySlugAction.rejected, (state, { payload }) => {
				state.deleteProductBySlug.loading = false;
				state.deleteProductBySlug.success = false;
				state.deleteProductBySlug.error = payload as string;
			});

		//Get transactions info
		builder
			.addCase(fetchCollaboratorTransactionsAction.pending, (state) => {
				state.getTransactionsInfo.loading = true;
			})
			.addCase(
				fetchCollaboratorTransactionsAction.fulfilled,
				(state, { payload }) => {
					state.transactionData = payload;
					state.getTransactionsInfo.loading = false;
					state.getTransactionsInfo.error = null;
					state.getTransactionsInfo.success = true;
				}
			)
			.addCase(
				fetchCollaboratorTransactionsAction.rejected,
				(state, { payload }) => {
					state.getTransactionsInfo.error = payload as string;
					state.getTransactionsInfo.success = false;
					state.getTransactionsInfo.loading = false;
				}
			);

		//get order info
		builder
			.addCase(fetchCollaboratorOrdersAction.pending, (state) => {
				state.getOrdersInfo.loading = true;
			})
			.addCase(
				fetchCollaboratorOrdersAction.fulfilled,
				(state, { payload }) => {
					state.ordersData = payload;
					state.getOrdersInfo.loading = false;
					state.getOrdersInfo.error = null;
					state.getOrdersInfo.success = true;
				}
			)
			.addCase(
				fetchCollaboratorOrdersAction.rejected,
				(state, { payload }) => {
					state.getOrdersInfo.error = payload as string;
					state.getOrdersInfo.success = false;
					state.getOrdersInfo.loading = false;
				}
			);

		//FieldAgent Products to verify
		builder
			.addCase(fecthProductsAssignedToFieldAgentAction.pending, (state) => {
				state.getProductsToVerify.loading = true;
			})
			.addCase(
				fecthProductsAssignedToFieldAgentAction.fulfilled,
				(state, { payload }) => {
					state.fieldAgentProductsToVerify = payload;
					state.getProductsToVerify.loading = false;
					state.getProductsToVerify.error = null;
					state.getProductsToVerify.success = true;
				}
			)
			.addCase(
				fecthProductsAssignedToFieldAgentAction.rejected,
				(state, { payload }) => {
					state.getProductsToVerify.error = payload as string;
					state.getProductsToVerify.success = false;
					state.getProductsToVerify.loading = false;
				}
			);

		//Product listing
		builder
			.addCase(fetchCollaboratorListingAction.pending, (state) => {
				state.getProductListing.loading = true;
			})
			.addCase(
				fetchCollaboratorListingAction.fulfilled,
				(state, { payload }) => {
					state.productListing = payload;
					state.getProductListing.loading = false;
					state.getProductListing.error = null;
					state.getProductListing.success = true;
				}
			)
			.addCase(
				fetchCollaboratorListingAction.rejected,
				(state, { payload }) => {
					state.getProductListing.error = payload as string;
					state.getProductListing.success = false;
					state.getProductListing.loading = false;
				}
			);

		//Pricing list
		builder
			.addCase(fetchQuicleapPricingListAction.pending, (state) => {
				state.getQuickLeapPricingListState.loading = true;
			})
			.addCase(
				fetchQuicleapPricingListAction.fulfilled,
				(state, { payload }) => {
					state.getQuickLeapPricingListState.loading = false;
					state.getQuickLeapPricingListState.error = null;
					state.getQuickLeapPricingListState.success = true;
					state.quickLeapPricingList = payload;
				}
			)
			.addCase(
				fetchQuicleapPricingListAction.rejected,
				(state, { payload }) => {
					state.getQuickLeapPricingListState.loading = false;
					state.getQuickLeapPricingListState.error = payload as string;
					state.getQuickLeapPricingListState.success = false;
				}
			);

		//Get product by slug
		builder
			.addCase(fetchProductBySlugAction.pending, (state) => {
				state.getProductBySlugState.loading = true;
			})
			.addCase(fetchProductBySlugAction.fulfilled, (state, { payload }) => {
				state.getProductBySlugState.loading = false;
				state.getProductBySlugState.error = null;
				state.getProductBySlugState.success = true;
				state.product = payload.product;
			})
			.addCase(fetchProductBySlugAction.rejected, (state, { payload }) => {
				state.getProductBySlugState.loading = false;
				state.getProductBySlugState.error = payload as string;
				state.getProductBySlugState.success = false;
			});
	},
});

export const selectCollaborator = (state: RootState) => state.collaborator;

export default collaboratorSlice.reducer;
