import axios from "../../axios.config";

const fecthUserProfile = async () => {
	const response = await axios.get("/profile/me");

	if (response.status === 200 || response.status === 201) {
		return response.data;
	}

	throw new Error(response.data.message);
};

const updateUserLocation = async (
	data: {
		address: string;
		country: string;
		state: string;
		city: string;
	},
	id?: string
) => {
	const response = id
		? await axios.put(`/profile/location/${id}`, data)
		: await axios.post(`/profile/location`, data);

	if (response.status === 200 || response.status === 201) {
		return response.data;
	}

	throw new Error(response.data.message);
};

const getNotifications = async () => {
	const response = await axios.get(`/profile/notifications`);

	if (response.status === 200 || response.status === 201) {
		return response.data;
	}

	throw new Error(response.data.message);
};


const handleUserProfileUpdate = async (data: {
	firstName: string;
	lastName: string;
	email: string;
	phoneNumber: string;
	countryCode: string;
	imageUrl?: string;
}) => {
	const { firstName, lastName, email, phoneNumber, countryCode, imageUrl } =
		data;

	const dataInfo = {
		firstName: firstName,
		lastName: lastName,
		individualEmail: email,
		individualPhoneNumber: phoneNumber,
		individualCountryCode: countryCode,
		individualImageUrl: imageUrl,
	};

	const response = await axios.put(`/profile/update`, {
		...dataInfo,
	});

	if (response.status === 200 || response.status === 201) {
		return response.data;
	}

	throw new Error(response.data.message);
};

const handleCollaboratorProfileUpdate = async (data: {
	firstName?: string;
	lastName?: string;
	email?: string;
	phoneNumber?: string;
	companyName?: string;
	image?: string;
}) => {
	const { firstName, lastName, email, phoneNumber, companyName, image } = data;

	let dataInfo;

	dataInfo = {
		firstName: firstName,
		lastName: lastName,
		individualEmail: email,
		collaboratorPhoneNumber: phoneNumber,
		collaboratorName: companyName,
		collaboratorCountryCode: "234",
	};

	if (image) {
		dataInfo = {
			...dataInfo,
			individualImageUrl: image,
			collaboratorImageUrl: image,
		};
	}

	const response = await axios.put(`/profile/collaborator/update`, {
		...dataInfo,
	});

	if (response.status === 200 || response.status === 201) {
		return response.data;
	}

	throw new Error(response.data.message);
};

const handleProfileBankAdd = async (data: {
	accountName: string;
	accountNumber: string;
	bankName: string;
	bankCode: string;
}) => {
	const response = await axios.post(`/profile/bank-information/add`, {
		...data,
	});

	if (response.status === 200 || response.status === 201) {
		return response.data;
	}

	throw new Error(response.data.message);
};

const handleProfileBankUpdate = async (data: {
	accountName: string;
	accountNumber: string;
	bankName: string;
	bankCode: string;
}) => {
	const response = await axios.put(`/profile/bank-information/update`, {
		...data,
	});

	if (response.status === 200 || response.status === 201) {
		return response.data;
	}

	throw new Error(response.data.message);
};

const updateUserPassword = async (data: {
	oldPassword: string;
	newPassword: string;
}) => {
	const response = await axios.post(`/auth/update-password`, data);
	if (response.status === 200 || response.status === 201) {
		return response.data;
	}

	throw new Error(response.data.message);
};

const resetPasswordSendOtp = async (data: { email: string }) => {
	const response = await axios.post(`/auth/reset-password/send-otp`, data);
	if (response.status === 200 || response.status === 201) {
		return response.data;
	}

	throw new Error(response.data.message);
};

const verifyOtp = async (data: { otp: string; token: string }) => {
	const response = await axios.post(`/auth/reset-password/verify-otp`, data);
	if (response.status === 200 || response.status === 201) {
		return response.data;
	}

	throw new Error(response.data.message);
};


const sendContactUsMessage = async (data: { fullname: string; email: string , phone: string ,
	 message: string }) => {
	const response = await axios.post(`/global/contact-us`, data);
	if (response.status === 200 || response.status === 201) {
		return response.data;
	}

	throw new Error(response.data.message);
};



const subscribeNewsletter = async (data: { email: string  }) => {
   const response = await axios.post(`/global/subscribe-to-newsletter`, data);
   if (response.status === 200 || response.status === 201) {
	   return response.data;
   }

   throw new Error(response.data.message);
};

const resetPassword = async (data: { password: string; token: string }) => {
	const response = await axios.post(`/auth/reset-password`, data);
	if (response.status === 200 || response.status === 201) {
		return response.data;
	}

	throw new Error(response.data.message);
};

export {
	fecthUserProfile,
	handleCollaboratorProfileUpdate,
	handleProfileBankAdd,
	sendContactUsMessage,
	handleProfileBankUpdate,
	handleUserProfileUpdate,
	subscribeNewsletter,
	updateUserLocation,
	resetPasswordSendOtp,
	updateUserPassword,
	verifyOtp,
	getNotifications,
	resetPassword
};
