import { Link, useNavigate } from "react-router-dom";
import LoginHeader from "../components/molecules/LoginHeader";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import FormInput from "../components/molecules/FormInput";
import PasswordInput from "../components/molecules/PasswordInput";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import Button from "../components/molecules/Button";
import { toast } from "react-toastify";
import { selectAuthUser } from "../store/auth/authReducer";
import AuthLoginLayout from "../components/organisms/layouts/AuthLoginLayout";
import { RegisterIndividualProps } from "../store/services/userServices";
import { registerIndividualAction } from "../store/auth/authActions";
import { useState } from "react";

const individualUserSchema = Yup.object({
  full_name: Yup.string().required("Full name is required"),
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters long")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/[0-9]/, "Password must contain at least one number")
    .matches(
      /[!@#$%^&*()\-_=+{};:,<.>]/,
      "Password must contain at least one special character"
    ),
  confirm_password: Yup.string().required("Confirm password is required"),
  email: Yup.string().email().required("Email is required"),
  phone_number: Yup.string().required("Phone number is required"),
});

type IndividualUser = Yup.InferType<typeof individualUserSchema>;

const RegisterScreen = () => {
  const [acceptedTerms, setAcceptedTerms] = useState<boolean>(false);
  const { register } = useAppSelector(selectAuthUser);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const quickleapDelivers = localStorage.getItem("quickleapDelivers")
    ? //@ts-ignore
      JSON.parse(localStorage.getItem("quickleapDelivers"))
    : null;

  const individualUserValues: IndividualUser = {
    full_name: "",
    password: "",
    confirm_password: "",
    email: "",
    phone_number: "",
  };

  const handleIndividualSubmit = async (
    values: IndividualUser,
    resetForm: any
  ) => {
    const { full_name, password, email, confirm_password, phone_number } =
      values;
    if (
      !full_name ||
      !password ||
      !email ||
      !confirm_password ||
      !phone_number
    ) {
      setAcceptedTerms(false);

      return toast.error("All fields are required");
    }

    if (password !== confirm_password) {
      setAcceptedTerms(false);

      return toast.error("Passwords do not match");
    }

    if (!acceptedTerms) {
      setAcceptedTerms(false);

      return toast.error("Please accept these terms & conditions");
    }

    const data: RegisterIndividualProps = {
      fullName: full_name,
      password,
      email,
      phoneNumber: phone_number,
      countryCode: "234",
    };

    try {
      const {
        meta: { requestStatus },
        payload,
      } = await dispatch(registerIndividualAction(data));

      if (requestStatus === "rejected") {
        toast.error(payload?.message ?? "Something went wrong");
        return;
      }

      localStorage.setItem("quickleapUser", JSON.stringify(payload));
      localStorage.setItem("showGflTooltip", JSON.stringify(true));

      toast.success("Successfully created an account");
      if (quickleapDelivers) {
        navigate("/grow-for-life/home");
      } else {
        navigate("/grow-for-life");
      }
    } catch (error) {
      //@ts-ignore
      toast.error(error?.response?.data?.message);
      resetForm();
    }
  };

  return (
    <AuthLoginLayout>
      <div className="p-8 py-10 bg-white rounded-md w-full h-full overflow-scroll max-w-[600px] mx-auto">
        {/*Company Logo*/}
        <div className="w-full h-16">
          <Link to="/">
            <img
              src={"/assets/images/logo.png"}
              alt="logo"
              className="w-full h-full object-contain"
            />
          </Link>
        </div>

        <div
          className="bg-[#F2F1F1] mx-auto w-full max-w-[300px] py-[10px] my-3 cursor-pointer rounded-lg flex items-center justify-center"
          onClick={() => navigate("/register/collaborator")}
        >
          <h3 className="text-black text-base leading-6 font-normal">
            Sign up as a business{" "}
            <strong className="font-semibold text-xs leading-[18px] text-green">
              change
            </strong>
          </h3>
        </div>

        {/*IndividualUser form*/}
        <div className="w-full">
          <LoginHeader
            header="Create your account"
            subHeader="Sign up with us today"
          />

          {/*Formik*/}
          <Formik
            initialValues={individualUserValues}
            onSubmit={async (values: IndividualUser, { resetForm }) => {
              await handleIndividualSubmit(values, resetForm);
            }}
            validationSchema={individualUserSchema}
          >
            {({ values, setFieldValue }) => {
              return (
                <Form className="mt-8 flex flex-col gap-1">
                  <FormInput
                    id="full_name"
                    name="full_name"
                    placeholder="Full Name"
                    label="Full Name"
                    onChange={(e) => {
                      setFieldValue("full_name", e.target.value);
                    }}
                  />

                  <FormInput
                    id="email"
                    name="email"
                    placeholder="Email Address"
                    label="Email Address"
                    onChange={(e) => {
                      setFieldValue("email", e.target.value);
                    }}
                  />

                  <FormInput
                    id="phone_number"
                    name="phone_number"
                    placeholder="Phone number"
                    label="Phone number"
                    onChange={(e) => {
                      setFieldValue("phone_number", e.target.value);
                    }}
                  />

                  <PasswordInput
                    id="password"
                    name="password"
                    placeholder="Password"
                    label="Password"
                    autoCompletePassword={true}
                  />

                  <PasswordInput
                    id="confirm_password"
                    name="confirm_password"
                    placeholder="Confirm Password"
                    label="Confirm Password"
                  />

                  <label className="w-full flex items-center cursor-pointer mt-1 mb-4">
                    <Field
                      type="checkbox"
                      name="accepted_terms"
                      className="w-4 h-4"
                      id="accepted_terms"
                      onClick={() => setAcceptedTerms(!acceptedTerms)}
                    />
                    <p className="text-sm text-[#6c6c6c] ml-3">
                      By signing up you agree to the{" "}
                      <Link
                        to="/legal?currentTab=terms"
                        target="_blank"
                        className="font-medium  text-sm text-green"
                      >
                        Terms & Conditions
                      </Link>{" "}
                      and{" "}
                      <Link
                        to="/legal?currentTab=privacy"
                        target="_blank"
                        className="font-medium text-green"
                      >
                        Privacy Policy
                      </Link>
                    </p>
                  </label>

                  <Button
                    type="submit"
                    className="bg-green w-full font-semibold rounded-lg h-[3rem] flex items-center justify-center leading-7"
                    isLoading={register.loading}
                    disabled={register.loading}
                  >
                    Create Account
                  </Button>

                  {/* <div className="my-3">
                    <p className="font-semibold text-center mb-3">
                      Or sign up using
                    </p>

                    <SocialAuthButtons />
                  </div> */}
                </Form>
              );
            }}
          </Formik>
          <div className="max-w-[300px] mx-auto mt-4 font-normal text-center text-[#A4A4A4]">
            Already have an account?{" "}
            <Link to="/login" className="font-medium text-green">
              Log In
            </Link>
          </div>
        </div>
      </div>
    </AuthLoginLayout>
  );
};

export default RegisterScreen;
