import Container from "../../Container";
import FaqsAccordin from "../../../molecules/FaqsAccordin";
import { useNavigate } from "react-router-dom";
import { selectAuthUser } from "../../../../store/auth/authReducer";
import { useAppSelector } from "../../../../store/hooks";

const CreateAccountSection = () => {
	const navigate = useNavigate();
	const { isAuthenticated } = useAppSelector(selectAuthUser);
	return (
		<div className="w-full bg-green-light">
			<div className="flex flex-col bg-green-dark px-5 py-24 justify-center items-center gap-8 md:gap-16">
				<div className="max-w-[40rem] text-center text-white flex flex-col justify-center items-center gap-4 md:gap-6 ">
					<h2 className="font-semibold text-3xl md:text-4xl">
						Get started today!
					</h2>
					<p className="">
						Join the Quick Leap family today and lets make healthy living
						a lifestyle for you and your family.
					</p>

					{isAuthenticated ? (
						<button
							className="rounded-md px-5 py-2.5 bg-[#00D348] text-white md:text-black md:font-medium mt-6 md:mt-0 text-sm md:text-base"
							onClick={() => navigate("/grow-for-life/home")}
						>
							Order Now
						</button>
					) : (
						<button
							className="rounded-md px-5 py-2.5 bg-[#00D348] text-white md:text-black md:font-medium mt-6 md:mt-0 text-sm md:text-base"
							onClick={() => navigate("/register")}
						>
							Create an account
						</button>
					)}
				</div>
				<Container className="mt-8 md:m-0">
					<img
						src="/assets/images/vegetables.png"
						alt=""
						className="w-full aspect-[1/1] md:aspect-[4/2] object-cover rounded-[2rem] md:rounded-xl max-h-[500px] max-w-[900px] lg:max-w-full mx-auto"
					/>
				</Container>
			</div>
			<Container className="px-5 py-20 ">
				<div className="mt-14">
					<div className="w-full flex flex-col gap-2">
						<h2 className="text-green text-xl font-normal text-center">
							FAQ
						</h2>
						<h3 className="font-medium text-center md:max-w-4xl md:mx-auto text-[32px] lg:text-[38px] xl:text-[42px] xl:leading-[64px] text-black 2xl:text-[48px]">
							Frequently asked questions
						</h3>
					</div>

					<div className="flex flex-col mt-14 md:mt-20 items-center gap-y-6">
						<FaqsAccordin header="What is Quick Leap?">
							<p>
								Quick Leap is a one stop shop for everything food for
								individuals, families, and businesses. Our goal is to
								provide good food for everyone, everywhere, every time.
							</p>
						</FaqsAccordin>

						<FaqsAccordin header="What can I do with Quick Leap?">
							{" "}
							<p>
								As an individual: With Grow for Life a free subscription
								based product you can automate the process of stocking
								your home with all the healthy and nutritious fresh
								produce, grains, groceries, recipes, and so much more
								you need and have them delivered to you monthly without
								fail.
								<br />
								<br />
								As a business: With the Inventory Manager and
								Collaborator center you can buy all the raw materials
								you need and sell the finished products you produce with
								ease.
							</p>
						</FaqsAccordin>

						<FaqsAccordin header="What is Collaborator Center?">
							<p>
								Collaborator center is the place where our collaborators
								and suppliers run their businesses, Collaborator
								provides you the ease of sourcing and buying everything
								you need to run your business and selling everything you
								produce in the same place.
							</p>
						</FaqsAccordin>

						<FaqsAccordin header="What is Grow For Life?">
							<p>
								Grow for Life is your one stop shop for everything good
								food, groceries, and other home supplies and essentials.
								It is designed to bring you the ease and delight of
								automating the process of shopping for food and home
								supplies.
								<br /> You simply create an account, create your
								shopping list and we will have your shopping list
								delivered to you weekly, bi-weekly, or month based on
								your needs and preferences and we will have your order
								delivered without fail.
							</p>
						</FaqsAccordin>

						<FaqsAccordin header="What is Inventory Manager?">
							<p>
								Inventory Manager is the resource for your business
								designed to give you the ease of sourcing all of your
								raw materials in one place and having them delivered to
								single or multiple locations, on the single or multiple
								dates.
							</p>
						</FaqsAccordin>

						<FaqsAccordin header="What is Meal Planner?">
							<p>
								Meal planner is your resource as an individuals, or an
								organization to order and enjoy home made quality meals
								delivered to you or picked up from specific locations
								provided by local providers of indigenous and global
								cuisine with details on nutrition and the content of
								your food.
							</p>
						</FaqsAccordin>
					</div>
				</div>
			</Container>
		</div>
	);
};

export default CreateAccountSection;
