import axios from "../../axios.config";

const getDashboardAnalytics = async () => {
	const response = await axios.get(`/collaborator/analytics`);

	if (response.status === 200 || response.status === 201) {
		return response.data;
	}

	throw new Error(response.data.message);
};

const getDashboardTopSellingItems = async () => {
	const response = await axios.get(`/collaborator/top-selling-products`);

	if (response.status === 200 || response.status === 201) {
		return response.data;
	}

	throw new Error(response.data.message);
};

const handleProductUploadService = async (productData: any) => {
	const response = await axios.post(`/collaborator/product/upload`, {
		...productData,
		categoryId: productData.category,
	});

	if (response.status === 200 || response.status === 201) {
		return response.data;
	}

	throw new Error(response.data.message);
};

const getDashboardProducts = async (data: {
	limit: number;
	page: number;
	search: string;
}) => {
	const { limit, page, search } = data;
	const response = await axios.get(
		`/collaborator/products?limit=${limit}&page=${page}&search=${search}&hasBeenApproved=false`
	);

	if (response.status === 200 || response.status === 201) {
		return response.data;
	}

	throw new Error(response.data.message);
};

const getCollaboratorListing = async (data: {
	limit: number;
	page: number;
	search: string;
}) => {
	const { limit, page, search } = data;
	const response = await axios.get(
		`/collaborator/products?limit=${limit}&page=${page}&search=${search}&hasBeenApproved=true`
	);

	if (response.status === 200 || response.status === 201) {
		return response.data;
	}

	throw new Error(response.data.message);
};

const deleteDashboardProductBySlug = async (data: { slug: string }) => {
	const { slug } = data;
	const response = await axios.delete(`/collaborator/products/${slug}`);

	if (response.status === 200 || response.status === 201) {
		return response.data;
	}

	throw new Error(response.data.message);
};

const getCollaboratorOrders = async (data: { page: number; limit: number }) => {
	const { page, limit } = data;

	const response = await axios.get(
		`/collaborator/orders?page=${page}&limit=${limit}`
	);

	if (response.status === 200 || response.status === 201) {
		return response.data;
	}

	throw new Error(response.data.message);
};

const getCollaboratorTransactions = async (data: {
	page: number;
	limit: number;
}) => {
	const { page, limit } = data;

	const response = await axios.get(
		`/collaborator/transactions?page=${page}&limit=${limit}`
	);

	if (response.status === 200 || response.status === 201) {
		return response.data;
	}

	throw new Error(response.data.message);
};

const getProductsAssignedToFieldAgent = async (data: {
	page: number;
	search: string;
}) => {
	const { page } = data;

	const response = await axios.get(
		`/collaborator/field-agent/verifications/products?page=${Number(
			page
		)}&limit=${10}`
	);

	if (response.status === 200 || response.status === 201) {
		return response.data;
	}

	throw new Error(response.data.message);
};

const verifyProduct = async (data: {
	collaboratorProductId: string;
	comment: string;
}) => {
	const { collaboratorProductId, comment } = data;

	const response = await axios.post(
		`/collaborator/field-agent/verifications/products/${collaboratorProductId}/comments`,
		{ comment }
	);

	if (response.status === 200 || response.status === 201) {
		return response.data;
	}

	throw new Error(response.data.message);
};

const getQuickleapPricingList = async (data: { page: number }) => {
	const { page } = data;

	const response = await axios.get(
		`/collaborator/quickleap-products-pricing-list?page=${page}&limit=${10}`
	);

	if (response.status === 200 || response.status === 201) {
		return response.data;
	}

	throw new Error(response.data.message);
};

const getProductBySlug = async (data: { slug: string }) => {
	const { slug } = data;

	const response = await axios.get(`/collaborator/products/${slug}`);

	if (response.status === 200 || response.status === 201) {
		return response.data;
	}

	throw new Error(response.data.message);
};

const updateProductBySlug = async (data: {
	slug: string;
	productData: any;
}) => {
	const { slug, productData } = data;

	const response = await axios.put(
		`/collaborator/products/${slug}`,
		productData
	);

	if (response.status === 200 || response.status === 201) {
		return response.data;
	}

	throw new Error(response.data.message);
};

export {
	getDashboardAnalytics,
	getDashboardTopSellingItems,
	handleProductUploadService,
	getDashboardProducts,
	deleteDashboardProductBySlug,
	getCollaboratorOrders,
	getCollaboratorTransactions,
	getProductsAssignedToFieldAgent,
	verifyProduct,
	getCollaboratorListing,
	getQuickleapPricingList,
	getProductBySlug,
	updateProductBySlug,
};
