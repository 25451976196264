import Container from "../../Container";

const StepInstruction = ({
	number,
	header,
	info,
}: {
	number: string;
	header: string;
	info: string;
}) => {
	return (
		<div className="flex gap-5">
			<div className="flex flex-col gap-3 font-bold text-green bg-white items-center">
				<h5>{number}</h5>
				<span className="w-[2px] h-full bg-green"></span>
			</div>

			<div className="flex gap-3 flex-col">
				<h4 className="text-green-dark text-xl leading-[2rem] font-semibold">
					{header}
				</h4>
				<p className="font-normal text-[#2E2D2D] leading-6 text-sm">
					{info}
				</p>
			</div>
		</div>
	);
};

const CollaboratorProcessSection = () => {
	return (
		<div className="w-full h-full bg-[#FEFEFE]">
			<Container className="px-5 py-14">
				<div className="w-full flex flex-col-reverse lg:flex-row gap-10">
					<div className="w-full flex flex-col justify-center lg:w-[50%]">
						<h2 className="text-3xl font-bold text-green-dark">
							Get started easily, it only takes few steps
						</h2>
						<p className="text-lg font-medium text-[#2E2D2D] mt-3">
							Collaborator is designed to simplify the process of
							sourcing and selling for our collaborators and suppliers.
						</p>
						<div className="w-full mt-10 space-y-6">
							<StepInstruction
								number={"01"}
								header="Create an account"
								info="Create an account as either a farmer, a trader or supplier, a food processor, a chef, a restaurant, or a logistics provider and enjoy the convenience of sourcing and selling everything you need in the same place."
							/>
							<StepInstruction
								number={"02"}
								header="Upload products and assets"
								info="Upload your fresh food, grains, food products, & logistics assets. We have seasoned field agents who are available to help you do this. All you need to do is call us or message in on WhatsApp at +2348134860483."
							/>
							<StepInstruction
								number={"03"}
								header="Enjoy ease and delight"
								info="We will then verify and approve, and you can source all the raw materials you need and sell  your finished products with ease."
							/>
						</div>
					</div>
					<div className="w-full lg:w-[50%] h-full flex items-center justify-center">
						<img
							src="/assets/images/collaborator-user.png"
							className="w-full object-contain"
							alt="user"
						/>
					</div>
				</div>
			</Container>
		</div>
	);
};

export default CollaboratorProcessSection;
