import FaqsAccordin from "../../../molecules/FaqsAccordin";
import Container from "../../Container";

const FaqsGrowforLifeSection = () => {
	return (
		<div className="w-full h-full bg-green-light">
			<Container className="px-5 py-5 lg:py-10">
				<div className="my-14">
					<h2 className="font-semibold text-black text-center text-[30px] leading-9 sm:text-[35px] sm:leading-10 lg:text-[40px] lg:leading-[60px]">
						FAQs
					</h2>

					<div className="flex flex-col mt-10 items-center gap-6">
						<FaqsAccordin header="What is Grow for Life?">
							<p>
								Grow for Life is your one stop shop for everything good
								food, groceries, and other home supplies and essentials.
								It is designed to bring you the ease and delight of
								automating the process of shopping for food and home
								supplies.
								<br /> You simply create an account, create your
								shopping list and we will have your shopping list
								delivered to you weekly, bi-weekly, or month based on
								your needs and preferences and we will have your order
								delivered without fail.
							</p>
						</FaqsAccordin>

						<FaqsAccordin header="Is my Membership truly free?">
							<p>
								Yes your subscription is truly free, you do get to pay
								for deliveries though
							</p>
						</FaqsAccordin>

						<FaqsAccordin header="How do subscriptions work?">
							<p>
								You could have a weekly subscription for fresh fruits, a
								subscription for wholesome recipes and other kitchen
								essentials every two weeks, and, or a month subscription
								for other household item and bulk, is designed to give
								you the flexibility, ease, and rest you need to live a
								healthy and happy life.
							</p>
						</FaqsAccordin>

						<FaqsAccordin
							header="What happens if I run out of the items on my 
            Shopping list before the end of the Month?"
						>
							<p>
								All you have to simply create a new shopping list and we
								will have it delivered to you
							</p>
						</FaqsAccordin>

						<FaqsAccordin header="Can I cancel my subscription?">
							<p>
								Yes you can anytime you like to! <br />
								We will miss you though, you should rather let us know
								how we can serve you better, please write us at{" "}
								<a href="mailto:my-success@quickleap.co">
									my-success@quickleap.co
								</a>{" "}
								or simply send us a message on WhatsApp at
								+2348134860483.
							</p>
						</FaqsAccordin>

						<FaqsAccordin header="Where do the food and products on Grow for Life come from?">
							<p>
								All the fresh food, grains, groceries, and other
								household essentials on Grow for Life come directly from
								the farms, farmers, traders, food processors, chefs, and
								restaurants we collaborate with.
								<br />
								<br />
								Your fresh food and fruits orders are mostly harvested a
								day to delivery to you and sometimes on the same day
								depending on proximity of the farms or Collaborator.
							</p>
						</FaqsAccordin>
					</div>
				</div>
			</Container>
		</div>
	);
};

export default FaqsGrowforLifeSection;
