import React, { FC } from "react";

interface IProps {
	pageNumber?: number;
	pageHeader: string;
}

const SectionHeader: FC<IProps> = ({ pageHeader, pageNumber }) => {
	return (
		<div className="flex w-full items-start justify-start gap-1">
			{pageNumber && (
				<h1 className="text-black text-lg sm:text-[20px] lg:text-[24px] font-semibold">
					{pageNumber}.
				</h1>
			)}
			<h1 className="text-black text-lg sm:text-[20px] lg:text-[24px] font-semibold capitalize">
				{pageHeader}
			</h1>
		</div>
	);
};

export default SectionHeader;
