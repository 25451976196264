import { lazy, Suspense } from "react";
import { createBrowserRouter } from "react-router-dom";
import LoadingScreen from "./components/molecules/LoadingScreen";

// Use lazy to dynamically import components
import HomeScreen from "./pages/HomeScreen";
import CollaboratorLandingScreen from "./pages/Collaborator/CollaboratorLandingScreen";
import RegisterScreen from "./pages/RegisterScreen";
import BlogPostScreen from "./pages/Blog/BlogPostScreen";
import LoginScreen from "./pages/LoginScreen";
import ForgotPasswordScreen from "./pages/ForgotPasswordScreen";
import GrowforlifeLandingScreen from "./pages/Grow-for-life/GrowforlifeLandingScreen";
import RegisterCollaboratorScreen from "./pages/RegisterCollaboratorScreen";
import AboutScreen from "./pages/AboutScreen";
import AdminLoginPage from "./pages/Admin/AdminLoginPage";
import ContactScreen from "./pages/ContactScreen";
import BlogLandingScreen from "./pages/Blog/BlogLandingScreen";
import LegalScreen from "./pages/LegalScreen";
import ErrorPage from "./pages/ErrorPage";

const CollaboratorAgentEditProductScreen = lazy(
	() => import("./pages/Collaborator/FieldAgent/EditFieldAgentProduct")
);

const CollaboratorEditProductScreen = lazy(
	() => import("./pages/Collaborator/EditCollaboratorProduct")
);

const CollaboratorDashboardScreen = lazy(
	() => import("./pages/Collaborator/CollaboratorDashboardScreen")
);
const CollaboratorOrderScreen = lazy(
	() => import("./pages/Collaborator/CollaboratorOrderScreen")
);
const CollaboratorTransactions = lazy(
	() => import("./pages/Collaborator/CollaboratorTransactions")
);

const CollaboratorUploadScreen = lazy(
	() => import("./pages/Collaborator/CollaboratorUploadScreen")
);
const CollaboratorSettingsScreen = lazy(
	() => import("./pages/Collaborator/CollaboratorSettingsScreen")
);
const CollaboratorListingScreen = lazy(
	() => import("./pages/Collaborator/CollaboratorListingScreen")
);
const CollaboratorPricingScreen = lazy(
	() => import("./pages/Collaborator/CollaboratorPricingList")
);

const ProfileOverviewScreen = lazy(
	() => import("./pages/Profile/ProfileOverviewScreen")
);
const ProfileOrdersScreen = lazy(
	() => import("./pages/Profile/ProfileOrdersScreen")
);
const ProfileMealPlanner = lazy(
	() => import("./pages/Profile/ProfileMealPlanner")
);
const ProfileGrowForLife = lazy(
	() => import("./pages/Profile/ProfileGrowForLife")
);
const ProfileInvetoryScreen = lazy(
	() => import("./pages/Profile/ProfileInvetoryScreen")
);
const ProfileHistoryScreen = lazy(
	() => import("./pages/Profile/ProfileHistoryScreen")
);
const CollaboratorApprovalScreen = lazy(
	() => import("./pages/Collaborator/CollaboratorApprovalScreen")
);
const CollaboratorOnboardNewMembersScreen = lazy(
	() => import("./pages/Collaborator/CollaboratorOnboardNewMembersScreen")
);
const CollaboratorViewOnboardMembers = lazy(
	() => import("./pages/Collaborator/CollaboratorViewOnboardMembers")
);

const GrowforlifeHomeScreen = lazy(
	() => import("./pages/Grow-for-life/GrowforlifeHomeScreen")
);
const GrowforlifeAccountScreen = lazy(
	() => import("./pages/Grow-for-life/GrowforlifeAccountScreen")
);
const GrowforlifeShoppingListScreen = lazy(
	() => import("./pages/Grow-for-life/GrowforlifeShoppingListScreen")
);
const GrowforLifeAccountProfile = lazy(
	() =>
		import(
			"./pages/Grow-for-life/GrowforLifeAccountPages/GrowforLifeAccountProfile"
		)
);
const GrowforLifeAccountSubscription = lazy(
	() =>
		import(
			"./pages/Grow-for-life/GrowforLifeAccountPages/GrowforLifeAccountSubscription"
		)
);
const GrowforLifeAccountAnalyticsBudget = lazy(
	() =>
		import(
			"./pages/Grow-for-life/GrowforLifeAccountPages/GrowforLifeAccountAnalyticsBudget"
		)
);
const GrowforLifeAccountPaymentHistory = lazy(
	() =>
		import(
			"./pages/Grow-for-life/GrowforLifeAccountPages/GrowforLifeAccountPaymentHistory"
		)
);

const PaymentHistoryScreen = lazy(
	() =>
		import(
			"./pages/Grow-for-life/GrowforLifeAccountPages/PaymentHistoryScreen"
		)
);
const GrowforlifeProductScreen = lazy(
	() => import("./pages/Grow-for-life/GrowforlifeProductScreen")
);

const CommodityMarketPrice = lazy(
	() =>
		import(
			"./pages/Collaborator/CollaboratorDataCollection/CommodityMarketPrice"
		)
);
const ExpectedLivestockHarvest = lazy(
	() =>
		import(
			"./pages/Collaborator/CollaboratorDataCollection/ExpectedLivestockHarvest"
		)
);
const ExpectedProduceHarvest = lazy(
	() =>
		import(
			"./pages/Collaborator/CollaboratorDataCollection/ExpectedProduceHarvest"
		)
);

const AdminDashboard = lazy(() => import("./pages/Admin/AdminDashboard"));
const AdminCollaborators = lazy(
	() => import("./pages/Admin/AdminCollaborators")
);
// const AdminPayments = lazy(() => import("./pages/Admin/AdminPayments"));
const AdminApproval = lazy(() => import("./pages/Admin/AdminApproval"));
const AdminIndividualCollaborator = lazy(
	() => import("./pages/Admin/AdminIndividualCollaborator")
);
const AdminOrder = lazy(() => import("./pages/Admin/AdminOrder"));
const AdminSettings = lazy(() => import("./pages/Admin/AdminSettings"));
const AdminPricingScreen = lazy(
	() => import("./pages/Admin/AdminPricingScreen")
);

const AdminGrowForLifeProductsScreen = lazy(
	() => import("./pages/Admin/AdminGrowForLifeProductsScreen")
);

const AdminPreApprovedProductsScreen = lazy(
	() => import("./pages/Admin/AdminGrowForLifePreApprovedScreen")
);
// const RecipeUpload = lazy(
// 	() => import("./pages/Admin/AdminUpload/RecipeUpload")
// );
// const ViewRecipeUploads = lazy(
// 	() => import("./pages/Admin/AdminViewUploads/ViewRecipeUploads")
// );
// const RecipeDetails = lazy(
// 	() => import("./pages/Admin/AdminViewUploads/RecipeDetails")
// );
// const ViewNutritionalFactsUploads = lazy(
// 	() => import("./pages/Admin/AdminViewUploads/ViewNutritionalFactsUploads")
// );
const AdminTicketsScreen = lazy(
	() => import("./pages/Admin/AdminTicketsScreen")
);
// const NutritionalFactsUpload = lazy(
// 	() => import("./pages/Admin/AdminUpload/NutritionalFactsUpload")
// );
const GrowforlifeCategoryProductsScreen = lazy(
	() => import("./pages/Grow-for-life/GrowforlifeCategoryProductsScreen")
);
const GrowforlifeAllProductsScreen = lazy(
	() => import("./pages/Grow-for-life/GrowforlifeAllProductsScreen")
);
const GrowforLifeAccountMyFavourites = lazy(
	() =>
		import(
			"./pages/Grow-for-life/GrowforLifeAccountPages/GrowforLifeAccountMyFavourites"
		)
);

const CollaboratorOnboardedMemberProduct = lazy(
	() => import("./pages/Collaborator/CollaboratorOnboardedMemberProduct")
);
const GrowforlifeRecipeScreen = lazy(
	() => import("./pages/Grow-for-life/GrowforlifeRecipeScreen")
);

const AdminResetPassword = lazy(
	() => import("./pages/Admin/AdminResetPassword")
);
const GrowforLifeOrdersScreen = lazy(
	() =>
		import(
			"./pages/Grow-for-life/GrowforLifeAccountPages/GrowforLifeOrdersScreen"
		)
);
const OrderDetailScreen = lazy(
	() =>
		import("./pages/Grow-for-life/GrowforLifeAccountPages/OrderDetailScreen")
);
const TrackOrderScreen = lazy(
	() =>
		import("./pages/Grow-for-life/GrowforLifeAccountPages/TrackOrderScreen")
);

export const router = createBrowserRouter([
	{
		path: "/",
		element: <HomeScreen />,
		ErrorBoundary() {
			return <ErrorPage />;
		},
	},
	{ path: "/register", element: <RegisterScreen /> },
	{ path: "/register/collaborator", element: <RegisterCollaboratorScreen /> },
	{ path: "/login", element: <LoginScreen /> },
	{ path: "/reset", element: <ForgotPasswordScreen /> },
	{ path: "/contact", element: <ContactScreen /> },
	{ path: "/about", element: <AboutScreen /> },
	{ path: "/blog", element: <BlogLandingScreen /> },
	{ path: "/blog/:id", element: <BlogPostScreen /> },
	{ path: "/admin/login", element: <AdminLoginPage /> },
	{
		path: "/admin/dashboard",
		element: (
			<Suspense fallback={<LoadingScreen />}>
				<AdminDashboard />
			</Suspense>
		),
	},
	{
		path: "/admin/collaborators",
		element: (
			<Suspense fallback={<LoadingScreen />}>
				<AdminCollaborators />
			</Suspense>
		),
	},
	{
		path: "/admin/collaborators/:id",
		element: (
			<Suspense fallback={<LoadingScreen />}>
				<AdminIndividualCollaborator />
			</Suspense>
		),
	},
	// {
	// 	path: "/admin/payments",
	// 	element: (
	// 		<Suspense fallback={<LoadingScreen />}>
	// 			<AdminPayments />
	// 		</Suspense>
	// 	),
	// },
	{
		path: "/admin/approval",
		element: (
			<Suspense fallback={<LoadingScreen />}>
				<AdminApproval />
			</Suspense>
		),
	},
	{
		path: "/admin/order",
		element: (
			<Suspense fallback={<LoadingScreen />}>
				<AdminOrder />
			</Suspense>
		),
	},
	{
		path: "/admin/reset-password",
		element: (
			<Suspense fallback={<LoadingScreen />}>
				<AdminResetPassword />
			</Suspense>
		),
	},
	{
		path: "/admin/grow-for-life-products",
		element: (
			<Suspense fallback={<LoadingScreen />}>
				<AdminGrowForLifeProductsScreen />
			</Suspense>
		),
	},
	{
		path: "/admin/pre-approved-products",
		element: (
			<Suspense fallback={<LoadingScreen />}>
				<AdminPreApprovedProductsScreen />
			</Suspense>
		),
	},
	// {
	// 	path: "/admin/upload/recipe",
	// 	element: (
	// 		<Suspense fallback={<LoadingScreen />}>
	// 			<RecipeUpload />
	// 		</Suspense>
	// 	),
	// },
	// {
	// 	path: "/admin/upload/nutritional-facts",
	// 	element: (
	// 		<Suspense fallback={<LoadingScreen />}>
	// 			<NutritionalFactsUpload />
	// 		</Suspense>
	// 	),
	// },
	// {
	// 	path: "/admin/view-uploads/recipe",
	// 	element: (
	// 		<Suspense fallback={<LoadingScreen />}>
	// 			<ViewRecipeUploads />
	// 		</Suspense>
	// 	),
	// },
	// {
	// 	path: "/admin/view-uploads/recipe/:id",
	// 	element: (
	// 		<Suspense fallback={<LoadingScreen />}>
	// 			<RecipeDetails />
	// 		</Suspense>
	// 	),
	// },
	// {
	// 	path: "/admin/view-uploads/nutritional-facts",
	// 	element: (
	// 		<Suspense fallback={<LoadingScreen />}>
	// 			<ViewNutritionalFactsUploads />
	// 		</Suspense>
	// 	),
	// },

	{
		path: "/admin/tickets",
		element: (
			<Suspense fallback={<LoadingScreen />}>
				<AdminTicketsScreen />
			</Suspense>
		),
	},
	{
		path: "/admin/settings",
		element: (
			<Suspense fallback={<LoadingScreen />}>
				<AdminSettings />
			</Suspense>
		),
	},
	{
		path: "/admin/pricing-model",
		element: (
			<Suspense fallback={<LoadingScreen />}>
				<AdminPricingScreen />
			</Suspense>
		),
	},
	//Collaborator
	{
		path: "/collaborator",
		element: <CollaboratorLandingScreen />,
	},
	{
		path: "/collaborator/dashboard",
		element: (
			<Suspense fallback={<LoadingScreen />}>
				<CollaboratorDashboardScreen />
			</Suspense>
		),
	},
	{
		path: "/collaborator/upload",
		element: (
			<Suspense fallback={<LoadingScreen />}>
				<CollaboratorUploadScreen />
			</Suspense>
		),
	},
	{
		path: "/collaborator/orders",
		element: (
			<Suspense fallback={<LoadingScreen />}>
				<CollaboratorOrderScreen />
			</Suspense>
		),
	},

	{
		path: "/collaborator/listing",
		element: (
			<Suspense fallback={<LoadingScreen />}>
				<CollaboratorListingScreen />
			</Suspense>
		),
	},

	{
		path: "/collaborator/transactions",
		element: (
			<Suspense fallback={<LoadingScreen />}>
				<CollaboratorTransactions />
			</Suspense>
		),
	},
	{
		path: "/collaborator/listing/:productSlug",
		element: (
			<Suspense fallback={<LoadingScreen />}>
				<CollaboratorEditProductScreen />
			</Suspense>
		),
	},
	{
		path: `/collaborator/onboarding/onboarded-member/:account/edit-product`,
		element: (
			<Suspense fallback={<LoadingScreen />}>
				<CollaboratorAgentEditProductScreen />
			</Suspense>
		),
	},

	{
		path: "/collaborator/profile",
		element: (
			<Suspense fallback={<LoadingScreen />}>
				<CollaboratorSettingsScreen />
			</Suspense>
		),
	},

	{
		path: "/collaborator/approval",
		element: (
			<Suspense fallback={<LoadingScreen />}>
				<CollaboratorApprovalScreen />
			</Suspense>
		),
	},
	{
		path: "/collaborator/onboarding/onboard-new-members",
		element: (
			<Suspense fallback={<LoadingScreen />}>
				<CollaboratorOnboardNewMembersScreen />
			</Suspense>
		),
	},

	{
		path: "/collaborator/onboarding/view-onboarded-members",
		element: (
			<Suspense fallback={<LoadingScreen />}>
				<CollaboratorViewOnboardMembers />
			</Suspense>
		),
	},
	{
		path: "/collaborator/onboarding/onboarded-member/:collaboratorId/products",
		element: (
			<Suspense fallback={<LoadingScreen />}>
				<CollaboratorOnboardedMemberProduct />
			</Suspense>
		),
	},
	{
		path: "/collaborator/data-collection/commodity-market-prices",
		element: (
			<Suspense fallback={<LoadingScreen />}>
				<CommodityMarketPrice />
			</Suspense>
		),
	},

	{
		path: "/collaborator/data-collection/expected-livestock-harvest",
		element: (
			<Suspense fallback={<LoadingScreen />}>
				<ExpectedLivestockHarvest />
			</Suspense>
		),
	},

	{
		path: "/collaborator/data-collection/expected-produce-harvest",
		element: (
			<Suspense fallback={<LoadingScreen />}>
				<ExpectedProduceHarvest />
			</Suspense>
		),
	},
	{
		path: "/collaborator/pricing-list",
		element: (
			<Suspense fallback={<LoadingScreen />}>
				<CollaboratorPricingScreen />
			</Suspense>
		),
	},

	//Profile
	{
		path: "/profile/overview",
		element: (
			<Suspense fallback={<LoadingScreen />}>
				<ProfileOverviewScreen />
			</Suspense>
		),
	},
	{
		path: "/profile/orders",
		element: (
			<Suspense fallback={<LoadingScreen />}>
				<ProfileOrdersScreen />
			</Suspense>
		),
	},
	{
		path: "/profile/meal-planner",
		element: (
			<Suspense fallback={<LoadingScreen />}>
				<ProfileMealPlanner />
			</Suspense>
		),
	},
	{
		path: "/profile/grow-for-life",
		element: (
			<Suspense fallback={<LoadingScreen />}>
				<ProfileGrowForLife />
			</Suspense>
		),
	},
	{
		path: "/profile/inventory",
		element: (
			<Suspense fallback={<LoadingScreen />}>
				<ProfileInvetoryScreen />
			</Suspense>
		),
	},
	{
		path: "/profile/history",
		element: (
			<Suspense fallback={<LoadingScreen />}>
				<ProfileHistoryScreen />
			</Suspense>
		),
	},

	//Grow for life
	{
		path: "/grow-for-life",
		element: <GrowforlifeLandingScreen />,
	},
	{
		path: "/grow-for-life/home",
		element: (
			<Suspense fallback={<LoadingScreen />}>
				<GrowforlifeHomeScreen />
			</Suspense>
		),
	},
	{
		path: "/grow-for-life/my-shopping-list",
		element: (
			<Suspense fallback={<LoadingScreen />}>
				<GrowforlifeShoppingListScreen />
			</Suspense>
		),
	},
	{
		path: "/grow-for-life/account",
		element: (
			<Suspense fallback={<LoadingScreen />}>
				<GrowforlifeAccountScreen />
			</Suspense>
		),
	},
	{
		path: "/grow-for-life/category/:slug",
		element: (
			<Suspense fallback={<LoadingScreen />}>
				<GrowforlifeCategoryProductsScreen />
			</Suspense>
		),
	},
	{
		path: "/grow-for-life/products/:productId",
		element: (
			<Suspense fallback={<LoadingScreen />}>
				<GrowforlifeProductScreen />
			</Suspense>
		),
	},

	{
		path: "/grow-for-life/products/all",
		element: (
			<Suspense fallback={<LoadingScreen />}>
				<GrowforlifeAllProductsScreen />
			</Suspense>
		),
	},

	{
		path: "/grow-for-life/recipe/:id",
		element: (
			<Suspense fallback={<LoadingScreen />}>
				<GrowforlifeRecipeScreen />
			</Suspense>
		),
	},

	{
		path: "/grow-for-life/account",
		element: (
			<Suspense fallback={<LoadingScreen />}>
				<GrowforlifeAccountScreen />
			</Suspense>
		),
	},
	{
		path: "/grow-for-life/account/profile",
		element: (
			<Suspense fallback={<LoadingScreen />}>
				<GrowforLifeAccountProfile />
			</Suspense>
		),
	},

	{
		path: "/grow-for-life/account/subscription",
		element: (
			<Suspense fallback={<LoadingScreen />}>
				<GrowforLifeAccountSubscription />
			</Suspense>
		),
	},

	{
		path: "/grow-for-life/account/my-favourites",
		element: (
			<Suspense fallback={<LoadingScreen />}>
				<GrowforLifeAccountMyFavourites />
			</Suspense>
		),
	},

	{
		path: "/grow-for-life/account/my-orders",
		element: (
			<Suspense fallback={<LoadingScreen />}>
				<GrowforLifeOrdersScreen />
			</Suspense>
		),
	},

	{
		path: "/grow-for-life/account/my-orders/:orderId",
		element: (
			<Suspense fallback={<LoadingScreen />}>
				<OrderDetailScreen />
			</Suspense>
		),
	},

	{
		path: "/grow-for-life/account/my-orders/track/:orderId",
		element: (
			<Suspense fallback={<LoadingScreen />}>
				<TrackOrderScreen />
			</Suspense>
		),
	},

	{
		path: "/grow-for-life/account/payment-history",
		element: (
			<Suspense fallback={<LoadingScreen />}>
				<GrowforLifeAccountPaymentHistory />
			</Suspense>
		),
	},

	{
		path: "/grow-for-life/account/payment-history/:year/:month",
		element: (
			<Suspense fallback={<LoadingScreen />}>
				<PaymentHistoryScreen />
			</Suspense>
		),
	},

	{
		path: "/grow-for-life/account/analytics-budget",
		element: (
			<Suspense fallback={<LoadingScreen />}>
				<GrowforLifeAccountAnalyticsBudget />
			</Suspense>
		),
	},

	{
		path: "/legal",
		element: <LegalScreen />,
	},
]);
