import { useEffect } from "react";
import useSuccessItemModal from "../../hooks/modal-hooks/useSucessItemModal";
import Modal from "./Modal";

const SuccessItemModal = () => {
	const { isOpen, onClose, successMessage } = useSuccessItemModal();

	/* eslint-disable react-hooks/exhaustive-deps*/
	useEffect(() => {
		let intervalId: string | number | NodeJS.Timeout | undefined;
		if (isOpen) {
			intervalId = setTimeout(() => {
				onClose();
			}, 2000);
		}

		return () => clearInterval(intervalId);
	}, [isOpen]);

	return (
		<>
			<Modal
				isOpen={isOpen}
				onClose={onClose}
				full={false}
				className="bg-white relative w-full md:w-auto md:max-w-[400px] overflow-scroll z-50 p-7 rounded-2xl md:rounded-xl"
			>
				<div className="w-full flex flex-col items-center justify-center pt-5 gap-y-2">
					<span>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width={88}
							height={87}
							fill="none"
						>
							<path
								stroke="#0B7A24"
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth={3}
								d="M80.25 40.164V43.5a36.25 36.25 0 1 1-21.496-33.132m21.496 4.132L44 50.786 33.125 39.91"
							/>
						</svg>
					</span>
					<p className="max-w-[420px] text-center mx-auto font-medium text-lg leading-7 text-black">
						{successMessage}
					</p>
				</div>
			</Modal>
		</>
	);
};

export default SuccessItemModal;
