import axios from "../../axios.config";

export type LoginUserProps = {
	email: string,
	password: string
}

export type RegisterCollaboratorProps = {
	fullName: string,
	individualEmail?: string,
	password?: string
	collaboratorName: string
	collaboratorEmail: string
	collaboratorCountryCode: string
	collaboratorPhoneNumber: string
	collaboratorType: string
	address: string 
	country: string
	state: string
	city: string
}

export interface RegisterIndividualProps {
	fullName: string;
	password: string;
	countryCode: string;
	email: string;
	phoneNumber: string;
}



export const loginUser = async (data: LoginUserProps) => {
	const response = await axios.post("/auth/sign-in", data);

	if (response.status === 200 || response.status === 201) {
		return response.data;
	}

	throw new Error(response.data.message);
};

export const registerCollaborator = async (data: RegisterCollaboratorProps) => {
	const response = await axios.post(`/auth/collaborator/sign-up`, data)

	if (response.status === 200 || response.status === 201) {
		return response.data;
	}

	throw new Error(response.data.message);
}


export const socialAuth = async (data: {
	token:string
}) => {
	const response = await axios.post(`/oauth`, data)

	if (response.status === 200 || response.status === 201) {
		return response.data;
	}

	throw new Error(response.data.message);
}

export const registerUser = async (data: RegisterIndividualProps) => {
	const response = await axios.post("/auth/individual/sign-up", data);

	if (response.status === 200 || response.status === 201) {
		return response.data;
	}

	throw new Error(response.data.message);
};

export const fecthUserProfile = async () => {
	const response = await axios.get("/profile/me");

   if (response.status === 200 || response.status === 201) {
      return response.data;
   }

   throw new Error(response.data.message);
}


export const makeComplaint = async (data: {
	email:string;
	issueType:string;
	description: string;
}) => {
	const response = await axios.post("/global/complaints", data);

	if (response.status === 200 || response.status === 201) {
		return response.data;
	}

	throw new Error(response.data.message);
};