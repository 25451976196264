import { useEffect, useState } from "react";
import Container from "../components/organisms/Container";
import PolicySection from "../components/organisms/pageSections/LegalSections/PolicySection";
import ReturnPolicySection from "../components/organisms/pageSections/LegalSections/ReturnPolicySection";
import TermsSection from "../components/organisms/pageSections/LegalSections/TermsSection";
import { useLocation } from "react-router-dom";
import WebLayout from "../components/organisms/layouts/WebLayout";
import ServiceLevelAgreement from "../components/organisms/pageSections/LegalSections/ServiceLevelAgreement";

const LegalScreen = () => {
	const location = useLocation();

	const [currentTab, setCurrentTab] = useState<"privacy" | "terms" | "return" | "sla">(
		"privacy"
	);

	/* eslint-disable react-hooks/exhaustive-deps*/
	useEffect(() => {
		if (location.search.split("=")[1]) {
			if (location.search.split("=")[1] === "terms") {
				setCurrentTab("terms");
			} else if (location.search.split("=")[1] === "return") {
				setCurrentTab("return");
			}
			else if (location.search.split("=")[1] === "sla") {
				setCurrentTab("sla");
			}
			else {
				setCurrentTab("privacy");
			}
		}
	}, [location.search.split("=")[1]]);

	const tabs: { label: string; value: "privacy" | "terms" | "return" | "sla" | 'sla' }[] = [
		{
			label: "Privacy Policy",
			value: "privacy",
		},
		{
			label: "Terms & Conditions",
			value: "terms",
		},
		{
			label: "Return Policy",
			value: "return",
		},
		{
			label: "Service Level Agreement",
			value: "sla",
		},
	];

	return (
		<WebLayout >
			<Container className="w-full gap-5 px-5 py-16 flex flex-col justify-center">
				<h2 className="font-semibold text-black text-4xl md:text-5xl xl:text-[56px]">
					Legal
				</h2>

				<section className="mt-5 relative  w-full h-full ">
					<div className="flex items-center justify-start gap-5 border-b border-[#E4E7EC] w-full mb-4">
						{tabs.map(
							(
								tab: {
									label: string;
									value: "privacy" | "terms" | "return" | "sla";
								},
								index
							) => (
								<button
									key={index}
									onClick={() => {
										setCurrentTab(tab.value);
									}}
									className={`${
										currentTab === tab.value
											? "border-b border-[#118507] text-[#118507] font-medium leading-5 text-sm"
											: "font-medium leading-5 text-sm"
									} py-3 flex items-center justify-center px-2 text-[#344054]`}
								>
									{tab.label}
								</button>
							)
						)}
					</div>
					{currentTab === "privacy" && <PolicySection />}
					{currentTab === "terms" && <TermsSection />}
					{currentTab === "return" && <ReturnPolicySection />}
					{currentTab === "sla" && <ServiceLevelAgreement />}
				</section>
			</Container>
		</WebLayout>
	);
};

export default LegalScreen;
