import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../index";
import { AuthenticatedUser } from "../../types/user";
import {
	addBankAccountInfoAction,
	fetchNotifications,
	getUserProfileAction,
	updateCollaboratorProfileAction,
	updateUserProfileAction,
} from "./action";

interface ProfileTypes {
	profile: AuthenticatedUser | null;
	getProfileInfo: {
		error: string | null;
		loading: boolean;
		success: boolean;
	};

	
	notifications: any;
	getNotifications: {
		loading: boolean;
		error: string | null;
		success: boolean;
	};


	profileUpdate: {
		error: string | null;
		loading: boolean;
		success: boolean;
	};

	bankUpdate: {
		error: string | null;
		loading: boolean;
		success: boolean;
	};
}

const initialState: ProfileTypes = {
	profile: null,
	getProfileInfo: {
		error: null,
		loading: false,
		success: false,
	},

	
	notifications: null,
	getNotifications: {
		success: false,
		error: null,
		loading: false,
	},

	
	profileUpdate: {
		error: null,
		loading: false,
		success: false,
	},
	bankUpdate: {
		error: null,
		loading: false,
		success: false,
	},
};

const profileSlice = createSlice({
	name: "profile",
	initialState,
	reducers: {
		clearUserProfile: (state, action) => {
			state.profile = null
		  }
		},
	extraReducers: (builder) => {
		builder
			.addCase(getUserProfileAction.pending, (state) => {
				state.getProfileInfo.loading = true;
			})
			.addCase(getUserProfileAction.fulfilled, (state, { payload }) => {
				state.profile = {...payload, locations: payload.locations[0] ?? {}};
				state.getProfileInfo.loading = false;
				state.getProfileInfo.error = null;
				state.getProfileInfo.success = true;
			})
			.addCase(getUserProfileAction.rejected, (state, { payload }) => {
				state.profile = null;
				state.getProfileInfo.loading = false;
				state.getProfileInfo.error = payload as string;
				state.getProfileInfo.success = false;
			});

		//Handle Collaborator Profile update
		builder
			.addCase(updateCollaboratorProfileAction.pending, (state) => {
				state.profileUpdate.loading = true;
			})
			.addCase(updateCollaboratorProfileAction.fulfilled, (state) => {
				state.profileUpdate.loading = false;
				state.profileUpdate.error = null;
				state.profileUpdate.success = true;
			})
			.addCase(
				updateCollaboratorProfileAction.rejected,
				(state, { payload }) => {
					state.profileUpdate.error = payload as string;
					state.profileUpdate.loading = false;
					state.profileUpdate.success = false;
				}
		);

		builder
			.addCase(updateUserProfileAction.pending, (state) => {
				state.profileUpdate.loading = true;
			})
			.addCase(updateUserProfileAction.fulfilled, (state) => {
				state.profileUpdate.loading = false;
				state.profileUpdate.error = null;
				state.profileUpdate.success = true;
			})
			.addCase(
				updateUserProfileAction.rejected,
				(state, { payload }) => {
					state.profileUpdate.error = payload as string;
					state.profileUpdate.loading = false;
					state.profileUpdate.success = false;
				}
		);

					//	Get collaborator notifications
					builder
					.addCase(fetchNotifications.pending, (state) => {
						state.getNotifications.loading = true;
					})
					.addCase(fetchNotifications.fulfilled, (state, { payload }) => {
						state.notifications = payload.data;
						state.getNotifications.loading = false;
						state.getNotifications.error = null;
						state.getNotifications.success = true;
					})
					.addCase(fetchNotifications.rejected, (state, { payload }) => {
						state.getNotifications.error = payload as string;
						state.getNotifications.success = false;
						state.getNotifications.loading = false;
					});
		


		//Handle add bank info
		builder
			.addCase(addBankAccountInfoAction.pending, (state) => {
				state.bankUpdate.loading = true;
			})
			.addCase(addBankAccountInfoAction.fulfilled, (state) => {
				state.bankUpdate.loading = false;
				state.bankUpdate.error = null;
				state.bankUpdate.success = true;
			})
			.addCase(addBankAccountInfoAction.rejected, (state, { payload }) => {
				state.bankUpdate.error = payload as string;
				state.bankUpdate.loading = false;
				state.bankUpdate.success = false;
			});
	},
});

export const selectProfile = (state: RootState) => state.profile;

export default profileSlice.reducer;
