import React from 'react'
import { selectAuthUser } from '../../store/auth/authReducer';
import { useAppSelector } from '../../store/hooks';
import { useNavigate } from 'react-router-dom';


const GetStartedCta = () => {
    const { isAuthenticated } = useAppSelector(selectAuthUser);
    const navigate = useNavigate()
  return (
    <div className="flex flex-col lg:flex-row bg-green-dark p-10 md:px-14 justify-center rounded-2xl gap-8 
    md:gap-16">
    <img
        src="/assets/images/vegetables.png"
        alt=""
        className="w-full lg:w-[55%] aspect-square sm:aspect-[3/2] object-cover rounded-xl"
    />
<div className="grow text-center text-white flex flex-col justify-center items-center gap-6">
    <h2 className="font-semibold text-4xl">
        Get started today!
    </h2> 
    <p className="">
        Join the Quick Leap family today and lets make healthy
        living a lifestyle for you and your family.
    </p>

    {
        isAuthenticated ?  <button className="border border-white rounded-md px-5 py-3 text-sm" onClick={()=>navigate('/grow-for-life/home')}>
        Order Now
    </button> :  <button className="border border-white rounded-md px-5 py-3 text-sm"  onClick={()=>navigate('/register')}>
        Create an account
    </button>
    }
   
</div>
</div>
  )
}

export default GetStartedCta