import { create } from "zustand";

interface SuccessItemProps {
	isOpen: boolean;
	onOpen: () => void;
	onClose: () => void;
	successMessage: string;
	setSuccessMessage: (message: string) => void;
}

const useSuccessItemModal = create<SuccessItemProps>((set) => ({
	isOpen: false,
	onOpen: () => set({ isOpen: true }),
	onClose: () => set({ isOpen: false }),
	successMessage: "",
	setSuccessMessage: (message: string) => set({ successMessage: message }),
}));

export default useSuccessItemModal;
