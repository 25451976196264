import React from "react";
import WebLayout from "../../components/organisms/layouts/WebLayout";
import Container from "../../components/organisms/Container";
import GetStartedCta from "../../components/molecules/GetStartedCta";
import BlogCard from "../../components/organisms/layouts/BlogCard";

const BlogScreen: React.FC = () => {
	return (
		<WebLayout>
			<Container>
				<main className=" py-10 px-3 flex flex-col gap-20">
					<section>
						<h3 className="text-2xl font-bold">Featured Posts</h3>
						<div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-5">
							<BlogCard title="" img="" category="" date="" />
							<BlogCard title="" img="" category="" date="" />
							<BlogCard title="" img="" category="" date="" />
							<BlogCard title="" img="" category="" date="" />
							<BlogCard title="" img="" category="" date="" />
							<BlogCard title="" img="" category="" date="" />
						</div>
						<div className="flex justify-center mt-10">
							<button className="font-semibold inline-block p-5 py-2 border border-green rounded-md text-green">
								Read more
							</button>
						</div>
					</section>

					<section>
						<h3 className="text-2xl font-bold">Growth</h3>
						<div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-5">
							<BlogCard title="" img="" category="" date="" />
							<BlogCard title="" img="" category="" date="" />
							<BlogCard title="" img="" category="" date="" />
							<BlogCard title="" img="" category="" date="" />
							<BlogCard title="" img="" category="" date="" />
							<BlogCard title="" img="" category="" date="" />
						</div>
						<div className="flex justify-center mt-10">
							<button className="font-semibold inline-block p-5 py-2 border border-green rounded-md text-green">
								Read more
							</button>
						</div>
					</section>

					<GetStartedCta />

					<section>
						<h3 className="text-2xl font-bold">Recent Articles</h3>
						<div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-5">
							<BlogCard title="" img="" category="" date="" />
							<BlogCard title="" img="" category="" date="" />
							<BlogCard title="" img="" category="" date="" />
							<BlogCard title="" img="" category="" date="" />
							<BlogCard title="" img="" category="" date="" />
							<BlogCard title="" img="" category="" date="" />
						</div>
						<div className="flex justify-center mt-10">
							<button className="font-semibold inline-block p-5 py-2 border border-green rounded-md text-green">
								Read more
							</button>
						</div>
					</section>
				</main>
			</Container>
		</WebLayout>
	);
};

export default BlogScreen;
