import React, { useRef, useState } from 'react'
import { MdOutlineKeyboardArrowDown } from 'react-icons/md'
import useClickOutside from '../../hooks/useClickOutside'

interface Props {
   header: string
   children: React.ReactNode
}

const FaqsAccordin: React.FC<Props> = ({ header, children }) => {
   const [toggle, setToggle] = useState(false)
   const container = useRef(null)

   useClickOutside(container, ()=>setToggle(false));


   return (
      <div ref={container} onClick={()=>setToggle(prev => !prev)}
       className={`bg-transparent accordion ${toggle ? 'bg-white border-green active' : ''}
        flex flex-col mx-auto w-full max-w-[710px]
       px-5 py-3 border border-grey rounded-lg duration-500 ease-in-out transition-all cursor-pointer`}>
         <div className='w-full flex items-center justify-between'>
            <h2 className='font-semibold text-green text-base'>{header}</h2>
            <button>
               <MdOutlineKeyboardArrowDown className={`block  ${toggle ? '-rotate-180' : ''} duration-200 ease-in-out transition-all`} />
            </button>
         </div>
         <div className="grid_cols">
            <div className="content">
            <div className='pt-3 text-ash text-sm'>{children}</div>
            </div>
         </div>
      </div>
   )
}

export default FaqsAccordin
