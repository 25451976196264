import React from 'react'
import Container from '../../Container'
import { Link } from 'react-router-dom'

const HeroQuickleapDeliversSection = () => {
  return (
    <div className="w-full lg:pt-10 h-full bg-green-light">
      		<Container className="px-5 py-16 flex flex-col gap-4">
				<h2 className="text-4xl md:text-[3.5rem] leading-tight max-w-[60rem] text-green text-center font-extrabold md:font-bold lg:px-5 mx-auto">
                Your One Stop Shop for Everything Food! 
				</h2>
				<p className="leading-6 text-[#6C6C6C] text-center md:max-w-2xl md:mx-auto">
                We are your one stop shop for everything food, through our collaborators we grow and source food of the best 
                quality and all the home essentials you need to live a healthy and functional life
				</p>

                <div className="flex items-center justify-center space-x-5 my-5">
						<Link
							to="/grow-for-life/home"
							className="border border-green font-medium rounded-md text-center bg-green
                             text-white p-3"
						>
							Proceed to Marketplace
						</Link>
					</div>

				<img
					src="/assets/images/grow-for-life.png"
					className="w-full max-h-[700px] mx-auto my-8 block object-contain"
					alt="collaborator"
				/>
			</Container>
    </div>
  )
}

export default HeroQuickleapDeliversSection
