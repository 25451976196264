import Modal from "./Modal";
import useUploadSuccessModal from "../../hooks/modal-hooks/useUploadSuccessModal";
import Button from "../molecules/Button";

const UploadProductSuccessModal = () => {
	const { isOpen, onClose, modalHeader, modalText, proceed } =
		useUploadSuccessModal();
	return (
		<>
			<Modal
				isOpen={isOpen}
				onClose={onClose}
				full={true}
				className="translate relative mx-auto flex h-[70dvh] w-full flex-col overflow-y-scroll rounded-t-[20px] border-0 bg-white shadow-lg outline-none focus:outline-none md:h-auto md:min-h-[250px] md:rounded-[20px] md:max-w-[450px] p-7 pb-10 md:pb-7"
			>
				<div className="w-full max-w-[196px] mx-auto h-[90px]">
					<img
						src="/assets/success_party.svg"
						className="w-full object-fill"
						alt="icon"
					/>
				</div>

				<span className="w-[70px] h-[58px] mx-auto mt-7">
					<img
						src="/assets/thumbs_up.svg"
						className="w-full object-fill"
						alt="icon"
					/>
				</span>

				<div className="w-full flex flex-col items-center mt-5 gap-3">
					<h2 className="font-semibold text-xl leading-7 lg:leading-8 xl:leading-[36px] xl:text-2xl">
						{modalHeader}
					</h2>
					<p className="text-center text-[#6C6C6C] leading-[21px] text-xs md:text-sm">
						{modalText}
					</p>

					<Button
						className="px-10 mt-3 py-4 bg-green text-white font-medium text-sm leading-[21px]"
						onClick={proceed}
					>
						Proceed
					</Button>
				</div>
			</Modal>
		</>
	);
};

export default UploadProductSuccessModal;
