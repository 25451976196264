import Container from "../../Container";
import { useEffect } from "react";
import { selectGrowForLife } from "../../../../store/grow-for-life/growForLifeReducer";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { ProductCard } from "../../../molecules/collaborator/GrowforlifeAssetCard";
import { getGrowForLifeBestSellingProducts } from "../../../../store/grow-for-life/growForLifeAction";
import "swiper/css";
import "swiper/css/pagination";


const TopSellingGrowforlifeSection = () => {
  const { bestSellingProducts } =
    useAppSelector(selectGrowForLife);

  const dispatch = useAppDispatch();
  
/* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if(bestSellingProducts.length < 1)
    dispatch(getGrowForLifeBestSellingProducts({ limit: 12, page: 1 }));
  }, []);

  if(bestSellingProducts.length < 1){
    return <></>
  }

  return (
    <div className="w-full bg-white">
      <Container className="px-5 py-16">
        <div className="w-full text-center flex flex-col mb-10">
          <h2 className="font-bold text-black text-2xl lg:text-3xl">
            Top selling products
          </h2>
        </div>
        <section className="">

            <Swiper
              spaceBetween={15}
              className="mySwiper"
              autoplay={true}
              modules={[Autoplay]}
              slidesPerView={"auto"}
            >
              {bestSellingProducts.map((product, index) => {
                return (
                  <SwiperSlide className="product-card-slide" key={index}>
                    <ProductCard
                      key={product.id}
                      product={product}
                      imageWidth="w-[12rem] md:w-[18rem]"
                    />
                  </SwiperSlide>
                );
              })}
            </Swiper>
        </section>
      </Container>
    </div>
  );
};

export default TopSellingGrowforlifeSection;
