import React from "react";
import Container from "../../Container";
import { HiArrowNarrowRight, HiOutlineShoppingCart } from "react-icons/hi";
import { BsChatDots } from "react-icons/bs";
import { LuClock5 } from "react-icons/lu";
import { CiCreditCard1 } from "react-icons/ci";
import useAuthInfoModal from "../../../../hooks/modal-hooks/useAuthInfoModal";
import { useNavigate } from "react-router-dom";
import { selectAuthUser } from "../../../../store/auth/authReducer";
import { useAppSelector } from "../../../../store/hooks";

const ServiceSection = () => {
	const authInfoModal = useAuthInfoModal();
	const navigate = useNavigate()
	const { isAuthenticated } = useAppSelector(selectAuthUser);
	return (
		<div className="w-full h-full bg-white">
			<Container className="px-5 py-10 lg:py-14 xl:py-20">
				{/*Header*/}
				<div className="w-full flex flex-col gap-2 items-start md:items-center">
					<h2 className="text-green text-xl font-normal">
						We are here for you
					</h2>
					<h3 className="font-medium text-start md:text-center md:max-w-4xl md:mx-auto text-[32px] lg:text-[38px] xl:text-[42px] xl:leading-[64px] text-black 2xl:text-[48px]">
						We provide you with services to make your life easier
					</h3>
				</div>

				{/*Grid info section*/}
				<section className="w-full grid grid-cols-1 sm:grid-cols-2 place-items-center xl:place-items-start xl:grid-cols-4 gap-10 mt-10 md:mt-14 lg:mt-20">
					<div className="flex flex-col items-start w-full sm:max-w-[303px] lg:max-w-xs xl:max-w-[303px] cursor-pointer h-full max-h-[240px] gap-3">
						<div className="w-[32px] h-[32px] rounded-full flex items-center justify-center">
							<HiOutlineShoppingCart className="text-green" size={24} />
						</div>

						<h2 className="font-semibold text-black text-lg">
							Make bulk orders at once
						</h2>

						<p className="text-sm text-[#3D3E48] font-normal">
							We source directly from farmers and manufacturers to provide
							you with the best at unbeatable costs, we provide the
							greatest rates on food items and supplies.
						</p>

						<button className="w-full flex items-center justify-start gap-1 md:gap-2 xl:gap-3 mt-4"
						onClick={()=>navigate('/grow-for-life/home')}
						>
							<h5 className="text-sm font-semibold leading-5 text-black">
								Make an order
							</h5>
							<HiArrowNarrowRight />
						</button>
					</div>
					<div className="flex flex-col items-start w-full sm:max-w-[303px] lg:max-w-xs xl:max-w-[303px] cursor-pointer h-full max-h-[240px] gap-3">
						<div className="w-[32px] h-[32px] rounded-full flex items-center justify-center">
							<CiCreditCard1 className="text-green" size={24} />
						</div>

						<h2 className="font-semibold text-black text-lg">
							Free subscription
						</h2>

						<p className="text-sm text-[#3D3E48] font-normal">
							Enjoy access to a vast array of well curated food and recipes carefully curated for you, 
							and arranged in categories on a free subscription, we provide the greatest rates on food items and supplies. 
						</p>

						<button className="w-full flex items-center justify-start gap-1 md:gap-2 xl:gap-3 mt-4"
						onClick={()=>isAuthenticated ? navigate('/grow-for-life/home') : authInfoModal.onOpen}
						// onClick={}
						>
							<h5 className="text-sm font-semibold leading-5 text-black">
								Start now
							</h5>
							<HiArrowNarrowRight />
						</button>
					</div>
					<div className="flex flex-col items-start w-full sm:max-w-[303px] lg:max-w-xs xl:max-w-[303px] cursor-pointer h-full max-h-[240px] gap-3">
						<div className="w-[32px] h-[32px] rounded-full flex items-center justify-center">
							<LuClock5 className="text-green" size={24} />
						</div>

						<h2 className="font-semibold text-black text-lg">
							Timely deliveries
						</h2>

						<p className="text-sm text-[#3D3E48] font-normal">
						For our Collaborators we provide products that are in stock right away, or source them within a day 
						so you can have all the raw materials you need, start operating and serving customers right away.
						</p>

						<button className="w-full flex items-center justify-start gap-1 md:gap-2 xl:gap-3 mt-4"

						>
							<h5 className="text-sm font-semibold leading-5 text-black">
								Coming soon
							</h5>
							<HiArrowNarrowRight />
						</button>
					</div>
					<div className="flex flex-col items-start w-full sm:max-w-[303px] lg:max-w-xs xl:max-w-[303px] cursor-pointer h-full max-h-[240px] gap-3">
						<div className="w-[32px] h-[32px] rounded-full flex items-center justify-center">
							<BsChatDots className="text-green" size={24} />
						</div>

						<h2 className="font-semibold text-black text-lg">
							Active customer support
						</h2>

						<p className="text-sm text-[#3D3E48] font-normal">
							People you can trust to assist with your inquiries and
							provide solutions to your complaints. Your feedback is
							important to us.
						</p>

						<button className="w-full flex items-center justify-start gap-1 md:gap-2 xl:gap-3 mt-4"
							onClick={()=>navigate('/contact')}>
							<h5 className="text-sm font-semibold leading-5 text-black">
								Contact us
							</h5>
							<HiArrowNarrowRight />
						</button>
					</div>
				</section>
			</Container>
		</div>
	);
};

export default ServiceSection;
