import {
	registerCollaborator,
} from "./../services/userServices";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { loginUser, registerUser } from "../services/userServices";

export interface LoginData {
	email: string;
	password: string;
}

export const socialLoginAction = createAction(
	"auth/socialLogin",
	(data) => {
	  return {
		payload: data
	  }
	}
  );

export const loginAction = createAsyncThunk<any, any>(
	"user/login",
	async (data, { rejectWithValue }) => {
		
		try {
			const response = await loginUser(data);
			return response.data;

			
		} catch (error: any) {
			return rejectWithValue(error?.response.data);
		}
	}
);

export const registerIndividualAction = createAsyncThunk<any, any>(
	"user/register",
	async (data, { rejectWithValue }) => {
		try {
			const response = await registerUser(data);
			return response.data;
		} catch (error: any) {
			return rejectWithValue(error.response.data.message);
		}
	}
);

export const registerCollaboratorAction = createAsyncThunk<any, any>(
	"user/register-collaborator",
	async (data, { rejectWithValue }) => {
		try {
			const response = await registerCollaborator(data);

			return response.data;
		} catch (error: any) {
			return rejectWithValue(error?.response.data);
		}
	}
);

export const fetchUserLoggedInUserAction = createAsyncThunk(
	"user/logged-user",
	async (_, { rejectWithValue }) => {
		const authUser =
			//@ts-ignore
			JSON.parse(localStorage.getItem("quickleapUser"))
				? //@ts-ignore
				  JSON.parse(localStorage.getItem("quickleapUser"))
				: null;

		if (authUser) {
		
			return authUser;
		} else {
			return rejectWithValue({ messsage: "Log in" });
		}
	}
);

export const logoutUserAction = createAsyncThunk(
	"user/logout-user",
	async (_, { rejectWithValue }) => {
		const authUser =
			//@ts-ignore
			JSON.parse(localStorage.getItem("quickleapUser"))
				? //@ts-ignore
				  JSON.parse(localStorage.getItem("quickleapUser"))
				: null;

		if (authUser) {
			localStorage.removeItem("quickleapUser");
			return;
		} else {
			return rejectWithValue({ messsage: "Log in" });
		}
	}
);
