import { create } from "zustand";

interface ModalInterface {
	isOpen: boolean;
	onOpen: () => void;
	onClose: () => void;
}

const useContactModal = create<ModalInterface>((set) => ({
	isOpen: false,
	modalType: 'BUDGET',
	onOpen: () => set({ isOpen: true }),
	onClose: () => set({ isOpen: false }),
}));

export default useContactModal;
