import { createAsyncThunk } from "@reduxjs/toolkit";
import {
	deleteMyCollaboratorProduct,
	getAssignedCustomerOrders,
	getFieldAgentCollaborator,
	getFieldAgentCollaboratorList,
	getFieldAgentCollaboratorProducts,
	handleFieldAgentCollaboratorOnboard,
	handleFieldAgentProductUpload,
	updateAssignedCustomerOrders,
	updateCollaboratorProduct,
	updateCollaboratorProfile,
} from "../services/fieldAgentService";
import {
	FieldAgentOnboardUserType,
	FieldAgentProductUploadType,
} from "../../types/collaborator";
import {
	FieldAgentCollaboratorProductType,
	FieldAgentCollboratorProfileUpdateTypes,
} from "../../types/fieldagentTypes";

const fetchFieldAgentCollaboratorListAction = createAsyncThunk<any, any>(
	"field-agent/collaborator-list",
	async (data: { page: number; limit: number }, { rejectWithValue }) => {
		try {
			const response = await getFieldAgentCollaboratorList(data);
			return response.data;
		} catch (error: any) {
			return rejectWithValue(error.response.data.message);
		}
	}
);

const fetchFieldAgentCollaboratorProductsAction = createAsyncThunk<any, any>(
	"field-agent/collaborator-products",
	async (
		data: {
			collaboratorId: string;
			page: number;
			limit: number;
		},
		{ rejectWithValue }
	) => {
		try {
			const response = await getFieldAgentCollaboratorProducts(data);
			return response.data;
		} catch (error: any) {
			return rejectWithValue(error.response.data.message);
		}
	}
);

const handleFieldAgentProductUploadAction = createAsyncThunk<any, any>(
	"field-agent/upload-collaborator-product",
	async (data: FieldAgentProductUploadType, { rejectWithValue }) => {
		try {
			const response = await handleFieldAgentProductUpload(data);

			return response.data;
		} catch (error: any) {
			return rejectWithValue(error.response.data.message);
		}
	}
);

const handleFieldAgentCollaboratorOnboardAction = createAsyncThunk<any, any>(
	"field-agent/collaborator-onboard",
	async (data: FieldAgentOnboardUserType, { rejectWithValue }) => {
		try {
			const response = await handleFieldAgentCollaboratorOnboard(data);

			return response.data;
		} catch (error: any) {
			return rejectWithValue(error.response.data.message);
		}
	}
);

const handleFieldAgentCollaboratorProfileAction = createAsyncThunk<any, any>(
	"field-agent/collaborator-profile",
	async (data: { collaboratorId: string }, { rejectWithValue }) => {
		try {
			const response = await getFieldAgentCollaborator(data);

			return response.data;
		} catch (error: any) {
			return rejectWithValue(error.response.data.message);
		}
	}
);

const handleFieldAgentCollaboratorUpdateProfileAction = createAsyncThunk<
	any,
	any
>(
	"field-agent/collaborator-update",
	async (
		data: {
			profileInfo: FieldAgentCollboratorProfileUpdateTypes;
			collaboratorId: string;
		},
		{ rejectWithValue }
	) => {
		try {
			const response = await updateCollaboratorProfile(
				data.profileInfo,
				data.collaboratorId
			);

			return response.data;
		} catch (error: any) {
			return rejectWithValue(error.response.data.message);
		}
	}
);

const fetchCustomOrdersAssignedToFieldAgentAction = createAsyncThunk<any, any>(
	"field-agent/custom-orders",
	async (data: { page: number }, { rejectWithValue }) => {
		try {
			const response = await getAssignedCustomerOrders(data);

			return response.data;
		} catch (error: any) {
			return rejectWithValue(error.response.data.message);
		}
	}
);

const handleCustomerOrderUpdateAction = createAsyncThunk<any, any>(
	"field-agent/update-customer-order",
	async (
		data: { customerOrderId: string; status: string },
		{ rejectWithValue }
	) => {
		try {
			const response = await updateAssignedCustomerOrders(data);
			return response.data;
		} catch (error: any) {
			return rejectWithValue(error.response.data.message);
		}
	}
);

const handleMyCollaboratorProductDeleteAction = createAsyncThunk<any, any>(
	"field-agent/delete-collaborator-product",
	async (
		data: {
			collaboratorId: string;
			productSlug: string;
		},
		{ rejectWithValue }
	) => {
		try {
			const response = await deleteMyCollaboratorProduct({
				collaboratorId: data.collaboratorId,
				productSlug: data.productSlug,
			});

			return response.data;
		} catch (error: any) {
			return rejectWithValue(error.response.data.message);
		}
	}
);

const editCollaboratorProductAction = createAsyncThunk<any, any>(
	"field-agent/edit-collaborator-product",
	async (
		data: {
			collaboratorId: string;
			productSlug: string;
			productData: {
				name: string;
				status: string;
				type: "PRODUCT" | "MEAL";
				moistureContent?: string;
				images: string[];
				quantity: number;
				quantityUnit: string;
				price: number;
				address: string;
				country: string;
				state: string;
				city: string;
				category: number;
				cuisine_type?: string | null;
			};
		},
		{ rejectWithValue }
	) => {
		try {
			const response = await updateCollaboratorProduct({
				collaboratorId: data.collaboratorId,
				productSlug: data.productSlug,
				productData: data.productData,
			});

			return response.data;
		} catch (error: any) {
			return rejectWithValue(error.response.data.message);
		}
	}
);

const handleProductToEditAction = createAsyncThunk<any, any>(
	"field-agent/edit-product",
	(data: FieldAgentCollaboratorProductType) => {
		if (data) {
			return data;
		}
	}
);

export {
	fetchFieldAgentCollaboratorListAction,
	fetchFieldAgentCollaboratorProductsAction,
	handleFieldAgentProductUploadAction,
	handleFieldAgentCollaboratorOnboardAction,
	handleFieldAgentCollaboratorProfileAction,
	handleFieldAgentCollaboratorUpdateProfileAction,
	fetchCustomOrdersAssignedToFieldAgentAction,
	handleCustomerOrderUpdateAction,
	handleMyCollaboratorProductDeleteAction,
	handleProductToEditAction,
	editCollaboratorProductAction,
};
