import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import LoginHeader from "../components/molecules/LoginHeader";
import { Formik, Form } from "formik";
import * as yup from "yup";
import FormInput from "../components/molecules/FormInput";
import PasswordInput from "../components/molecules/PasswordInput";
import Button from "../components/molecules/Button";
import { toast } from "react-toastify";
import AuthLoginLayout from "../components/organisms/layouts/AuthLoginLayout";
import { useAppDispatch } from "../store/hooks";
import { loginAction } from "../store/auth/authActions";
import useAuthInfoModal from "../hooks/modal-hooks/useAuthInfoModal";
import SocialAuthButtons from "../components/molecules/SocialAuthButtons";

const userSchema = yup.object({
  password: yup.string().required("Password is required"),
  email: yup.string().email().required("Email is required"),
});

type User = yup.InferType<typeof userSchema>;

const LoginScreen = () => {
  const initialValues: User = {
    password: "",
    email: "",
  };
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const authInfoModal = useAuthInfoModal();

  console.log({
	apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
	authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
	projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
	storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
	messagingSenderId: process.env.REACT_APP_FIREBASE_SENDERID,
	appId: process.env.REACT_APP_FIREBASE_APPID,
}
)

  const quickleapDelivers = localStorage.getItem("quickleapDelivers")
    ? //@ts-ignore
      JSON.parse(localStorage.getItem("quickleapDelivers"))
    : null;

  // const redirectUrl = localStorage.getItem("quickleapRedirectUrl")
  //   ? //@ts-ignore
  //     JSON.parse(localStorage.getItem("quickleapRedirectUrl"))
  //   : null;

  const handleSubmit = async (values: User) => {
    setLoading(true);
    const { password, email } = values;

    if (!password || !email) {
      setLoading(false);
      return toast.error("All fields are required");
    }

    const data = {
      email,
      password,
    };

    try {
      const {
        meta: { requestStatus },
        payload,
      } = await dispatch(loginAction(data));

      if (requestStatus === "rejected") {
        toast.error(payload?.message);
        return;
      }

      localStorage.setItem("quickleapUser", JSON.stringify(payload));
      toast.success("Successfully signed in");

      // if (redirectUrl) {
      //   navigate(`${redirectUrl?.path}`);
      //   return;
      // }

      if (!payload?.isCollaborator && quickleapDelivers) {
        navigate("/grow-for-life/home");
      } else if (!payload?.isCollaborator) {
        navigate("/grow-for-life");
      } else {
        navigate("/collaborator");
      }
    } catch (error) {
      //@ts-ignore
      toast.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <AuthLoginLayout loginPage={"LOGIN"}>
      <section className="p-8 py-10 bg-white rounded-md w-full max-w-[600px]">
        <div className="w-full h-16 mb-2">
          <Link to="/">
            <img
              src={"/assets/images/logo.png"}
              alt="logo"
              className="w-full h-full object-contain"
            />
          </Link>
        </div>

        <div className="w-full xl:px-10">
          <LoginHeader
            header="Welcome Back!"
            subHeader="Log in to your account"
          />

          <Formik
            initialValues={initialValues}
            onSubmit={async (values: User, { resetForm }) => {
              await handleSubmit(values);
              resetForm();
            }}
            validationSchema={userSchema}
          >
            {({ values, setFieldValue }) => {
              return (
                <Form className="mt-8 flex flex-col gap-1">
                  <FormInput
                    id="email"
                    name="email"
                    placeholder="Email Address"
                    label="Email Address"
                    onChange={(e) => {
                      setFieldValue("email", e.target.value);
                    }}
                  />

                  <PasswordInput
                    id="password"
                    name="password"
                    placeholder="Password"
                    label="Password"
                  />

                  <Link
                    to="/reset"
                    className="text-green text-right inline-block text-sm font-medium mb-2"
                  >
                    Forgot Password?
                  </Link>

                  <Button
                    type="submit"
                    className="bg-green w-full font-semibold rounded-lg h-[3rem] flex items-center justify-center leading-7"
                    isLoading={loading}
                    disabled={loading}
                  >
                    Login
                  </Button>

				  <div className="my-3">
                    <p className="font-semibold text-center mb-3">
                      Or sign in using
                    </p>

                   <SocialAuthButtons />
                  </div>
                </Form>
              );
            }}
          </Formik>

          <div className="max-w-[300px] mx-auto mt-4 text-center font-normal text-[#A4A4A4] flex items-center justify-center gap-1">
            Don't have an account?{" "}
            <p
              onClick={() => {
                authInfoModal.onOpen();
              }}
              className="font-medium text-green cursor-pointer"
            >
              Create one
            </p>
          </div>
        </div>
      </section>
    </AuthLoginLayout>
  );
};

export default LoginScreen;
