import Carousel from "nuka-carousel";
import ScrollToTop from "../../atoms/ScrollToTop";
import Container from "../Container";
import { FC, ReactNode } from "react";
import AllModals from "../../modal/AllModals";
import { useLocation } from "react-router-dom";
import MetaTags from "../MetaTags";

const AuthLoginLayout: FC<{
	children: ReactNode;
	loginPage?: "LOGIN" | "REGISTER";
}> = ({ children, loginPage }) => {
	const location = useLocation();
	const images =
		location.pathname === "/register"
			? [
					"/login/man-eating.png",
					"/login/family.png",
					"/login/youth.png",
					"/login/dining.png",
			  ]
			: [
					"/login/chef.png",
					"/login/farmer.png",
					"/login/driver.png",
					"/login/fruits.png",
			  ];
	return (
		<ScrollToTop>
			<div className="w-full relative bg-green-light h-screen">
				<AllModals />
				<MetaTags
					title="Quick Leap"
					description="Good food for everyone, everywhere, everytime!"
					image="https://firebasestorage.googleapis.com/v0/b/quickleap-web.appspot.com/o/profile%2F01b6b69e-1bb1-4d6a-ab09-dc33194cceb4?alt=media&token=a725b207-bcbc-4893-b709-5bf2e7b0f151"
					name="Quick Leap"
				/>
				<Container className="grid grid-cols-1 lg:grid-cols-2 h-full">
					<section className="h-full w-full hidden lg:grid place-items-center">
						<div className="w-[26rem] h-[35rem] rounded-lg overflow-hidden">
							<Carousel
								slidesToShow={1}
								wrapAround={true}
								speed={1000}
								autoplay={true}
								withoutControls={true}
							>
								{images.map((image: string, index: number) => (
									<div className="w-full h-full my-auto" key={index}>
										<img
											src={`/assets${image}`}
											alt="asset"
											className="w-full h-full object-cover"
										/>
									</div>
								))}
							</Carousel>
						</div>
					</section>

					<section
						className={` h-full relative bg-black/0 overflow-scroll flex justify-center items-center ${
							loginPage === "LOGIN" ? "py-5 md:p-5" : "py-5 md:p-5"
						}`}
					>
						{children}
					</section>
				</Container>
			</div>
		</ScrollToTop>
	);
};

export default AuthLoginLayout;
