import axios from "../../axios.config";

const handleAdminLogin = async (data: { email: string; password: string }) => {
	const response = await axios.post(`/admin/auth/sign-in`, {
		email: data.email,
		password: data.password,
	});

	if (response.status === 200 || response.status === 201) {
		return response.data;
	}

	throw new Error(response.data.message);
};

const getAdminProfile = async () => {
	const response = await axios.get(`/admin/auth/profile`);

	if (response.status === 200 || response.status === 201) {
		return response.data;
	}

	throw new Error(response.data.message);
};

const getAllCollaborators = async (data: {
	page: number;
	limit: number;
	search: string;
	collaboratorType?: string;
	country?: string;
	state?: string;
	city?: string;
}) => {
	const response = await axios.get(
		`/admin/collaborators?page=${data.page}&limit=${data.limit}&search=${
			data.search
		}&country=${data?.country ?? ""}&collaboratorType=${
			data.collaboratorType ?? ""
		}&state=${data?.state ?? ""}`
	);

	if (response.status === 200 || response.status === 201) {
		return response.data;
	}

	throw new Error(response.data.message);
};

const getcCollaboratorById = async (data: { collaboratorId: string }) => {
	const { collaboratorId } = data;

	const response = await axios.get(`/admin/collaborators/${collaboratorId}`);

	if (response.status === 200 || response.status === 201) {
		return response.data;
	}

	throw new Error(response.data.message);
};

const getCollaboratorProducts = async (data: {
	collaboratorId: string;
	page: number;
}) => {
	const { collaboratorId, page } = data;

	const response = await axios.get(
		`/admin/collaborators/${collaboratorId}/products?page=${page}&limit=${10}`
	);

	if (response.status === 200 || response.status === 201) {
		return response.data;
	}

	throw new Error(response.data.message);
};

const getAdminGrowforlifeAnalytics = async () => {
	const response = await axios.get(`/admin/grow-for-life/analytics`);

	if (response.status === 200 || response.status === 201) {
		return response.data;
	}

	throw new Error(response.data.message);
};

const getAdminLatestCustomers = async (data: { page: number }) => {
	const { page } = data;

	const response = await axios.get(
		`/admin/recent-customers?page=${page}&limit=${10}`
	);

	if (response.status === 200 || response.status === 201) {
		return response.data;
	}

	throw new Error(response.data.message);
};

const getAdminGrowforlifeTopProducts = async (data: { page: number }) => {
	const { page } = data;

	const response = await axios.get(
		`/admin/grow-for-life/top-products?page=${page}&limit=${10}`
	);

	if (response.status === 200 || response.status === 201) {
		return response.data;
	}

	throw new Error(response.data.message);
};

const getUnverifiedFieldAgents = async (data: { page: number }) => {
	const { page } = data;

	const response = await axios.get(
		`/admin/collaborators/unverified-field-agents?page=${page}&limit=${10}`
	);

	if (response.status === 200 || response.status === 201) {
		return response.data;
	}

	throw new Error(response.data.message);
};

const getUnverifiedProducts = async (data: { page: number }) => {
	const { page } = data;

	const response = await axios.get(
		`/admin/collaborators/unverified-products?page=${page}&limit=${10}`
	);

	if (response.status === 200 || response.status === 201) {
		return response.data;
	}

	throw new Error(response.data.message);
};

const getAdminTeamMembers = async () => {
	const response = await axios.get(`/admin/auth/profile/team/view-members`);

	if (response.status === 200 || response.status === 201) {
		return response.data;
	}

	throw new Error(response.data.message);
};

const changeAdminPassword = async (data: {
	oldPassword: string;
	newPassword: string;
}) => {
	const response = await axios.put(`/admin/auth/profile/change-password`, {
		oldPassword: data?.oldPassword,
		newPassword: data?.newPassword,
	});

	if (response.status === 200 || response.status === 201) {
		return response.data;
	}

	throw new Error(response.data.message);
};

const resetAdminPasswordSendOtp = async (data: { email: string }) => {
	const response = await axios.post(
		`/admin/auth/reset-password/send-otp`,
		data
	);
	if (response.status === 200 || response.status === 201) {
		return response.data;
	}

	throw new Error(response.data.message);
};

const verifyAdminOtp = async (data: { otp: string; token: string }) => {
	const response = await axios.post(
		`/admin/auth/reset-password/verify-otp`,
		data
	);
	if (response.status === 200 || response.status === 201) {
		return response.data;
	}

	throw new Error(response.data.message);
};

const resetAdminPassword = async (data: {
	password: string;
	token: string;
}) => {
	const response = await axios.post(`/admin/auth/reset-password`, data);
	if (response.status === 200 || response.status === 201) {
		return response.data;
	}

	throw new Error(response.data.message);
};

const updateAdminProfile = async (data: {
	firstName?: string;
	lastName?: string;
	email?: string;
	profilePicture?: string;
}) => {
	const response = await axios.put(`/admin/auth/profile`, data);

	if (response.status === 200 || response.status === 201) {
		return response.data;
	}

	throw new Error(response.data.message);
};

const verifyCollaborator = async (data: { collaboratorId: string }) => {
	const response = await axios.post(
		`/admin/collaborators/verify-field-agent/${data?.collaboratorId}`
	);

	if (response.status === 200 || response.status === 201) {
		return response.data;
	}

	throw new Error(response.data.message);
};

const approvedCollaboratorProduct = async (data: {
	collaboratorProductId: string;
}) => {
	const response = await axios.post(
		`/admin/collaborators/verify-product/${data?.collaboratorProductId}`
	);

	if (response.status === 200 || response.status === 201) {
		return response.data;
	}

	throw new Error(response.data.message);
};

const getAdminOrders = async (data: {
	page: number;
	search: string;
	orderStatus: string;
}) => {
	const { page, search, orderStatus } = data;

	const response = await axios.get(
		`/admin/grow-for-life/orders?page=${Number(page)}&limit=${10}&search=${
			search ?? ""
		}&orderStatus=${orderStatus ?? ""}`
	);

	if (response.status === 200 || response.status === 201) {
		return response.data;
	}

	throw new Error(response.data.message);
};

const verifyFieldAgent = async (data: { collaboratorId: string }) => {
	const response = await axios.post(
		`/admin/collaborators/verify-field-agent/${data.collaboratorId}`
	);

	if (response.status === 200 || response.status === 201) {
		return response.data;
	}

	throw new Error(response.data.message);
};

const assignProductToFieldAgent = async (data: {
	collaboratorId: string;
	collaboratorProductId: string;
}) => {
	const { collaboratorId, collaboratorProductId } = data;

	const response = await axios.post(
		`/admin/collaborators/${collaboratorId}/assign-product/product/${collaboratorProductId}`
	);

	if (response.status === 200 || response.status === 201) {
		return response.data;
	}

	throw new Error(response.data.message);
};

const updateOrderStatusAdmin = async (data: {
	status: string;
	orderId: string;
}) => {
	const response = await axios.put(
		`/admin/grow-for-life/orders/${data?.orderId}`,
		{ status: data.status }
	);

	if (response.status === 200 || response.status === 201) {
		return response.data;
	}

	throw new Error(response.data.message);
};

const assignCustomerOrderToFieldAgent = async (data: {
	customerOrderId: string;
	collaboratorId: string;
}) => {
	const { customerOrderId, collaboratorId } = data;

	const response = await axios.post(
		`/admin/grow-for-life/orders/${customerOrderId}/assign-collaborator/${collaboratorId}`
	);

	if (response.status === 200 || response.status === 201) {
		return response.data;
	}

	throw new Error(response.data.message);
};

const getAllGrowForLifeFeedback = async (data: { page: number }) => {
	const { page } = data;

	const response = await axios.get(
		`/admin/grow-for-life/feedbacks?page=${page}&limit=${10}`
	);

	if (response.status === 200 || response.status === 201) {
		return response.data;
	}

	throw new Error(response.data.message);
};

const getAllComplaints = async () => {
	const response = await axios.get(`/global/complaints`);

	if (response.status === 200 || response.status === 201) {
		return response.data;
	}

	throw new Error(response.data.message);
};

const getPreApprovedAdminProducts = async () => {
	const response = await axios.get(
		`/admin/grow-for-life/pre-approved-customer-products`
	);

	if (response.status === 200 || response.status === 201) {
		return response.data;
	}

	throw new Error(response.data.message);
};

const updatePreApprovedAdminProduct = async (
	data: any,
	productSlug: string
) => {
	const response = await axios.put(
		`/admin/grow-for-life/pre-approved-customer-products/${productSlug}`,
		data
	);

	if (response.status === 200 || response.status === 201) {
		return response.data;
	}
	throw new Error(response.data.message);
};

const approvePreApprovedAdminProduct = async (data: {
	preApprovedCustomerProductId: string;
}) => {
	const { preApprovedCustomerProductId } = data;

	const response = await axios.post(
		`/admin/grow-for-life/pre-approved-customer-products/${preApprovedCustomerProductId}`
	);

	if (response.status === 200 || response.status === 201) {
		return response.data;
	}
	throw new Error(response.data.message);
};

const getGflProductsAdmin = async (data: { page: number; search: string }) => {
	const { page, search } = data;

	const response = await axios.get(
		`/admin/grow-for-life/products?page=${
			Number(page) ?? 1
		}&limit=${10}&search=${search}`
	);

	if (response.status === 200 || response.status === 201) {
		return response.data;
	}
	throw new Error(response.data.message);
};

const updateGflProductAdmin = async (data: any, productSlug: string) => {
	const response = await axios.put(
		`/admin/grow-for-life/products/${productSlug}`,
		data
	);

	if (response.status === 200 || response.status === 201) {
		return response.data;
	}
	throw new Error(response.data.message);
};

const deleteGflProductAdmin = async (productSlug: string) => {
	const response = await axios.delete(
		`/admin/grow-for-life/products/${productSlug}`
	);

	if (response.status === 200 || response.status === 201) {
		return response.data;
	}
	throw new Error(response.data.message);
};

export {
	handleAdminLogin,
	getAdminProfile,
	getAllCollaborators,
	getcCollaboratorById,
	getCollaboratorProducts,
	getAdminGrowforlifeAnalytics,
	getAdminLatestCustomers,
	getAdminGrowforlifeTopProducts,
	getUnverifiedFieldAgents,
	getUnverifiedProducts,
	getAdminTeamMembers,
	changeAdminPassword,
	resetAdminPasswordSendOtp,
	verifyAdminOtp,
	resetAdminPassword,
	updateAdminProfile,
	verifyCollaborator,
	approvedCollaboratorProduct,
	getAdminOrders,
	verifyFieldAgent,
	assignProductToFieldAgent,
	updateOrderStatusAdmin,
	assignCustomerOrderToFieldAgent,
	getAllGrowForLifeFeedback,
	getAllComplaints,
	getPreApprovedAdminProducts,
	approvePreApprovedAdminProduct,
	getGflProductsAdmin,
	updateGflProductAdmin,
	deleteGflProductAdmin,
	updatePreApprovedAdminProduct,
};
