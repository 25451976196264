import React from 'react'

interface HeaderProps {
   header: string,
   subHeader?: string
}

const LoginHeader: React.FC<HeaderProps> = ({ header, subHeader }) => {
   return (
      <div className='w-full mt-5'>
         <h1 className='text-black font-semibold text-3xl'>{header}</h1>
         <p className='text-[#6C6C6C] text-sm mt-2 '>{subHeader}</p>
      </div>
   )
}

export default LoginHeader
