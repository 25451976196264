import { createAsyncThunk } from "@reduxjs/toolkit";
import {
	approvePreApprovedAdminProduct,
	approvedCollaboratorProduct,
	assignCustomerOrderToFieldAgent,
	assignProductToFieldAgent,
	changeAdminPassword,
	getAdminGrowforlifeAnalytics,
	getAdminGrowforlifeTopProducts,
	getAdminLatestCustomers,
	getAdminOrders,
	getAdminProfile,
	getAdminTeamMembers,
	getAllCollaborators,
	getAllComplaints,
	getCollaboratorProducts,
	getPreApprovedAdminProducts,
	getUnverifiedFieldAgents,
	getUnverifiedProducts,
	getcCollaboratorById,
	handleAdminLogin,
	updateAdminProfile,
	updateOrderStatusAdmin,
	verifyCollaborator,
	verifyFieldAgent,
	getGflProductsAdmin,
	updateGflProductAdmin,
	deleteGflProductAdmin,
	updatePreApprovedAdminProduct,
} from "../services/adminService";

const handleAdminLoginAction = createAsyncThunk<any, any>(
	"admin/login",
	async (data: { email: string; password: string }, { rejectWithValue }) => {
		try {
			const response = await handleAdminLogin(data);
			// console.log(response);
			return response.data;
		} catch (error: any) {
			return rejectWithValue(error.response.data.message);
		}
	}
);

const fetchAdminProfileAction = createAsyncThunk(
	"admin/profile",
	async (_, { rejectWithValue }) => {
		try {
			const response = await getAdminProfile();

			return response.data;
		} catch (error: any) {
			window.location.href = "/admin/login";
			localStorage.removeItem("quickleapUser");
			return rejectWithValue(error.response.data.message);
		}
	}
);

const fetchAdminCollaboratorsAction = createAsyncThunk<any, any>(
	"admin/collaborators",
	async (
		data: {
			page: number;
			limit: number;
			search: string;
			collaboratorType?: string;
			country?: string;
			state?: string;
			city?: string;
		},
		{ rejectWithValue }
	) => {
		try {
			const response = await getAllCollaborators(data);

			return response.data;
		} catch (error: any) {
			return rejectWithValue(error.response.data.message);
		}
	}
);

const fetchAdminCollaboratorByIdAction = createAsyncThunk<any, any>(
	"admin/collaborator-by-id",
	async (data: { collaboratorId: string }, { rejectWithValue }) => {
		try {
			const response = await getcCollaboratorById(data);

			return response.data;
		} catch (error: any) {
			return rejectWithValue(error.response.data.message);
		}
	}
);

const fetchAdminCollaboratorProductsAction = createAsyncThunk<any, any>(
	"admin/collaborator-products",
	async (
		data: { collaboratorId: string; page: number },
		{ rejectWithValue }
	) => {
		try {
			const response = await getCollaboratorProducts(data);

			return response.data;
		} catch (error: any) {
			return rejectWithValue(error.response.data.message);
		}
	}
);

const fetchAdminGrowforlifeAnalyticsAction = createAsyncThunk(
	"admin/grow-for-life-analytics",
	async (_, { rejectWithValue }) => {
		try {
			const response = await getAdminGrowforlifeAnalytics();

			return response.data;
		} catch (error: any) {
			return rejectWithValue(error.response.data.message);
		}
	}
);

const fetchAdminRecentCustomersAction = createAsyncThunk<any, any>(
	"admin/recent-customers",
	async (data: { page: number }, { rejectWithValue }) => {
		try {
			const response = await getAdminLatestCustomers(data);

			return response.data;
		} catch (error: any) {
			return rejectWithValue(error.response.data.message);
		}
	}
);

const fetchAdminGrowforlifeTopProductsAction = createAsyncThunk<any, any>(
	"admin/grow-for-life-top-products",
	async (data: { page: number }, { rejectWithValue }) => {
		try {
			const response = await getAdminGrowforlifeTopProducts(data);

			return response.data;
		} catch (error: any) {
			return rejectWithValue(error.response.data.message);
		}
	}
);

const fetchUnverifiedFieldAgentsAction = createAsyncThunk<any, any>(
	"admin/unverified-field-agents",
	async (data: { page: number }, { rejectWithValue }) => {
		try {
			const response = await getUnverifiedFieldAgents(data);

			return response.data;
		} catch (error: any) {
			return rejectWithValue(error.response.data.message);
		}
	}
);

const fetchUnverifiedProductsAction = createAsyncThunk<any, any>(
	"admin/unverified-products",
	async (data: { page: number }, { rejectWithValue }) => {
		try {
			const response = await getUnverifiedProducts(data);

			return response.data;
		} catch (error: any) {
			return rejectWithValue(error.response.data.message);
		}
	}
);

const fetchAdminTeamMembersAction = createAsyncThunk(
	"admin/team-members",
	async (_, { rejectWithValue }) => {
		try {
			const response = await getAdminTeamMembers();

			return response.data;
		} catch (error: any) {
			return rejectWithValue(error.response.data.message);
		}
	}
);

const handleChangeAdminPasswordAction = createAsyncThunk<any, any>(
	"admin/change-password",
	async (
		data: { oldPassword: string; newPassword: string },
		{ rejectWithValue }
	) => {
		try {
			const response = await changeAdminPassword(data);

			return response.data;
		} catch (error: any) {
			return rejectWithValue(error.response.data.message);
		}
	}
);

const handleAdminProfileUpdateAction = createAsyncThunk<any, any>(
	"admin/profile-update",
	async (
		data: {
			firstName?: string;
			lastName?: string;
			email?: string;
			profilePicture?: string;
		},
		{ rejectWithValue }
	) => {
		try {
			const response = await updateAdminProfile(data);

			return response.data;
		} catch (error: any) {
			return rejectWithValue(error.response.data.message);
		}
	}
);

const handleCollaboratorVerificationAction = createAsyncThunk<any, any>(
	"admin/collaborator-verify",
	async (data: { collaboratorId: string }, { rejectWithValue }) => {
		try {
			const response = await verifyCollaborator(data);

			return response.data;
		} catch (error: any) {
			return rejectWithValue(error.response.data.message);
		}
	}
);

const handleApproveProductAction = createAsyncThunk<any, any>(
	"admin/approve-product",
	async (data: { collaboratorProductId: string }, { rejectWithValue }) => {
		try {
			const response = await approvedCollaboratorProduct(data);

			return response.data;
		} catch (error: any) {
			return rejectWithValue(error.response.data.message);
		}
	}
);

const fetchAdminOrdersAction = createAsyncThunk<any, any>(
	"admin/orders",
	async (
		data: { page: number; search: string; orderStatus: string },
		{ rejectWithValue }
	) => {
		try {
			const response = await getAdminOrders({
				page: data.page,
				search: data.search,
				orderStatus: data.orderStatus,
			});
			return response.data;
		} catch (error: any) {
			return rejectWithValue(error.response.data.message);
		}
	}
);

const handleFieldAgentVerificationAction = createAsyncThunk<any, any>(
	"admin/verify-field-agent",
	async (data: { collaboratorId: string }, { rejectWithValue }) => {
		try {
			const response = await verifyFieldAgent({
				collaboratorId: data.collaboratorId,
			});
			return response.data;
		} catch (error: any) {
			return rejectWithValue(error.response.data.message);
		}
	}
);

const handleProductAssignToFieldAgent = createAsyncThunk<any, any>(
	"admin/assign-product",
	async (
		data: {
			collaboratorId: string;
			collaboratorProductId: string;
		},
		{ rejectWithValue }
	) => {
		try {
			const response = await assignProductToFieldAgent(data);
			return response.data;
		} catch (error: any) {
			return rejectWithValue(error.response.data.message);
		}
	}
);

const handleOrderStatusUpdate = createAsyncThunk<any, any>(
	"admin/order-status",
	async (data: { status: string; orderId: string }, { rejectWithValue }) => {
		try {
			const response = await updateOrderStatusAdmin(data);
			return response.data;
		} catch (error: any) {
			return rejectWithValue(error.response.data.message);
		}
	}
);

const handleOrderAssignAction = createAsyncThunk<any, any>(
	"admin/order-assign",
	async (
		data: {
			customerOrderId: string;
			collaboratorId: string;
		},
		{ rejectWithValue }
	) => {
		try {
			const response = await assignCustomerOrderToFieldAgent(data);
			return response.data;
		} catch (error: any) {
			return rejectWithValue(error.response.data.message);
		}
	}
);

const fetchAllUsersComplaintAction = createAsyncThunk(
	"admin/all-complaints",
	async (_, { rejectWithValue }) => {
		try {
			const response = await getAllComplaints();
			return response.data.complaints;
		} catch (error: any) {
			return rejectWithValue(error.response.data.message);
		}
	}
);

const fetchPreApprovedProductsAction = createAsyncThunk(
	"admin/pre-approve-products",
	async (_, { rejectWithValue }) => {
		try {
			const response = await getPreApprovedAdminProducts();
			return response.data;
		} catch (error: any) {
			return rejectWithValue(error.response.data.message);
		}
	}
);

const handlePreApprovedProductsAction = createAsyncThunk<any, any>(
	"admin/approve-pre-approve-product",
	async (
		data: { preApprovedCustomerProductId: string },
		{ rejectWithValue }
	) => {
		try {
			const response = await approvePreApprovedAdminProduct(data);

			return response.data;
		} catch (error: any) {
			return rejectWithValue(error.response.data.message);
		}
	}
);

const updatePreApprovedProductAction = createAsyncThunk<any, any>(
	"admin/update-pre-approve-product",
	async (
		data: {
			productData: any;
			productSlug: string;
		},
		{ rejectWithValue }
	) => {
		try {
			const response = await updatePreApprovedAdminProduct(
				data.productData,
				data.productSlug
			);
			return response.data;
		} catch (error: any) {
			return rejectWithValue(error.response.data.message);
		}
	}
);

const fetchGflProductsAdminAction = createAsyncThunk<any, any>(
	"admin/get/grow-for-life-products",
	async (data: { page: number; search: string }, { rejectWithValue }) => {
		try {
			const response = await getGflProductsAdmin(data);
			// console.log(response, "Grow for life products");

			return response.data;
		} catch (error: any) {
			return rejectWithValue(error.response.data.message);
		}
	}
);

const updateGflProductAdminAction = createAsyncThunk<any, any>(
	"admin/update-gfl-product",
	async (
		data: {
			productData: any;
			productSlug: string;
		},
		{ rejectWithValue }
	) => {
		try {
			const response = await updateGflProductAdmin(
				data.productData,
				data.productSlug
			);
			return response.data;
		} catch (error: any) {
			return rejectWithValue(error.response.data.message);
		}
	}
);

const deleteGflProductAdminAction = createAsyncThunk<any, any>(
	"admin/delete-gfl-product",
	async (data: { productSlug: string }, { rejectWithValue }) => {
		try {
			const response = await deleteGflProductAdmin(data.productSlug);
			return response.data;
		} catch (error: any) {
			return rejectWithValue(error.response.data.message);
		}
	}
);

export {
	handleAdminLoginAction,
	fetchAdminProfileAction,
	fetchAdminCollaboratorsAction,
	fetchAdminCollaboratorByIdAction,
	fetchAdminCollaboratorProductsAction,
	fetchAdminGrowforlifeAnalyticsAction,
	fetchAdminRecentCustomersAction,
	fetchAdminGrowforlifeTopProductsAction,
	fetchUnverifiedFieldAgentsAction,
	fetchUnverifiedProductsAction,
	fetchAdminTeamMembersAction,
	handleChangeAdminPasswordAction,
	handleAdminProfileUpdateAction,
	handleCollaboratorVerificationAction,
	handleApproveProductAction,
	fetchAdminOrdersAction,
	handleFieldAgentVerificationAction,
	handleProductAssignToFieldAgent,
	handleOrderStatusUpdate,
	handleOrderAssignAction,
	fetchAllUsersComplaintAction,
	fetchPreApprovedProductsAction,
	handlePreApprovedProductsAction,
	fetchGflProductsAdminAction,
	updateGflProductAdminAction,
	deleteGflProductAdminAction,
	updatePreApprovedProductAction,
};
