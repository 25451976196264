import Container from "../../Container";
import { Link } from "react-router-dom";
import Button from "../../../molecules/Button";
import useAuthInfoModal from "../../../../hooks/modal-hooks/useAuthInfoModal";
import { selectAuthUser } from "../../../../store/auth/authReducer";
import { useAppSelector } from "../../../../store/hooks";

const HeroCollaboratorSection = () => {
	const authInfoModal = useAuthInfoModal();
	const { currentUser, isAuthenticated } = useAppSelector(selectAuthUser);

	return (
		<div className="w-full lg:pt-10 h-full bg-green-light">
			<Container className="px-5 py-16 flex flex-col gap-4">
				<h2 className="text-4xl md:text-[3.5rem] leading-tight max-w-[60rem] text-green text-center font-extrabold md:font-bold lg:px-5 mx-auto">
					Where our collaborators run their business
				</h2>
				<p className="leading-6 text-[#6C6C6C] text-center md:max-w-2xl md:mx-auto">
					Collaborator helps you our collaborator source and buy everything
					you need to create amazing food in one place, and sell everything
					you create in one place, so you can focus on running your
					business.
				</p>

				{isAuthenticated && currentUser && currentUser?.isCollaborator && (
					<div className="flex items-center justify-center space-x-5 my-5">
						<Link
							to="/collaborator/dashboard"
							className="border border-green font-semibold rounded-md text-center bg-green text-white px-4 py-3"
						>
							Launch Dashboard
						</Link>
					</div>
				)}

				{isAuthenticated && currentUser && !currentUser?.isCollaborator && (
					<div className="flex items-center justify-center space-x-5 my-5">
						<Link
							to="/register/collaborator"
							className="border border-green font-semibold rounded-md text-center bg-green text-white px-4 py-3"
						>
							Become a collaborator
						</Link>
					</div>
				)}

				{!isAuthenticated && !currentUser && (
					<div className="flex items-center justify-center space-x-5 my-5">
						<Button
							className="border py-5 flex items-center justify-center border-green text-center px-10 h-[50px] font-semibold rounded-md bg-green text-white"
							onClick={authInfoModal.onOpen}
						>
							Sign up
						</Button>
						<Link
							to="/login"
							className="border border-green w-32 px-4 font-semibold text-center py-3 rounded-md text-green"
						>
							Login
						</Link>
					</div>
				)}

				<img
					src="/assets/images/collaborator.png"
					className="w-full max-h-[700px] mx-auto my-8 block object-contain"
					alt="collaborator"
				/>
			</Container>
		</div>
	);
};

export default HeroCollaboratorSection;
