import React, { useState } from "react";
import useAuthInfoModal from "../../hooks/modal-hooks/useAuthInfoModal";
import Modal from "./Modal";
import { FaCircleCheck } from "react-icons/fa6";
import Button from "../molecules/Button";
import {  useNavigate } from "react-router-dom";
import { cn } from "../lib/utils";

const AuthInfoModal = () => {
  const { isOpen, onClose } = useAuthInfoModal();
  const navigate = useNavigate();
  const [activeAccount, setActiveAccount] = useState<string>("");

  const accountOptions = [
    {
      slug: "business",
      header: "As a Business",
      message:
        "Sign up as a business on Collaborator center and enjoy a single solution for sourcing all you need and selling all your produce.",
    },
    {
      slug: "individual",
      header: "As an Individual",
      message:
        "Sign up as an individual or a family and enjoy ease, rest, and access to a wide variety of products, and recipes curated for your delight",
    },
  ];

  return (
    <Modal
      isOpen={isOpen}
      full={true}
      onClose={() => {
        onClose();
        setActiveAccount("");
      }}
      className="translate duration-300 bg-white relative h-[70dvh] w-full md:w-full md:max-w-[650px]
        overflow-scroll md:h-auto md:max-h-[80vh] z-50 p-7 rounded-t-2xl md:rounded-xl md:min-h-[200px] md:min-w-[500px] "
    >
      <div className="w-full flex flex-col items-center gap-3 md:gap-4 xl:gap-5">
        <h2 className="text-black text-2xl leading-9 font-medium">
          How would you like to join Quickleap?
        </h2>

        <div className="flex flex-col w-full gap-y-3 md:gap-y-4 xl:gap-y-5">
          {accountOptions.map(
            ({
              slug,
              header,
              message,
            }: {
              slug: string;
              header: string;
              message: string;
            }) => (
              <div
                key={slug}
                className={cn(
                  `${
                    activeAccount === slug ? "border-green" : "border-[#B8B8B8]"
                  }`,
                  "w-full flex flex-col items-center justify-between cursor-pointer bg-white border p-5 rounded-2xl"
                )}
                onClick={() => setActiveAccount(slug)}
              >
                <div className="flex w-full items-center justify-between">
                  <h2 className="font-medium text-black text-base xl:text-lg">
                    {header}
                  </h2>

                  {activeAccount === slug ? (
                    <FaCircleCheck className="w-5 h-5 text-green" />
                  ) : (
                    <div className="w-5 h-5 min-h-5 min-w-5 rounded-full border border-[#B8B8B8]"></div>
                  )}
                </div>
                <p className="text-[#6C6C6C] text-xs mt-2 font-normal lg:text-sm">
                  {message}
                </p>
              </div>
            )
          )}
        </div>

        <Button
          size="small"
          className="bg-green text-white w-full flex items-center justify-center"
          disabled={activeAccount.length === 0 ? true : false}
          onClick={() => {
            if (activeAccount === "business") {
              navigate(`/register/collaborator`);
              onClose();
              setActiveAccount("");
              return;
            } else if (activeAccount === "individual") {
              navigate(`/register`);
              onClose();
              setActiveAccount("");
              return;
            }

            return;
          }}
        >
          Continue
        </Button>

        <div className="max-w-[300px] mx-auto mt-4 font-normal text-center text-[#A4A4A4]">
          Already have an account?{" "}
          <button
            className="font-medium text-green"
            onClick={() => {
              onClose();
              navigate("/login");
            }}
          >
            Log In
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default AuthInfoModal;
