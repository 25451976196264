import { create } from "zustand";

interface ModalProps {
   isOpen: boolean;
   onOpen: () => void;
   onClose: () => void;
   modalHeader: string;
   setModalHeader: (value: string) => void;
   modalContent: string;
   setModalContent: (value: string) => void;
   proceed: any;
   handleProceed: (func: any) => void;
}

const useDeleteModal = create<ModalProps>((set) => ({
   isOpen: false,
   onOpen: () => set({ isOpen: true }),
   onClose: () => set({ isOpen: false }),
   modalHeader: "",
   setModalHeader: (value: string) => set({ modalHeader: value }),
   modalContent: "",
   setModalContent: (value: string) => set({ modalContent: value }),
   proceed: null,
   handleProceed: (func: any) => set({ proceed: func }),
}));

export default useDeleteModal;
