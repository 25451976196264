import { ReactNode, FC, useState, useEffect } from "react";
import styled from "styled-components";
import { cn } from "../lib/utils";

const ModalBody = styled.div`
	&::-webkit-scrollbar {
		display: none;
	}
`;

interface ModalProps {
	isOpen: boolean;
	onClose: () => void;
	children: ReactNode;
	disabled?: boolean;
	prevStep?: boolean;
	prevStepAction?: () => void;
	className?: string;
	full: boolean;
	editModal?: boolean;
}

const Modal: FC<ModalProps> = ({
	isOpen,
	onClose,
	children,
	className,
	full,
	editModal,
}) => {
	const [showModal, setShowModal] = useState(isOpen);

	useEffect(() => {
		setShowModal(isOpen);
	}, [isOpen]);

	if (!showModal) {
		return null;
	}

	return (
		<ModalBody
			className={`fixed top-0 left-0 bg-black/30 z-[1000] flex ${
				full ? "items-end md:items-center" : "items-center p-5"
			} justify-center h-screen w-full`}
		>
			<div
				onClick={onClose}
				className="absolute cursor-pointer top-0 left-0 bg-transparent w-full h-full"
			/>

			<div
				className={cn(
					`bg-white max-h-[90vh] h-auto overflow-y-scroll duration-300 
					ease-in-out relative w-full md:w-auto ${
						editModal ? "max-w-[900px]" : "md:max-w-[600px] "
					} md:max-h-[80vh] 
					z-50 p-7 pb-10 md:pb-7 rounded-2xl md:rounded-xl md:min-h-[200px] md:min-w-[500px] m-3
					`,
					className
				)}
			>
				{children}
			</div>
		</ModalBody>
	);
};

export default Modal;
