import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../index";
import {
	AdminCollaboratorUser,
	AdminMembers,
	AdminOrders,
	AdminRecentCustomers,
	AdminUser,
	ComplaintType,
	PreApprovedProductType,
	UnverifiedFieldAgents,
	UnverifiedProducts,
} from "../../types/admin";
import {
	fetchAdminCollaboratorByIdAction,
	fetchAdminCollaboratorProductsAction,
	fetchAdminCollaboratorsAction,
	fetchAdminGrowforlifeAnalyticsAction,
	fetchAdminOrdersAction,
	fetchAdminProfileAction,
	fetchAdminRecentCustomersAction,
	fetchAdminTeamMembersAction,
	fetchAllUsersComplaintAction,
	fetchGflProductsAdminAction,
	fetchPreApprovedProductsAction,
	fetchUnverifiedFieldAgentsAction,
	fetchUnverifiedProductsAction,
	handleAdminLoginAction,
	handlePreApprovedProductsAction,
} from "./action";
import {
	CollaboratorProductType,
	PaginationType,
} from "../../types/collaborator";

interface AdminReducerTypes {
	isAuthenticated: boolean;
	currentAdmin: AdminUser | null;
	getAdminLogin: {
		error: string | null;
		loading: boolean;
		success: boolean;
	};
	collaborators: {
		data: AdminCollaboratorUser[];
		meta: PaginationType;
	};
	getAdminCollaborators: {
		error: string | null;
		loading: boolean;
		success: boolean;
	};
	collaborator: AdminCollaboratorUser | null;
	getCollaboratorById: {
		error: string | null;
		loading: boolean;
		success: boolean;
	};
	collaboratorProducts: {
		data: CollaboratorProductType[];
		meta: PaginationType;
	};
	getCollaboratorProducts: {
		error: string | null;
		loading: boolean;
		success: boolean;
	};

	adminGrowforlifeAnalytics: {
		averageSales: number;
		totalCollaborators: number;
		totalOrders: number;
		totalRevenue: number;
		totalUsers: number;
	};

	getAdminGrowforlifeAnalytics: {
		error: string | null;
		loading: boolean;
		success: boolean;
	};

	recentCustomers: {
		data: AdminRecentCustomers[];
		meta: PaginationType;
	};
	getRecentCustomers: {
		error: string | null;
		loading: boolean;
		success: boolean;
	};
	adminGrowforlifeTopProducts: [];
	getAdminTopProducts: {
		error: string | null;
		loading: boolean;
		success: boolean;
	};
	unverifiedFieldAgents: {
		data: UnverifiedFieldAgents[];
		meta: PaginationType;
	};

	getUnverifiedFieldAgents: {
		error: string | null;
		loading: boolean;
		success: boolean;
	};

	unverifiedProducts: {
		data: UnverifiedProducts[];
		meta: PaginationType;
	};

	getUnverifiedProductsState: {
		error: string | null;
		loading: boolean;
		success: boolean;
	};

	adminMembers: AdminMembers[];
	getAdminMembers: {
		error: string | null;
		loading: boolean;
		success: boolean;
	};
	adminOrders: {
		data: AdminOrders[];
		meta: PaginationType;
	};
	getAdminOrdersStatus: {
		error: string | null;
		loading: boolean;
		success: boolean;
	};
	getComplaintsStatus: {
		error: string | null;
		loading: boolean;
		success: boolean;
	};
	usersComplaints: ComplaintType[];
	preApprovedGrowForLifeProducts: PreApprovedProductType[];
	getPreApprovedProductsState: {
		error: string | null;
		loading: boolean;
		success: boolean;
	};

	approvingPreApprovedProductState: {
		error: string | null;
		loading: boolean;
		success: boolean;
	};

	adminGflProducts: {
		data: PreApprovedProductType[];
		meta: PaginationType;
	};

	getAdminGflProductState: {
		error: string | null;
		loading: boolean;
		success: boolean;
	};
}

const initialState: AdminReducerTypes = {
	isAuthenticated: false,
	currentAdmin: null,
	getAdminLogin: {
		error: null,
		loading: false,
		success: false,
	},
	collaborators: {
		data: [],
		meta: {
			totalCount: 0,
			currentPage: 0,
			itemsPerPage: 0,
			totalPages: 0,
			hasNextPage: false,
			hasPreviousPage: false,
		},
	},
	getAdminCollaborators: {
		error: null,
		loading: false,
		success: false,
	},
	collaborator: null,
	getCollaboratorById: {
		error: null,
		loading: false,
		success: false,
	},
	collaboratorProducts: {
		data: [],
		meta: {
			totalCount: 0,
			currentPage: 0,
			itemsPerPage: 0,
			totalPages: 0,
			hasNextPage: false,
			hasPreviousPage: false,
		},
	},
	getCollaboratorProducts: {
		error: null,
		loading: false,
		success: false,
	},
	adminGrowforlifeAnalytics: {
		averageSales: 0,
		totalCollaborators: 0,
		totalOrders: 0,
		totalRevenue: 0,
		totalUsers: 0,
	},
	getAdminGrowforlifeAnalytics: {
		error: null,
		loading: false,
		success: false,
	},

	recentCustomers: {
		data: [],
		meta: {
			totalCount: 0,
			currentPage: 0,
			itemsPerPage: 0,
			totalPages: 0,
			hasNextPage: false,
			hasPreviousPage: false,
		},
	},

	getRecentCustomers: {
		error: null,
		loading: false,
		success: false,
	},

	adminGrowforlifeTopProducts: [],
	getAdminTopProducts: {
		error: null,
		loading: false,
		success: false,
	},

	unverifiedFieldAgents: {
		data: [],
		meta: {
			totalCount: 0,
			currentPage: 0,
			itemsPerPage: 0,
			totalPages: 0,
			hasNextPage: false,
			hasPreviousPage: false,
		},
	},

	getUnverifiedFieldAgents: {
		error: null,
		loading: false,
		success: false,
	},

	unverifiedProducts: {
		data: [],
		meta: {
			totalCount: 0,
			currentPage: 0,
			itemsPerPage: 0,
			totalPages: 0,
			hasNextPage: false,
			hasPreviousPage: false,
		},
	},
	getUnverifiedProductsState: {
		error: null,
		loading: false,
		success: false,
	},

	adminMembers: [],
	getAdminMembers: {
		error: null,
		loading: false,
		success: false,
	},

	adminOrders: {
		data: [],
		meta: {
			totalCount: 0,
			currentPage: 0,
			itemsPerPage: 0,
			totalPages: 0,
			hasNextPage: false,
			hasPreviousPage: false,
		},
	},
	getAdminOrdersStatus: {
		error: null,
		loading: false,
		success: false,
	},

	getComplaintsStatus: {
		error: null,
		loading: false,
		success: false,
	},
	usersComplaints: [],
	preApprovedGrowForLifeProducts: [],
	getPreApprovedProductsState: {
		error: null,
		loading: false,
		success: false,
	},
	approvingPreApprovedProductState: {
		error: null,
		loading: false,
		success: false,
	},

	adminGflProducts: {
		data: [],
		meta: {
			totalCount: 0,
			currentPage: 0,
			itemsPerPage: 0,
			totalPages: 0,
			hasNextPage: false,
			hasPreviousPage: false,
		},
	},
	getAdminGflProductState: {
		error: null,
		loading: false,
		success: false,
	},
};

const adminSlice = createSlice({
	name: "admin",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		//Login admin
		builder
			.addCase(handleAdminLoginAction.pending, (state) => {
				state.getAdminLogin.loading = true;
			})
			.addCase(handleAdminLoginAction.fulfilled, (state, { payload }) => {
				state.getAdminLogin.loading = false;
				state.getAdminLogin.error = null;
				state.getAdminLogin.success = true;
				state.isAuthenticated = true;
				state.currentAdmin = payload.admin;
			})
			.addCase(handleAdminLoginAction.rejected, (state, { payload }) => {
				state.getAdminLogin.error = payload as string;
				state.getAdminLogin.success = false;
				state.getAdminLogin.loading = false;
				state.isAuthenticated = false;
			});

		//Fecth admin profile
		builder
			.addCase(fetchAdminProfileAction.pending, (state) => {
				state.getAdminLogin.loading = true;
			})
			.addCase(fetchAdminProfileAction.fulfilled, (state, { payload }) => {
				state.getAdminLogin.loading = false;
				state.getAdminLogin.error = null;
				state.getAdminLogin.success = true;
				state.isAuthenticated = true;
				state.currentAdmin = payload.admin;
			})
			.addCase(fetchAdminProfileAction.rejected, (state, { payload }) => {
				state.getAdminLogin.error = payload as string;
				state.getAdminLogin.success = false;
				state.getAdminLogin.loading = false;
				state.isAuthenticated = false;
			});

		//Get all collaborators
		builder
			.addCase(fetchAdminCollaboratorsAction.pending, (state) => {
				state.getAdminCollaborators.loading = true;
			})
			.addCase(
				fetchAdminCollaboratorsAction.fulfilled,
				(state, { payload }) => {
					state.getAdminCollaborators.loading = false;
					state.getAdminCollaborators.error = null;
					state.getAdminCollaborators.success = true;
					state.collaborators.data = payload.data;
					state.collaborators.meta = payload.meta;
				}
			)
			.addCase(
				fetchAdminCollaboratorsAction.rejected,
				(state, { payload }) => {
					state.getAdminCollaborators.error = payload as string;
					state.getAdminCollaborators.success = false;
					state.getAdminCollaborators.loading = false;
				}
			);

		//Get collaborator by id
		builder
			.addCase(fetchAdminCollaboratorByIdAction.pending, (state) => {
				state.getCollaboratorById.loading = true;
			})
			.addCase(
				fetchAdminCollaboratorByIdAction.fulfilled,
				(state, { payload }) => {
					state.getCollaboratorById.loading = false;
					state.getCollaboratorById.error = null;
					state.getCollaboratorById.success = true;
					state.collaborator = payload;
				}
			)
			.addCase(
				fetchAdminCollaboratorByIdAction.rejected,
				(state, { payload }) => {
					state.getCollaboratorById.error = payload as string;
					state.getCollaboratorById.success = false;
					state.getCollaboratorById.loading = false;
				}
			);

		//Get collaborator products
		builder
			.addCase(fetchAdminCollaboratorProductsAction.pending, (state) => {
				state.getCollaboratorProducts.loading = true;
			})
			.addCase(
				fetchAdminCollaboratorProductsAction.fulfilled,
				(state, { payload }) => {
					state.getCollaboratorProducts.loading = false;
					state.getCollaboratorProducts.error = null;
					state.getCollaboratorProducts.success = true;
					state.collaboratorProducts.data = payload.data;
					state.collaboratorProducts.meta = payload.meta;
				}
			)
			.addCase(
				fetchAdminCollaboratorProductsAction.rejected,
				(state, { payload }) => {
					state.getCollaboratorProducts.error = payload as string;
					state.getCollaboratorProducts.success = false;
					state.getCollaboratorProducts.loading = false;
				}
			);

		//Get admin growforlife analytics
		builder
			.addCase(fetchAdminGrowforlifeAnalyticsAction.pending, (state) => {
				state.getAdminGrowforlifeAnalytics.loading = true;
			})
			.addCase(
				fetchAdminGrowforlifeAnalyticsAction.fulfilled,
				(state, { payload }) => {
					state.getAdminGrowforlifeAnalytics.loading = false;
					state.getAdminGrowforlifeAnalytics.error = null;
					state.getAdminGrowforlifeAnalytics.success = true;
					state.adminGrowforlifeAnalytics = payload;
				}
			)
			.addCase(
				fetchAdminGrowforlifeAnalyticsAction.rejected,
				(state, { payload }) => {
					state.getAdminGrowforlifeAnalytics.error = payload as string;
					state.getAdminGrowforlifeAnalytics.success = false;
					state.getAdminGrowforlifeAnalytics.loading = false;
				}
			);

		//Get recent customers
		builder
			.addCase(fetchAdminRecentCustomersAction.pending, (state) => {
				state.getRecentCustomers.loading = true;
			})
			.addCase(
				fetchAdminRecentCustomersAction.fulfilled,
				(state, { payload }) => {
					state.getRecentCustomers.loading = false;
					state.getRecentCustomers.error = null;
					state.getRecentCustomers.success = true;
					state.recentCustomers.data = payload.data;
					state.recentCustomers.meta = payload.meta;
				}
			)
			.addCase(
				fetchAdminRecentCustomersAction.rejected,
				(state, { payload }) => {
					state.getRecentCustomers.error = payload as string;
					state.getRecentCustomers.success = false;
					state.getRecentCustomers.loading = false;
				}
			);

		//Get unverified field agents
		builder
			.addCase(fetchUnverifiedFieldAgentsAction.pending, (state) => {
				state.getUnverifiedFieldAgents.loading = true;
			})
			.addCase(
				fetchUnverifiedFieldAgentsAction.fulfilled,
				(state, { payload }) => {
					state.getUnverifiedFieldAgents.loading = false;
					state.getUnverifiedFieldAgents.error = null;
					state.getUnverifiedFieldAgents.success = true;
					state.unverifiedFieldAgents.data = payload.data;
					state.unverifiedFieldAgents.meta = payload.meta;
				}
			)
			.addCase(
				fetchUnverifiedFieldAgentsAction.rejected,
				(state, { payload }) => {
					state.getUnverifiedFieldAgents.error = payload as string;
					state.getUnverifiedFieldAgents.success = false;
					state.getUnverifiedFieldAgents.loading = false;
				}
			);

		//Get unverified field agents
		builder
			.addCase(fetchUnverifiedProductsAction.pending, (state) => {
				state.getUnverifiedProductsState.loading = true;
			})
			.addCase(
				fetchUnverifiedProductsAction.fulfilled,
				(state, { payload }) => {
					state.getUnverifiedProductsState.loading = false;
					state.getUnverifiedProductsState.error = null;
					state.getUnverifiedProductsState.success = true;
					state.unverifiedProducts.data = payload.data;
					state.unverifiedProducts.meta = payload.meta;
				}
			)
			.addCase(
				fetchUnverifiedProductsAction.rejected,
				(state, { payload }) => {
					state.getUnverifiedProductsState.error = payload as string;
					state.getUnverifiedProductsState.success = false;
					state.getUnverifiedProductsState.loading = false;
				}
			);

		//Get ADMIN MEMBERS
		builder
			.addCase(fetchAdminTeamMembersAction.pending, (state) => {
				state.getAdminMembers.loading = true;
			})
			.addCase(
				fetchAdminTeamMembersAction.fulfilled,
				(state, { payload }) => {
					state.getAdminMembers.loading = false;
					state.getAdminMembers.error = null;
					state.getAdminMembers.success = true;
					state.adminMembers = payload;
				}
			)
			.addCase(
				fetchAdminTeamMembersAction.rejected,
				(state, { payload }) => {
					state.getAdminMembers.error = payload as string;
					state.getAdminMembers.success = false;
					state.getAdminMembers.loading = false;
				}
			);

		//Get admin orders
		builder
			.addCase(fetchAdminOrdersAction.pending, (state) => {
				state.getAdminOrdersStatus.loading = true;
			})
			.addCase(fetchAdminOrdersAction.fulfilled, (state, { payload }) => {
				state.getAdminOrdersStatus.loading = false;
				state.getAdminOrdersStatus.error = null;
				state.getAdminOrdersStatus.success = true;
				state.adminOrders = payload;
			})
			.addCase(fetchAdminOrdersAction.rejected, (state, { payload }) => {
				state.getAdminOrdersStatus.loading = false;
				state.getAdminOrdersStatus.error = payload as string;
				state.getAdminOrdersStatus.success = false;
			});

		//get all user complaints
		builder
			.addCase(fetchAllUsersComplaintAction.pending, (state) => {
				state.getComplaintsStatus.loading = true;
			})
			.addCase(
				fetchAllUsersComplaintAction.fulfilled,
				(state, { payload }) => {
					state.getComplaintsStatus.loading = false;
					state.getComplaintsStatus.error = null;
					state.getComplaintsStatus.success = true;
					state.usersComplaints = payload;
				}
			)
			.addCase(
				fetchAllUsersComplaintAction.rejected,
				(state, { payload }) => {
					state.getComplaintsStatus.loading = false;
					state.getComplaintsStatus.error = payload as string;
					state.getComplaintsStatus.success = false;
				}
			);

		//Get pre approved products
		builder
			.addCase(fetchPreApprovedProductsAction.pending, (state) => {
				state.getPreApprovedProductsState.loading = true;
			})
			.addCase(
				fetchPreApprovedProductsAction.fulfilled,
				(state, { payload }) => {
					state.getPreApprovedProductsState.loading = false;
					state.getPreApprovedProductsState.error = null;
					state.getPreApprovedProductsState.success = true;
					state.preApprovedGrowForLifeProducts = payload;
				}
			)
			.addCase(
				fetchPreApprovedProductsAction.rejected,
				(state, { payload }) => {
					state.getPreApprovedProductsState.loading = false;
					state.getPreApprovedProductsState.error = payload as string;
					state.getPreApprovedProductsState.success = false;
				}
			);

		//Handle pre-approved product approval
		builder
			.addCase(handlePreApprovedProductsAction.pending, (state) => {
				state.approvingPreApprovedProductState.loading = true;
			})
			.addCase(
				handlePreApprovedProductsAction.fulfilled,
				(state, { payload }) => {
					state.approvingPreApprovedProductState.loading = false;
					state.approvingPreApprovedProductState.success = payload.success;
					state.approvingPreApprovedProductState.error = payload.error;
				}
			)
			.addCase(
				handlePreApprovedProductsAction.rejected,
				(state, { payload }) => {
					state.approvingPreApprovedProductState.loading = false;
					state.approvingPreApprovedProductState.error = payload as string;
					state.approvingPreApprovedProductState.success = false;
				}
			);

		//Get products on Grow for life
		builder
			.addCase(fetchGflProductsAdminAction.pending, (state) => {
				state.getAdminGflProductState.loading = true;
			})
			.addCase(
				fetchGflProductsAdminAction.fulfilled,
				(state, { payload }) => {
					state.getAdminGflProductState.loading = false;
					state.getAdminGflProductState.error = null;
					state.getAdminGflProductState.success = true;
					state.adminGflProducts = payload;
				}
			)
			.addCase(
				fetchGflProductsAdminAction.rejected,
				(state, { payload }) => {
					state.getAdminGflProductState.loading = false;
					state.getAdminGflProductState.error = payload as string;
					state.getAdminGflProductState.success = false;
				}
			);
	},
});

export const selectAdmin = (state: RootState) => state.admin;

export default adminSlice.reducer;
