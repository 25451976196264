import React from "react";
import WebLayout from "../components/organisms/layouts/WebLayout";
import HeroSection from "../components/organisms/pageSections/HomeSections/HeroSection";
import PartnerSection from "../components/organisms/pageSections/HomeSections/PartnerSection";
import ProductSection from "../components/organisms/pageSections/HomeSections/ProductSection";
import StriveSection from "../components/organisms/pageSections/HomeSections/StriveSection";

import CreateAccountSection from "../components/organisms/pageSections/HomeSections/CreateAccountSection";
import ServiceSection from "../components/organisms/pageSections/HomeSections/ServiceSection";

const HomeScreen = () => {
	return (
		<WebLayout>
			<HeroSection />
			<PartnerSection />
			<ProductSection />
			<StriveSection />
			<ServiceSection />
			<CreateAccountSection />
		</WebLayout>
	);
};

export default HomeScreen;
