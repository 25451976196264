import { useLoadingOverlay } from '../atoms/LoadingOverlay';
import { signInWithPopup } from 'firebase/auth';
import { toast } from 'react-toastify';
import { auth, googleProvider } from '../../firebase/firebase';
import { socialLoginAction } from '../../store/auth/authActions';
import { socialAuth } from '../../store/services/userServices';
import { GoogleIcon } from '../Icons';
import { useAppDispatch } from '../../store/hooks';
import { useNavigate } from 'react-router-dom';

const SocialAuthButtons = () => {
	const { openOverLay, closeOverLay } = useLoadingOverlay();
    const dispatch = useAppDispatch();
    const quickleapDelivers = localStorage.getItem("quickleapDelivers")
    ? //@ts-ignore
      JSON.parse(localStorage.getItem("quickleapDelivers"))
    : null;
    const redirectUrl = localStorage.getItem("quickleapRedirectUrl")
    ? //@ts-ignore
      JSON.parse(localStorage.getItem("quickleapRedirectUrl"))
    : null;
    const navigate = useNavigate();

    const loginUser = async (authDetails:any)=>{
        dispatch(socialLoginAction(authDetails.data));
        localStorage.setItem("quickleapUser", JSON.stringify(authDetails.data));
        toast.success("Successfully signed in");
  
        if (redirectUrl) {
          navigate(`${redirectUrl?.path}`);
          return;
        }
  
        if (!authDetails?.isCollaborator && quickleapDelivers) {
          navigate("/grow-for-life/home");
        } else if (!authDetails?.isCollaborator) {
          navigate("/grow-for-life");
        } else {
          navigate("/collaborator");
        }
    }
    
    const handleGoogleSignIn = async () => {
     
        openOverLay();
        try {
          const result: any = await signInWithPopup(auth, googleProvider);
          const authDetails = await socialAuth({ token: result?.user.accessToken });
           loginUser(authDetails)
        } catch (error) {
          toast.error("Google sign-in error");
        } finally {
          closeOverLay();
        }
      };
    
    //   const handleFacebookSignIn = async () => {
    //     openOverLay()
    //     try {
    //       const result:any = await signInWithPopup(auth, facebookProvider);
    //       const authDetails = await socialAuth({ token: result?.user.accessToken });
    //       loginUser(authDetails)
    //     } catch (error) {
    //         console.log(error)
    //       toast.error("Facebook sign-in error");
    //     }finally{
    //         closeOverLay()
    //     }
    //   };

  return (
    <section className="flex gap-5 items-center justify-center">
    <button
      type="button"
      className="flex gap-3 items-center border-2 font-semibold
     rounded-lg p-3 px-5"
      onClick={handleGoogleSignIn}
    >
      <GoogleIcon /> Google
    </button>
    {/* <button
      type="button"
      className="flex gap-3 items-center border-2 font-semibold
     rounded-lg p-3 px-5"
      onClick={handleFacebookSignIn}
    >
      <FacebookIcon />
      Facebook
    </button> */}
  </section>
  )
}

export default SocialAuthButtons
