import { create } from "zustand";

interface ItemProps {
	shareSlug: string | null;
    openShare: (arg:string) => void;
    closeShare: ()=> void
}

const useShareModal = create<ItemProps>((set) => ({
    shareSlug: null,
	openShare: (slug:string) => set({ shareSlug: slug }),
	closeShare: () => set({ shareSlug: null }),
}));

export default useShareModal;
