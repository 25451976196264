import React from "react";

const Container = ({
	children,
	className,
}: {
	children: React.ReactNode;
	className?: string;
}) => {
	return (
		<div className={`${className} w-full max-w-[1300px] mx-auto`}>
			{children}
		</div>
	);
};

export default Container;
