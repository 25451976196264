import React, { useEffect } from "react";
import Navbar from "../../molecules/Navbar";
import Footer from "../../molecules/Footer";
import ScrollToTop from "../../atoms/ScrollToTop";
import AllModals from "../../modal/AllModals";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { fetchUserLoggedInUserAction } from "../../../store/auth/authActions";
import { selectAuthUser } from "../../../store/auth/authReducer";
import MetaTags from "../MetaTags";

export default function WebLayout({ children }: { children: React.ReactNode }) {
	const dispatch = useAppDispatch();
	const { currentUser, getUserInfo } = useAppSelector(selectAuthUser);

	/* eslint-disable react-hooks/exhaustive-deps*/
	useEffect(() => {
		if (!currentUser) {
			dispatch(fetchUserLoggedInUserAction());
		}
	}, [currentUser]);

	console.log(window.location.href);

	if (getUserInfo.loading) return null;

	return (
		<ScrollToTop>
			<AllModals />
			<MetaTags
				title="Quick Leap"
				description="Good food for everyone, everywhere, everytime!"
				image="https://firebasestorage.googleapis.com/v0/b/quickleap-web.appspot.com/o/profile%2F01b6b69e-1bb1-4d6a-ab09-dc33194cceb4?alt=media&token=a725b207-bcbc-4893-b709-5bf2e7b0f151"
				name="Quick Leap"
			/>

			<div className="w-full h-full relative">
				<Navbar />
				<main className="w-full">{children}</main>
				<Footer />
			</div>
		</ScrollToTop>
	);
}
