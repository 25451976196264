import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getAllCategoryProducts,
  getAllProducts,
  getAllSubCategoryProducts,
  getBestSellingProducts,
  getGrowforLifePaymentHistory,
  getGrowforLifeProductReviews,
  getGrowforLifeShoppingListSubscriptions,
  getProductCategories,
  getProductDetails,
  getShoppingListProducts,
  getSuggestedProducts,
  getUserBudgetAgeRange,
  getUserFavouriteProducts,
} from "../services/growForLifeServices";

import { createAction } from "@reduxjs/toolkit";

export const toggleFavouriteProductsAction = createAction(
  "growforlife/toggleFavouriteProducts",
  (data) => {
    return {
      payload: data,
    }
  }
);

export const addProductToGrowForLifeShoppingListAction = createAction(
  "growforlife/addProductToShoppingList",
  (data) => {
    return {
      payload: data
    }
  }
);

export const deleteProductInShoppingListAction = createAction("growforlife/deleteProductInShoppingList", 
(id:string) => {
  return {
    payload: id,
  }
})

export const updateProductInShoppingListAction = createAction(
  "growforlife/updateProductInShoppingList", (quantity:number, id:string, price:string) => {
    return {
      payload:{
        quantity,
        id,
        price
      } 
    }
  }
);

export const clearShoppingListAction = createAction("growforlife/clearProductInShoppingList");

export const getGrowforLifeShoppingListSubscriptionsAction = createAsyncThunk(
  "grow-for-life/shopping-list-subscriptions",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getGrowforLifeShoppingListSubscriptions();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getGrowforLifePaymentHistoryAction = createAsyncThunk(
  "grow-for-life/payment-history",
  async (data: { limit: number; page: number }, { rejectWithValue }) => {
    try {
      const response = await getGrowforLifePaymentHistory(data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getGrowforLifeProductReviewsAction = createAsyncThunk<any, any>(
  "grow-for-life/product-reviews",
  async (productSlug, { rejectWithValue }) => {
    try {
      const response = await getGrowforLifeProductReviews(productSlug);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getGrowForLifeAllProductsAction = createAsyncThunk<any, any>(
  "grow-for-life/all-products",
  async (data: { limit: number; page: number, search?:string }, { rejectWithValue }) => {
    try {
      const response = await getAllProducts(data);
      if(data?.search){
        return {
          response,
          searchString: data.search
        }
      }
      return {
        response,
        searchString: ''
      };
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getGrowForLifeAllCategoryProducts = createAsyncThunk<any, any>(
  "grow-for-life/all-category-products",
  async (
    data: { limit: number; page: number; categorySlug: string, subCategorySlug:string },
    { rejectWithValue }
  ) => {
    try {
      const response = data.subCategorySlug !== '' ? 
      await getAllSubCategoryProducts(data) : await getAllCategoryProducts(data);
      return {
        data: response.data,
        queryInfo: {
          limit: data.limit,
          categorySlug: data.categorySlug,
          subCategorySlug: data.subCategorySlug
        }
      }
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getGrowForLifeAllSubCategoryProducts = createAsyncThunk<any, any>(
  "grow-for-life/all-sub-category-products",
  async (
    subCategorySlug,
    { rejectWithValue }
  ) => {
    try {
      const response = await getAllSubCategoryProducts(subCategorySlug);
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getGrowForLifeProductCategories = createAsyncThunk(
  "grow-for-life/get-categories",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getProductCategories();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getGrowForLifeSuggestedProducts = createAsyncThunk<any, any>(
  "grow-for-life/get-suggested-products",
  async(
    data: { limit: number; page: number; categorySlug: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await getSuggestedProducts(data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getGrowForLifeBestSellingProducts = createAsyncThunk<any, any>(
  "grow-for-life/get-best-selling-products",
  async(
    data: { limit: number; page: number; categorySlug: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await getBestSellingProducts(data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getGrowForLifeProductDetails = createAsyncThunk<any, any>(
  "grow-for-life/get-product",
  async (data, { rejectWithValue }) => {
    const { slug } = data;
    const product = await getProductDetails(slug);
    return product;
  }
);

export const getGrowForLifeUserFavouriteProducts = createAsyncThunk<any, any>(
  "grow-for-life/get-favourite-products",
  async (data: { limit: number; page: number;}, { rejectWithValue }) => {
    const product = await getUserFavouriteProducts(data);
    return product.data;
  }
);


export const getGrowforLifeBudgetAgeRangeAction = createAsyncThunk(
  "grow-for-life/get-budget-age-range",
  async (_, { rejectWithValue }) => {
    const data = await getUserBudgetAgeRange();
    return data.data;
  }
);


export const getShoppingListProductsAction = createAsyncThunk(
  "grow-for-life/shopping-list-subscription",
  async (_, { rejectWithValue }) => {
    try {
      const shoppingList = await getShoppingListProducts()
    return shoppingList.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  }
);



