import { VariantProps, cva } from "class-variance-authority";
import { ButtonHTMLAttributes, FC, forwardRef } from "react";
import { cn } from "../lib/utils";
import { RiLoader4Fill } from "react-icons/ri";

export const buttonVariants = cva(
	"active:scale-95 inline-flex items-center justify-center, rounded-md disabled:opacity-70",
	{
		variants: {
			variant: {
				default: "bg-slate-900 text-white",
			},
			size: {
				default: "h-10 px-4 py-3",
				small: "py-3 px-5",
				medium: "h-10 py-5 px-4",
			},
		},
		defaultVariants: {
			variant: "default",
			size: "default",
		},
	}
);

interface ButtonProps
	extends ButtonHTMLAttributes<HTMLButtonElement>,
		VariantProps<typeof buttonVariants> {
	isLoading?: boolean;
}

const Button: FC<ButtonProps> = forwardRef<HTMLButtonElement, ButtonProps>(
	({ className, children, variant, isLoading, size, ...props }, ref) => {
		return (
			<button
				className={cn(
					buttonVariants({ size, variant, className }),
					"py-6 md:py-4"
				)}
				ref={ref}
				disabled={isLoading}
				{...props}
			>
				{isLoading ? (
					<RiLoader4Fill className="mr-2 h-4 w-4 animate-spin" />
				) : null}
				{children}
			</button>
		);
	}
);

Button.displayName = " button";

export default Button;
