import WebLayout from "../components/organisms/layouts/WebLayout";
import Container from "../components/organisms/Container";
import GetStartedCta from "../components/molecules/GetStartedCta";

const AboutScreen = () => {
  const team = [
    {
      name: 'Damion Udoh',
      imageUrl: '/assets/images/ceo.png',
      role: 'Co-founder and chief executive officer'
    },
    {
      name: 'Bezaleel Nwabia',
      imageUrl: '/assets/about/bezaleel.png',
      role: 'Chief Technology Officer'
    },
    {
      name: 'Yakubu Danjuma',
      imageUrl: '/assets/about/yakubu_danjuma.png',
      role: 'Field Operations'
    },
    {
      name: 'Stephen Agbayinta',
      imageUrl: '/assets/about/stephen_agbayinta.png',
      role: 'Trade Success'
    },
    {
      name: 'Joshua Magani',
      imageUrl: '/assets/about/joshua_magani.png',
      role: 'Engineering'
    },
    {
      name: 'Ezi-umeonu Menuchim',
      imageUrl: '/assets/about/menuchim.jpg',
      role: 'Engineering'
    },
    {
      name: 'Joshua Ogundele',
      imageUrl: '/assets/about/joshua_ogundele.png',
      role: 'Design'
    },

    // {
    //   name: 'Samuel Laolu Kolawole',
    //   imageUrl: '/assets/about/samuel_laolu.jpg',
    //   role: 'Research & Innovations'
    // },

    {
      name: 'Ruth Okoduwa',
      imageUrl: '/assets/about/ruth_okoduwa.png',
      role: 'People and Products'
    },

    {
      name: 'Adeleye Oluwasegun',
      imageUrl: '/assets/about/adeleye_oluwasegun.png',
      role: 'Markets and Products'
    },

    {
      name: 'Glory Nnedinma',
      imageUrl: '/assets/about/glory_nnedinma.png',
      role: 'Customer Success'
    },

  ]

  const board = [
    {
      name: 'Dr. Toluwanimi Osinowo',
      imageUrl: '/assets/about/dr_toluwanimi.png',
      role: 'Founder and Chief executive CANTAB Associates'
    },
    {
      name: 'Ayo Arikawe',
      imageUrl: '/assets/about/ayo_arikawe.png',
      role: 'Co-founder & CTO, Thrive Agric'
    },
    {
      name: 'Sosthene Oluwaseun',
      imageUrl: '/assets/about/sosthene_oluwasegun.png',
      role: 'Managing Director, Aella MFB'
    },
    {
      name: 'Tomi Osho',
      imageUrl: '/assets/about/tomi_osho.png',
      role: 'Head of Business development West & Central Africa Honeywell UOP'
    },
  ]
  return (
    <WebLayout>
      <div className="bg-green-light py-12">
        <Container className="px-5">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
            <section className="flex items-center justify-center md:order-last">
              <img
                className="object-contain w-full rounded-xl"
                src="/assets/images/world-food.png"
                alt="world-food"
              />
            </section>

            <section className="space-y-10">
              <article>
                <div>
                  <h2 className="text-green pt-2 font-bold text-3xl md:text-4xl border-t-[5px] mb-4 border-green inline-block">
                    What we do
                  </h2>
                  <p className="text-ash">
                    At our core we are daily promoting life from infancy to
                    adulthood by providing all of the food you’ll need to live
                    healthy and thrive, connecting trades, enabling businesses
                    by providing qualiity raw materials at scale for all of
                    their raw material needs, and transforming communities.
                    <br />
                    <br />
                    Our goal is to provide the food and raw materials that
                    promotes life and powers the productivity of people and
                    businesses around the world.
                    <br/>
                    <br/>
                    We exist to provide good food for everyone, everywhere, every time.
                  </p>
                </div>
              </article>

              <article>
                <div>
                  <h2 className="text-green pt-2 font-bold text-3xl md:text-4xl border-t-[5px] mb-4 border-green inline-block">
                    How do we do this ?
                  </h2>
                  <p className="text-ash">
                    We are always asking ourselves the question: “How do we feed
                    people” <br />
                    <br />
                    Our answer is simple, a true system where every individual
                    part is working effortlessly to promote the life of the
                    individual members and the whole system, from Quick Leap
                    primary production, to the small holder farmers whose
                    produce are on the market place, to the traders, logistics
                    providers, and the businesses who buy and sell produce and
                    products with us, every part working seemlessly to provide
                    food of the best quality, and of various varieties.
                  </p>
                </div>
              </article>
            </section>
          </div>

          <div className="my-32">
            <h2 className="text-center text-4xl  text-green font-bold">
              Our values
            </h2>
            <p className="text-center mt-4">
              Our values keep us connected and guide us as one team.
            </p>

            <article className="grid grid-cols-1 md:grid-cols-3 gap-10 mt-8 mx-auto">
              <div className="rounded-xl overflow-hidden bg-white shadow-md shadow-[rgba(130, 130, 130, 0.25)]">
                <img
                  src="assets/images/heart-face.png"
                  className="h-[15rem] block bg-[#F6E4FF] object-cover w-full"
                  alt="heart"
                />
                <div className="p-8 space-y-3">
                  <h4 className="text-2xl font-bold text-green">Love</h4>
                  <p className="text-ash">
                    Understand what matters the most to you, the individual we
                    serve, to our collaborators and to the people of Quick Leap,
                    and serve you with an intention so pure it captures your
                    heart & delivers to you joy, beauty & life at its fullest.
                  </p>
                </div>
              </div>

              <div className="rounded-xl overflow-hidden bg-white shadow-md shadow-[rgba(130, 130, 130, 0.25)]">
                <img
                  src="assets/images/people-values.png"
                  className="h-[15rem] block bg-[#FFF1F1] object-cover w-full"
                  alt="people values"
                />
                <div className="p-8 space-y-3">
                  <h4 className="text-2xl font-bold text-green">People</h4>
                  <p className="text-ash">
                    You, yes you are the greatest value we hold. We delight in
                    serving good to YOU! To you our users, to our collaborators,
                    and to our teams.
                  </p>
                </div>
              </div>

              <div className="rounded-xl overflow-hidden bg-white shadow-md shadow-[rgba(130, 130, 130, 0.25)]">
                <img
                  src="assets/images/collaboration-value.png"
                  className="h-[15rem] block bg-[#C2E2FF] object-cover w-full"
                  alt="collaboration value"
                />
                <div className="p-8 space-y-3">
                  <h4 className="text-2xl font-bold text-green">
                    Collaboration
                  </h4>
                  <p className="text-ash">
                    Together we can and we will feed the world, since we believe
                    this we have created an ecosystems where all the individual
                    parts give and receive good things from one another creating
                    high traffic of good things for everyone, everywhere, every
                    time
                  </p>
                </div>
              </div>
            </article>
          </div>

          <div className="my-32">
            <h2 className="text-center text-4xl  text-green font-bold">
              Meet our team
            </h2>
            <p className="text-center mt-4 max-w-[35rem] text-ash mx-auto">
            Our philosophy is simple — hire a team of diverse, passionate people and foster a 
            culture that empowers you to do your best work.
            </p>

            <article className="grid grid-cols-2 md:grid-cols-3 mt-20 lg:grid-cols-4 gap-10">
              {team.map((member, index) => (
                <div
                  key={index}
                  className="flex flex-col items-center gap-0.5 text-center"
                >
                  <img
                    src={member.imageUrl}
                    className="object-cover object-top w-[85%] aspect-square rounded-full"
                    alt={member.name}
                  />
                  <h5 className="font-bold text-lg text-green mt-3">
                    {member.name}
                  </h5>
                  <p className="text-ash  text-sm md:text-base">
                  {member.role}
                  </p>
                </div>
              ))}
            </article>
          </div>

          <div className="my-32">
            <h2 className="text-center text-4xl text-green font-bold">
              Board and advisory team
            </h2>

            <p className="text-center mt-4 max-w-[35rem] text-ash mx-auto">
            Our advisers are a guiding light, these are individual of impeachable 
            character with decades of practical experience working across different industries across the globe.
            </p>

          <article className="grid grid-cols-2 md:grid-cols-3 my-10 lg:grid-cols-4 gap-10">
              {board.map((member, index) => (
                <div
                  key={index}
                  className="flex flex-col items-center gap-0.5 text-center"
                >
                  <img
                    src={member.imageUrl}
                    className="object-cover w-[85%] object-top aspect-square rounded-full"
                    alt={member.name}
                  />
                  <h5 className="font-bold text-lg text-green mt-3">
                    {member.name}
                  </h5>
                  <p className="text-ash  text-sm md:text-base">
                  {member.role}
                  </p>
                </div>
              ))}
            </article>
          </div>

       
				<GetStartedCta />

        </Container>
      </div>
    </WebLayout>
  );
};

export default AboutScreen;