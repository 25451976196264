import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "./auth/authReducer";
import growForLifeReducer from "./grow-for-life/growForLifeReducer";
import collaboratorReducer from "./collaborator/reducer";
import profileReducer from "./profile/reducer";
import fieldAgent from "./field-agent/reducer";
import adminReducer from "./admin/reducer";

export default combineReducers({
	auth: authReducer,
	growForLife: growForLifeReducer,
	collaborator: collaboratorReducer,
	profile: profileReducer,
	fieldAgent: fieldAgent,
	admin: adminReducer,
});
