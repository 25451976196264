import { FC, useState } from "react";
import { ErrorMessage, Field } from "formik";
import { IoIosArrowDown } from "react-icons/io";
import { useOutsideClick } from "../../helpers/clickOutside";

interface IProps {
  id: string;
  name: string;
  options: {
    label: string;
    value: string;
  }[];
  label: string;
  onChange: (event: any) => void;
  placeholder?: string;
}

const FormSelectDropDown: FC<IProps> = ({
  id,
  name,
  options,
  label,
  onChange,
  placeholder,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const dropdownRef = useOutsideClick(() => {
    setIsOpen(false);
  });

  return (
    <div className="w-full flex flex-col items-start">
      {label && (
        <label
          htmlFor={name}
          className="font-medium text-[#111111] text-[.9rem]"
        >
          {label}
        </label>
      )}
      <Field id={id} name={name}>
        {({ field, form, meta }: { field: any; form: any; meta: any }) => {
          // console.log(meta.touched, meta.error)
          return (
            <>
              <div
                ref={dropdownRef}
                className={`${
                  isOpen ? "custom-select active" : "custom-select"
                } mt-1 w-full`}
                id="custom-select"
              >
                <button
                  type="button"
                  aria-haspopup="listbox"
                  aria-controls="select-dropdown"
                  {...field}
                  className={`select-button rounded-md h-[2.8rem] ${
                    meta.touched && meta.error
                      ? "ring-2 ring-red-400 focus:ring-red-400"
                      : "border-[#9F9F9F] hover:border-green focus:border-green"
                  }`}
                  onClick={() => {
                    setIsOpen(!isOpen);
                  }}
                >
                  {field?.value ? (
                    <p className="text-[#6C6C6C] text-sm font-medium capitalize">
                      {
                        options.find((option) => option.value === field.value)
                          ?.label
                      }
                    </p>
                  ) : (
                    <p className="font-normal text-sm text-[#A1A7AD]">
                      {placeholder ?? ""}
                    </p>
                  )}
                  <span className="arrow">
                    <IoIosArrowDown />
                  </span>
                </button>
                <ul
                  role="listbox"
                  className="select-dropdown rounded-md gap-y-1"
                  id="select-dropdown"
                >
                  {options?.map((item: { value: string; label: string }) => (
                    <li
                      key={item.value}
                      onClick={() => {
                        onChange(item.value);
                        setIsOpen(false);
                      }}
                    >
                      <input type="radio" id={item.label} name={name} />
                      <label htmlFor={item.label}>{item.label}</label>
                    </li>
                  ))}
                </ul>
              </div>
            </>
          );
        }}
      </Field>

      <span className="text-red-600 text-xs font-medium w-full h-2 my-1">
        <ErrorMessage name={name} />
      </span>
    </div>
  );
};

export default FormSelectDropDown;
