import React, { useEffect, useState } from "react";
import { Link, Element, Events, scrollSpy } from "react-scroll";
import SectionHeader from "../../../molecules/SectionHeader";

const PolicySection = () => {
	const [activeTab, setActiveTab] = useState<string>("introduction");

	const sideTabs: { label: string; value: string }[] = [
		{ label: "Introduction", value: "introduction" },
		{ label: "Information we obtain", value: "information-we-obtain" },
		{
			label: "How we use the information we obtain",
			value: "use-information-obtain",
		},
		{ label: "	Information we share", value: "information-we-share" },
		{ label: "Your rights & choices", value: "rights-choices" },
		{ label: "Children's privacy", value: "children" },
		{
			label: "Links to third-party services & features",
			value: "third-party-service",
		},
		{ label: "How we protect your data", value: "data-protection" },
		{ label: "Where we store your personal data", value: "personal-data" },
		{ label: "Your right as a data subject", value: "data-subject" },
		{ label: "Violation of privacy", value: "privacy-violation" },
		{ label: "Marketing", value: "marketing" },
		{
			label: "Updates to our privacy statement",
			value: "updates-to-privacy",
		},

		{
			label: "Payment processors and partners",
			value: "payment-processor",
		},
		{
			label: "How to contact us",
			value: "contact-us",
		},
	];

	useEffect(() => {
		// Registering the 'begin' event and logging it to the console when triggered.
		Events.scrollEvent.register("begin", (to, element) => {
			console.log("begin", to, element);
		});

		// Registering the 'end' event and logging it to the console when triggered.
		Events.scrollEvent.register("end", (to, element) => {
			console.log("end", to, element);
		});

		// Updating scrollSpy when the component mounts.
		scrollSpy.update();

		// Returning a cleanup function to remove the registered events when the component unmounts.
		return () => {
			Events.scrollEvent.remove("begin");
			Events.scrollEvent.remove("end");
		};
	}, []);
	return (
		<div className="relative w-full min-h-[90vh] max-h-[90vh]">
			<div className="absolute left-0 top-0 hidden xl:w-[200px] 2xl:w-[250px] show-scrollbar border-r bg-white border-gray-100 overflow-y-scroll h-[100dvh] xl:flex items-start justify-start text-left flex-col gap-7 p-2">
				{sideTabs.map(
					(tab: { label: string; value: string }, indx: number) => (
						<Link
							to={`${tab.value}`}
							key={tab.value}
							smooth={true}
							duration={600}
							containerId="containerElement"
						>
							<div
								className={`cursor-pointer ${
									activeTab === tab.value
										? "text-[#118507] "
										: "text-black"
								}`}
								onClick={() => {
									setActiveTab(tab.value);
								}}
							>
								{tab.label}
							</div>
						</Link>
					)
				)}
			</div>
			<div
				className="absolute right-0 top-0 h-full w-full xl:w-[calc(100%-200px)] 2xl:w-[calc(100%-250px)] max-h-[100dvh] overflow-y-scroll bg-white py-2 xl:px-5 2xl:px-8 gap-1"
				id="containerElement"
			>
				<Element name="introduction">
					<div className="min-h-[100px] pb-5 pt-10">
						<div className="w-full flex flex-col items-center justify-center gap-3 text-center">
							<h2 className="text-3xl md:text-4xl xl:text-5xl text-black font-semibold">
								Privacy Policy
							</h2>
							<p className="text-sm text-gray-300 font-normal">
								Last Updated: November 8th, 2022
							</p>
						</div>
						<p className="text-black text-sm font-normal mt-5">
							At Quick Leap ("Quick Leap," "we," "us," or "our") we
							respect your concerns about privacy as we understand that
							your privacy is important. We respect and value the privacy
							of everyone who visits{" "}
							<strong className="text-green font-normal">
								www.quickleap.co
							</strong>
							(the “Site”)
						</p>
						<p className="text-black text-sm font-normal mt-5">
							The Site is owned and operated by Quick Leap Global
							Limited, a company registered in Nigeria with offices at No
							3, Ayinde Sanni close, Oregun, Ikeja, Lagos State, Nigeria
							("Quick Leap Global Limited ", or “Quick Leap” "we", "us"
							or "our"). For the purposes of the Nigerian Data Protection
							Regulation 2019, we are the Data Controller which means
							that we are responsible for determining the purposes for
							which and means of how your personal information (“Personal
							Data”) is processed.
						</p>
						<p className="text-black text-sm font-normal mt-5">
							Quick Leap takes its responsibilities as regards to the
							management of Personal Data very seriously and we do not
							take lightly the trust you have reposed in us, in providing
							us with your personal information.
						</p>
						<p className="text-black text-sm font-normal mt-5">
							This Privacy Statement describes the types of personal
							information we collect, how we use the information, with
							whom we may share it and the choices available to you
							regarding our use of the information. We also describe
							measures we take to protect the security of the information
							and how you can contact us about our privacy practices, but
							it may not address all possible data processing scenarios.
							This Policy is a guide to help you understand the
							following:
						</p>
						<p className="text-black text-sm font-normal mt-8">
							Click on one of the links below to jump to the listed
							section:
						</p>

						<ol type="1" className="gap-y-[5px] mt-5 flex flex-col">
							{sideTabs.slice(1).map((tab, index) => (
								<li
									className={`cursor-pointer text-[#004687] text-sm font-normal`}
									onClick={() => {
										setActiveTab(tab.value);
									}}
								>
									{index + 1}.{" "}
									<Link
										to={`${tab.value}`}
										key={tab.value}
										smooth={true}
										duration={600}
										containerId="containerElement"
									>
										{tab.label}
									</Link>
								</li>
							))}
						</ol>
					</div>
				</Element>
				<Element name="information-we-obtain">
					<div className="min-h-[100px] pb-5 pt-10">
						<SectionHeader
							pageHeader="Information we obtain"
							pageNumber={1}
						/>

						<p className="text-black text-sm font-normal mt-5">
							We obtain personal information about you in various ways,
							such as when you visit our website ( the "Service"). The
							types of personal information we may obtain include:
						</p>
						<ol type="1" className="mt-2 pl-2 space-y-2">
							<li className="text-black text-sm font-normal flex items-start justify-start gap-1">
								1.{" "}
								<p>
									Contact information, such as name, telephone number
									and postal and email address;
								</p>
							</li>
							<li className="text-black text-sm font-normal flex items-start justify-start gap-1">
								2.{" "}
								<p>
									Account information, such as login information,
									account preferences and other account details;
								</p>
							</li>
							<li className="text-black text-sm font-normal flex items-start justify-start gap-1">
								3.{" "}
								<p>
									Payment information, including name, billing and
									delivery address, and payment card details;
								</p>
							</li>
							<li className="text-black text-sm font-normal flex items-start justify-start gap-1">
								4.{" "}
								<p>
									Transaction and information, such as purchase
									history;
								</p>
							</li>
							<li className="text-black text-sm font-normal flex items-start justify-start gap-1">
								5.{" "}
								<p>
									Demographic information and interests, such as
									household income and shopping preferences;
								</p>
							</li>
							<li className="text-black text-sm font-normal flex items-start justify-start gap-1">
								6. <p>Geolocation information;</p>
							</li>
							<li className="text-black text-sm font-normal flex items-start justify-start gap-1">
								7.{" "}
								<p>
									If you use a third party  login to create your Quick
									Leap  account (such as Facebook or Google) or
									otherwise link to us, we may receive certain
									information about you from the third party (such as a
									social network). This Privacy Statement does not
									cover the privacy and security practices of these
									social media sites;
								</p>
							</li>
							<li className="text-black text-sm font-normal flex items-start justify-start gap-1">
								8.{" "}
								<p>
									Information you provide when you interact with our
									social media pages, such as, Facebook, Instagram,
									Twitter and YouTube, which may include your name,
									profile picture, social media handles, email address,
									gender, age group, networks, friends list, language,
									birthday, education, work history, interest and
									likes. In some instances, we may communicate with you
									through these social media pages and may collect the
									information that you provide in response to such
									communications. We may combine this information with
									the information we collect from and about you; and
								</p>
							</li>
							<li className="text-black text-sm font-normal flex items-start justify-start gap-1">
								9.{" "}
								<p>
									Other information you choose to provide, such as
									through emails or other communications, the chat
									feature on the site, surveys, registrations and
									sign-up forms.
								</p>
							</li>
						</ol>

						<p className="text-black text-sm font-normal mt-5">
							When you use our Services or open our emails, we may obtain
							certain information by automated means, such as cookies,
							pixel tags, server or device logs and other technologies. A
							"cookie" is a text file that websites send to a visitor's
							device to uniquely identify the visitor's browser or to
							store information or settings in the browser. A "pixel tag"
							(also known as a web beacon), which is a type of technology
							that is often used in combination with cookies, is placed
							on a website or within an email to track certain activity,
							such as views of a website or when an email is opened.
						</p>
						<p className="text-black text-sm font-normal mt-5">
							We may use these automated technologies on the Services to
							collect information about your device, browsing actions,
							and usage patterns. We obtain information about your device
							and web browser in this manner such as your device IP
							address, general location information, unique device
							identifiers, device type and model, device characteristics
							and settings, browser settings and characteristics,
							operating system type and version, language and country
							preferences, battery and signal strength, usage statistics,
							referring emails and web addresses, other behavioural and
							technical data attributes, and other application details. 
						</p>
						<p className="text-black text-sm font-normal mt-5">
							We also may obtain information about your interactions with
							or navigation of our Services, such as pages visited, links
							clicked, features used, dates and times of usage, session
							information, actions you take on our Services, and other
							information about your use of our Services.
						</p>
						<p className="text-black text-sm font-normal mt-5">
							Your browser may tell you how to be notified about certain
							types of automated collection technologies and how to
							restrict or disable them. Please note, however, that
							without these technologies, you may not be able to use all
							of the features of the Services. For mobile devices, you
							can manage how your device and browser share certain device
							data by adjusting the privacy and security settings on your
							mobile device.
						</p>
						<p className="text-black text-sm font-normal">
							In addition, we may collect your device's geolocation
							information through GPS, Bluetooth, WiFi signals and other
							technologies. Your device's operating platform may provide
							you with a notification when the app attempts to collect
							your precise geolocation. Please note that if you decline
							to allow the app to collect your precise geolocation, you
							may not be able to use all of the Services' features or the
							offers available through the Services.
						</p>
					</div>
				</Element>
				<Element name="use-information-obtain">
					<div className="min-h-[100px] pb-5 pt-10">
						<SectionHeader
							pageHeader="How we use the information we obtain"
							pageNumber={2}
						/>{" "}
						<p className="text-black text-sm font-normal mt-5">
							We may use the personal information we obtain to:
						</p>
						<ol className="mt-2 pl-2 space-y-2">
							<li className="text-black text-sm font-normal flex items-start justify-start gap-1">
								1.{" "}
								<p>
									Provide our products and services and manage your
									accounts;
								</p>
							</li>
							<li className="text-black text-sm font-normal flex items-start justify-start gap-1">
								2.{" "}
								<p>
									Process and fulfil orders, including payment
									transactions;
								</p>
							</li>
							<li className="text-black text-sm font-normal flex items-start justify-start gap-1">
								3. <p>Manage delivery services;</p>
							</li>
							<li className="text-black text-sm font-normal flex items-start justify-start gap-1">
								4. <p>Facilitate gift card purchases;</p>
							</li>
							<li className="text-black text-sm font-normal flex items-start justify-start gap-1">
								5.{" "}
								<p>
									Communicate with you (including to send newsletters,
									emails or other marketing communications), respond to
									inquiries and offer customer support;
								</p>
							</li>
							<li className="text-black text-sm font-normal flex items-start justify-start gap-1">
								6.{" "}
								<p>
									Personalise your experience with our products and
									services and tailor our communications to you;
								</p>
							</li>
							<li className="text-black text-sm font-normal flex items-start justify-start gap-1">
								7.{" "}
								<p>
									Performing statistical analyses of our products and
									services;
								</p>
							</li>
							<li className="text-black text-sm font-normal flex items-start justify-start gap-1">
								8.{" "}
								<p>Advertise and market our products and services;</p>
							</li>
							<li className="text-black text-sm font-normal flex items-start justify-start gap-1">
								9. <p>Offer coupons, promotions or other discounts;</p>
							</li>
							<li className="text-black text-sm font-normal flex items-start justify-start gap-1">
								10.{" "}
								<p>
									Administer participation in surveys, sweepstakes,
									promotions or other programs;
								</p>
							</li>
							<li className="text-black text-sm font-normal flex items-start justify-start gap-1">
								11.{" "}
								<p>
									Perform analytics and market, trend or statistical
									research;
								</p>
							</li>
							<li className="text-black text-sm font-normal flex items-start justify-start gap-1">
								12.{" "}
								<p>
									Compile, anonymize or aggregate personal data for our
									business purposes;
								</p>
							</li>
							<li className="text-black text-sm font-normal flex items-start justify-start gap-1">
								13.{" "}
								<p>
									Operate, evaluate and improve our business (including
									improving the Services; improving and developing new
									products and services; improving and analysing our
									products and services; managing our communications;
									and performing accounting, auditing and other
									internal functions);
								</p>
							</li>
							<li className="text-black text-sm font-normal flex items-start justify-start gap-1">
								14.{" "}
								<p>
									Maintain and enhance the safety and security of our
									products and services, prevent misuse and
									troubleshoot technical issues, including by
									detecting, preventing and addressing technical issues
									and misuse of the services;
								</p>
							</li>
							<li className="text-black text-sm font-normal flex items-start justify-start gap-1">
								15.{" "}
								<p>
									Verify your identity and protect against theft, fraud
									and other criminal activity, claims and other
									liabilities;
								</p>
							</li>
							<li className="text-black text-sm font-normal flex items-start justify-start gap-1">
								16.{" "}
								<p>
									Exercise our rights and remedies and defend against
									legal claims and resolve disputes; and
								</p>
							</li>
							<li className="text-black text-sm font-normal flex items-start justify-start gap-1">
								17.{" "}
								<p>
									Comply with and enforce applicable legal
									requirements, relevant industry standards and Quick
									Leap  policies, including the program's{" "}
									<strong className="font-normal text-sm text-green">
										Terms & Conditions
									</strong>
								</p>
							</li>
						</ol>
						<p className="text-black text-sm font-normal my-5">
							We also may use the information in other ways for which we
							provide specific notice at the time of collection.
						</p>
						<SectionHeader pageHeader="Third-Party Analytics Services" />{" "}
						<p className="text-black text-sm font-normal mt-5">
							We may use third-party analytics services on the site, such
							as Google Analytics. The providers of these analytics
							services use technologies such as cookies and web beacons
							to help us analyse your use of the Services. The
							information collected through these means may be disclosed
							to or collected directly by these services. To learn more
							about Google Analytics, please visit
							<strong className="text-sm font-normal text-green">
								https://www.google.com/policies/privacy/partners/
							</strong>
							.
						</p>
					</div>
				</Element>
				<Element name="information-we-share">
					<div className="min-h-[100px] pb-5 pt-10">
						<SectionHeader
							pageHeader="Information We Share"
							pageNumber={3}
						/>
						<p className="text-black text-sm font-normal mt-5">
							We may share all categories of personal information we
							obtain about you with certain third parties as described in
							this Privacy Statement or as otherwise described to you at
							the point of collection. We also may share personal
							information we obtain about you at your request.
						</p>
						<p className="text-black text-sm font-normal mt-5">
							We may share information we obtain about you with third
							parties that perform services on our behalf. Examples of
							these third parties include entities that assist us in:
						</p>
						<ul className="mt-2 pl-5 space-y-1 list-disc">
							<li>
								<p className="text-black text-sm font-normal flex items-start justify-start">
									Administering our rewards program;
								</p>
							</li>
							<li>
								<p className="text-black text-sm font-normal flex items-start justify-start">
									Responding to your requests;
								</p>
							</li>
							<li>
								<p className="text-black text-sm font-normal flex items-start justify-start">
									Delivering customised promotions and advertising to
									you, which may include the third parties combining
									the personal information they capture with other
									information they maintain;
								</p>
							</li>
							<li>
								<p className="text-black text-sm font-normal flex items-start justify-start">
									Performing statistical analyses of our services,
									including through session replay; and
								</p>
							</li>
							<li>
								<p className="text-black text-sm font-normal flex items-start justify-start">
									Providing customer support (including through live
									chat features).
								</p>
							</li>
						</ul>

						<p className="text-black text-sm font-normal mt-5">
							We may share the information we obtain about you with our
							select partners, affiliates, and other third parties that
							we believe may have offers of interest to you. We may also
							share your personal information with social media platforms
							if you use those services to connect with us.
						</p>

						<p className="text-black text-sm font-normal mt-5">
							We may disclose personal information (1) if we are required
							to do so by law or legal process, such as a court order or
							subpoena; (2) in response to requests by government
							agencies, such as law enforcement authorities; (3) to
							establish, exercise or defend our legal rights; (4) when we
							believe disclosure is necessary or appropriate to prevent
							physical or other harm or financial loss; (5) in connection
							with an investigation of suspected or actual illegal
							activity or (6) otherwise with your consent or as directed
							by your representative.
						</p>

						<p className="text-black text-sm font-normal mt-5">
							In addition, we reserve the right to transfer to relevant
							third parties the personal information we have about you in
							the event of a potential or actual sale or transfer of all
							or a portion of our business or assets (including in the
							event of a merger, acquisition, joint venture,
							reorganisation, divestiture, dissolution, or liquidation),
							or other business transaction. Should such a sale or
							transfer occur, we will use reasonable efforts to try to
							require that the transferee use personal information
							provided through the Services in a manner that is
							consistent with this Privacy Statement.
						</p>
					</div>
				</Element>
				<Element name="rights-choices">
					<div className="min-h-[100px] pb-5 pt-10">
						<SectionHeader
							pageHeader="Your Rights and Choices"
							pageNumber={4}
						/>
						<p className="text-black text-sm font-normal mt-5">
							We offer you certain choices in connection with the
							personal information we collect from you. To update your
							preferences, limit the communications you receive from us
							(such as product information, product samples, or
							promotional mailings/emails), or submit a request, please
							contact us as indicated in the{" "}
							<strong className="font-normal text-sm text-green underline">
								How To Contact Us
							</strong>{" "}
							section of this Privacy Statement. In addition, users of
							the Services are given the opportunity to opt out of having
							their information used for purposes not directly related to
							placement, processing, fulfilment or delivery of a product
							order at the point where we ask for the information. Also,
							if any of your personal information changes, or if you no
							longer desire to use the Services, we can correct, update,
							or remove the personal information provided to us. This can
							be done by sending an email with your new information to
							service@freshdirect.com, and specifying the information to
							be corrected, updated, or removed. Please do not send
							credit or debit card information via email. You also may
							update certain of your account details and settings by
							logging into your account on the Services. You can also
							unsubscribe from our marketing mailing lists by following
							the "Unsubscribe" link in our emails.
						</p>
					</div>
				</Element>
				<Element name="children">
					<div className="min-h-[100px] pb-5 pt-10">
						{" "}
						<SectionHeader
							pageHeader="Children's Privacy"
							pageNumber={5}
						/>
						<p className="text-black text-sm font-normal mt-5">
							The Services are designed for a general audience and are
							not directed to children. In connection with the Services,
							we do not knowingly solicit or collect personal information
							from children under the age of 13 without parental consent.
							If we learn that we have collected personal information
							from a child under age 13 without parental consent, we will
							either seek parental consent or promptly delete that
							information. If you believe that a child under age 13 may
							have provided us with personal information without parental
							consent, please contact us as specified in the How To
							Contact Us section of this Privacy Statement.
						</p>
					</div>
				</Element>
				<Element name="third-party-service">
					<div className="min-h-[100px] pb-5 pt-10">
						<SectionHeader
							pageHeader="Links to Third-Party Services and Features"
							pageNumber={6}
						/>
						<p className="text-black text-sm font-normal mt-5">
							For your convenience and information, the Services may
							provide links to other online services (such as websites or
							social media platforms), and may include third-party
							features such as apps, tools, widgets and plug-ins. These
							online services and third-party features may operate
							independently from us. The privacy practices of the
							relevant third parties, including details on the
							information they may collect about you, are subject to the
							privacy statements of these parties, which we strongly
							suggest you review. To the extent any linked online
							services or third-party features are not owned or
							controlled by Quick Leap, we are not responsible for these
							third parties' information practices.
						</p>
					</div>
				</Element>
				<Element name="data-protection">
					<div className="min-h-[100px] pb-5 pt-10">
						<SectionHeader
							pageHeader="How We Protect Your Data"
							pageNumber={7}
						/>
						<p className="text-black text-sm font-normal mt-5">
							Quick Leap implements and maintains appropriate safeguards
							to protect Personal Data, taking into account in particular
							the risks to you, presented by unauthorised or unlawful
							processing or accidental loss, destruction of, or damage to
							your Personal Data.
						</p>
						<p className="text-black text-sm font-normal mt-5">
							Safeguarding will include the use of encryption and
							pseudonymisation where appropriate. It also includes
							protecting confidentiality (i.e. that only those who need
							to know and are authorised to use Personal Data have access
							to it), integrity and availability of the Personal Data. We
							regularly evaluate and test the effectiveness of those
							safeguards to ensure security of our processing of Personal
							Data.
						</p>
						<p className="text-black text-sm font-normal mt-5">
							You should be aware, however, that no method of
							transmission over the Internet or method of electronic
							storage is completely secure. While we strive to protect
							the security of your information and are constantly
							reviewing and enhancing our information security measures,
							we cannot guarantee absolute security. Quick Leap,
							therefore, accepts no liability for any damage or loss,
							however caused, in connection with transmission over the
							Internet or electronic storage.
						</p>
					</div>
				</Element>

				<Element name="personal-data">
					<div className="min-h-[100px] pb-5 pt-10">
						{" "}
						<SectionHeader
							pageHeader="Where We Store Your Personal Data"
							pageNumber={8}
						/>
						<p className="text-black text-sm font-normal mt-5">
							The Personal Data we collect from you may be transferred to
							and stored at a destination outside Nigeria. By submitting
							your Personal Data, you agree to this transfer, storing or
							processing. We will take all steps reasonably necessary to
							ensure that your Personal Data is treated securely with an
							appropriate level of protection and that the transfer is
							lawful.
						</p>
						<p className="text-black text-sm font-normal mt-5">
							We may share personal information with other corporate
							entities and affiliates to help detect and prevent identity
							theft, fraud, and other potentially illegal acts, correlate
							related or multiple accounts to avoid the abuse of our
							services, and facilitate joint or co-branded services that
							you request where such services are provided by more than
							one corporate entity. However, those entities and
							affiliates may not market to you due to sharing unless you
							explicitly opt-in.
						</p>
						<p className="text-black text-sm font-normal mt-5">
							We may disclose personal information if required to do so
							by law or in the good faith belief that such disclosure is
							reasonably necessary to respond to subpoenas, court orders,
							or other legal processes. In addition, we may disclose
							personal information to law enforcement offices, third
							party rights owners, or others in the good faith belief
							that such disclosure is reasonably necessary to: enforce
							our Terms or Privacy Policy; respond to claims that an
							advertisement, posting, or other content violates the
							rights of a third party; or protect the rights, property or
							personal safety of our users or the general public.
						</p>
						<p className="text-black text-sm font-normal mt-5">
							Quickleap.co and its affiliates will share some or all of
							your personal information with another business entity
							should we (or our assets) plan to merge with or be acquired
							by that business entity. Accordingly, should such a
							transaction occur, the other business entity (or the new
							combined entity) will be required to follow this privacy
							policy concerning the management of your personal
							information.
						</p>
					</div>
				</Element>
				<Element name="data-subject">
					<div className="min-h-[100px] pb-5 pt-10">
						<SectionHeader
							pageHeader="Your Rights As A Data Subject"
							pageNumber={9}
						/>
						<p className="text-black text-sm font-normal mt-5">
							The law gives you certain rights in respect to your
							Personal Data that we hold about you. Below is a highlight
							of some of those rights. At any point while we are in
							possession of or processing your Personal Data, you, the
							Data Subject has the following rights:
						</p>
						<ul className="mt-2 pl-5 space-y-2 list-disc">
							<li>
								<p className="text-black text-sm font-normal flex items-start justify-start">
									RIGHT OF ACCESS – You have the right to request a
									copy of the information that we hold about you . We
									will respond to your access request within one-month
									of receiving your request.Where we are not able to
									provide this personal data to you within the
									One-Month timeline provided by law, we will
									communicate the same to you, and may request an
									additional time within which we will provide the
									information to you. Your Personal Data shall be
									provided to you in a structured, commonly used and
									machine-readable format.
								</p>
							</li>
							<li>
								<p className="text-black text-sm font-normal flex items-start justify-start">
									RIGHT TO RECTIFY – You have the right to correct the
									Personal Data we hold about you that is inaccurate at
									any point in time.
								</p>
							</li>
							<li>
								<p className="text-black text-sm font-normal flex items-start justify-start">
									RIGHT TO BE FORGOTTEN – In certain circumstances you
									may ask for the data we hold about you to be erased
									from our records and where we do not have another
									legal basis for continuing the processing of your
									personal data, we will erase the same from our
									records.
								</p>
							</li>
							<li>
								<p className="text-black text-sm font-normal flex items-start justify-start">
									RIGHT TO RESTRICT PROCESSING – Where certain
									conditions apply, you have a right to restrict
									processing of your Personal Data.
								</p>
							</li>
							<li>
								<p className="text-black text-sm font-normal flex items-start justify-start">
									RIGHT TO PORTABILITY – You have the right to have
									your Personal Data transferred to another
									organisation and we will do the same upon receipt of
									your written instruction.
								</p>
							</li>
							<li>
								<p className="text-black text-sm font-normal">
									LODGE COMPLAINT – You have a right to lodge a
									complaint about the handling of your Personal Data
									with the Nigeria Data Protection Bureau (NDPB) at{" "}
									<strong className="font-normal text-sm text-green underline">
										info@ndpb.gov.ng
									</strong>
								</p>
							</li>
							<li>
								<p className="text-black text-sm font-normal flex items-start justify-start">
									RIGHT TO OBJECT – You have the right to object to the
									processing of your Personal Data. 
								</p>
							</li>
						</ul>

						<p className="text-black text-sm font-normal mt-5">
							If you want to remove your contact information from all
							quickleap.co lists and newsletters, please visit{" "}
							<strong className="font-normal text-sm text-green underline">
								https://www.quickleap.co/unsubscribe. 
							</strong>
						</p>
						<p className="text-black text-sm font-normal my-5">
							For emphasis see:
						</p>
						<h3 className="text-xl font-semibold">
							Your Rights and Choices
						</h3>
						<p className="text-black text-sm font-normal mt-5">
							We offer you certain choices in connection with the
							personal information we collect from you. To update your
							preferences, limit the communications you receive from us
							(such as product information, product samples, or
							promotional mailings/emails), or submit a request, please
							contact us as indicated in the{" "}
							<strong className="font-normal text-sm text-green underline">
								How To Contact Us
							</strong>{" "}
							section of this Privacy Statement. In addition, users of
							the Services are given the opportunity to opt out of having
							their information used for purposes not directly related to
							placement, processing, fulfilment or delivery of a product
							order at the point where we ask for the information. Also,
							if any of your personal information changes, or if you no
							longer desire to use the Services, we can correct, update,
							or remove the personal information provided to us. This can
							be done by sending an email with your new information to
							service@freshdirect.com, and specifying the information to
							be corrected, updated, or removed. Please do not send
							credit or debit card information via email. You also may
							update certain of your account details and settings by
							logging into your account on the Services. You can also
							unsubscribe from our marketing mailing lists by following
							the "Unsubscribe" link in our emails.
						</p>
					</div>
				</Element>
				<Element name="privacy-violation">
					<div className="min-h-[100px] pb-5 pt-10">
						<SectionHeader
							pageHeader="Violation of privacy"
							pageNumber={10}
						/>
						<p className="text-black text-sm font-normal mt-5">
							In the event of a breach of security leading to the
							accidental or unlawful destruction, loss, alteration,
							unauthorised disclosure of, or access to Personal Data, we
							shall within 72 (Seventy-Two) hours of having knowledge of
							such breach report the details of the breach to NDPB, in
							addition to such internal breach remediation process as we
							may activate to mitigate the breach.
						</p>
						<p className="text-black text-sm font-normal mt-5">
							Furthermore, where we ascertain that such breach is
							detrimental to your rights and freedoms in relation to your
							Personal Data, we shall within 7 (Seven) days of having
							knowledge of the occurrence of such breach take steps to
							inform you of the breach incident, the risk to your rights
							and freedoms resulting from such breach and any course of
							action to remedy said breach.
						</p>
						<p className="text-black text-sm font-normal mt-5">
							We have put in place procedures to deal with any suspected
							Personal Data breach and will notify you of any breach to
							your Personal Data and will let you know the steps we have
							taken to remedy the breach and the security measures we
							have applied to render your Personal Data unintelligible.
							If you know or suspect that a Personal Data breach has
							occurred, you should immediately contact the Quick Leap 
							team at https//:quickleap.com/contact us.
						</p>
						<p className="text-black text-sm font-normal mt-5">
							Quick Leap will not be responsible for any Personal Data
							breach which occurs as a result of:
						</p>
						<ul className="mt-2 pl-5 space-y-1 list-decimal">
							<li>
								<p className="text-black text-sm font-normal flex items-start justify-start">
									An event which is beyond the control of Quick Leap;
								</p>
							</li>
							<li>
								<p className="text-black text-sm font-normal flex items-start justify-start">
									An act or threats of terrorism;
								</p>
							</li>
							<li>
								<p className="text-black text-sm font-normal flex items-start justify-start">
									An act of nature (such as, but not limited to fires,
									explosions, earthquakes, drought, tidal waves and
									floods) which compromises Vendease’s data protection
									measures;
								</p>
							</li>
							<li>
								<p className="text-black text-sm font-normal flex items-start justify-start">
									War, hostilities (whether war be declared or not),
									invasion, act of foreign enemies, mobilisation,
									requisition, or embargo;
								</p>
							</li>
						</ul>
					</div>
				</Element>
				<Element name="marketing">
					<div className="min-h-[100px] pb-5 pt-10">
						<SectionHeader pageHeader="Marketing" pageNumber={11} />
						<p className="text-black text-sm font-normal mt-5">
							You can tell us whether or not you wish to be contacted for
							marketing purposes and, if so, how we can contact you. We
							will obtain this information from you when we collect your
							Personal Data and will ask you what kind of communication
							you would like to receive from us. You can opt-out from
							receiving marketing communications from us at any time by
							following the instruction below:
						</p>
						<ul className="mt-2 pl-5 space-y-1 list-disc">
							<li>
								<p className="text-black text-sm font-normal flex items-start justify-start">
									Email marketing: to opt-out from emails, use the
									unsubscribe link provided within any email you
									receive.
								</p>
							</li>
							<li>
								<p className="text-black text-sm font-normal flex items-start justify-start">
									Or, write us at{" "}
									<a
										href="mailto:my-success@quickleap.co"
										className="font-normal text-sm text-green underline cursor-pointer"
									>
										my-success@quickleap.co
									</a>{" "}
								</p>
							</li>
						</ul>

						<p className="text-black text-sm font-normal mt-5">
							Please be aware it may take up to 26 (twenty-six) days for
							your request to take effect. Please note you may still
							receive other important information about our product and
							services.
						</p>
					</div>
				</Element>
				<Element name="updates-to-privacy">
					<div className="min-h-[100px] pb-5 pt-10">
						<SectionHeader
							pageHeader="Updates to our privacy statement"
							pageNumber={12}
						/>
						<p className="text-black text-sm font-normal mt-5">
							We may update this Privacy Statement from time to time and
							without prior notice to you to reflect changes in our
							personal information practices. We will indicate at the top
							of the statement when it was most recently updated.
						</p>
					</div>
				</Element>

				<Element name="payment-processors">
					<div className="min-h-[100px] pb-5 pt-10">
						<SectionHeader
							pageHeader="Payment Processors and Partners"
							pageNumber={13}
						/>
						<p className="text-black text-sm font-normal mt-5">
							We partner with local Nigerian payment processors to
							process payments for your orders. We may share your payment
							information with our payment partners to facilitate the
							payment process. We may also share your personal
							information with our delivery partners to deliver your
							orders.
						</p>
						<p className="text-black text-sm font-normal mt-5">
							We partner with third-party service providers to help us
							deliver our services, such as hosting providers, customer
							service providers, and marketing providers. These service
							providers may have access to your personal information, but
							only to perform services on our behalf.
						</p>
					</div>
				</Element>
				<Element name="contact-us">
					<div className="min-h-[100px] pb-5 pt-10">
						<SectionHeader
							pageHeader="How to contact us"
							pageNumber={14}
						/>
						<p className="text-black text-sm font-normal mt-5">
							You can reach us at{" "}
							<a
								href="mailto:hello@quickleap.co"
								className="font-normal text-sm text-green underline cursor-pointer"
							>
								hello@quickleap.co
							</a>
							, or{" "}
							<a
								href="mailto:my-success@quickleap.co"
								className="font-normal text-sm text-green underline cursor-pointer"
							>
								my-success@quickleap.co
							</a>{" "}
							also through our website{" "}
							<Link
								to="https://www.quickleap.co"
								className="font-normal text-sm text-green underline cursor-pointer"
							>
								https://www.quickleap.co
							</Link>{" "}
							call us on +2348134860483 or simply send us a message on
							WhatsApp.
						</p>
					</div>
				</Element>
			</div>
		</div>
	);
};

export default PolicySection;
