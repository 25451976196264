import { BakeryIcon, CerealsIcon, CondimentsIcon, DairyIcon, FishIcon, FlourIcon, FreshProduceIcon, FrozenFoodsIcon, FruitsIcon, GrainsIcon, MealPrepIcon, MeatIcon, RecipeIcon, SpicesIcon, VegetablesIcon } from "../components/Icons";

export const sortByOptions = [
    {
      label: "New in",
      value: "new",
    },
  
    {
      label: "Price(Low to High)",
      value: "ascending",
    },
    {
      label: "Price(High to Low)",
      value: "descending",
    },
    {
      label: "Highest rated",
      value: "rating",
    },
  ];

  export const productCategoriesIcons = [
    { name: 'Fruits', icon: FruitsIcon  },
    { name: 'Vegetables', icon: VegetablesIcon  },
    { name: 'Fresh Produce', icon: FreshProduceIcon  },
    { name: 'Bakery & Confections', icon: BakeryIcon  },
    { name: 'Fish', icon: FishIcon  },
    { name: 'Cereals & Legumes', icon: CerealsIcon  },
    { name: 'Dairy Products', icon: DairyIcon  },
    { name: 'Condiments & Sauces', icon: CondimentsIcon  },
    { name: 'Meat', icon: MeatIcon  },
    { name: 'Grains', icon: GrainsIcon  },
    { name: 'Frozen Foods', icon: FrozenFoodsIcon  },
    { name: 'Flour, Powders & Semi-Powders', icon: FlourIcon  },
    { name: 'Spices', icon: SpicesIcon  },
    { name: 'Recipe', icon: RecipeIcon  },
    { name: 'Meal Prep', icon: MealPrepIcon  }
  ];


  export const extraLocations = [
    {
      state: 'lagos',
      locations: [
        "Alimosho",
        "Maryland",
        "Ilupeju",
        "Ifako-Ijaye",
        "Agege",
        "Apapa",
        "Epe",
        "Badagry",
        "Kosofe",
        "Berger",
        "Ogudu & GRA",
        "Gbagada",
        "Omole Estates",
        "Ikeja & GRA",
        "Amuwo-Odofin",
        "Ikordu",
        "Oshodi - Isolo",
        "Ikoyi",
        "Eti-osa",
        "Ojo",
        "Shomolu",
        "Lagos Island",
        "Lagos Mainland",
        "Mushin",
        "Surulere",
        "Lekki",
        "Victoria Island",
        "Magodo Estates",
        "Yaba",
        "Ajeromi-Ifelodun",
        "Ogba",
        "Ketu",
        "Ajah"
      ]
    }
  ]
  