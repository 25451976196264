import React from "react";
import Container from "../../Container";
import { BsArrowRight } from "react-icons/bs";
import { Link } from "react-router-dom";

const ProductSection = () => {
  return (
    <div className="w-full h-full bg-[#F5F5F5]">
      <Container className="px-5 py-10 lg:py-14">
        <div className="w-full text-center h-full lg:flex md:items-center md:justify-center lg:flex-col  space-y-4">
          <h2 className="font-semibold text-2xl md:text-3xl lg:text-4xl  leading-9 text-darkText">
            Our Products
          </h2>
          <p className="text-lightText leading-6 max-w-[400px] lg:max-w-lg mx-auto">
            We have tailored these products intentionally to serve the specific
            needs you have.
          </p>
        </div>
      </Container>
      <Container className="px-5 py-10 lg:py-14 grid grid-cols-1 lg:grid-cols-2 gap-10">
        {/*Collaborator*/}
        <Link
          to={"/collaborator"}
          className="relative group w-full bg-white hover:bg-purple xl:max-w-3xl rounded-3xl shadow-xl flex flex-col justify-between min-h-[28rem] cursor-pointer duration-500 transition ease-in-out overflow-hidden shadow-[#39393a1a]"
        >
          <div className="w-full space-y-4 px-5 pt-5 lg:pt-10 lg:px-10">
            <h2 className="text-purple group-hover:text-white font-bold text-[28px] md:text-[30px] xl:text-[36px] duration-500 transition ease-in-out">
              Collaborator Center
            </h2>
            <p className="w-full text-ash group-hover:text-white duration-500 transition ease-in-out md:max-w-[70%]">
              Collaborator helps you source and buy everything you need to
              create amazing food in one place, and sell everything you create
              in one place
            </p>
          </div>

          <div className="flex flex-col-reverse md:flex-row md:items-end gap-4 pl-5 lg:pl-10 mt-7">
            <div className="flex items-center space-x-3 mb-10 grow">
              <BsArrowRight
                size={22}
                className="text-purple group-hover:text-white duration-500 transition ease-in-out"
              />
              <h2 className="text-purple group-hover:text-white duration-500 transition ease-in-out">
                Collaborator
              </h2>
            </div>

            <div
              className="duration-500 transition self-end md:self-auto max-w-[70%] ease-in-out max-h-[233px] sm:max-w-[350px]
                relative translate-x-[110%] group-hover:translate-x-1"
            >
              <img
                src="/assets/images/collaborator.png"
                alt="Collabor"
                className="w-full h-full object-cover md:object-contain rounded-lg"
              />
            </div>
          </div>
        </Link>
        {/*Grow for life*/}
        <Link
          to="/grow-for-life"
          className="relative group w-full bg-white hover:bg-green xl:max-w-3xl rounded-3xl shadow-xl shadow-[#39393a1a] flex flex-col justify-between min-h-[28rem] cursor-pointer duration-500 transition ease-in-out overflow-hidden"
        >
          <div className="w-full space-y-4 px-5 pt-5 lg:pt-10 lg:px-10">
            <h2 className="text-green group-hover:text-white font-bold text-[28px] md:text-[30px] xl:text-[36px] duration-500 transition ease-in-out">
              Grow For Life
            </h2>
            <p className="w-full text-ash group-hover:text-white duration-500 transition ease-in-out md:max-w-[70%]">
              Subscribe for weekly or monthly delivery of super fresh food,
              proteins, and groceries.
            </p>
          </div>

          <div className="flex flex-col-reverse md:flex-row md:items-end gap-4 pl-5 lg:pl-10 mt-7">
            <div className="flex items-center space-x-3 mb-10 grow">
              <BsArrowRight
                size={22}
                className="text-green group-hover:text-white duration-500 transition ease-in-out"
              />
              <h2 className="text-green group-hover:text-white duration-500 transition ease-in-out">
                Grow For Life
              </h2>
            </div>

            <div
              className="duration-500 transition self-end md:self-auto max-w-[70%] ease-in-out max-h-[233px] sm:max-w-[350px]
                relative translate-x-[110%] group-hover:translate-x-1"
            >
              <img
                src="/assets/images/grow-for-life.png"
                alt="grow for life"
                className="w-full h-full object-cover md:object-contain rounded-lg"
              />
            </div>
          </div>
        </Link>

        {/*Inventory*/}
        <div className="relative group w-full bg-white hover:bg-blue xl:max-w-3xl rounded-3xl shadow-xl shadow-[#39393a1a] flex flex-col justify-between min-h-[28rem] cursor-pointer duration-500 transition ease-in-out overflow-hidden">
          <div className="w-full space-y-4 px-5 pt-5 lg:pt-10 lg:px-10">
            <h2 className="text-blue group-hover:text-white font-bold text-[28px] md:text-[30px] xl:text-[36px] duration-500 transition ease-in-out">
              Inventory Manager
            </h2>
            <p className="w-full text-ash group-hover:text-white duration-500 transition ease-in-out md:max-w-[70%]">
              A tool that enables food businesses to forward plan and make
              arrangements for their raw material needs to be fulfilled through
              timely delivery all-year round.
            </p>
          </div>

          <div className="flex flex-col-reverse md:flex-row md:items-end gap-4 pl-5 lg:pl-10 mt-7">
            <div className="flex items-center space-x-3 mb-10 grow">
              <h2 className="text-white bg-blue inline-block p-3 font-semibold px-4 rounded-full group-hover:text-white duration-500 transition ease-in-out">
                Coming soon
              </h2>
            </div>

            <div
              className="duration-500 transition self-end md:self-auto max-w-[70%] ease-in-out max-h-[233px] sm:max-w-[350px]
                relative translate-x-[110%] group-hover:translate-x-1"
            >
              <img
                src="/assets/images/inventory.png"
                alt="Collabor"
                className="w-full h-full object-cover md:object-contain rounded-lg"
              />
            </div>
          </div>
        </div>

        {/*MealPlanner*/}
        <div
          className="relative group w-full bg-white hover:bg-orange xl:max-w-3xl rounded-3xl 
            shadow-xl shadow-[#39393a1a] flex flex-col justify-between min-h-[28rem] cursor-pointer duration-500 
            transition ease-in-out overflow-hidden"
        >
          <div className="w-full space-y-4 px-5 pt-5 lg:pt-10 lg:px-10">
            <h2 className="text-orange group-hover:text-white font-bold text-[28px] md:text-[30px] xl:text-[36px] duration-500 transition ease-in-out">
              Meal Planner
            </h2>
            <p className="w-full text-ash group-hover:text-white duration-500 transition ease-in-out md:max-w-[70%]">
              A resource that aids individuals and groups in the organisation of
              home made meals for delivery or collection from local providers of
              indigenous and global cuisine
            </p>
          </div>

          <div className="flex flex-col-reverse md:flex-row md:items-end gap-4 pl-5 lg:pl-10 mt-7">
            <div className="flex items-center space-x-3 mb-10 grow">
              <h2 className="text-white bg-orange inline-block p-3 font-semibold px-4 rounded-full group-hover:text-white duration-500 transition ease-in-out">
                Coming soon
              </h2>
            </div>

            <div
              className="duration-500 transition self-end md:self-auto max-w-[70%] ease-in-out max-h-[233px] sm:max-w-[350px]
                relative translate-x-[110%] group-hover:translate-x-1"
            >
              <img
                src="/assets/images/meal planner.png"
                alt="Collabor"
                className="w-full h-full object-cover md:object-contain rounded-lg"
              />
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ProductSection;
